import React from "react";
import { withTranslation } from "react-i18next";
import {flatTruth} from "../util/utils";

const ErrorList = ({ t, f, id = f.key, skip = ["required"], errors = [] }) => {const fullKey = f.spec.i18nKey || (f.i18nPrefix + id);
    return <div>{ f.submittedOrDirty && [...errors, ...f.errors.filter(error => flatTruth(skip).indexOf(error.code) < 0)].map(error =>
        <p key={error.code} className="help-block" dangerouslySetInnerHTML={{__html: t([`${fullKey}.${error.code}`, `${id}.${error.code}`, error.code], error.args)}}>
        </p>
    )}</div>;
};
export default withTranslation()(ErrorList);