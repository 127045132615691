import React from "react";

import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {PcIdentification, PcIdentificationStatus} from "../redux/auth";
import IdentificationType from "../redux/IdentificationType";
import {ReduxState} from "../redux/redux";

interface StateProps {
    minAge: number;
    identification: PcIdentification;
}

type PcBindProps = StateProps;

const PcBind: React.FC<PcBindProps> = ({ identification, minAge }) => {
    const { t } = useTranslation();
    return <div className="row">
        <div className="col-md-6 col-md-offset-3">
            <h2 className="text-center">{ t("identification.pc.title") }</h2>
        </div>
        <div className="col-md-6 col-md-offset-3">
            { t("identification.pc." + identification.type, { minAge, ...identification }) }
        </div>
    </div>;
};

export default connect<StateProps, {}, {}, ReduxState>(state => ({
    minAge: state.settings.minAge,
    identification: state.auth.user
        ? state.auth.user.pcIdentification
        : { type: IdentificationType.CLUB, status: PcIdentificationStatus.NOT_IDENTIFIED }
}))(PcBind);