import axios from "axios";
import "bootstrap";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import queryString from "querystring";

import React from "react";
import ReactDOM from "react-dom";
import {initReactI18next} from "react-i18next";
import {Provider} from "react-redux";

import App from "./App";
import './bootstrap/theme.less';

import 'react-datepicker/dist/react-datepicker.css';
import './forms/react-datepicker.css';

import messages from "./i18n/messages.json";
import reduxStore from "./redux/redux";

import {Notifications} from "./util/Notifications";

// wiring i18next, axios and moment
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ["navigator"]
        },
        // lng: "ru",
        debug: true,
        fallbackLng: "en",
        resources: messages,
        returnEmptyString: false,
        returnNull: false,
        returnObjects: false,
        joinArrays: '',
        parseMissingKeyHandler: key => {
            console.log("Missing key: " + key);
            return key;
        }
    })
    .catch(err => console.log("i18n error: " + err));

i18next.on("languageChanged", lng => {
    console.log("Language changed to " + lng);
    moment.locale(lng);
    axios.defaults.headers.common["Accept-Language"] = lng;
});

axios.defaults.paramsSerializer = function (params) {
    // 'repeat' is default in query-string lib
    return queryString.stringify(params);
};


ReactDOM.render(
    <Provider store={reduxStore}>
        <Notifications>
            <App/>
        </Notifications>
    </Provider>,
    document.getElementById("root")
);
