import React from "react";

import {compose} from "redux";

import { ReduxState } from "../../redux/redux";

import { form, resultType } from "../../validation/FormController";
import axios from "axios";
import moment from "moment";
import NameBirthDateGenderForm from "../../components/NameBirthDateGenderForm";
import { Location } from 'history';

import {
    digitsOnly,
    email,
    eq, inn,
    maxLength,
    passwordValidation,
    required,
    ruPhone
} from "../../validation/functionalPlugins"
import AddressForm from "../../components/AddressForm";
import PassportForm from "../../components/PassportForm";
import IdentificationForm from "../../components/IdentificationForm";
import {connect, useSelector} from "react-redux";
import * as queryString from "query-string";

const i18nPrefix = "register.";
import { DesktopRegisterForm } from "../desktop/DesktopRegisterForm";
import { MobileRegisterForm } from "../mobile/MobileRegisterForm";
import AdditionalInfoForm from "../../components/AdditionalInfoForm";

type MobileRegisterFormProps = {
    f: resultType;
    mainUrl: string;
}

const CommonRegisterForm = (props: MobileRegisterFormProps) => {

    const { f, mainUrl } = props;
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    if (isMobile) {
        return <MobileRegisterForm mainUrl={mainUrl} f={f}/>;
    } else {
        return <DesktopRegisterForm f={f} mainUrl={mainUrl}/>
    }
};

export default compose(
    connect((state: ReduxState) => ({ minAge: state.settings.minAge, mainUrl: state.settings.mainUrl })),
    form({
        i18nPrefix,
        initialModel: ({ location }: { location: Location }) => (
            {
                identification:
                    {
                        type: queryString.parse(location.search).type,
                        callDate: moment(),
                        meetingDate: moment()
                    },
                citizenship: 'RUS'
            }),
        schema: (account: any, {t, minAge}: {t: Function; minAge: string}) => {
            const now = moment();
            const birthDate = account.birthDate
                ? moment(account.birthDate, "YYYY-MM-DD")
                : null;

            return {
                ...NameBirthDateGenderForm.schema(now, t, i18nPrefix, minAge),
                email: [
                    required(),
                    email(),
                    maxLength(255),
                    {
                        asyncValidators: (val: any) =>
                            axios.get("/api/register/emailInUse", {params: {email: val}})
                                .then(resp => resp.data && "emailInUse")
                    },
                    {
                        asyncValidators: (val: any) =>
                            axios.get("/api/register/emailTemplated", {params: {email: val}})
                                .then(async resp => {
                                     return !resp.data && "emailTemplated";
                                })
                    }
                ],
                phone: [
                    required(),
                    ruPhone(),
                    {
                        asyncValidators: (val: any) => axios.get("/api/register/phoneInUse", { params: { phone: val } })
                            .then(resp => resp.data && "phoneInUse")
                    }
                ],
                password: [
                    required(),
                    passwordValidation,
                ],
                passwordRepeat: [
                    required(),
                    eq(account.password)
                ],
                acceptRules: [
                    required(),
                    eq(true)
                ],
                ...PassportForm.schema(now, birthDate),
                ...AdditionalInfoForm.schema,
                ...AddressForm.schema,
                ...IdentificationForm.schema(account.identification.type),
            };
        }
    })
)(CommonRegisterForm);
