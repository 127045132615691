import React from 'react';
import './close.css';
import img from './lk_close.png';

class Close extends React.PureComponent {
    constructor(props) { 
       super(props);
    }
    render() {
        const {close} = this.props;
        return (<input type="image" className="close-button" src={ img } onClick={ close }/>);
    }
}

export default (Close);
