import React from "react";
import { useTranslation } from "react-i18next";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import CreateSessionButton from "../CreateSessionButton";

export type MobileGamesProps = {
    games: Array<string>;
    titleKey: string;
    readonly: any;
}

export function MobileSports(props: MobileGamesProps) {
    const { titleKey, games, readonly } = props;
    const { t } = useTranslation();
    return (
        <div>
            <MobileDualHeader firstHeader={t('play.title')} secondHeader={t(titleKey)}/>
            <div className="form-group">
                { games.map(game =>
                    <div key={game} className="col-md-4 text-center">
                        <CreateSessionButton game={game} disabled={readonly}/>
                    </div>
                ) }
            </div>
        </div>
    )

}
