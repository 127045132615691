import React from 'react';
import {useTranslation} from "react-i18next";
import {useProgressBar} from "../redux/loadingPage";
import {useDispatch} from "react-redux";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";

export const CongratulationMessage: React.FC = () => {
    const {t} = useTranslation();
    const {start, complete} = useProgressBar(useDispatch());

    function confirm() {
        start();
        axios.post("/api/bind/confirm").finally(() => {
            queryAndUpdateAuth();
            complete();
        });
    }

    return (<div className="text-center">
        <h2>{t("ecups.identification.title")}</h2>

        <div
            className="text-success"
            style={{fontWeight: "bold"}}
            dangerouslySetInnerHTML={{__html: t("ecups.identification.congratulation")}}
        />

        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={confirm} className="form-control btn btn-primary">
                    {t('ecups.goToPa')}
                </button>
            </div>
        </div>
    </div>)
}