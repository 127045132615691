import React from "react";

type EventListProps = {
    events: Array<any>
}

export const EventList = ({ events }: EventListProps) => {
    return (
        <ul>
            {events.map((e) =>
                <li key={e.id}>
                    { e.eventName }
                </li>
            )}
        </ul>
    );
};


