import React from "react";
import { withTranslation } from "react-i18next";
import "./channel-picture.less";
import {compose} from "redux";
import {confirm} from "../util/ConfirmDialog";
import {connect} from "react-redux";

class ChannelPicture extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {channel, onSelect, images, t, selectedChannel, disabled} = this.props;
        const mobilePrefix = this.props.isMobile ? 'mobile/' : '';
        const image = images["./" + mobilePrefix + channel.channel.toLowerCase() + ".png"];
        const active = selectedChannel !== null && selectedChannel.channel === channel.channel ? 'active' : '';
        const mobileActive = selectedChannel !== null && selectedChannel.channel === channel.channel ? '#D4D4D4' : 'initial';

        if (this.props.isMobile) {
            return (
                <div className="col-sm-3" style={{backgroundColor: mobileActive}}>

                    <div style={{display: "flex", alignItems: 'center'}} onClick={onSelect}>
                        <div style={{paddingRight: 5, minWidth: 25}}>
                            <img alt="" src={image} style={{width: '100%'}}/>
                        </div>
                        <div style={{display: "flex", flexDirection: 'column', width: '100%', borderBottom: '1px solid #BFBEBE', padding: 5}}>
                            <div style={{ textTransform: "capitalize"}}>{t("payment.channels.titles." + channel.channel.toLowerCase())}</div>
                            <div style={{display: "flex", opacity: '0.6', fontSize: 10, paddingTop: 5}}>
                                <div style={{display: "flex", flexGrow: 4, flexBasis: 0}}>
                                    {t("payment.amount.min") + ": " + channel.minAmount}
                                </div>
                                <div style={{display: "flex", flexGrow: 6, flexBasis: 0}}>
                                    {t("payment.amount.max") + ": " + channel.maxAmount}
                                </div>
                            </div>
                        </div>

                    </div>

                    {channel.fee != null && <div style={{ fontSize: '10px' }}>{t("payment.amount.fee") + ": " + channel.fee}</div>}
                </div>
            );
        } else {
            return (
                <div className="col-sm-3" style={{marginBottom:'30px'}}>
                    <button className={`btn btn-default ${active}`} onClick={onSelect} type="button" disabled={disabled}>
                        <img alt="" src={image} style={{width: '100%'}}/>
                        <div>
                            {/*<span>*/}
                            <div style={{fontSize: '10px'}}>
                                {t("payment.amount.minMax", {
                                    min: channel.minAmount !== null ? channel.minAmount : '-',
                                    max: channel.maxAmount != null ? channel.maxAmount : '-',
                                })}
                            </div>
                            {channel.fee != null && <div style={{ fontSize: '10px' }}>{t("payment.amount.fee") + ": " + channel.fee}</div>}
                            {/*</span>*/}
                        </div>
                    </button>
                </div>
            );
        }

    }

}

export default compose(
    withTranslation(),
    connect(state => ({isMobile: state.isMobileView})),
)(ChannelPicture);
