import React from "react";
import { withTranslation } from "react-i18next";
import ErrorList from "./ErrorList";
import ValidationInput from "./ValidationInput";
import classNames from "classnames";
import {validationClass} from "./utils";

const Input = ({t, f, id = f.key, autoComplete, type = "text", readOnly}) => {
    const fullKey = f.spec.i18nKey || (f.i18nPrefix + id);
    return <div className={classNames("form-group", !readOnly && validationClass(f))}>
    <div className="col-md-8">
        <ValidationInput id={id}
                         type={type}
                         value={f.view}
                         autoComplete={autoComplete}
                         onChange={e => f.onChange(e.target.value)}
                         placeholder={t([`${fullKey}.placeholder`, `${fullKey}.label`, `${id}.placeholder`, `${id}.label`])}
                         required={f.spec.required}
                         valid={f.valid}
                         readOnly={readOnly}
        />
        <ErrorList f={f} id={id}/>
    </div>
    </div>;
};

export default withTranslation()(Input);
