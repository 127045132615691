import React, { Component } from 'react';
import PropTypes from "prop-types";
import KvitokNewChessBoardETCDatum from './KvitokNewChessBoardETCDatum';
import ChessLinks from '../../commons/comps/chess/ChessLinks';
import PrintButton from '../../commons/comps/print-button/Print';
import CloseButton from '../../commons/comps/close-button/Close';
import { withTranslation } from "react-i18next";
import { dateFormatter } from "../../commons/DateTimeUtils";
import "./style.css";

class KvitokNew extends Component {

  constructor( props ) {
      super(props);
      this.state = {
        dataProvider: [{},{nosecond: true}],
        arrowsVisible: "visible"
      };
      this.leftShift1 = 5;
      this.leftShift2 = 220;
      this.leftShiftForOnlyOne = 110;
      this.chessLinks = new ChessLinks();
      this.rawsOnTheBoard = [];
      this.tempFEN = "r2qkbnr/pp2pppp/2b5/8/8/8/PPPP1PPP/RNBQK1NR w KQkq - 0 6";
      this.totalDataProvider = [];
      this.indexInTotalDataProvider = 0;
      this.templateOfDatum = {
        eventName: "F13",
        eventNumber: "123",
        chessBoardNumber: "12334",
        gameNumber: "112",
        stepNumber: "112",
        moveTime: "2223",
        sum: "1122",
        group: "1",
        symbol: "2",
        timeResult: "333",
        categoryOfCoeff: "asddad",
        timeChoose: "123",
        pieces: [],
        fen:"r2qkbnr/pp2pppp/2b5/8/8/8/PPPP1PPP/RNBQK1NR w KQkq - 0 6",
        leftShift: ""
      }
  }

  getLocaleString = (prefix, a, args) => this.props.t(prefix + "." + a, args);

  formattedDate = (date) => {
    const {dataForKvitok} = this.props;
    return dateFormatter(date, dataForKvitok.data[0].timezone, this.getLocaleString);
  };

  shouldComponentUpdate(nextProps) {
    return !(nextProps.dataForKvitok.data.length <= 0);
  }

  UNSAFE_componentWillMount() {
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.totalDataProvider = nextProps.dataForKvitok.data;
    if(nextProps.dataForKvitok.data.length <= 0) {
      let hollow = [{},{nosecond: true}];
      this.setState({dataProvider:hollow});
      return;
    }
    this.setState({dataProvider:this.prepareDataProvider(this.totalDataProvider, 0)});
    this.setState({arrowsVisible: this.totalDataProvider.length > 2 ? "visible" : "hidden"});
  }

  prepareDataProvider(totalDataProvider,indexToStartWith){
    let pr = [];
    let elem1;
    let elem2;
    if (totalDataProvider && totalDataProvider[indexToStartWith] && totalDataProvider.length > 0) {
      elem1 = totalDataProvider[indexToStartWith];
      let rawsOnTheBoard = this.boardPositionFEN(elem1.fen);
      elem1.pieces = rawsOnTheBoard;
      elem1.leftShift = this.leftShift1;

      if(totalDataProvider[indexToStartWith + 1]){
          elem2 = totalDataProvider[indexToStartWith + 1];
          let rawsOnTheBoard = this.boardPositionFEN(elem2.fen);
          elem2.pieces = rawsOnTheBoard;
          elem2.leftShift = this.leftShift2;
      }
      else {
        elem1.leftShift = this.leftShiftForOnlyOne;
        elem2 = {nosecond: true};
      }
      pr.push(elem1);
      pr.push(elem2);
    }
    return pr;
  }

  boardPositionFEN(value){
    let rawsOnTheBoard = [];

    let dividedBySpaces = value.split(' ');
    let boardSquares = (dividedBySpaces[0]).split('/');
    for (let i = 0; i < boardSquares.length; i++) {
      if(boardSquares[i] == "")
        continue;
      rawsOnTheBoard = this.createRawOfPieces(rawsOnTheBoard,boardSquares[i],i)
    }
    return rawsOnTheBoard;
  }

  createRawOfPieces(rawsOnTheBoard,rawString, rawNumber) {
    let arr = rawString.split("");
    let index = 0;

    for (let i in arr) {
      let piece = arr[i];
      let spacesInRaw = parseInt(piece);
      if (!isNaN(spacesInRaw)) {
        index = index + spacesInRaw;
        continue;
      }
      rawsOnTheBoard.push({
        link: this.chessLinks.links[piece],
        x: (this.chessLinks.sq_side*index)+'px',
        y: (this.chessLinks.sq_side*rawNumber)+'px',
        color: this.chessLinks.fontFigures[piece].color,
        letter: this.chessLinks.fontFigures[piece].letter
      });
      index++;
    }
    return rawsOnTheBoard;
  }

leftHandler = () => {
  this.indexInTotalDataProvider = this.indexInTotalDataProvider <= 0 || (this.indexInTotalDataProvider - 2 < 0)
    ? this.totalDataProvider.length - 1
    : this.indexInTotalDataProvider - 2;
  this.setState({dataProvider:this.prepareDataProvider(this.totalDataProvider, this.indexInTotalDataProvider)});
};

rightHandler = () => {
  this.indexInTotalDataProvider = (this.indexInTotalDataProvider >= (this.totalDataProvider.length - 1) || (this.indexInTotalDataProvider + 2 > (this.totalDataProvider.length - 1)))
    ? 0
    : this.indexInTotalDataProvider + 2;
  this.setState({dataProvider:this.prepareDataProvider(this.totalDataProvider, this.indexInTotalDataProvider)});
};

getStringDueToCurrency(number) {
    const {dataForKvitok, t} = this.props;
    const denomination = dataForKvitok.constData.denomination;
    const currencyType = dataForKvitok.constData.currencyType;
    let result;

    switch (currencyType){
      case "CREDITS":
          result = t("kvitok.credits",{CREDITS_RESULT:Number(number).toFixed(0)}) ;
          break;
      case "CURRENCY":
          result = t("kvitok.points", {POINTS_RESULT: Number(number * denomination).toFixed(2)} ) ;
          break;
      case "CURRENCY_CREDITS":
          result = t("kvitok.credits", {
            CREDITS_RESULT : Number(number).toFixed(0)
          }) + "(" + t("kvitok.points", { POINTS_RESULT: Number(number * denomination).toFixed(2)} )  + ")";
          break;
    }
    return result;
}

  render() {
    const {dataForKvitok, print, close, t, step} = this.props;
    const championship = dataForKvitok.details.rateDetails.championship;
    const tour = dataForKvitok.details.rateDetails.tour;
    const stepNo = step.id;
    return (
      <div className = "kvitokNewWrapper">
        <div className = "kvitokNew">
          <div className = "kvitokNewInner">
            <div className = "kvitokNewHeaderDiv">
              <PrintButton print={ print }/>
              <div className = "kvitokNewHeader1">{t("kvitok.kvitokNumber", {KVITNUMBER: dataForKvitok.constData.kvitNumber})}</div>
              <div className = "kvitokNewHeader2">{t("regularKvitok.subtitle" )}</div>
              <CloseButton close={ close }/>
            </div>

            <div className = "kvitokNewConstant">
                 <span>{t("kvitok.stepNumber") + " " + stepNo}</span><br/>
                 <span>{t("kvitok.eventName", {GAME_TYPE: dataForKvitok.constData.eventName})}</span><br/>
                 <span>{t("kvitok.championship", {championship: championship})}</span>
                <span>{t("kvitok.tour", {tour: tour})}</span>
                 <span >{t("kvitok.stepSize", {STEPSIZE: this.getStringDueToCurrency(this.props.dataForKvitok.constData.stepSizeCredits)})}</span><br/><br/>
                 <span >{t("kvitok.stepTime", {STEPTIME: this.formattedDate(dataForKvitok.constData.stepTime)})}</span><br/>
                 <span >{t("kvitok.resultTime", {RESULTTIME: this.formattedDate(dataForKvitok.constData.countTime)})}</span><br/>
                 <span >{(dataForKvitok.constData.creditsResult > 0)?
                   t("kvitok.resultStep", {CREDITS_RESULT: this.getStringDueToCurrency(dataForKvitok.constData.creditsResult)}):
                   t("kvitok.noresult")
                 }</span><br/>
            </div>

            <span className="kvitokNewTitleOfChangeble"></span>

            <div className="kvitokNewChangeble">
              <div className="kvitokNewChangebleInner">
                <div className="kvitokNewChessChangebleData">
                  <KvitokNewChessBoardETCDatum params={this.state.dataProvider[0]}/>
                  {(this.state.dataProvider[1].nosecond != true) ? <KvitokNewChessBoardETCDatum  params={this.state.dataProvider[1]}/>: <div></div>}
                  <div className="kvitokNewArrowLeft" onClick={this.leftHandler} style={{ visibility: this.state.arrowsVisible}}/>
                  <div className="kvitokNewArrowRight" onClick={this.rightHandler} style={{ visibility: this.state.arrowsVisible}}/>
                </div>

              </div>
              <p className="kvitokNewPageNumber" >{t("kvitok.page", {CURRENT_PAGE: (this.indexInTotalDataProvider/2+1), TOTAL_PAGES: Math.ceil(dataForKvitok.data.length/2)})}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

KvitokNew.childContextTypes = {
  assets: PropTypes.object,
  consts: PropTypes.object,
  sounds: PropTypes.object
};

KvitokNew.contextTypes = {
  assets: PropTypes.object,
  consts: PropTypes.object,
  sounds: PropTypes.object
};

export default withTranslation()(KvitokNew);