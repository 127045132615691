
import Path, {parsePathParts, parseIntOrThrow, makePathFromPathLikeValue} from "./Path";

export default class PathExpression {

    parts;

    constructor(parts) {
        this.parts = parts;
    }

    matches(p) {
        const path = Path.of(p);
        if (this.parts.length !== path.parts.length) {
            return false;
        }
        for (let i = 0; i < this.parts.length; i++) {
            if (this.parts[i] === null) {
                continue;
            }
            if (this.parts[i] !== path.parts[i]) {
                return false;
            }
        }
        return true;
    }

    list(obj, index = 0, parts = []) {
        const result = [];
        this.traverse(obj, (path, value) => result.push({ path: path, value: value}), index, parts);
        return result;
    }

    traverse(obj, callback, index = 0, parts = []) {

        while (index < this.parts.length) {

            const part = this.parts[index];
            if (part === null) {
                if (obj === null || obj === undefined) {
                    return;
                }
                if (Array.isArray(obj)) {
                    for (let i = 0; i < obj.length; i++) {
                        this.traverse(obj[i], callback, index + 1, parts.concat(i));
                    }
                } else {
                    Object.keys(obj).forEach(k => this.traverse(obj[k], callback, index + 1, parts.concat(k)));
                }
                return;
            }

            parts.push(part);
            if (obj !== null && obj !== undefined) {
                obj = obj[part];
            }
            ++index;

        }

        callback(Path.of(parts), obj);
    }

    static of(p) {
        if (p instanceof Path) {
            return PathExpression.of(p.parts);
        }
        return makePathFromPathLikeValue(PathExpression, p);
    }

    static parse(path) {
        return PathExpression.of(parsePathParts(path,
            (part, isIndex) => isIndex ? part.length === 0 ? null : parseIntOrThrow(part) : part));
    }
}