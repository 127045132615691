import React from "react";

import "./Tpl.css";
import Master from "./Master";
import {preloadGet, ReloadCallback} from "../util/preload";
import {useTranslation} from "react-i18next";
import {UsedeskWidget} from "../components/UsedeskWidget";
import {CookieDisclaimer} from "../cookie/CookieWidget";

interface Tpl {
    header: string;
    footer: string;
    footerDetails: string;
    css: string;
}

interface TplProps {
    tpls: {
        ru: Tpl,
        en: Tpl
    };
    reload: ReloadCallback;
}

const Tpl: React.FunctionComponent<TplProps> = ({ tpls }) => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru' || i18n.language === 'ru-RU';
    const getTpl = () => isRu ? tpls['ru'] : tpls['en'];
    const [tpl, setTpl] = React.useState(getTpl());
    React.useEffect(() => setTpl(getTpl), [i18n.language]);
    return (<div className="page_container">
        <UsedeskWidget/>
        <CookieDisclaimer mobile={false}/>
        {tpl ? (<>
            <style>{tpl.css}</style>
            <div dangerouslySetInnerHTML={{__html: tpl.header}}/>
            <div id="lk" style={{marginBottom: '150px'}}>
                <Master/>
            </div>
            <div dangerouslySetInnerHTML={{__html: tpl.footer}}/>
            <div dangerouslySetInnerHTML={{__html: tpl.footerDetails}}/>
        </>) : <Master/>}

    </div>);
};

export default preloadGet("/api/tpl", "tpls")(Tpl);
