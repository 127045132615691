import { default as H, History } from "history";
import React from 'react';
import {Link, LinkProps, match, NavLink, NavLinkProps, Redirect, RedirectProps, RouteComponentProps, withRouter} from "react-router-dom";
import LocationDescriptor = History.LocationDescriptor;

export const langUrlRegex = "[a-z]{2}";
export const urlPrefixRegex = `^\\/(${langUrlRegex})(?:\\/.*)?$`;

export function getLangFromUrl(url: string): string {
    const match = url.match(urlPrefixRegex);
    return match ? match[1] : "";
}

export function getUrlPrefix(url: string): string {
    let lang = getLangFromUrl(url);
    return lang ? "/" + lang : "";
}

export function up(match: match<any>): string {
    return getUrlPrefix(match.url);
}

export function withUp<T>(match: match<any>, to: H.LocationDescriptor<T> | ((location: H.Location<T>) => H.LocationDescriptor<T>), location: H.Location<T>):
    LocationDescriptor<T> {
    if (typeof to === "function") {
        return to(location);
    } else if (typeof to === "string") {
        return up(match) + to;
    } else {
        return { ...to, pathname: up(match) + to.pathname };
    }
}

const UPLinkImpl: React.FunctionComponent<LinkProps & RouteComponentProps> = ({ to, match, history, location, staticContext, ...rest }) =>
    <Link {...rest} to={withUp(match, to, location)}/>;

const UPNavLinkImpl: React.FunctionComponent<NavLinkProps & RouteComponentProps> =
    ({ to, match, history, location, staticContext, ...rest }) => <NavLink {...rest} to={withUp(match, to, location)}/>;

const UPRedirectImpl: React.FunctionComponent<RedirectProps & RouteComponentProps> =
    ({ to, match, history, location, staticContext, ...rest }) => <Redirect {...rest} to={withUp(match, to, location)}/>;

export const UPLink = withRouter(UPLinkImpl);
export const UPNavLink = withRouter(UPNavLinkImpl);
export const UPRedirect = withRouter(UPRedirectImpl);
