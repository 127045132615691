import React from 'react';
import {preventDefault} from "../util/utils";
import Countdown from "../util/Countdown";
import { withTranslation } from "react-i18next";
import {digitsOnly, exactLength, required} from "../validation/functionalPlugins";
import LabelInput from "../forms/LabelInput";
import moment from "moment";
import axios from "axios";
import {useProgressBar} from "../redux/loadingPage";
import {useDispatch} from "react-redux";

const ConfirmationResendButton = ({t, canResendCode, until, onTimeout, onResendRequest, i18nPrefix = 'resendButton'}) => {
    return (
        <div>
            <button type={'button'}
                    className="btn btn-default resend-button"
                    disabled={!canResendCode}
                    onClick={preventDefault(onResendRequest)}>
                {t(i18nPrefix + 'resendButton')}
                <span style={canResendCode ? {visibility: 'hidden'} : {}}>
                    <span> </span>(<Countdown until={until} onTimeout={onTimeout}/>)
                </span>
            </button>
        </div>
    );
}

const ConfirmationResend = (props) =>  {
    const {canResendAt, t, i18nPrefix} = props;
    const [canResendCode, setCanResendCode] = React.useState(false);
    const {start, complete} = useProgressBar(useDispatch());

    const onResendRequest = () => {
        const {resendUrl, resendParams, resendData, confirmationRequestResultExtractor, onTooManyAttempts, onResend} = props;
        start();
        axios.post(resendUrl, resendData || {}, {params: resendParams || {}}).then(resp => onResendResponse(
            confirmationRequestResultExtractor ? confirmationRequestResultExtractor(resp.data) : resp.data,
            onTooManyAttempts,
            onResend
        )).finally(complete);
    };

    const onTimeout = () => {
        const {onTimeout} = props;
        setCanResendCode(true)
        onTimeout && onTimeout(true);
    };

    const onResendResponse = (confirmationRequestResult, onTooManyAttempts, onResend) => {
        const {onTimeout} = props;
        setCanResendCode(false)
        onTimeout && onTimeout(false);

        if (confirmationRequestResult.status === 'TOO_MANY_ATTEMPTS') {
            onTooManyAttempts(moment(confirmationRequestResult.canResendAt));
        } else if (confirmationRequestResult.status === 'SUCCESS') {
            onResend(confirmationRequestResult.codeExpiresAt, confirmationRequestResult.canResendAt);
        }
    };

    return <div className="form-group">
        <ConfirmationResendButton
            t={t}
            i18nPrefix={i18nPrefix}
            canResendCode={canResendCode}
            until={canResendAt}
            onTimeout={onTimeout}
            onResendRequest={onResendRequest}
        />
    </div>;
}

class PhoneConfirmationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codeExpiresAt: this.props.confirmationRequestResult.codeExpiresAt,
            canResendAt: this.props.confirmationRequestResult.canResendAt,
            tooManyAttempts: this.props.confirmationRequestResult.status === 'TOO_MANY_ATTEMPTS',
            canRepeat: false
        }
    }

    render() {
        const {f, t, readOnly, resendUrl, resendParams, resendData, confirmationRequestResultExtractor, success} = this.props;
        const i18nPrefix = f.i18nPrefix;
        const {codeExpiresAt} = this.state;
        const now = moment();
        const expiredAt = moment(codeExpiresAt);
        const codeExpire = Math.max(now.diff(expiredAt), 0);

        let message = '';
        if (readOnly !== undefined || success !== undefined) {
            if (readOnly === Boolean(false) || success === Boolean(false)) {
                message = codeExpire ? 'restore.code.expire' : 'restore.code.notValid';
            }
        }
        return (
            <div>
                <LabelInput f={f("code")} autoFocus readOnly={readOnly} extraValid={message === ''}/>
                <div className="form-group col-md-12 error">
                    {t(message)}
                </div>
                <div className="form-group col-md-12">
                    {
                        this.state.tooManyAttempts
                            ? t(i18nPrefix + "code.tooManyAttempts")
                            : <div>
                                {t(i18nPrefix + "code.remaining")}
                                <span> </span>
                                <Countdown until={codeExpiresAt}/>
                            </div>
                    }
                </div>

                <div className="col-md-12">
                    <ConfirmationResend
                        t={t}
                        i18nPrefix={i18nPrefix}
                        resendUrl={resendUrl}
                        resendData={resendData}
                        resendParams={resendParams}
                        confirmationRequestResultExtractor={confirmationRequestResultExtractor}
                        canResendAt={this.state.canResendAt}
                        onTooManyAttempts={this.onTooManyAttempts}
                        onResend={this.onResend}
                        onTimeout={this.onTimeout}
                    />
                </div>

            </div>
        );
    }

    onTimeout = (canRepeat) => {
        this.setState(prev => ({...prev, canRepeat}))
    }

    onTooManyAttempts = (canResendAt) => {
        this.setState({tooManyAttempts: true, canResendAt, canRepeat: false})
    };

    onResend = (codeExpiresAt, canResendAt) => {
        this.setState({codeExpiresAt, canResendAt, canRepeat: false});
        this.props.onResent();
    };

}

export const schema = (isRequired, codeLength, invalidCode) => ({
    code: [
        required(isRequired),
        digitsOnly(),
        exactLength(codeLength)
    ]
});


export default withTranslation()(PhoneConfirmationForm);
