import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel-styles.css";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import img06 from '../logos/mobile/games/06.png';
import img07 from '../logos/mobile/games/07.png';
import img08 from '../logos/mobile/games/08.png';
import img09 from '../logos/mobile/games/09.png';
import img10 from '../logos/mobile/games/10.png';

export function MobileGames() {
    const { t } = useTranslation();
    return (
        <div>
            <MobileDualHeader firstHeader={t('play.title')} secondHeader={t('play.games.title')}/>
            <div>
                <Carousel
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={true}
                    infiniteLoop={true}
                    stopOnHover={false}
                    autoPlay={true}
                    transitionTime={3}>
                    <div>
                        <img src={img06} alt="06"/>
                    </div>
                    <div>
                        <img src={img07} alt="07"/>
                    </div>
                    <div>
                        <img src={img08} alt="08"/>
                    </div>
                    <div>
                        <img src={img09} alt="09"/>
                    </div>
                    <div>
                        <img src={img10} alt="10"/>
                    </div>
                </Carousel>

            </div>
        </div>
    );
}
