import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { up } from "../util/urlPrefix";
import { MasterRoutes } from "./device-independent-components/MasterRoutes";
import Header from "./Header";
import LangPicker from "./lang-picker/LangPicker";

type MasterProps = RouteComponentProps;

const Master: React.FunctionComponent<MasterProps> = ({match}) => {
    const urlPrefix = up(match);
    return <div>
        <div className="row">
            <div className="col-md-12">
                <Header/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <LangPicker/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="pa-page">
                    <MasterRoutes urlPrefix={urlPrefix}/>
                </div>
            </div>
        </div>
    </div>;
};

const MasterHOC = withRouter(Master);

export default MasterHOC;
