/*
 Функция cupis получает на входе объект с настройками (опциональный аргумент, задаёт пути для запросов. Дефолтные
 значения соответствуют настройкам боевого сервер ЕЦУПС, для тестовой среды можно задать произвольный набор)
 Объект, возвращаемый функцией, сожержит методы refill и withdraw.

 Оба метода принимают обязательный аргумент RequestId (Идентификатор платёжного запроса из API ЕЦУПС), и,
 опционально, коллбэки для успешного и неуспешного платежей:
 successCallBack - функция, вызывается при успешном окончании платежа,
 failCallBack - функция, вызывается при неуспешном платеже или при закрытии фрейма пользователем.

 Стандартый вызов фрейма:
 cupis().refill(requestId, successCallBack, failCallBack);

 */
export default function cupis(options) {
    var cupisOrigin = "", result = {}, siteUrl = {};

    function getUrl(pathName, defaultValue) {
        return cupisOrigin + (options && options[pathName] ? options[pathName] : defaultValue);
    }

    function payment(direction, rid) {

        if (typeof(rid) !== "string") {
            throw "error: CUPIS request started without Request ID";
        }
        window.location.assign(siteUrl[direction] + "?requestId=" + rid);
    }

    cupisOrigin      = getUrl("domain", "https://sportbet-ipay.itasystems.ru");
    siteUrl.refill   = getUrl("siteRefillPath", "/refill");
    siteUrl.withdraw = getUrl("siteWithdrawPath", "/withdraw");

    result.refill = function (rid, successCallBack, failCallBack) {
        return payment("refill", rid, successCallBack, failCallBack);
    };
    result.withdraw = function (rid, successCallBack, failCallBack) {
        return payment("withdraw", rid, successCallBack, failCallBack);
    };
    return result;
}