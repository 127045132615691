import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import classNames from "classnames";
import { resultType } from "../validation/FormController";
import ErrorList from "./ErrorList";
import {validationClass} from "./utils";

export type LabelCheckboxProps = {
    f: resultType;
    id?: any;
    label?: any;
    readOnly?: any;
}

function LabelCheckbox(props: LabelCheckboxProps): React.ReactElement {

    const { t } = useTranslation();
    const { f, label, readOnly} = props;
    const id = f.key;
    const fullKey = f.i18nPrefix + id;

    return (
        <div className={classNames("checkbox", validationClass(f))}>
            <label>
                <input id={id} name={id} type="checkbox" onChange={ e => f.onChange(e.target.checked) } checked={ f.view }
                       disabled={readOnly}/>
                {t(`${fullKey}.label`, `${id}.label`)}
            </label>
            <ErrorList f={ f } id={ id }/>
        </div>
    );

}

export default LabelCheckbox;
