import React from 'react';
import LabelInput from "../forms/LabelInput";
import PhoneConfirmationForm, {schema as confirmationSchema} from "../components/ConfirmationResend";
import {useTranslation} from "react-i18next";
import {form} from "../validation/FormController";
import {required, ruPhone} from "../validation/functionalPlugins";
import {useDispatch, useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import {useProgressBar} from "../redux/loadingPage";
import {CaptchaWidget} from "../captcha/Captcha";

const RestoreView = ({
                         f,
                         phone,
                         phoneNotInUse,
                         captchaResolved,
                         confirmationRequestResult,
                         success,
                         onRestoreRequest,
                         onVerifyRequest,
                         onCodeResent,
                         unknownNumber,
                         onChangeCallback,
                         onCaptchaResolved
}) => {

    const i18nPrefix = f.i18nPrefix;
    const isMobile = useSelector((state) => state.isMobileView);
    const {start, complete} = useProgressBar(useDispatch());
    const { t } = useTranslation();
    const phoneIsValid = phoneNotInUse === false;
    const when = (condition, makeEl) => condition ? makeEl() : null;

    const onSubmit = ({phone, code}) => {
        if (success) {
            return;
        }
        start()
        if (!phoneIsValid) {
            onRestoreRequest(phone, complete)
        } else {
            onVerifyRequest({phone, code}, complete)
        }
    };

    const captcha = () => {
        return <CaptchaWidget form="restore" verified={ok => onCaptchaResolved(ok)}/>
    }

    const restore = () => {
        return <form className="form-horizontal" onSubmit={f.submit(onSubmit)}>

            {when(!phoneIsValid, () =>
                <p>{t(i18nPrefix + 'tip')}</p>
            )}

            <LabelInput
                f={f('phone')}
                autoFocus
                readOnly={phoneIsValid}
                extraValid={!unknownNumber}
                onChangeCallback={onChangeCallback}
            />

            {when(phoneIsValid, () =>
                <div>
                    {
                        success
                            ? <div className="alert alert-success">
                                {t(i18nPrefix + 'success')}
                            </div>
                            : <div>
                                <PhoneConfirmationForm
                                    f={f}
                                    resendUrl="/api/restore/resend-code"
                                    resendParams={{phone}}
                                    isRequired={phoneIsValid}
                                    codeLength={6}
                                    confirmationRequestResult={confirmationRequestResult}
                                    onResent={onCodeResent}
                                    readOnly={success}
                                />
                                <p>{t(i18nPrefix + 'tip2')}</p>
                            </div>
                    }
                </div>
            )}

            {when(unknownNumber, () => (<div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="alert alert-danger">{t('restore.phone.unknownPhone')}</div>
                    </div>
                </div>)
            )}

            {/*{ when(!captchaResolved, () => captcha()) }*/}
            {/*{ captcha() }*/}

            <div className="form-group">
                <div className="col-md-8 col-md-offset-2">
                    <button className='btn btn-primary'
                            style={{width: '100%'}}
                            type="submit"
                            disabled={success || !(f.errors.length===0) || (phoneIsValid && (!f("code").value || f("code").value.length < 6))}>
                        {t(i18nPrefix + (phoneIsValid ? 'verifyButton' : 'restoreButton'))}
                    </button>
                </div>
            </div>
        </form>
    }

    return (
        <div>
            {isMobile && <MobileDualHeader firstHeader={t('restore.menu.title')} secondHeader={t(i18nPrefix + "title")}/>}
            {!isMobile && <h2 className="text-center">{t(i18nPrefix + 'title')}</h2>}

            <div className="row">
                <div className="col-md-6 col-md-offset-3">
                    { captchaResolved ? restore() : captcha() }
                </div>
            </div>
        </div>
    );
};

export default form({
    i18nPrefix: 'restore.',
    schema: (m, {phoneNotInUse, invalidCode}) => {
        const phoneIsValid = phoneNotInUse === false;

        return {
            phone: [
                required(),
                ruPhone(),
                {
                    validators: val => val && val === phoneNotInUse && "unknownPhone",
                }
            ],
            ...confirmationSchema(phoneIsValid, 6, invalidCode)
        }
    }
})(RestoreView);
