import React, {Component} from 'react';
import DialogView from "./PhoneDialogView";
import axios from "axios";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {setLoading, setUnmountDate} from "../redux/loadingPage";

class ChangePhoneDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneInUse: undefined,
            invalidPassword: undefined,
            invalidCode: undefined,
            success: undefined,
            changeRequestData: undefined,
            confirmationRequestResult: undefined
        };
    }

    render() {
        const {phoneInUse, invalidPassword, confirmationRequestResult, invalidCode, success, changeRequestData} = this.state;
        return <DialogView
            isOpen={this.props.isOpen}
            phoneInUse={phoneInUse}
            changeRequestData={changeRequestData}
            invalidPassword={invalidPassword}
            invalidCode={invalidCode}
            confirmationRequestResult={confirmationRequestResult}
            success={success}

            onRequestHide={this.props.onRequestHide}
            onChangeRequest={this.onChangeRequest}
            onSaveRequest={this.onSaveRequest}
            onCodeResent={this.onCodeResent}
        />;
    }

    onChangeRequest = (changeRequestData) => this.setState({changeRequestData}, () => {
        this.props.start();
        axios.post("/api/personal/change-phone", changeRequestData)
            .then(resp => resp.data)
            .then(data => this.setState({
                phoneInUse: data.phoneInUse ? changeRequestData.phone : false,
                invalidPassword: !data.passwordValid ? changeRequestData.password : false,
                confirmationRequestResult: data.confirmationRequestResult
            }))
            .finally(() => this.props.complete())
        }
    );

    onSaveRequest = (verifyRequestData) => {
        this.props.start();
        axios.post("/api/personal/verify-phone", verifyRequestData)
            .then(resp => resp.data)
            .then(data => {
                this.setState({
                    invalidCode: !data.ok ? this.state.verifyRequestData.code : false,
                    success: data.ok
                });
                if (data.ok) {
                    this.props.onRequestHide();
                    this.props.addNotification({level: 'success', message: this.props.t("personal.changePhoneDialog.success")});
                    this.props.refresh();
                }
            })
            .finally(() => this.props.complete());
    }

    onCodeResent = () => {
        this.setState({invalidCode: false});
        this.props.addNotification({level: 'success', message: this.props.t("personal.changePhoneDialog.resendSuccess")});
    };
}

export default withTranslation()(ChangePhoneDialog);
