import axios from "axios";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {CupisBindClientStatus, CupisIdentification, CupisIdentificationStatus, UserInfo} from "../redux/auth";
import IdentificationType from "../redux/IdentificationType";
import {ReduxState} from "../redux/redux";

import "./cupis-loader.css";
import {LockLoader} from "../loader/LockLoader";
import {queryAndUpdateAuth} from "../Auth";

interface StateProps {
    identificationType: IdentificationType;
    cupisIdentification: CupisIdentification;
    minAge: number;
}

type CupisBindProps = StateProps;

const CupisBind: React.FC<CupisBindProps> = ({ cupisIdentification: ci, identificationType, minAge }) => {
    const { t } = useTranslation();
    const passportErrors = [1705, 1804, 1805];

    function identify() {
        axios.post("/api/bind/identify/");
    }

    useEffect(() => {
        if (ci.status === CupisIdentificationStatus.PHONE_CONFIRMED) {
            identify();
        }
    }, []);

    const clarifyPD = () => {
        axios.post("/api/bind/check-personal-data/")
            .then(() => queryAndUpdateAuth())
            .then(() => console.log('CLARIFICATION'))
    }


    const isPassportError = (codeNum: number) => {
        return passportErrors.includes(codeNum);
    }

    const Identify = <div>
        <h2 className="text-center">{ t("ecups.identification.title") }</h2>
        {
            !ci.codeNum &&
            <div>
                <div className="text-center" style={{fontWeight: "bold"}}>{ t("ecups.identification.inProcess") }</div>
                <LockLoader/>
            </div>
        }
        {
            ci.codeNum &&
            <div>
                <div className="row">
                    <div className="col-md-offset-3 col-md-6 text-danger text-center" style={{fontWeight: "bold"}}>
                        { t(`ecups.error.label`) }: { t('ecups.error.' + ci.errorCode, {code: ci.codeNum}) }
                    </div>
                </div>
                {
                    !isPassportError(ci.codeNum) && <div className="row" style={{marginTop: 20}}>
                        <div className="col-md-offset-4 col-md-4 text-center">
                            <button type="button" onClick={() => identify()} className="form-control btn btn-primary">
                                {t('ecups.identification.reIdentify')}
                            </button>
                        </div>
                    </div>
                }
                {
                    isPassportError(ci.codeNum) && <div className="row" style={{marginTop: 20}}>
                        <div className="col-md-offset-4 col-md-4 text-center">
                            <button type="button" onClick={() => clarifyPD()} className="form-control btn btn-primary">
                                {t('ecups.identification.clarifyPD')}
                            </button>
                        </div>
                    </div>
                }
            </div>
        }
    </div>;

    const ErrorRebind = (<div className="text-center">
        <h2>{ t("ecups.identification.title") }</h2>
        <div
            className="text-danger"
            style={{fontWeight: "bold"}}
            dangerouslySetInnerHTML={{__html: t("identification.cupis.status." + ci.clientStatus, {error: ci.codeNum})}}
        />

    </div>)
    const SuccessRebind = (<div className="text-center">
        <h2>{t("ecups.identification.title")}</h2>
        <div dangerouslySetInnerHTML={{__html: t("identification.cupis.status." + ci.clientStatus)}}/>
    </div>)
    switch (ci.clientStatus) {
        // case CupisBindClientStatus.DELETED_BY_CUPIS:
        //     return Rebind;
        case CupisBindClientStatus.REBIND_TIMEOUT_ERROR_FOR_DELETED_CLIENT:
        case CupisBindClientStatus.REBIND_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION:
        case CupisBindClientStatus.NOTIFICATION_TIMEOUT_ERROR_FOR_DELETED_CLIENT:
        case CupisBindClientStatus.NOTIFICATION_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION:
        // case CupisBindClientStatus.ERROR_REBIND_FOR_DELETED_CLIENT:
        // case CupisBindClientStatus.ERROR_REBIND_FOR_FIRST_REGISTRATION:
        // case CupisBindClientStatus.REBIND_ERROR_FOR_DELETED_CLIENT:
        // case CupisBindClientStatus.REBIND_ERROR_FOR_FIRST_REGISTRATION:
            return ErrorRebind;
        case CupisBindClientStatus.SUCCESS_REBIND_FOR_DELETED_CLIENT:
            return SuccessRebind;
        default:
            return Identify;
    }
};

export default connect<StateProps, {}, {}, ReduxState>(state => {
    const user = state.auth.user as UserInfo;
    return {
        minAge: state.settings.minAge,
        identificationType: user.pcIdentification.type,
        cupisIdentification: user.cupisIdentification
    } as StateProps;
})(CupisBind);
