

export default class ParseResult {

    value;
    errors;

    constructor(value, errors = []) {
        this.value = value;
        this.errors = errors;
    }

    static withValue(value) {
        return new ParseResult(value, []);
    }

    static withErrors(errors) {
        return new ParseResult(undefined, errors);
    }

}