import React from 'react';
import {useTranslation} from "react-i18next";
import {UPNavLink} from "../util/urlPrefix";
import axios from "axios";

const GuestPanel: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [isRegistrationVisible, setIsRegistrationVisible] = React.useState<boolean>(false);

    React.useEffect( () => {
        axios.get("/api/settings/isFormRegistrationVisible").then(response => setIsRegistrationVisible(response.data.value));
    }, []);

    return <div>
        {isRegistrationVisible && <UPNavLink to="/register" className="btn btn-link">{ t("register.title") }</UPNavLink>}
        <UPNavLink to="/login" className="btn btn-link">{ t("login.title") }</UPNavLink>
    </div>;
};

export default GuestPanel;