import React from 'react';
import { useSelector } from "react-redux";
import LabelInput from "../forms/LabelInput";
import LabelDateInput from "../forms/LabelDateInput";
import { ReduxState } from "../redux/redux";
import ScanUpload from "../register/ScanUpload";
import { resultType } from "../validation/FormController";
import {
    date,
    digitsOnly,
    exactLength,
    fileBase64,
    fileMaxSize,
    fileMimeType,
    maxDate,
    minDate,
    isFirstJanuary,
    pattern,
    required
} from "../validation/functionalPlugins";
import moment from "moment";
import ReadOnly from "../forms/ReadOnly";
import { blockedConditionally } from "../forms/utils";
import classNames from "classnames";

type PassportFormProps = {
    f: resultType,
    t: Function,
    blockedFields?: any[],
    readOnly?: boolean
}

const PassportForm = (props: PassportFormProps) => {
    const {t, f, readOnly, blockedFields} = props;
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const citizenship = f.model.citizenship === 'RUS' ? t("personal.citizenship.RUS") : f.model.citizenship;
    const part1 = (
        <div>
            <div className="form-group">
                <label className={ classNames("col-md-4", "control-label") }>{t("personal.citizenship.label")}</label>
                <div className="col-md-8">
                    <input value={citizenship} className="form-control control-for-read" readOnly={true}/>
                </div>
            </div>
            <ReadOnly readOnly={readOnly}>
                <LabelInput f={f("series")} blockedConditionally={blockedConditionally('series', blockedFields)}/>
                <LabelInput f={f("number")} blockedConditionally={blockedConditionally('number', blockedFields)}/>
                <LabelDateInput f={f("issueDate")} blockedConditionally={blockedConditionally('issueDate', blockedFields)}/>
            </ReadOnly>
        </div>
    );

    const part2 = (
        <ReadOnly readOnly={readOnly}>
            <LabelInput f={f("birthPlace")} blockedConditionally={blockedConditionally('birthPlace', blockedFields)}/>
            <LabelInput f={f("subdivisionCode")} blockedConditionally={blockedConditionally('subdivisionCode', blockedFields)}/>
            <LabelInput f={f("issuer")} blockedConditionally={blockedConditionally('issuer', blockedFields)} maxLength={200}/>
        </ReadOnly>
    );

    const readOnlyLayout = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    {part1}
                </div>
                <div className="col-md-6">
                    {part2}
                </div>
            </div>
        );
    };

    const normaLayout = () => {
        return (
            <div className="row">
                {/*<div className="col-md-6" readOnly={readOnly}>*/}
                <div className="col-md-6" aria-readonly={readOnly}>
                    {part1}
                    {part2}
                </div>
                <div className="col-md-6">
                    <div className="info-box">
                        <p>{t(f.i18nPrefix + "uploadTips")}</p>
                        <ScanUpload f={f("mainPageUrl")}/>
                        <ScanUpload f={f("altPageUrl")}/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {isMobile ? <span>{t(f.i18nPrefix + "passportTitle")}</span> : <h4 className="text-center">{t(f.i18nPrefix + "passportTitle")}</h4>}
                </div>
            </div>
            {readOnly ? readOnlyLayout() : normaLayout()}
        </div>
    );
};


PassportForm.schema = (now: any, birthDate: any) => {
    let minIssueDate = null;

    if (birthDate) {
        const age = moment(now).diff(birthDate, "y");
        const passportChangeAges = [14, 20, 45];
        minIssueDate = moment(birthDate).add(passportChangeAges.reduce((cur, next) => age >= next ? next : cur), "y");
    } else {
        minIssueDate = moment({year: moment().year()-18, month: moment().month(), day: moment().days()});
    }

    return {
        "citizenship": [
            required()
        ],
        "series": [
            required(),
            exactLength(4),
            digitsOnly()
        ],
        "number": [
            required(),
            exactLength(6),
            digitsOnly()
        ],
        "issueDate": [
            required(),
            date(),
            maxDate(now),
            minDate(minIssueDate),
            isFirstJanuary()
        ],
        "issuer": required(),
        "birthPlace": required(),
        "subdivisionCode": [
            required(),
            pattern(/^\d{3}-\d{3}$/),
            {
                beautifiers: (val: any) => {
                    let result = "";
                    for (let ch of val.replace("-", "")) {
                        if (result.length === 3) {
                            result += "-";
                        }
                        result += ch;
                    }
                    return result;
                }
            }
        ],
        "mainPageUrl": [
            required,
            fileBase64,
            fileMimeType("image/jpeg"),
            fileMaxSize(5, "MB")
        ],
        "altPageUrl": [
            required,
            fileBase64,
            fileMimeType("image/jpeg"),
            fileMaxSize(5, "MB")
        ]
    }
};

export default PassportForm;
