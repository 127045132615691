import React from 'react';
import ReactModal from "react-modal";
import {withTranslation} from "react-i18next";
import {form} from "../validation/FormController";
import LabelInput from "../forms/LabelInput";
import {eq, noeq, passwordValidation, required} from "../validation/functionalPlugins";
import {compose} from "redux";
import {useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import ValidationError from "../validation/ValidationError";

const PasswordDialogView = ({
                                t, f,
                                isOpen,
                                success,
                                invalidPassword,
                                maxAttemptsReached,
                                onRequestHide,
                                onChangeRequest,
                            }) => {
    const tr = (key) => t(f.i18nPrefix + key);
    const isMobile = useSelector((state) => state.isMobileView);
    const onSubmit = (request) => {
        if (!success) {
            onChangeRequest(request);
        }
    };

    function footerInner() {
        if (!isMobile) {
            return <footer>
                <button className='btn btn-primary'>
                    {tr('changeButton')}
                </button>
            </footer>;
        } else {
            return <footer>
                <button className='btn btn-primary' style={{width: '100%', marginBottom: 15}}>
                    {tr('changeButton')}
                </button>
                <button onClick={onRequestHide} className='btn btn-primary' style={{width: '100%'}}>
                    {tr('cancel')}
                </button>
            </footer>;
        }
    }

    function renderInner() {
        return <form className="form-horizontal" onSubmit={f.submit(onSubmit)}>
            <div className="form-group row modal-window" style={{minWidth: '500px'}}>
                {!isMobile && <header>
                    <button onClick={onRequestHide}>
                        <span className="glyphicon glyphicon-remove"/>
                    </button>
                    <h2>{tr('title')}</h2>
                </header>}
                <div>
                    <LabelInput
                        f={f('password')}
                        autoFocus
                        type="password"
                        extraValid={!invalidPassword}
                        errors={invalidPassword ? [new ValidationError('invalidPassword')] : []}
                    />
                    <LabelInput f={f('newPassword')} type="password"/>
                    <LabelInput f={f('newPasswordRepeat')} type="password"/>

                    {
                        success
                            ? <div className="alert alert-success">
                                {tr('success')}
                            </div>
                            : null
                    }
                    {
                        maxAttemptsReached
                            ? <div className="alert alert-danger">
                                {tr('maxAttemptsReached')}
                            </div>
                            : null
                    }
                </div>
                {footerInner()}
            </div>
        </form>

    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
            {renderInner()}
        </div>;
    } else {
        return <div>
            <ReactModal isOpen={isOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={onRequestHide}>
                {renderInner()}
            </ReactModal>
        </div>
    }
};

const formDef = {
    i18nPrefix: 'personal.changePasswordDialog.',
    schema: (m) => (
        {
            password: [
                required(),
            ],

            newPassword: [
                required(),
                passwordValidation,
                noeq(m.password)
            ],

            newPasswordRepeat: [
                required(),
                eq(m.newPassword)
            ],
        }
    )
};

export default compose(
    withTranslation(),
    form(formDef),
)(PasswordDialogView);
