import React from 'react';
import {useTranslation} from "react-i18next";
// @ts-ignore TODO add types react-table right version
import "react-table/react-table.css";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../redux/redux";
import NumberFormat from "../../util/NumberFormat";
import {DesktopAccountDetails} from "../desktop/DesktopAccountDetails";
import "../../icons/pa-icons.css";
import {MobileAccountDetails} from "../mobile/MobileAccountDetails";
import {useProgressBar} from "../../redux/loadingPage";
import axios from 'axios';

export enum PlayerPhaseType {
    PREPARED,
    ACTIVE,
    DELETE,
    JACKBET,
    CLOSING_PROCESS,
    CLOSED
}

export type AccountDetails = {
    id: string;
    receiptNo: string;
    money: number;
    reserve: number;
    lifePhase: PlayerPhaseType;
    jackBet: boolean;
}

function AccountDetails() {
    const [accounts, setAccounts] = React.useState<Array<AccountDetails>>([]);
    const [localLoading, setLocalLoading] = React.useState<boolean>(false);
    const {start, complete} = useProgressBar(useDispatch());
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const loadingPage = useSelector((state: ReduxState) => state.loading.loading);
    React.useEffect(() => {
        if (!loadingPage && !localLoading) {
            start();
            axios.get('/api/account/details').then(resp => setAccounts(resp.data))
                .finally(() => {
                    complete();
                    setLocalLoading(true);
                });
        }
    }, [loadingPage, localLoading]);
    const {t} = useTranslation();

    const renderMoney = (row: any) => {
        const tooltip = row.original.jackBet ? t("details.jackBetTooltip") : undefined;
        return <NumberFormat style="currency" title={tooltip} currency="RUB" value={row.value}/>
    };

    const renderReserveMoney = (row: any) => {
        const tooltip = row.value > 0 ? t("details.reserveTooltip") : undefined;
        return <NumberFormat style="currency" title={tooltip} currency="RUB" value={row.value}/>
    };

    const renderReceiptNo = (row: any) => {
        return <div>
            {
                row.original.jackBet && <span className="pa-icon pa-icon-jackbet" style={{marginRight: "50px"}}/>
            }
            {row.value}
        </div>
    };

    const makeColumns = () => {

        const i18nKeyPrefix = 'details.table.headers.';

        return [{
            Header: t(i18nKeyPrefix + 'receiptNo'),
            accessor: 'receiptNo',
            Cell: renderReceiptNo,
            className: 'text-right'
        }, {
            Header: t(i18nKeyPrefix + 'balance'),
            accessor: 'money',
            Cell: renderMoney,
            className: 'text-right'
        }, {
            Header: t(i18nKeyPrefix + 'reserve'),
            accessor: 'reserve',
            Cell: renderReserveMoney,
            className: 'text-right'
        }]
    };
    return isMobile ?
        <MobileAccountDetails accounts={accounts}/>
        : <DesktopAccountDetails accounts={accounts} columns={makeColumns()}/>
}

export default (AccountDetails);
