import React, {Component} from 'react';
import PasswordDialogView from "./PasswordDialogView";
import axios from "axios";
import {compose} from "redux";
import {connect} from "react-redux";
import {setLoading, setUnmountDate} from "../redux/loadingPage";

class ChangePasswordDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailInUse: undefined,
            invalidPassword: false,
            success: undefined,
            maxAttemptsReached: undefined
        };
    }

    render() {
        return <PasswordDialogView
            isOpen={this.props.isOpen}
            invalidPassword={this.state.invalidPassword}
            success={this.state.success}
            maxAttemptsReached={this.state.maxAttemptsReached}
            onRequestHide={this.props.onRequestHide}
            onChangeRequest={this.onChangeRequest}
        />;
    }

    onChangeRequest = (changeRequestData) => {
        this.props.setUnmountDate({unmountDate: new Date(), loading: true});
        axios.post("/api/personal/change-password", changeRequestData)
            .then(resp => resp.data)
            .then(data => this.setState({
                invalidPassword: !data.passwordValid,
                maxAttemptsReached: data.maxAttemptsReached,
                success: data.ok
            }))
            .finally(() => {
                this.props.setUnmountDate({unmountDate: new Date(), loading: false});
                this.props.setLoading({loading: false});
            });
    };
}

export default compose(
    connect(null, dispatch => ({
        setUnmountDate: (props) => dispatch(setUnmountDate(props)),
        setLoading: (props) => dispatch(setLoading(props)),
    }))
)(ChangePasswordDialog);
