import React from "react";

import ReactDatePicker from "react-datepicker";
import "./react-datepicker.css";

import { withTranslation } from "react-i18next";

import 'react-datepicker/dist/react-datepicker.css';
import classNames from "classnames";
import moment from "moment";
import ErrorList from "./ErrorList";
import {validationClass} from "./utils";
import ValidationInput from "./ValidationInput";

import PropTypes from "prop-types";
import LabelFor from "./LabelFor";

function DatePickerInput(props) {
    const {
        t,
        f,
        id = f.key,
        readOnly,
        blockedConditionally = false,
        skipErrors,
        showTimeSelectOnly = false,
        showLabel = true,
        withManual = true
    } = props;
    const fullKey = f.i18nPrefix + id;
    const isDate = () => f.view.length === 10 && !showTimeSelectOnly;
    const isTime = () => f.view.length === 5 && showTimeSelectOnly;
    let selected = f.view && (isDate() || isTime()) ? moment(f.view, f.spec.dateFormat) : null;
    if (selected && !selected.isValid()) {
        selected = null;
    }

    const placeholder = t([`${fullKey}.placeholder`, `${fullKey}.label`, `${id}.placeholder`, `${id}.label`]);
    const colSize = `col-md-${showLabel ? '8' : '12'}`;
    return <div className={classNames("form-group", !readOnly && validationClass(f))}>
        {showLabel && <LabelFor t={t} id={id} translateKey={fullKey} required={f.spec.required && !readOnly}/>}

        <div className={colSize}>
            {
                readOnly
                    ? <ValidationInput placeholder={placeholder}
                                       { ...(selected && {value: selected.format(f.spec.dateFormat)})}
                                       blockedConditionally={ blockedConditionally }
                                       readOnly/>
                    : <div>
                        <ReactDatePicker id={id}
                                         placeholderText={placeholder}
                                         showYearDropdown
                                         showMonthDropdown
                                         showTimeSelect={showTimeSelectOnly}
                                         showTimeSelectOnly={showTimeSelectOnly}
                                         timeIntervals={1}
                                         {...(f.spec.minDate && {minDate: f.spec.minDate})}
                                         {...(f.spec.maxDate && {maxDate: f.spec.maxDate})}
                                         dateFormat={f.spec.dateFormat}
                                         timeFormat="HH:mm"
                                         selected={selected}
                                         onChange={val => f.onChange(val ? val.format(f.spec.dateFormat) : null)}
                                         onChangeRaw={evt => f.onChange(evt.target.value)}
                                         required={f.spec.required}
                                         customInput={<ValidationInput valid={f.valid} showTimeSelectOnly={showTimeSelectOnly}/>}
                                         onKeyDown={(e) => {
                                             if (!withManual) {
                                                 e.preventDefault();
                                             }
                                         }}/>
                        <ErrorList f={f} id={id} skip={skipErrors}/>
                    </div>
            }
        </div>
    </div>;
}

DatePickerInput.propTypes = {
    t: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    id: PropTypes.string,
    blockedConditionally: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default withTranslation()(DatePickerInput);
