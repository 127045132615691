import {Reducer} from "redux";

export interface UnmountDate {
    unmountDate: Date;
    loading: boolean;
}

export interface Loading {
    loading: boolean;
}

export interface SetUnmountDateAction {
    type: "SET_UNMOUNT_DATE";
    props: UnmountDate;
}

export interface SetLoadingAction {
    type: "SET_LOADING";
    props: Loading;
}

export function setUnmountDate(props: UnmountDate): SetUnmountDateAction {
    return {
        type: "SET_UNMOUNT_DATE",
        props
    };
}

export function setLoading(props: Loading): SetLoadingAction {
    return {
        type: "SET_LOADING",
        props
    };
}

export const unmountDateDefault: UnmountDate = {unmountDate: new Date(), loading: false};
export const unmountDateReducer: Reducer<UnmountDate, SetUnmountDateAction> = (state, action) => {
    return action.type === "SET_UNMOUNT_DATE" ? action.props : state || unmountDateDefault;
};

export const loadingDefault: Loading = {loading: false};
export const loadingReducer: Reducer<Loading, SetLoadingAction> = (state, action) => {
    return action.type === "SET_LOADING" ? action.props : state || loadingDefault;
};

export const useProgressBar = (dispatch: any) => {
    const start = () => dispatch(setUnmountDate({unmountDate: new Date(), loading: true}));
    const complete = () => {
        dispatch(setUnmountDate({unmountDate: new Date(), loading: false}));
        dispatch(setLoading({loading: false}));
    };
    return {start, complete};
};
