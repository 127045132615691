import React from "react";
import { useRouteMatch } from "react-router";
import { UserInfo } from "../../redux/auth";
import NumberFormat from "../../util/NumberFormat";
import {up, UPNavLink} from "../../util/urlPrefix";
import { SidebarItem } from "./SidebarItem";
import { getUserName } from "../../master/UserPanel"
import {useTranslation} from "react-i18next";
import axios from "axios";

export function AuthorithedSidebarItems({user}: {user: UserInfo} ) {

    const match = useRouteMatch();
    const {t} = useTranslation();
    const [games, setGames] = React.useState<string>('');
    React.useEffect( () => {
        axios.get("/api/play/categories").then(response => {
            setGames(response.data);
        });
    }, []);

    const getBasicGamesMenuItem = () => {
        return games.includes("BASIC") &&
            <SidebarItem setHeader={t('play.title')} value={t('play.games.title')} href={`${up(match)}/play/games`}/>
    }

    const getSportGamesMenuItem = () => {
        return games.includes("SPORT") &&
            <SidebarItem setHeader={t('play.title')} value={t('play.sport.title')} href={`${up(match)}/play/sport`}/>
    }
    return (
        <>
            <SidebarItem value={
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: "space-between",
                    textTransform: "initial"
                }}>
                    <div style={{display: 'flex'}}>
                        <div className="pa-icon pa-icon-profile" style={{ minWidth: 18, height: 18 }}/>
                        <div>{getUserName(user)}</div>
                    </div>
                </div>
            } href={`${up(match)}/personal`}/>
            {user.balance !== null && <SidebarItem value={<div style={{ display: "flex" }}>
                <div className="pa-icon pa-icon-balance" style={{ minWidth: 18, height: 18 }}/>
                <div><NumberFormat style="currency" currency="RUB" value={ user.balance }/></div>
            </div>}>
                <SidebarItem setHeader={ t('payment.title.management') } value={t('details.title')} href={`${up(match)}/account/details`}/>
                <SidebarItem setHeader={ t('payment.title.management') } value={t('payment.title.deposit')} href={`${up(match)}/payment/deposit`}/>
                <SidebarItem setHeader={ t('payment.title.management') } value={t('payment.title.withdraw')} href={`${up(match)}/payment/withdraw`}/>
            </SidebarItem>}
            <SidebarItem value={ t('play.sessions.title') } href={`${up(match)}/play/sessions`} warningIcon={user.sessionType !== 'NONE'}/>
            <SidebarItem value={ t('play.title') }>
                {getBasicGamesMenuItem()}
                {getSportGamesMenuItem()}
            </SidebarItem>
            {user.jackBetAvailable && <SidebarItem value={t('jackbet.title')} href={`${up(match)}/jackbet`}/>}
            <SidebarItem value={t('history.title')}>
                <SidebarItem setHeader={t('history.title')} value={t('history.operations.title')} href={`${up(match)}/history/operations`}/>
                <SidebarItem setHeader={t('history.title')} value={t('history.activity.title')} href={`${up(match)}/history/activity`}/>
                <SidebarItem setHeader={t('history.title')} value={t('history.steps.title')} href={`${up(match)}/history/steps`}/>
            </SidebarItem>
            <SidebarItem setHeader={t('restrictions.title')} value={t('restrictions.title')} href={`${up(match)}/restrictions`}/>
            <SidebarItem setHeader={t('help.title')} value={t('help.title')} href={`${up(match)}/help`}/>

            {/*TODO доделать кнопку выхода, надо её как-то вставить ниже пунктов меню, которые приходят с lebel.sportebet*/}
            {/*<SidebarItem value={'Выход'} href={`${up(match)}/restrictions`}/>*/}

            {/*<button className="btn btn-link" onClick={ e => { e.preventDefault(); logout(); } }>*/}
            {/*    <span className="pa-icon pa-icon-logout"/>*/}
            {/*</button>*/}


        </>
    );


}