import React from 'react';
import PropTypes from 'prop-types';

const ReadOnly = ({readOnly, children, ...restProps}) => {
    return (
        <div {...restProps}>
            {
                children.map(
                    (child, i) => React.cloneElement(child, {readOnly, key: i})
                )
            }
        </div>
    );
};

ReadOnly.propTypes = {
    readOnly: PropTypes.bool,
    children: PropTypes.array
};

export default ReadOnly;
