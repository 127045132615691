import React from "react";
import {connect, useSelector} from "react-redux";

import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import {Help} from "../help/common/Help";
import {Activity} from "../history/Activity";
import {Operations} from "../history/Operations";
import {Steps} from "../history/Steps";
import JackBet from "../jackbet/JackBet";
import AccountDetails from "../payment/common/AccountDetails";

import Payment from "../payment/Payment";
import Personal from "../personal/Personal";
import {PlayGames} from "../play/common/PlayGames";
import {PlaySessions} from "../play/common/PlaySessions";
import {PlaySports} from "../play/common/PlaySports";
import Game from "../play/Game";
import {CupisBindClientStatus, CupisIdentificationStatus, UserInfo, UserStatus} from "../redux/auth";
import {ReduxState} from "../redux/redux";
import Restrictions from "../restrictions/common/Restrictions";
import {up} from "../util/urlPrefix";
import {Frozen} from "./Preconditions";
import {ChessGame} from "../play/ChessGame";
import {GameRedirect} from "../play/GameRedirect";
import {EditPersonal} from "../personal/EditPersonal";
import {AppStepRoute} from "../personal/PreApply";
import GameCategorySelector from "../play/common/GameCategorySelector";
import {CupisStatusShow} from "../components/CupisStatusShow";
import {LockLoaderPageWrapper, LockPageLoaderTimerWrapper} from "../loader/LockLoader";

interface StateProps {
    user: UserInfo;
}

type UserMasterProps = StateProps & RouteComponentProps;

const UserMaster: React.FC<UserMasterProps> = ({ user, match }) => {
    const urlPrefix = up(match);
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const cantPayment = [
        CupisBindClientStatus.NONE,
        CupisBindClientStatus.DELETED_BY_CUPIS,
        CupisBindClientStatus.BLOCKED_BY_CUPIS,
        CupisBindClientStatus.BLOCKED_BY_CUPIS_AND_CHANGED_PHONE_BY_CUPIS,
        CupisBindClientStatus.CHANGED_PHONE_BY_CUPIS,
        CupisBindClientStatus.CHANGED_PHONE_BY_CLIENT
    ].indexOf(user.cupisIdentification.clientStatus) > -1;
    return <LockPageLoaderTimerWrapper>
        <LockLoaderPageWrapper>
            <Switch>
                <Route path={`${urlPrefix}/account/details`} component={AccountDetails}/>
                <Route path={`${urlPrefix}/payment/deposit`} render={p => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user} showChildren={!cantPayment}>
                        <Payment type="DEPOSIT" {...p}/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/payment/withdraw`} render={p => (
                    <CupisStatusChecker user={user} showChildren={!cantPayment}>
                        <Payment type="WITHDRAW" {...p}/>
                    </CupisStatusChecker>
                )}/>
                <Route path={`${urlPrefix}/jackbet`} render={() => <FrozenChecker user={user}><JackBet/></FrozenChecker>}/>
                <Route path={`${urlPrefix}/history/operations`} render={() => <FrozenChecker user={user}><Operations/></FrozenChecker>}/>
                <Route path={`${urlPrefix}/history/activity`} render={() => <FrozenChecker user={user}><Activity/></FrozenChecker>}/>
                <Route path={`${urlPrefix}/history/steps`} render={() => <FrozenChecker user={user}><Steps/></FrozenChecker>}/>
                {isMobile && <Route path={`${urlPrefix}/play/sessions`} render={() => <FrozenChecker user={user}><PlaySessions/></FrozenChecker>}/>}
                <Route path={`${urlPrefix}/play/games`} render={() => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user}>
                        <PlayGames/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/play/sport`} render={() => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user}>
                        <PlaySports/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/play/game`} render={() => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user}>
                        <Game/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/play/chess/:game/:variant/:locale`} render={() => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user}>
                        <ChessGame/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/play/categories`} render={() => (<FrozenChecker user={user}>
                    <CupisStatusChecker user={user}>
                        <GameCategorySelector/>
                    </CupisStatusChecker>
                </FrozenChecker>)}/>
                <Route path={`${urlPrefix}/play/redirect`} render={() => <FrozenChecker user={user}><GameRedirect/></FrozenChecker>}/>
                <Route path={`${urlPrefix}/help`} render={() => <FrozenChecker user={user}><Help/></FrozenChecker>}/>
                <Route path={`${urlPrefix}/personal/edit/:type`} component={AppStepRoute}/>
                <Route path={`${urlPrefix}/personal/edit`} component={EditPersonal}/>
                <Route path={`${urlPrefix}/personal`} component={Personal}/>
                <Route path={`${urlPrefix}/restrictions`} render={p => <FrozenChecker user={user}><Restrictions {...p}/></FrozenChecker>}/>
                {
                    user.cupisIdentification.status === CupisIdentificationStatus.CLARIFICATION_PD
                        ? <Redirect to={`${urlPrefix}/personal`}/>
                        : user.closingAccountExist
                            ? <Redirect to={`${urlPrefix}/payment/withdraw`}/>
                            : <Redirect to={`${urlPrefix}/play/categories`}/>

                }
            </Switch>
        </LockLoaderPageWrapper>
    </LockPageLoaderTimerWrapper>
};

const CupisStatusChecker: React.FC<{user: UserInfo, showChildren?: boolean}> = ({user, showChildren = true, children}) => {
    if (user.cupisIdentification.clientStatus === CupisBindClientStatus.OK) {
        return <>{children}</>;
    }
    return <>
        <CupisStatusShow status={user.cupisIdentification.clientStatus}/>
        {showChildren && children}
    </>
}

const FrozenChecker: React.FC<{user: UserInfo}> = ({user, children}) => {
    if (user.status === UserStatus.FROZEN) {
        return <Frozen user={user}/>;
    }
    return <>{children}</>;
};

export default connect<StateProps, {}, {}, ReduxState>(state => ({ user: state.auth.user as UserInfo }))(withRouter(UserMaster));
