import React from 'react';
import './print.css';
import img from './lk_print.png';

class Print extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        const {print} = this.props;

        if (print == null) {
            return null;
        } else {
            return (<input type="image" className="print-button" src={ img } onClick={ print }/>);
        }
    }
}

export default (Print);
