import React from 'react';
import {withTranslation} from "react-i18next";
import ChessBoardElem from '../../commons/comps/chess/ChessBoardElem';
import '../chess.css';
import Header from './Header';
import {getCreditStepResult, prettyCoefficient} from './StepResultFormula';
import TotalLinePrize from './TotalLinePrize';
import Circulation from './Circulation';
import CirculationTime from './CirculationTime';
import StepSize from './StepSize';
import StepResult from './StepResult';
import {isSixteenBallsGame} from "../Helper";
import {ChessCoeff} from "../../ChessCoeff";

class Middle extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {kvitokData, dataForBoard, currencyType, t} = this.props;
        return (
            <div className="matrixRegularKvitokChess-middle bg">
                <h6 className={'bold-text'}>{t("chess.gameCombo", kvitokData)}</h6>
                <h6 className={'bold-text'}><StepSize kvitokData={kvitokData} currencyType={currencyType}/></h6>
                <ChessCoeff coeff={prettyCoefficient(dataForBoard.coefficient)}/>
            </div>
        );
    }
}

class ChessBoardElems extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {kvitokData, dataForBoard, t, game, expanderSymbol, isSEBMode, endOfSEB} = this.props;
        return (
            <div>
                <p className="chessKvitokText bold-text">{t("regularKvitok.step") + " " + kvitokData.STEPNUMBER}</p>
                <div className="chessKvitokText"><Circulation kvitokData={kvitokData} championship={dataForBoard.championship} tour={dataForBoard.tour}/></div>
                <div className="chessElementsInKvitok">
                    {dataForBoard.symbolTriads.map((triadNum, index) => (
                        <ChessBoardElem dataForBoard={dataForBoard} triadNum={index + 1} key={index} expanderSymbol={expanderSymbol}/>
                    ))}
                </div>
                {
                    // sixteen ball games
                    isSixteenBallsGame(game)
                        ? <div>
                            <br/>
                            {
                                isSEBMode || endOfSEB
                                    ? <div className="chessKvitokText">
                                        {t("regularKvitok.totalGroup", { GROUP: dataForBoard.totalRegGroup})}
                                    </div>
                                    : <div className="chessKvitokText">
                                        {t("regularKvitok.totalSumAndGroup", { TOTAL_SUM: dataForBoard.totalSum, GROUP: dataForBoard.totalRegGroup})}
                                    </div>
                            }
                            <div className="chessKvitokText">{t("quittance.tape.chess.symbol", {symbol: expanderSymbol})}</div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

class Bottom extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {kvitokData, balls, endOfSEB, expanderSymbol, game, t} = this.props;

        return (
            <div className="matrixRegularKvitokChess-bottom">
                <div className="bottomTexts">
                    <p className="chessKvitokText2">{t("regularKvitok.stepTime")}</p>
                    <p className="chessKvitokText2">{kvitokData.STEPTIME}</p>
                    <p className="chessKvitokText2"><CirculationTime/></p>
                    <p className="chessKvitokText2">{kvitokData.TIMERESULT}</p>
                    <p className="chessKvitokText2">{t("regularKvitok.resultStepTime")}</p>
                    <p className="chessKvitokText2">{kvitokData.RESULTTIME}</p>

                    {endOfSEB ? this.getEndOfSEBBottom() : this.getNormalBottom()}
                </div>
                <table className="matrixRegularKvitokChess-result-table">
                    <tbody>
                    <tr>
                        <th colSpan="5">{t("chess.symbols")}</th>
                    </tr>
                    {(balls.length === 15
                            ? [
                                [0, 1, 2, 3, 4],
                                [5, 6, 7, 8, 9],
                                [10, 11, 12, 13, 14]
                            ]
                            : [
                                [0, 1, 2],
                                [3, 4, 5],
                                [6, 7, 8]
                            ]
                    ).map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {
                                    row.map((ballNumber) => (
                                        <td key={ballNumber}>
                                            <div className={this.getClassNameForBall(ballNumber)}>
                                                <span>
                                                    {
                                                        // ball === '10' - символ в СЭБ игр БОР, который необходимо заменить на expanderSymbol
                                                        // смотри также src/main/web/src/history/commons/comps/chess/ChessBoardElem.js:79
                                                        balls[ballNumber] === '10' && isSixteenBallsGame(game) ? expanderSymbol : balls[ballNumber]
                                                    }
                                                </span>
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>);
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    getEndOfSEBBottom = () => {
        const {kvitokData, prizeLines, currencyType, denomination, t} = this.props;
        return (
            <div>
                <p className="chessKvitokText2">
                    <TotalLinePrize kvitokData={kvitokData} prizeLines={prizeLines} currencyType={currencyType} denomination={denomination}/>
                </p>
                <p className="chessKvitokText2">{t("regularKvitok.endOfSEB")}</p>
                <p className="chessKvitokText2">
                    <StepResult kvitokData={kvitokData} currencyType={currencyType}/>
                </p>
            </div>
        );
    };

    getNormalBottom = () => {
        const {isSEBMode, currencyType, kvitokData, isSEBAgain, isFreeSpinsAvailable, curStep, totalSteps, t, ptpId} = this.props;
        const creditResultForStep = getCreditStepResult(this.props);
        return (
            <div>
                {
                    isSEBMode
                        ? <p className="chessKvitokText2">{t("regularKvitok.sebSteps", {
                            STEP: curStep,
                            TOTAL_STEPS: totalSteps
                        })}</p>
                        : null
                }
                <p className="chessKvitokText2">
                    {
                        isSEBMode && kvitokData.CREDITS_RESULT === kvitokData.CREDITS
                            ? t("regularKvitok.sebNoResult")
                            : creditResultForStep
                                ? t("regularKvitok.resultStep_" + currencyType,
                                    {
                                        BALLS_RESULT: Number(kvitokData.BALLS_RESULT).toFixed(2),
                                        CREDITS_RESULT: kvitokData.CREDITS_RESULT
                                    })
                                : t("kvitok.noresult")
                    }
                </p>
                {
                    (isFreeSpinsAvailable || isSEBAgain)
                        ? <p className="seb-available chessKvitokText2">{t("regularKvitok.sebavailable")}</p>
                        : null
                }
                {
                    ptpId === 2 && curStep === totalSteps
                    ? <p className="chessKvitokText2">{t("regularKvitok.endOfSEB")}</p>
                        : null
                }
            </div>
        );
    };

    getClassNameForBall = (index) => {
        if (this.props.prizeLines && this.props.prizeLines.length > 0 && this.props.animeLine !== -1 && this.props.prizeLines[this.props.animeLine]) {
            let pL = this.props.prizeLines[this.props.animeLine];
            if (pL.coordinates.indexOf(index) !== -1) {
                return "circleBase combo_" + pL.line;
            } else {
                return "circleBase combo_0";
            }
        } else {
            return "circleBase combo_0";
        }
    };
}

class MatrixRegularKvitokChess extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {kvitokData, dataForBoard, endOfSEB, balls, game, combo, currencyType, isSEBMode, print, close, t, prizeLines} = this.props;
        const {markoPoloSebAgain, sebCount, isFreeSpinsAvailable, isSEBAgain, expanderSymbol} = this.props;
        const {curStep, totalSteps, ptpId} = this.props;
        return (
            <div className={['matrixRegularKvitokChess', game].join(' ')}>
                <Header kvitokData={kvitokData} t={t} print={print} close={close}/>
                <ChessBoardElems kvitokData={kvitokData} dataForBoard={dataForBoard} t={t} game={game}
                                 expanderSymbol={expanderSymbol} isSEBMode={isSEBMode} endOfSEB={endOfSEB}/>
                <Middle kvitokData={kvitokData} dataForBoard={dataForBoard} t={t}
                        currencyType={currencyType}/>
                <Bottom kvitokData={kvitokData} dataForBoard={dataForBoard} t={t}
                        isSEBMode={isSEBMode} endOfSEB={endOfSEB}
                        balls={balls} prizeLines={prizeLines} game={game} combo={combo}
                        currencyType={currencyType}
                        markoPoloSebAgain={markoPoloSebAgain} sebCount={sebCount} isSEBAgain={isSEBAgain}
                        curStep={curStep} totalSteps={totalSteps} isFreeSpinsAvailable={isFreeSpinsAvailable}
                        expanderSymbol={expanderSymbol} ptpId={ptpId}
                />
                <p className="chessKvitokText">{t("chess.ptp." + ptpId)}</p>
            </div>
        );
    }
}

export default withTranslation()(MatrixRegularKvitokChess);
