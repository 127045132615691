import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import queryString from "query-string";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";
import {ReduxState} from "../redux/redux";
import {useNotifications} from "../util/Notifications";
import {SessionType} from "../redux/auth";

interface OwnProps {
    game: string;
    disabled: boolean;
}

type CreateSessionButtonProps = OwnProps & RouteComponentProps;

const CreateSessionButton: React.FunctionComponent<CreateSessionButtonProps> = ({ history, game, disabled }) => {

    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const mobileDirPrefix = isMobile ? 'mobile/sports/' : '';
    const auth = useSelector((state: ReduxState) => state.auth);
    const { addNotification } = useNotifications();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        const selectorWindowName = "game-selector";
        if (auth && auth.user && auth.user.applicationExist) {
            addNotification({
                message: t('personal.applicationExist')
            });
            return;
        }
        if (game === "GAMES") {
            const emptyWindow = window.open("", selectorWindowName);
            if (emptyWindow === null) {
                console.error("Unable to open window");
                return;
            }

            axios.get("/api/play/create-session?type=" + game).then(resp => {
                queryAndUpdateAuth();
                const url = resp.data.replace("{lang}", lang);
                const newWindow = window.open(url, selectorWindowName);
                if (newWindow) {
                    newWindow.focus();
                }
            }, () => emptyWindow.close());

        } else {
            axios.get("/api/play/create-session?type=" + game).then(resp => {
                const params = queryString.stringify({url: resp.data, game: game});
                queryAndUpdateAuth();
                history.push("/play/game?" + params);
            });
        }
    };

    let image = game === "GAMES" ? game + "_" + lang.slice(0, 2) : game;

    return (
        <div className="btn-group btn-group-sm">
            <button className="btn btn-default" onClick={handleClick} disabled={disabled}>
                <img src={require(`./logos/${mobileDirPrefix + image}_TRANSPARENT.png`)} alt={t("gameSelector.games." + game + ".alt")}/>
                {game !== "GAMES" && <div>{t(`gameSelector.games.${game}.alt`)}</div>}
            </button>
        </div>
    );

};

export default withRouter(CreateSessionButton);
