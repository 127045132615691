import React, { ChangeEvent } from "react";
import { RootRouteProps } from "../../App";
import { useTranslation } from "react-i18next";
import "../../icons/pa-icons.css";
import "./LangPicker.css";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ReduxState } from "../../redux/redux";
import { urlPrefixRegex } from "../../util/urlPrefix";

interface OwnProps {
    langs?: Array<string>;
}

// type LangPickerProps = OwnProps & RouteComponentProps;

const LangPicker = () => {
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const langs = ["ru", "en"];
    const { i18n } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    const langMapping = {
        'ru': 'RUS',
        'en': 'ENG'
    } as {[k:string]: string};

    function removeLangPrefixFromUrl(url: string): string {
        const match = url.match(urlPrefixRegex);
        return match ? url.substring(3) : url;
    }

    function handleChangeLang(e: ChangeEvent<HTMLSelectElement>) {
        // console.log('match');
        // console.log(match);
        const path = "/" + e.target.value + removeLangPrefixFromUrl(location.pathname);
        history.push(path);
        // history.push(`/${e.target.value}${location.pathname.substring(match.url.length)}${location.search}`);
    }

    if (isMobile) {
        return <>
            <span className="right right-align vertical-center">
                <select className="lang-select" onChange={handleChangeLang} value={i18n.language}>
                    {langs.map((lang) => <option key={lang} value={lang}>
                        {langMapping[lang]}
                    </option>)}
                </select>
            </span>
        </>;
    } else {
        return <div className="pa-lang-picker">
            {langs.map(lang =>
                <a key={lang}
                   href={`/${lang}${location.pathname.substring(match.url.length)}${location.search}`}
                   className={lang === i18n.language ? "active" : undefined}>
                    <span className={"pa-icon pa-icon-flag-" + lang}/>
                        {langMapping[lang]}
                </a>
            )}
        </div>;
    }
};
export default LangPicker;
