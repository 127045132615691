import { Reducer } from "redux";

export interface SetMobileAppLink {
    type: "SET_MOBILE_APP_LINK";
    link: string;
}

export const mobileAppLinkReducerDefault: string | null = null;

export const mobileAppLinkReducer: Reducer<string | null, SetMobileAppLink> = (state = null, action) => {
    return action.type === 'SET_MOBILE_APP_LINK' ? action.link : state;
};
