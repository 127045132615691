import React, {useState} from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { MobileFooter } from "../../mobile-components/MobileFooter";
import { MobileHeader } from "../../mobile-components/MobileHeader";
import { SidebarMenu } from "../../mobile-components/sidebar/SidebarMenu";
import { up } from "../../util/urlPrefix";
import { MasterRoutes } from "../device-independent-components/MasterRoutes";
import axios from "axios";
import {queryAndUpdateAuth} from "../../Auth";

const MobileMaster: React.FunctionComponent<RouteComponentProps> = ({ match }) => {
    const urlPrefix = up(match);
    const [version, setVersion] = useState('');
    React.useEffect(() => {
        requestChannels();
    });
    const requestChannels = () => {
        axios.get("/api/external-menu/getVersion").then((resp) => {
            setVersion(resp.data);
        });
    };
    return <>

        <SidebarMenu/>
        <MobileHeader/>

        <div className="page_container">
            <div id="lk">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            {/*<MobileHeadTitles firstHeader={'firstHeader'} secondHeader={'secondHeader'}/>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pa-page">
                                <MasterRoutes urlPrefix={urlPrefix}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MobileFooter version={version}/>
    </>;
};

const MasterHOC = withRouter(MobileMaster);

export default MasterHOC;
