import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { logout } from "../../Auth";
import { ReduxState } from "../../redux/redux";
import { up } from "../../util/urlPrefix";
import { AuthorithedSidebarItems } from "./AuthorithedSidebarItems";
import ExternalMenuItems from "./ExternalMenuItems";
import './sidebar.css';
import { SidebarItem } from "./SidebarItem";
import { UnaithorithedSidebaritems } from "./UnaithorithedSidebaritems";
import {useTranslation} from "react-i18next";

export function SidebarMenu() {
    const dispatch = useDispatch();
    const sidebarStatus = useSelector((state: ReduxState) => state.mobileSidebarOpen);
    const {auth, settings: {mainUrl}} = useSelector((state: ReduxState) => state);
    const match = useRouteMatch();
    const {t} = useTranslation();

    function toggleSidebar() {
        dispatch({ type: "TOGGLE_SIDEBAR_STATUS" });
    }

    return (
        <>
            <div className={sidebarStatus ? 'sidebar-menu show-sidebar-menu' : 'sidebar-menu hide-sidebar-menu'}>


                <div style={{background: '#D4D4D4'}}>
                    {auth.authenticated && auth.user ? <AuthorithedSidebarItems user={auth.user}/> : <UnaithorithedSidebaritems/>}
                </div>

                <ExternalMenuItems/>
                {auth.authenticated && auth.user && <div onClick={e => { e.preventDefault(); logout(mainUrl)}}>
                    <SidebarItem setHeader={t('help.title')} value={t('exit.title')} href={`${up(match)}/help`}/>
                </div>}

            </div>
            <div className={sidebarStatus ? 'sidebar-menu-overlay show-sidebar-menu-overlay' : 'sidebar-menu-overlay hide-sidebar-menu-overlay'} onClick={toggleSidebar}/>
        </>
    )
}