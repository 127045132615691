import React from 'react';

import axios from "axios";
import classNames from "classnames";
import i18n from "i18next";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import "../icons/event-type-icons.css";
import { queryAndUpdateAuth } from "../Auth";
import {EventTypeAndGame, SessionType} from "../redux/auth";
import {useNotifications} from "../util/Notifications";

function eventTypeClassName(game: EventTypeAndGame) {
    return "event-type-" + game.eventType;
}

function sportTypeClassName(sessionType: SessionType) {
    let suffix = "";
    switch(sessionType) {
        case SessionType.SPORT_MB: suffix = "MB"; break;
        case SessionType.SPORT_SB: suffix = "SB"; break;
        default:
    }
    return "event-type-" + suffix;
}

interface GameSessionProps {
    sessionType: SessionType;
    activeGames: Array<EventTypeAndGame>;
    openInNewTab: (eventTypeAndGame: EventTypeAndGame | null) => React.ReactEventHandler;
    closeSession: (url: string) => React.ReactEventHandler;
}

const GameSession: React.FunctionComponent<GameSessionProps> = ({ sessionType, activeGames, openInNewTab, closeSession }) => {
    const { t } = useTranslation();
    return <ul className="dropdown-menu">
        <li>
            {
                sessionType !== "CHESS_GAMES" && <NavLink className="btn btn-link" to="/" onClick={openInNewTab(null)}>
                    { t("game.gameSelector") }
                </NavLink>
            }
        </li>
        {
            activeGames && activeGames.map(eventTypeAndGame => {
                return <li key={eventTypeAndGame.eventType + "#" + eventTypeAndGame.game}>
                    <NavLink className="btn btn-link" to={'/'} onClick={openInNewTab(eventTypeAndGame)}>
                        <span className={classNames("event-type-icon", eventTypeClassName(eventTypeAndGame))}/>
                        {
                            eventTypeAndGame.eventType !== "CHESS_LIVE"
                                ? t("game.name", {index: eventTypeAndGame.gameIndex})
                                :t("game.chesslive", {index: eventTypeAndGame.gameIndex - 1000})
                        }
                    </NavLink>
                </li>
            })
        }
        <li>
            <NavLink className="btn btn-link" to="/" onClick={closeSession("/play/games")}>
                { t("game.closeSession") }
            </NavLink>
        </li>
    </ul>;
};

interface SportSessionProps {
    sessionType: SessionType;
    closeSession: (url: string) => React.ReactEventHandler;
    handleClick: React.ReactEventHandler;
}

const SportSession: React.FunctionComponent<SportSessionProps> = ({ sessionType, closeSession, handleClick }) => {
    const { t } = useTranslation();
    return <ul className="dropdown-menu">
        <li>
            <NavLink className="btn btn-link" to={'/'} onClick={handleClick}>
                <span className={classNames("event-type-icon", sportTypeClassName(sessionType))}/>{ t("game." + sessionType) }
            </NavLink>
        </li>
        <li>
            <NavLink className="btn btn-link" to={'/'} onClick={closeSession("/play/sport")}>
                { t("game.closeSession") }
            </NavLink>
        </li>
    </ul>
};

interface WebSessionProps extends RouteComponentProps {
    sessionType: SessionType,
    activeGames?: Array<EventTypeAndGame>,
    shiftActive: boolean,
    gamesAllowed: boolean
}

const WebSession: React.FunctionComponent<WebSessionProps> = ({ sessionType, activeGames, shiftActive, gamesAllowed, history }) => {
    const { addNotification } = useNotifications();
    const { t } = useTranslation();

    const closeSession = (url: string): React.ReactEventHandler => e => {
        e.preventDefault();
        if (!shiftActive) {
            addNotification({
                message: t("game.shiftNotActive")
            });
        } else {
            axios.post("/api/play/close-session").then(() => {
                queryAndUpdateAuth();
            });
            history.push(url);
        }
    };

    const openInNewTab = (eventTypeAndGame: EventTypeAndGame): React.ReactEventHandler => (e) => {
        e.preventDefault();
        if (checkGamesAllowed()) {
            if (!shiftActive) {
                addNotification({
                    message: t("game.shiftNotActive")
                });
            } else {
                const windowName = eventTypeAndGame ? eventTypeAndGame.eventType + '#' + eventTypeAndGame.gameIndex : "game-selector";
                window.open("", windowName);
                axios.get("/api/play/open-session?type=" + sessionType)
                    .then(resp => {
                        const params = eventTypeAndGame ? "&subject=" + eventTypeAndGame.eventType + "&wager=" + eventTypeAndGame.gameIndex : "";
                        const url = resp.data.replace("{lang}", i18n.language) + params;
                        const win = window.open(url, windowName);
                        win && win.focus();
                    });
            }
        }
    };

    const checkGamesAllowed = () => {
        if (!gamesAllowed) {
            addNotification({
                message: t('personal.applicationExist')
            });
            return false;
        }
        return true;
    };

    const handleClick: React.ReactEventHandler = (e) => {
        e.preventDefault();
        if (checkGamesAllowed()) {
            queryAndUpdateAuth()
                .then(auth => {
                    if (auth && auth.user && auth.user.sessionType === sessionType) {
                        axios.get("/api/play/open-session?type=" + sessionType).then(resp => {
                            const url = queryString.stringify({url : resp.data});
                            history.push("/play/game?" + url +" &game=" + sessionType)
                        });
                    }
                });
        }
    };

    const [isDownloading, setDownloading] = React.useState(false);
    const updateAuth = () => queryAndUpdateAuth().then(() => setDownloading(true));

    return <div className="dropdown" style={{display: "inline"}}>
        <button className="btn btn-link" data-toggle="dropdown" onClick={updateAuth}>
            <span className="pa-icon pa-icon-session-exist"/>{t('play.sessions.title')}
        </button>
        {
            isDownloading &&
            (sessionType === "ACTIVE_GAMES" || sessionType === "INACTIVE_GAMES" || sessionType === "CHESS_GAMES"
                ? <GameSession sessionType={sessionType}
                               openInNewTab={openInNewTab}
                               closeSession={closeSession}
                               activeGames={activeGames as Array<EventTypeAndGame>}/>
                : <SportSession sessionType={sessionType}
                                closeSession={closeSession}
                                handleClick={handleClick}/>)
        }
    </div>;
};

export default withRouter(WebSession);