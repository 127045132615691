import React from 'react';
import { useSelector } from "react-redux";
import LabelInput from "../forms/LabelInput";
import { ReduxState } from "../redux/redux";
import { resultType } from "../validation/FormController";

import {required} from "../validation/functionalPlugins"
import ReadOnly from "../forms/ReadOnly";
import { blockedConditionally } from "../forms/utils";
import {useFiasLevels} from "../fias/fias-api";


type AddressFormProps = {
    f: resultType;
    t: Function;
    blockedFields?: any[];
    readOnly?: boolean;
}

const AddressForm = (props: AddressFormProps) => {
    const { f, t, readOnly, blockedFields } = props;
    const {getParentId, getParenValue, updateLevel, getLevel} = useFiasLevels();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {isMobile ? <div>{t(f.i18nPrefix + "addressTitle")}</div> : <h4 className="text-center">{t(f.i18nPrefix + "addressTitle")}</h4>}
                </div>
            </div>
            <div className="row">
                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelInput
                        f={f("address.region")}
                        blockedConditionally={blockedConditionally('address.region', blockedFields)}
                        level={getLevel("regions")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />
                    <LabelInput
                        f={f("address.district")}
                        blockedConditionally={blockedConditionally('address.district', blockedFields)}
                        level={getLevel("districts")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />
                    <LabelInput
                        f={f("address.city")}
                        blockedConditionally={blockedConditionally('address.city', blockedFields)}
                        level={getLevel("cities")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />
                    <LabelInput
                        f={f("address.locality")}
                        blockedConditionally={blockedConditionally('address.locality', blockedFields)}
                        level={getLevel("localities")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />
                </ReadOnly>

                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelInput
                        f={f("address.street")}
                        blockedConditionally={blockedConditionally('address.street', blockedFields)}
                        level={getLevel("streets")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />
                    <LabelInput
                        f={f("address.house")}
                        blockedConditionally={blockedConditionally('address.house', blockedFields)}
                        level={getLevel("houses")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                    />

                    <LabelInput
                        f={f("address.building")}
                        blockedConditionally={blockedConditionally('address.building', blockedFields)}
                        level={getLevel("buildings")}
                        updateLevel={updateLevel}
                        getParentId={getParentId}
                        getParenValue={getParenValue}
                    />
                    <LabelInput
                        f={f("address.flat")}
                        blockedConditionally={blockedConditionally('address.flat', blockedFields)}
                    />
                </ReadOnly>
            </div>
        </div>
    );
};



AddressForm.schema = {
    "address.region": required(),
    "address.district": {},
    "address.city": {},
    "address.locality": {},
    "address.street": {},
    "address.house": required(),
    "address.building": {},
    "address.flat": {},
};

export default AddressForm;
