export interface AuthResponseType {
    ok: boolean;
    status: AuthStatusEnum;
    statementSubmittedClub: string;
    statementType: StatementType;
    confirmationRequestResult: ConfirmationRequestResultType;
}
export enum AuthStatusEnum {
    OK = 'OK',
    NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
    BLOCKED = 'BLOCKED',
    DELETED = 'DELETED',
    HAS_STATEMENT = 'HAS_STATEMENT',
    BLOCKED_CONDITIONALLY = 'BLOCKED_CONDITIONALLY'
}
export enum StatementType {
    UNLOCKING = "UNLOCKING",
    BLOCKING = 'BLOCKING',
    DELETE = "DELETE"
}
export interface ConfirmationRequestResultType {
    status: RequestStatusEnum;
    codeExpiresAt: number;
    canResendAt: number;
}
enum RequestStatusEnum {
    SUCCESS = 'SUCCESS',
    TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
    NOT_SENT = 'NOT_SENT'
}
// Request types
export interface AuthRequestType {
    phone: string;
    password: string;
}
