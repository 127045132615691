import React from "react";
import { EventTypeAndGame } from "../../redux/auth";
import {useTranslation} from "react-i18next";

type MobileGameSessionProps = {
    closeSession: (e: React.MouseEvent) => void;
    activeGames?: Array<EventTypeAndGame>
}

export function MobileGameSession({closeSession, activeGames}: MobileGameSessionProps) {
    const {t} = useTranslation();
    return <div>
        <button className="btn btn-primary btn-block btn-sm" disabled>
            {t('game.gameSelector')}
        </button>

        <div style={{marginTop: 5, marginBottom: 5}}>
            {activeGames && activeGames.map(el => <ActiveGameTitle eventTypeAndGame={el} />)}
        </div>

        <button className="btn btn-primary btn-block btn-sm" onClick={closeSession}>
            {t('game.closeSession')}
        </button>
    </div>
}


type ActiveGameTitleProps = {
    eventTypeAndGame: EventTypeAndGame
}

function ActiveGameTitle({eventTypeAndGame}: ActiveGameTitleProps) {
    const {t} = useTranslation();
    return <div style={{marginBottom: 10, fontWeight: 'bold', marginTop: 10}}>
        <div style={{borderRadius: 5, backgroundColor: '#d9d9d9', padding: '13px 15px'}}>{t('game.name', { index: eventTypeAndGame.gameIndex })}</div>
        <div style={{marginTop: 5, fontSize: 12, paddingLeft: 7, paddingRight: 7}}>{t('game.mobileInfo')}</div>
    </div>
}
