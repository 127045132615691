import React from "react";
import { useSelector } from "react-redux";
import '../../main-styles.css';
import { ReduxState } from "../../redux/redux";

import "../Tpl.css";
import { InstallAppMessage } from "./InstallAppMessage";
import MobileMaster from "./MobileMaster";
import {UsedeskWidget} from "../../components/UsedeskWidget";
import {CookieDisclaimer} from "../../cookie/CookieWidget";

export const MobileTpl: React.FunctionComponent = () => {

    const showInstallAppMessage = useSelector((state: ReduxState) => state.showInstallAppMessage);

    return <>
        <UsedeskWidget/>
        <CookieDisclaimer mobile={true}/>
        <MobileMaster/>
    </>;
};