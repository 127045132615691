export interface OfferDocument {
    type: OfferType;
    content: string;
    version: string;
}

export type OfferDocumentMap = {
    [type in OfferType]: OfferDocument;
};

export enum OfferType {
    OFFER = "OFFER",
    PD_AGREEMENT = "PD_AGREEMENT"
}
