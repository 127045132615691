import React from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {RouteComponentProps, withRouter} from "react-router";
import { queryAndUpdateAuth } from "../Auth";

type CloseSessionButtonProps = RouteComponentProps;

const CloseSessionButton: React.FunctionComponent<CloseSessionButtonProps> = ({history}) => {
    const { t } = useTranslation();
    const handleClick: React.ReactEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        axios.post("/api/play/close-session").then(() => {
            queryAndUpdateAuth();
            history.push("/play/sport");
        });
    };
    return (
        <div>
            <button className="btn btn-default" onClick={handleClick}>{ t("game.closeButton") }</button>
        </div>
    );
};

export default withRouter(CloseSessionButton);