import { Reducer } from "redux";

export interface SetIsMobileAction {
    type: "TOGGLE_MOBILE_VIEW_STATUS";
}

function resolveIsMobileByUserAgent(): boolean {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent);
}

// Сначала проверсятся локальный storage, если он не пустой,
// то берётся значение из него, если пустой то вычисляется исходя из Http хереда UserAgent
export function initIsMobileDefault(): boolean {
    const isMobileFromStorage = localStorage.getItem('isMobile');
    switch (isMobileFromStorage) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            const newIsMobile = resolveIsMobileByUserAgent();
            localStorage.setItem('isMobile', String(newIsMobile));
            return newIsMobile;
    }
}

export const isMobileReducer: Reducer<boolean, SetIsMobileAction> = (state = false, action) => {
    if (action.type === 'TOGGLE_MOBILE_VIEW_STATUS') {
        localStorage.setItem('isMobile', String(!state));
        return !state;
    } else {
        return state;
    }
};
