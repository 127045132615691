import React from "react";
import { useRouteMatch } from "react-router";
import { preloadGet } from "../../util/preload";
import { up } from "../../util/urlPrefix";
import fbIcon from "./imgs/fb.png";
import twIcon from "./imgs/tw.png";
import vkIcon from "./imgs/vk.png";
import { SidebarItem } from "./SidebarItem";
import {useDispatch} from "react-redux";
import {SetMobileAppLink} from "../../redux/mobileAppLinkReducer";
import {useTranslation} from "react-i18next";

type ExternalMenuItemsProps = {
    externalMobileMenus: {
        ru: Array<ExternalMenuItem>,
        en: Array<ExternalMenuItem>
    };
}

export type ExternalMenuItem = {
    title: string;
    link: string;
}

function ExternalMenuItems(props: ExternalMenuItemsProps) {
    const {externalMobileMenus} = props;
    // const APP_LINK = 'Приложения';


    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru' || i18n.language === 'ru-RU';
    const getMenu = () => isRu ? externalMobileMenus['ru'] : externalMobileMenus['en'];
    const [externalMobileMenu, setMenu] = React.useState(getMenu());
    React.useEffect(() => setMenu(getMenu), [i18n.language]);
    const hasMatch = (url: string) => url.match('.(apps)');
    const appItem = externalMobileMenu.find(el => hasMatch(el.link));
    const dispatch = useDispatch();

    if (appItem) {
        dispatch<SetMobileAppLink>({type: "SET_MOBILE_APP_LINK", link: appItem.link});
    }

    return (
        <>
            {externalMobileMenu.filter(el => !hasMatch(el.link)).map(externalMenuitem => (
                <SidebarItem
                    isExternalLink
                    key={externalMenuitem.link}
                    setHeader={externalMenuitem.title}
                    value={externalMenuitem.title}
                    href={externalMenuitem.link}/>
            ))}


            <div style={{padding: '13px 14px'}}>
                <a href="https://vk.com/sportbetbk" style={{marginRight: 36}}>
                    <img src={vkIcon} alt="vkIcon"/>
                </a>
                <a href="https://www.facebook.com/groups/BKSportbet/" style={{marginRight: 36}}>
                    <img src={fbIcon} alt="fbIcon"/>
                </a>
                <a href="https://twitter.com/BKSportbet">
                    <img src={twIcon} alt="twIcon"/>
                </a>
            </div>


            {appItem && <div style={{marginTop: "auto"}}>
                <SidebarItem
                    isExternalLink
                    key={appItem.link}
                    setHeader={appItem.title}
                    value={appItem.title}
                    href={appItem.link}/>
            </div>}


        </>
    );

}

export default preloadGet("/api/external-menu/mobile", "externalMobileMenus")(ExternalMenuItems);