import React, {Component} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import ChessBoardElem from '../../commons/comps/chess/ChessBoardElem';
import Header from '../regular/Header';
import StepSize from '../regular/StepSize';
import Circulation from '../regular/Circulation';
import CirculationTime from '../regular/CirculationTime';
import '../chess.css';
import './style.css';
import {prettyCoefficient} from '../regular/StepResultFormula';

class MatrixDoubleKvitokChess extends Component {

    constructor(props) {
        super(props);
    }

    getRedBall = () => <div className="tube-ball_RED tube-ball kvitok-ball-chess"/>

    getBlackBall = () => <div className="tube-ball_BLACK tube-ball kvitok-ball-chess"/>

    render() {
        const {kvitokData, dataForBoard, currencyType, game, selectedBall, close, ptpId, print, t} = this.props;
        return (
            <div className={"matrixRegularKvitokChess " + game}>
                <Header kvitokData={kvitokData} t={t} print={print} close={close}/>
                <div>
                    <p className="chessKvitokText bold-text">{t("regularKvitok.step") + " " + kvitokData.STEPNUMBER}</p>
                    <p className="chessKvitokText"><Circulation kvitokData={kvitokData} championship={dataForBoard.championship} tour={dataForBoard.tour}/></p>
                    <div className="chessElementsInKvitok">
                        {dataForBoard.symbolTriads.map((triadNum, index) => (
                            <ChessBoardElem
                                dataForBoard={dataForBoard}
                                triadNum={index + 1}
                                key={index}
                                short="true"/>
                        ))}
                    </div>
                    <p className="chessKvitokText">{t("regularKvitok.totalSumAndGroup", {
                        TOTAL_SUM: dataForBoard.totalSum,
                        GROUP: dataForBoard.totalGroup
                    })}</p>
                </div>
                <div className="matrixRegularKvitokChess-middle bg">
                    <h6>{t("chess.gameCombo", {COMBO: selectedBall === "RED" ? 1 : 9})}</h6>
                    <h6><StepSize kvitokData={kvitokData} currencyType={currencyType}/></h6>
                    <h6>{t("chess.coeff") + ": " + (kvitokData.CREDITS_RESULT > 0 ? 2 : 0)}</h6>
                </div>
                <div className="matrixRegularKvitokChess-bottom">
                    <div className="bottomTexts">
                        <p className="chessKvitokText2">{t("regularKvitok.stepTime")}</p>
                        <p className="chessKvitokText2">{kvitokData.STEPTIME}</p>
                        <p className="chessKvitokText2"><CirculationTime kvitokData={kvitokData}/></p>
                        <p className="chessKvitokText2">{kvitokData.TIMERESULT}</p>
                        <p className="chessKvitokText2">{t("regularKvitok.resultStepTime")}</p>
                        <p className="chessKvitokText2">{kvitokData.RESULTTIME}</p>
                        <p className="chessKvitokText2">
                            {kvitokData.CREDITS_RESULT > 0
                                ? t("regularKvitok.resultStep_" + currencyType, {
                                    CREDITS_RESULT: kvitokData.CREDITS_RESULT,
                                    BALLS_RESULT: kvitokData.BALLS_RESULT
                                })
                                : t("kvitok.noresult")}
                        </p>
                        <p className="chessKvitokText2">
                            {kvitokData.CREDITS_RESULT > 0 ? t("regularKvitok.stepDone") : null}
                        </p>
                    </div>
                    <div className="ballWithLabDoubleChess">
                        <p className="chessKvitokText" style={{fontWeight: "bold"}}>{t("chess.symbol")}</p>
                        <Ball value={this.props.curBall}/>
                    </div>
                </div>
                <p className="chessKvitokText">{t("chess.ptp." + ptpId)}</p>
            </div>
        );
    }
}

const Ball = ({value}) => {
    const {t} = useTranslation();
    return (<React.Fragment>
        <div className={`tube-ball_${value} tube-ball kvitok-ball-chess`}/>
        <div className={"ball-title"}>
            {t('eb.ball.' + value)}
        </div>
    </React.Fragment>)
}

export default withTranslation()(MatrixDoubleKvitokChess);
