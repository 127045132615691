import React from "react";
import ValidationInputAddon from "./ValidationInputAddon";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./readOnly.less";
import "./blockedConditionally.css";
import moment from "moment";

export default class ValidationInput extends React.Component {
    render() {
        if (this.props.readOnly) {
            return this.makeInput()
        } else {
            return <div className="input-group">
                {this.makeInput()}
                {this.props.datalistId && this.makeDataList()}
                <ValidationInputAddon valid={this.props.valid}/>
            </div>
        }
    }

    makeInput() {
        const {id, type, readOnly, value, autoComplete, onClick, onChange, required, placeholder, autoFocus, onFocus, onBlur, maxLength} = this.props;
        const {blockedConditionally, datalistId, onKeyDown = () => {}, onKeyUp = () => {}, showTimeSelectOnly = false} = this.props;
        const req = !readOnly && required;
        const forReadStyle = readOnly
            && value !== ''
            && value !== null
            && typeof (value) !== 'undefined';
        const handleOnChange = (e) => {
            if (!showTimeSelectOnly) {
                return onChange(e);
            }
            let result = "";
            for (let ch of e.target.value.replace(":", "")) {
                if (result.length === 2) {
                    result += ":";
                }
                result += ch;
            }
            e.target.value = result;
            onChange(e);
        }
        return <input id={id}
                      type={type || "text"}
                      value={value}
                      autoComplete={autoComplete}
                      onClick={onClick}
                      onChange={handleOnChange}
                      autoFocus={autoFocus}
                      {...(!readOnly && {placeholder})}
                      className={classNames("form-control", req && "required", forReadStyle && "control-for-read", blockedConditionally && "blocked-conditionally")}
                      readOnly={readOnly}
                      list={datalistId}
                      onKeyDown={onKeyDown}
                      onKeyUp={onKeyUp}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      maxLength={maxLength}
        />;
    }

    makeDataList() {
        const {datalist, datalistId} = this.props;
        return (<datalist id={datalistId}>
            {datalist.map(a => (
                <option key={a.id} value={a.name}>{a.name}</option>
            ))}
        </datalist>)
    }

    static propTypes = {
        id: PropTypes.string,
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        value: PropTypes.string,
        required: PropTypes.bool,
        placeholder: PropTypes.string,
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        valid: PropTypes.bool,
        type: PropTypes.string,
        blockedConditionally: PropTypes.bool,
        readOnly: PropTypes.bool,
        datalist: PropTypes.array,
        datalistId: PropTypes.string,
        onKeyUp: PropTypes.func,
        onKeyDown: PropTypes.func,
        showTimeSelectOnly: PropTypes.bool
    }
}

