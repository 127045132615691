import React from 'react';
import PropTypes from 'prop-types';
import LabelInput from "../../forms/LabelInput";
import ReadOnly from "../../forms/ReadOnly";
import { blockedConditionally } from "../../forms/utils";

const AddressInOwnCountryForm = ({f, t, readOnly, blockedFields}) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">{t(f.i18nPrefix + "addressInOwnCountryTitle")}</h4>
                </div>
            </div>
            <div className="row">
                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelInput f={f("citizenship")} blockedConditionally={blockedConditionally('citizenship', blockedFields)}/>
                    <LabelInput f={f("foreignCitizen.foreignRegistration.address")} blockedConditionally={blockedConditionally('foreignCitizen.foreignRegistration.address', blockedFields)}/>
                </ReadOnly>
            </div>
        </div>
    );
};

AddressInOwnCountryForm.propTypes = {
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    blockedFields: PropTypes.array,
    readOnly: PropTypes.bool
};

AddressInOwnCountryForm.schema = {
    "citizenship": {},
    "foreignCitizen.foreignRegistration.address": {}
};

export default AddressInOwnCountryForm;