import React, {useEffect, useState} from "react";

import moment from "moment";
import {useTicker} from "./hooks";

const DEFAULT_FORMAT = 'HH:mm:ss';

interface CountdownProps {
    until: string | Date | moment.Moment;
    onTimeout?: () => void;
    format?: string;
}

const Countdown: React.FunctionComponent<CountdownProps> = ({ until, onTimeout, format = DEFAULT_FORMAT }) => {

    // remaining: difference in milliseconds (until - now) or zero (if until < now) - never negative
    const [remaining, setRemaining] = useState(0);
    const ticker = useTicker(() => {

        const n = moment();
        const u = moment(until);
        const d = Math.max(u.diff(n), 0);

        if (d <= 0) {
            if (onTimeout) {
                onTimeout();
            }
            ticker.stop();
        }

        setRemaining(d);

    }, 25, false, [until]);

    // every time 'until' is changed there is several cases:
    // - ticker already started: no actions required, continue ticking using new 'until' value
    // - until < now: we must call onTimeout and stop ticking (this case is handled internally by {@link IntervalTicker} class)
    // - until >= now: start ticker
    useEffect(() => ticker.start(), [until]);
    return <span>{ moment.utc(remaining).format(format) } </span>

};

export default Countdown;