import React from "react";
import { useTranslation } from "react-i18next";
import IdentificationForm from "../../components/IdentificationForm";
import LabelCheckbox from "../../forms/LabelCheckbox";

const mainRulesUrl = (mainUrl: string, lang: string, document: any) => `${mainUrl}/rules${lang === "ru" ? "" : "/en"}/${document}`;
const i18nPrefix = "register.";

export type RegisterRulesFormProps = {
    mainUrl: string;
    f: any;
}

export function RegisterRulesForm(props: RegisterRulesFormProps) {
    const { mainUrl, f } = props;
    const { t, i18n } = useTranslation();
    return (
        <>
            <div>
                <a className="text-bold btn btn-primary btn-selector"
                   href={mainRulesUrl(mainUrl, i18n.language, 'Rules_of_interactive_bet_acceptance.pdf')} rel='noopener noreferrer'
                   target='_blank'>{t("register.rules")}</a>
                <a className="text-bold btn btn-primary btn-selector"
                   href={mainRulesUrl(mainUrl, i18n.language, 'Privacy_policy.pdf')} rel='noopener noreferrer'
                   target='_blank'>{t("register.policy")}</a>
            </div>
            <p>{t("register.info")}</p>
            <p>{t("register.info2")}</p>
            <LabelCheckbox f={f("acceptRules")}/>
        </>
    );
}
