import React from "react";
import LangPicker from "../master/lang-picker/LangPicker";

type MobileDualHeader = {
    firstHeader: string;
    secondHeader?: string;
}

export function MobileDualHeader(props: MobileDualHeader) {
    const { firstHeader, secondHeader } = props;
    return (
        <div style={{
            textTransform: "uppercase",
            fontSize: 12,
            marginLeft: -15,
            marginRight: -15,
            marginBottom: 30
        }}>
            {firstHeader &&
            <div style={{
                backgroundColor: '#D4D4D4',
                justifyContent: 'space-between',
                display: 'flex',
                padding: '8px 16px'
            }}>
                <div>{firstHeader}</div>
                <div>
                    <LangPicker/>
                </div>
            </div>}
            {secondHeader && <div style={{ padding: '8px 16px' }}>{secondHeader}</div>}
        </div>
    );
}
