import axios from "axios";
import moment from "moment";
import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { compose } from "redux";
import { ReduxState } from "../../redux/redux";
import { preloadGet } from "../../util/preload";
import { parseNumber } from "../../util/utils";

import { form, resultType } from "../../validation/FormController";
import {digitsOnly, max, maxMoney, min, minMoney, money, required} from "../../validation/functionalPlugins";
import { DesktopRestriction } from "../desktop/DesktopRestriction";
import { MobileRestriction } from "../mobile/MobileRestriction";
import "./style.css";
import {useProgressBar} from "../../redux/loadingPage";

type MainRestrictionsPropTypes = {
    f: resultType,
    commonRestrictions: any,
    doComponentReload: Function,
};

export type RestrictionType = {
    webAccountId: string;
    maxDepositPerDay: string;
    maxDepositPerMonth: string;
    maxStepsPerDay: string;
    maxStepsPerMonth: string;
    timeoutInMinutes: string;
}

export type RestrictionWithoutWebAccountIdType = 'maxDepositPerDay' | 'maxDepositPerMonth' | 'maxStepsPerDay' | 'maxStepsPerMonth' | 'timeoutInMinutes'

function MainRestrictions(props: MainRestrictionsPropTypes) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const {f, doComponentReload, commonRestrictions} = props;
    const {start, complete} = useProgressBar(useDispatch());

    const money = (val: string) => parseNumber(val).format({thousandSeps: true, decimalPlaces: 2});

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const onSubmit = (newRestrictions: any) => {
        start();
        axios.post('/api/restrictions/set-restrictions', newRestrictions,)
            .then(() => doComponentReload())
            .finally(complete);
    };

    const onSubmitFreeze = (freeze: any) => {
        start();
        const date = (freeze.date && freeze.time)
            ? moment(freeze.date + ' ' + freeze.time, 'YYYY-MM-DD hh:mm').format()
            : null;
        axios.post('/api/restrictions/freeze', {...freeze, date})
            .then(() => doComponentReload())
            .finally(complete);
    };

    if (isMobile) {
        return <MobileRestriction
            f={f}
            handleOpenModal={f.submit && f.submit(handleOpenModal)}
            maxDepositPerDay={money(commonRestrictions.maxDepositPerDay)}
            maxDepositPerMonth={money(commonRestrictions.maxDepositPerMonth)}
            maxStepsPerDay={money(commonRestrictions.maxStepsPerDay)}
            maxStepsPerMonth={money(commonRestrictions.maxStepsPerMonth)}
            handleCloseModal={handleCloseModal}
            timeoutInMinutes={commonRestrictions.timeoutInMinutes}
            historyStepsOkSubmit={f.submit && f.submit(onSubmit)}
            showModal={showModal}
            onSubmitFreeze={onSubmitFreeze}/>
    } else {
        return <DesktopRestriction
            f={f}
            handleOpenModal={f.submit && f.submit(handleOpenModal)}
            maxDepositPerDay={money(commonRestrictions.maxDepositPerDay)}
            maxDepositPerMonth={money(commonRestrictions.maxDepositPerMonth)}
            maxStepsPerDay={money(commonRestrictions.maxStepsPerDay)}
            maxStepsPerMonth={money(commonRestrictions.maxStepsPerMonth)}
            handleCloseModal={handleCloseModal}
            timeoutInMinutes={commonRestrictions.timeoutInMinutes}
            historyStepsOkSubmit={f.submit && f.submit(onSubmit)}
            showModal={showModal}
            onSubmitFreeze={onSubmitFreeze}
        />;

    }
}

export default compose<
        typeof React.Component,
        React.ComponentType<MainRestrictionsPropTypes>,
        React.ElementType,
        React.ComponentType<Omit<MainRestrictionsPropTypes, 'commonRestrictions' | 'f'>>
    >(
    preloadGet<{restrictions: RestrictionType}, RestrictionType, MainRestrictionsPropTypes>('/api/restrictions/get-restrictions', 'restrictions'),
    preloadGet<{commonRestrictions: RestrictionType}, RestrictionType, MainRestrictionsPropTypes>('/api/restrictions/get-common-restrictions', 'commonRestrictions'),
    form({
        i18nPrefix: "restrictions.",
        initialModel: ({restrictions}: {restrictions: RestrictionType}) => {
            Object.keys(restrictions)
                .filter(k => restrictions.hasOwnProperty(k))
                .filter(k => k !== 'webAccountId')
                .forEach((k: RestrictionWithoutWebAccountIdType) => restrictions[k] = restrictions[k] + '');

            return restrictions
        },
        schema: (restrictions: any, {commonRestrictions}: {commonRestrictions: RestrictionType}) => {
            const moneySchema = (m: any) => [
                required,
                money(2),
                maxMoney(m == 0 ? 99999999 : m),
                minMoney(0),
            ];

            return {
                'maxDepositPerDay': moneySchema(commonRestrictions.maxDepositPerDay),
                'maxDepositPerMonth': moneySchema(commonRestrictions.maxDepositPerMonth),
                'maxStepsPerDay': moneySchema(commonRestrictions.maxStepsPerDay),
                'maxStepsPerMonth': moneySchema(commonRestrictions.maxStepsPerMonth),
                'timeoutInMinutes': [
                    required(),
                    max(parseInt(commonRestrictions.timeoutInMinutes) == 0 ? 99999999 : commonRestrictions.timeoutInMinutes),
                    min(0),
                    digitsOnly()
                ]
            }
        }
    })
)(MainRestrictions);
