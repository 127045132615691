import React from 'react';
import LabelInput from "../forms/LabelInput";
import LabelDateInput from "../forms/LabelDateInput";
import RadioButtons from "../forms/RadioButtons";
import moment from "moment";
import {date, maxDate, maxLength, minDate, pattern, required} from "../validation/functionalPlugins";
import ReadOnly from "../forms/ReadOnly";
import { blockedConditionally } from "../forms/utils";

type NameBirthDateGenderFormProps = {
    f: Function;
    blockedFields?: any[];
    readOnly?: boolean;
};

const NameBirthDateGenderForm = (props: NameBirthDateGenderFormProps) => {
    const { f, readOnly, blockedFields } = props;
    return (
        <ReadOnly readOnly={readOnly}>
            <LabelInput f={f("lastName")} skipErrors={[]} blockedConditionally={blockedConditionally('lastName', blockedFields)}/>
            <LabelInput f={f("firstName")} skipErrors={[]} blockedConditionally={blockedConditionally('firstName', blockedFields)}/>
            <LabelInput f={f("middleName")} skipErrors={[]} blockedConditionally={blockedConditionally('middleName', blockedFields)}/>
            <LabelDateInput f={f("birthDate")} skipErrors={[]} blockedConditionally={blockedConditionally('birthDate', blockedFields)}/>

            {
                readOnly
                    ? <LabelInput f={f("gender")} readOnly/>
                    : <RadioButtons f={f("gender")}/>
            }
            {
                readOnly ? <LabelInput f={f("id")}/> : <label/>
            }

        </ReadOnly>
    );
};



NameBirthDateGenderForm.schema = (now: any, t: Function, i18nPrefix: any, minAge: string) => {
    const nameSpec = [
        required(),
        maxLength(30),
        pattern(/^[а-яА-я \-'ёЁ]+$/),
        {
            beautifiers: beautifyNamePart,
            validators: validateNamePart
        }
    ];
    return {
        "firstName": nameSpec,
        "lastName": nameSpec,
        "middleName": nameSpec,
        "birthDate": [
            required(),
            date(),
            minDate(moment(now).subtract(100, "y")),
            maxDate(moment(now).subtract(minAge, "y")),
        ],
        "gender": [
            required(),
            {
                formatters: (val: any) => val && t(`${i18nPrefix}gender.values.${val}`),
                spec: {
                    values: ["MALE", "FEMALE"]
                }
            }
        ],
        "id": []
    }
};

function isNameSeparator(ch: string): boolean {
    return ch === " " || ch === "'" || ch === "-";
}

function beautifyNamePart(name: any): any {
    let makeCap = true, result = "";
    for (let ch of name) {
        const isSeparator = isNameSeparator(ch);
        result += isSeparator ? ch : (makeCap ? ch.toUpperCase() : ch.toLowerCase());
        makeCap = isSeparator;
    }
    return result;
}

function validateNamePart(name: any): any {
    if (!name) {
        return null;
    }

    const errors = [];
    let sepCount = 0;
    for (let i = 0; i < name.length; i++) {
        if (!isNameSeparator(name[i])) {
            continue;
        }
        if (i === 0) {
            errors.push("separatorAtStart");
        }
        ++sepCount;
        if (sepCount > 2) {
            errors.push("tooMuchSeparators");
            break;
        }
    }
    return errors;
}

export default NameBirthDateGenderForm;
