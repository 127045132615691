import React from "react";

import ReactFileReader from "react-file-reader";
import ErrorList from "../forms/ErrorList";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import "./ScanUpload.less";
import {validationClass} from "../forms/utils";

const ScanUpload = ({ f, t }) => {
    const [fileName, setFileName] = React.useState();
    const tPrefix = f.i18nPrefix + f.key;
    const handleChange = (file) => {
        f.onChange(file);
        file && file.fileList && setFileName(file.fileList[0].name)
    }
    return <div className={ classnames(validationClass(f)) }>
        <p>{t(`${tPrefix}.hint`)}</p>
        <div style={{display: 'flex', gap: '5px'}}>
            <input disabled className={'form-control'} value={fileName}/>
            <ReactFileReader fileTypes="image/jpeg" handleFiles={ file => handleChange(file) } base64 style={ {float: "left"} }>
                <button className={ classnames("btn", f.submittedOrDirty && !f.valid ? "btn-danger" : "btn-primary") } type="button">
                    { t(`${tPrefix}.label`) }
                </button>
            </ReactFileReader>
        </div>
        <ErrorList f={ f }/>
        { f.view && !f.errors.some(e => e.code === "wrongFormat") && <div className="row top-offset">
            <img className="col-md-12" src={ f.view.base64 || f.view } alt={ t(`${tPrefix}.alt`) }/>
        </div> }
    </div>;
};
ScanUpload.propTypes = {
    t: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired
};

export default withTranslation()(ScanUpload);