import React from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../redux/redux";
import GameSelector  from "../common/GameSelector";
import { MobileGames } from "../mobile/MobileGames";
import {Category} from "./interface";

export function PlayGames() {
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    if (isMobile) {
        return <MobileGames/>;
    } else {
        return <GameSelector category={Category.BASIC}/>;
    }
}
