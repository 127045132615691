export interface EcupsRegistration {
    offerAccepted: boolean;
    offerVersion: string;
    pdAgreementAccepted: boolean;
    pdAgreementVersion: string;
    acceptedDate: Date;
}

export const InitEcupsRegistration: EcupsRegistration = {
    offerAccepted: false,
    offerVersion: '',
    pdAgreementAccepted: false,
    pdAgreementVersion: '',
    acceptedDate: new Date()
};

export enum PhoneVerificationFailReason {
    WRONG_CODE = "WRONG_CODE",
    EXPIRED_CODE = "EXPIRED_CODE"
}

export interface EcupsCommonResponse {
    ok: boolean;
    errorNum: number;
    errorCode: string;
    verified?: boolean;
    failReason?: PhoneVerificationFailReason;
}
