exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./events/INTERLOTWORLD.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./events/FLIGHT.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./events/WEATHER.png"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./events/CHESS_PIECES.png"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./events/CHESS_PLUS.png"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./events/CHESS_LIVE.png"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./events/MT.png"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./events/RB.png"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("./events/MB.png"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./events/SB.png"));

// Module
exports.push([module.id, ".event-type-icon {\r\n    display: inline-block;\r\n    vertical-align: text-bottom;\r\n    background-size: cover;\r\n}\r\n\r\n.event-type-INTERLOTWORLD {\r\n    background-image: url(" + ___CSS_LOADER_URL___0___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-FLIGHT {\r\n    background-image: url(" + ___CSS_LOADER_URL___1___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-WEATHER {\r\n    background-image: url(" + ___CSS_LOADER_URL___2___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-CHESS_PIECES {\r\n    background-image: url(" + ___CSS_LOADER_URL___3___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-CHESS_PLUS {\r\n    background-image: url(" + ___CSS_LOADER_URL___4___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-CHESS_LIVE {\r\n    background-image: url(" + ___CSS_LOADER_URL___5___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-MT {\r\n    background-image: url(" + ___CSS_LOADER_URL___6___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-RB {\r\n    background-image: url(" + ___CSS_LOADER_URL___7___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-MB {\r\n    background-image: url(" + ___CSS_LOADER_URL___8___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.event-type-SB {\r\n    background-image: url(" + ___CSS_LOADER_URL___9___ + ");\r\n    width: 16px;\r\n    height: 16px;\r\n    margin-right: 10px;\r\n}", ""]);

