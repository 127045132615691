import React from 'react';
import {useTranslation} from "react-i18next";
import {UserInfo} from "../redux/auth";
import {disabledStyle} from "../util/cssUtils";
import {UPNavLink} from "../util/urlPrefix";
import axios from "axios";

interface StateProps {
    user: UserInfo;
}

type UserMenuProps = StateProps;

const UserMenu: React.FunctionComponent<UserMenuProps> = ({user}) => {
    const { t } = useTranslation();
    const [games, setGames] = React.useState<string>('');
    React.useEffect( () => {
        axios.get("/api/play/categories").then(response => {
            setGames(response.data);
        });
    }, []);

    const getBasicGamesMenuButtons = () => {
        return games.includes("BASIC") &&
            <li>
                <UPNavLink to="/play/games" className="btn btn-link">{t("play.games.title")}</UPNavLink>
            </li>
    }

    const getSportGamesMenuButtons = () => {
        return games.includes("SPORT") &&
            <li>
                <UPNavLink to="/play/sport" className="btn btn-link">{t("play.sport.title")}</UPNavLink>
            </li>
    }

    return <div style={{float: "left"}}>
        <div style={disabledStyle(user.closingAccountExist)}>
            <div className="dropdown" style={{display: "inline"}}>
                <button className="btn btn-link" data-toggle="dropdown">
                    <span className="pa-icon pa-icon-game"/>{t("play.title")} <span className="caret"/>
                </button>
                <ul className="dropdown-menu">
                    {getBasicGamesMenuButtons()}
                    {getSportGamesMenuButtons()}
                </ul>
            </div>
            {
                user.jackBetAvailable && (
                    <UPNavLink to="/jackbet" className="btn btn-link">
                        <span className="pa-icon pa-icon-jackbet"/>{t("jackbet.title")}
                    </UPNavLink>)
            }
            <div className="dropdown" style={{display: "inline"}}>
                <button className="btn btn-link" data-toggle="dropdown">
                    <span className="pa-icon pa-icon-history"/>{t("history.title")} <span className="caret"/>
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <UPNavLink to="/history/operations" className="btn btn-link">{t("history.operations.title")}</UPNavLink>
                    </li>
                    <li>
                        <UPNavLink to="/history/activity" className="btn btn-link">{t("history.activity.title")}</UPNavLink>
                    </li>
                    <li>
                        <UPNavLink to="/history/steps" className="btn btn-link">{t("history.steps.title")}</UPNavLink>
                    </li>
                </ul>
            </div>
            <UPNavLink to="/restrictions" className="btn btn-link">
                <span className="pa-icon pa-icon-restrictions"/>{t("restrictions.title")}
            </UPNavLink>
            <UPNavLink to="/help" className="btn btn-link">
                <span className="pa-icon pa-icon-help"/>{t("help.title")}
            </UPNavLink>
        </div>

    </div>;
};

export default UserMenu;
