import React, {useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import {validationClass} from "./utils";
import ErrorList from "./ErrorList";
import ValidationInput from "./ValidationInput";
import LabelFor from "./LabelFor";
import {useFiasAddress} from "../fias/fias-api";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const LabelInput = ({
                        t,
                        f,
                        id = f.key,
                        autoComplete,
                        type = "text",
                        placeholder = "default",
                        required = false,
                        readOnly,
                        autoFocus,
                        colMd = 8,
                        blockedConditionally = false,
                        level = null,
                        getParentId,
                        getParenValue,
                        updateLevel,
                        extraValid = true,
                        onChangeCallback,
                        skipErrors,
                        changeViewOnBlur = (value) => value,
                        errors = [],
                        maxLength = "2147483647"
}) => {
    const fullKey = f.spec.i18nKey || (f.i18nPrefix + id);
    const {address, update, onKeyUp, onKeyDown} = useFiasAddress(level);
    const [focus, setFocus] = React.useState(false);
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);
    const handleChange = ({target: {value}}) => {
        f.onChange(value)
        onChangeCallback && onChangeCallback();
        if (level && getParentId && update) {
            if (level.levelType === "buildings") {
                getParenValue && update(getParenValue("houses"), getParentId(level.levelType))
            } else {
                update(value, getParentId(level.levelType))
            }
            const findId = (address.find(a => a.name === value) || {id : 0}).objectId;
            updateLevel && updateLevel(level.levelType, findId, value);
        }
    }
    const customPlaceholder = placeholder !== "default";
    const isEmailInput = id === 'email';
    const [emailTemplateList, setEmailTemplateList] = useState([]);

    if (isEmailInput) {
        useEffect(() => {
            const fetchData = async () => {
                const resp = await axios.get("/api/register/emailTemplateList");
                setEmailTemplateList(resp.data);
            };

            fetchData();
        }, []);
    }


    const emailTooltip = () => {
        if (isEmailInput && emailTemplateList.length > 0) {
            return <div className={"col-md-1"}>
                <div style={{color: "#ab1919", fontSize: "23px", marginLeft: "-5px"}} data-tip data-for="email-tooltip">ⓘ</div>
                <ReactTooltip id="email-tooltip" type="dark" effect="float" place="right">
                    {t("register.email.allowedEmailTemplates") + emailTemplateList.join(', ')}
                </ReactTooltip>
            </div>
        }
    }

    const colMdNumber = isEmailInput && emailTemplateList.length > 0 ? colMd - 1: colMd;

    return <div className={classNames("form-group", !readOnly && validationClass(f, extraValid))}>
        <LabelFor id={id} translateKey={fullKey} required={f.spec.required && !readOnly}/>
        <div className={"col-md-" + colMdNumber}>
            <ValidationInput id={id}
                             type={type}
                             value={focus ? f.view : changeViewOnBlur(f.view)}
                             autoComplete={autoComplete}
                             onChange={handleChange}
                             placeholder={customPlaceholder
                                 ? placeholder
                                 : t([`${fullKey}.placeholder`, `${fullKey}.label`, `${id}.placeholder`, `${id}.label`])
                             }
                             required={f.spec.required}
                             valid={f.valid && extraValid}
                             readOnly={readOnly}
                             autoFocus={autoFocus}
                             onFocus={onFocus}
                             onBlur={onBlur}
                             blockedConditionally={blockedConditionally}
                             datalist={address}
                             datalistId={level ? level.levelType : null}
                             onKeyUp={onKeyUp}
                             onKeyDown={onKeyDown}
                             maxLength={maxLength}
            />
            <ErrorList f={f} errors={errors} id={id} skip={skipErrors}/>
        </div>
        {emailTooltip()}
    </div>;
};

export default withTranslation()(LabelInput);
