import {dateFormatter} from "./DateTimeUtils";

export const getDenomination = (state) => {
    if (state && state.session && state.session.details && state.session.details.denomination) {
        return state.session.details.denomination;
    } else {
        return 1;
    }
}

export const getCurrencyShowType = (state) => {
    //return "CURRENCY_CREDITS";
    //return "CURRENCY";
    //return "CREDITS";
    if (state && state.session && state.session.details) {
        return state.session.details.currencyShowType;
    } else {
        return "CREDITS";
    }
};

export const getTopQuittanceData = (state, receiptNo, stepNo, typeDetails) => {
    if (state && state.session && state.session.details && state.session.details[typeDetails]) {
        return {
            receiptNumber: receiptNo,
            stepNumber: stepNo,
            gameType: state.session.details[typeDetails].eventName,
            event: state.session.details[typeDetails].eventNumber ? state.session.details[typeDetails].eventNumber : "",
            stepTime: dateFormatter(state.session.details[typeDetails].registrationTime, state.session.timezoneOffset), //шаг выполнен
            timeResult: dateFormatter(state.session.details[typeDetails].eventTime, state.session.timezoneOffset), //время расчета результата
            resultTime: dateFormatter(state.session.details[typeDetails].calculationTime, state.session.timezoneOffset), //время наступления события
            eventType: state.session.details.eventType,
        }
    } else {
        return {
            receiptNumber: "/",
            stepNumber: "",
            gameType: "",
            stepTime: "",
            event: "",
            timeResult: "",
            resultTime: ""
        }
    }
};

export const getMainQuittanceData = (state, rate, prize, topQuittanceData, typeDetails) => {
    let denomination = getDenomination(state);
    if (state && state.session && state.session.details && state.session.details[typeDetails]) {
        return {
            ...topQuittanceData,
            combo: state.session.details[typeDetails].lineCount,
            credits: Math.round(rate / denomination),
            scores: rate,
            creditsResult: Math.round(prize / denomination),
            scoresResult: prize,
            choiceCombination: state.session.details[typeDetails].choiceCombination,
            symbolsExpressBet: (state.session.details[typeDetails].symbolsExpressBet || []).slice(0, 6),
            symbolsSeb: (state.session.details[typeDetails].symbolsSeb || []).map(s => ({symbol: s, color: 'WHITE'})),
            eb: {
                rate: rate
            }
        }
    }
    return topQuittanceData;
};