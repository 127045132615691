import React from "react";
import {withTranslation} from "react-i18next";
import {form} from "../validation/FormController";
import LabelInput from "../forms/LabelInput";
import LabelCheckbox from "../forms/LabelCheckbox";
import {compose} from "redux";
import {preloadGet} from "../util/preload";
import withReloading from "../Reloading";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import {useNotifications} from "../util/Notifications";
import {withRouter} from "react-router";

const JackBet = ({t, f, history}) => {

    const auth = useSelector((state) => state.auth);
    const { addNotification } = useNotifications();

    const onSubmit = (stepParams) => {
        if (auth && auth.user && auth.user.applicationExist) {
            addNotification({
                message: t('personal.applicationExist')
            });
        } else {
            history.push({
                pathname: "/payment/deposit",
                state: {jackBetParams : stepParams}
            });
        }
    };

    // Ничего более умного не придумал, возможно есть более простой способ
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = addZero(date.getFullYear());
        // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
        const month = addZero(date.getMonth() + 1);
        const dayOfMonth = addZero(date.getDate());
        const hours = addZero(date.getHours());
        const minutes = addZero(date.getMinutes());
        const seconds = addZero(date.getSeconds());
        return dayOfMonth + "." + month + "." + year + " " + hours + ":" + minutes + ":" + seconds;
    };

    const isMobile = useSelector((state) => state.isMobileView);

    const addZero = (n) => {
        return n < 10 ? '0' + n : n;
    };

    let gamesList = "";
    f.model.games.forEach((game) => gamesList += " " + game);


    function renderHeaderTitle() {
        if (isMobile) {
            return <MobileDualHeader firstHeader={t('jackbet.title')}/>
        } else {
            return <h2 className="text-center">{t('jackbet.title')}</h2>
        }

    }

    return <div className={"row"} style={{fontSize: isMobile ? 12 : 'inherit'}}><div className="col-md-8 col-md-offset-2">

        {renderHeaderTitle()}

        <form name="jackbet" className="form-horizontal" onSubmit={f.submit(onSubmit)}>
            <div>
                <div className="col" style={{marginTop: 10, textAlign: isMobile ? 'left' : 'center'}}>
                    <p>{t('jackbet.rules')}</p>
                </div>
                <LabelInput f={f('step')} readOnly={true}/>
                <div style={{textAlign: isMobile ? 'left' : 'center'}}>
                    <label>{t('jackbet.prizes.label')}</label>
                </div>
                <LabelInput f={f('odds')} readOnly={true}/>
                <LabelInput f={f('level2Prize')} readOnly={true}/>
                <LabelInput f={f('level3Prize')} readOnly={true}/>

                <div className="form-group">
                    <label className={ classNames("col-md-4", "control-label") }>{t("jackbet.end.label")}</label>
                    <div className="col-md-8">
                        <input
                            value={formatDate(f.model.end)}
                            className="form-control control-for-read"
                            readOnly={true}
                        />
                    </div>
                </div>

                <label>{t('jackbet.games.label')}</label> {gamesList}

                <div className="col">
                    <div className="text-center">
                        <LabelCheckbox f={f('acceptConditions')} readOnly={!f.model.available }/>
                    </div>
                </div>

                <div className="col" style={{textAlign: "center", marginTop: 10}}>
                    <button style={{width: isMobile ? '100%' : 'initial'}} className="btn btn-primary"
                            disabled={!f.model.acceptConditions || !f.model.available }>
                        {t('jackbet.sendBtn')}
                    </button>
                </div>

            </div>
        </form>
    </div>
    </div>;
};

export default compose(
    withTranslation(),
    withReloading(),
    preloadGet("/api/jackbet/current-params", "model"),
    form({
        initialModel: ({model}) => {return model},
        i18nPrefix: "jackbet.",
        schema: {
            "step": {},
            "odds": {},
            "level2Prize": {},
            "level3Prize": {},
            "end": {},
            "games": {},
            "acceptConditions": {},
            "available": {}
        }
    }),
)(withRouter(JackBet));
