import React from "react";
import { useDispatch } from "react-redux";
import menuIcon from '../icons/mobile/menu.png';
import logo = require('../icons/mobile/logo.png');

export function MobileHeader() {
    const dispatch = useDispatch();

    function toggleSidebar() {
        dispatch({ type: "TOGGLE_SIDEBAR_STATUS" });
    }

    return (<div style={{
        backgroundColor: '#AB1919',
        height: 60,
        backgroundImage: `url(${logo})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 18px'
    }}>
        <div style={{
            backgroundImage: `url(${menuIcon})`,
            height: 20,
            width: 24,
            cursor: "pointer"
        }} onClick={toggleSidebar}/>
    </div>);
}
