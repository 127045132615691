import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import {ChessBoard} from './ChessBoard';
import ChessLinks from './ChessLinks';
import { timeFormatter } from "../../DateTimeUtils";

class ChessBoardRip extends Component {

  constructor(props) {
    super(props);
    this.state = {
      triad: [-1,-1,-1],
      chessInfoDatum: {},
      view:"showAll"
    }
    this.chessLinks = new ChessLinks();
    this.returnRightString = this.returnRightString.bind(this);
  }

  getLocaleString = (prefix, a, args) => this.props.t(prefix + "." + a, args);

  UNSAFE_componentWillReceiveProps(nextProps){
    if (typeof(nextProps) === 'undefined' || typeof(this.nextProps) === 'undefined') {
        return
    }
    if (typeof(this.nextProps.triadNum) === "undefined") {
        nextProps.triadNum = 1;
    }
    let triad = nextProps.dataForBoard.symbolTriads[nextProps.triadNum-1].reverse();
    this.setState({triad: triad});
    let chessInfoDatum = nextProps.dataForBoard.chessInfoDatums[nextProps.triadNum-1];
    this.setState({chessInfoDatum: chessInfoDatum});
    this.setState({pieces:this.rawsOnTheBoard});
  }

  UNSAFE_componentWillMount(){
    if (typeof(this.props.triadNum) === "undefined") {
        this.props.triadNum = 1;
    }
    let triad = this.props.dataForBoard.symbolTriads[this.props.triadNum-1];
    this.setState({triad: triad});
    let chessInfoDatum = this.props.dataForBoard.chessInfoDatums[this.props.triadNum-1];
    if (chessInfoDatum) {
        this.setState({chessInfoDatum: chessInfoDatum});
    }
  }

  returnRightString(short, groupNumber) {
    return (short) ? "" : " " + this.getLocaleString("chess", "group") + " " +  groupNumber;
  }

  render() {
    const {chessInfoDatum, triad} = this.state;
    const {dataForBoard, short, expanderSymbol} = this.props;
    let datums;
    if (typeof(dataForBoard.datums[this.props.triadNum-1]) === "undefined") {
        datums = 1;
    } else {
        datums = dataForBoard.datums[this.props.triadNum-1].group
    }
    const isHatGame = typeof(dataForBoard.hatState) === "undefined";
    return (
          <div className="dataChessInKvitok">
              <ChessBoard fen={chessInfoDatum.fen}/>
              <div>
                  {[
                      this.getLocaleString("chess", "chessBoard") +" "+  chessInfoDatum.currentRound,
                      this.getLocaleString("chess", isHatGame ? "gameNumber" : "game", {game: chessInfoDatum.matchId}),
                      this.getLocaleString("chess", isHatGame ? "moveNumber" : "move", {move: chessInfoDatum.moveNumber}),
                      this.getLocaleString("chess", "timeOfMove", {time: timeFormatter(chessInfoDatum.timeOfMove, dataForBoard.timezone)}),
                      this.getLocaleString("chess", "sum", {sum: chessInfoDatum.sum + this.returnRightString(short, datums)})
                  ].map((el, i) => (<p key={i} className="dataUnderChessBoardInKvitokText">{el}</p>))}
              </div>
              <div className="dataUnderChessBoardInKvitokText" style={{fontWeight : "bold"}}>
                { isHatGame
                    ? (short)
                        ? ""
                        // s === '10' - символ в СЭБ игр БОР, который необходимо заменить на expanderSymbol
                        // смотри также src/main/web/src/history/balls/regular/MatrixRegularKvitokChess.js:115
                        : this.getLocaleString("chess", "symbolsOfMulti", {symbols: triad.map(s => s === '10' && !!expanderSymbol ? expanderSymbol : s).filter((e, i) => i < 3).join(",")})
                    : this.getLocaleString("chess", "symbol", {symbol: dataForBoard.result[parseInt(chessInfoDatum.currentRound)-1]}) }
              </div>
          </div>
    );
  }
}

export default withTranslation()(ChessBoardRip);