import React, {useEffect, useState} from "react";
import axios, {AxiosPromise} from "axios";

export type PreloadCallback<Props, PreloadProps> = (props: Props) => Promise<PreloadProps>;
export type ReloadCallback = () => Promise<void>;
export type AxiosPreloadCallback<Props, ResponseData> = (props: Props) => AxiosPromise<ResponseData>;
export type ResponseCallback<ResponseData, PreloadProps> = (data: ResponseData) => PreloadProps;
export type ResponseCallbackOrProp<ResponseData, PreloadProps> = keyof PreloadProps | ResponseCallback<ResponseData, PreloadProps>;
export type UrlOrFunc<Props> = string | ((props: Props) => string);
export type InnerComponentProps<Props, PreloadProps> = Props & PreloadProps & { reload: ReloadCallback };

export type PreloadHOCType<PreloadProps, Props> =
    (WrappedComponent: React.ComponentType<InnerComponentProps<Props, PreloadProps>>) => React.ComponentType<Props>;

export const preload = <PreloadProps, Props>(fn: PreloadCallback<Props, PreloadProps>, reloadProps?: Array<keyof Props>): PreloadHOCType<PreloadProps, Props> =>
    Component => props => {
        const [ resultProps, setResultProps ] = useState<PreloadProps | undefined>(undefined);
        const reload: ReloadCallback = () => fn(props).then(setResultProps);
        useEffect(() => { reload(); }, reloadProps ? reloadProps.map(reloadPropsKey => props[reloadPropsKey]) : []);
        return resultProps ? <Component {...props} {...resultProps} reload={reload}/> : null;
    };

export const preloadHttp = <PreloadProps, Result, Props>(
    req: AxiosPreloadCallback<Props, Result>,
    callbackOrProp: ResponseCallbackOrProp<Result, PreloadProps>,
    reloadProps?: Array<keyof Props>
): PreloadHOCType<PreloadProps, Props> => preload(props =>
    req(props).then(resp => typeof callbackOrProp === "function"
        ? callbackOrProp(resp.data)
        : {[callbackOrProp]: resp.data} as any as PreloadProps
    ),
    reloadProps
);

export const preloadGet = <PreloadProps, Result, Props>(url: UrlOrFunc<Props>, callbackOrProp: ResponseCallbackOrProp<Result, PreloadProps>, reloadProps?: Array<keyof Props>): PreloadHOCType<PreloadProps, Props> =>
    preloadHttp<PreloadProps, Result, Props>(props => axios(typeof url === "function" ? url(props) : url), callbackOrProp, reloadProps);
