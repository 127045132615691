import React from "react";

interface OwnProps {
    label: string;
}

// can't be a functional component (function components can't be given refs)
class PickerInput extends React.Component<OwnProps> {
    render() {
        const { label, ...rest } = this.props;
        return <div className="input-group">
            <div className="input-group-addon">{label}</div>
            <input type="text" {...rest} className="form-control"/>
        </div>
    }
}

export default PickerInput;
