import React from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import CupisBind from "../bind/CupisBind";
import PcBind from "../bind/PcBind";
import {
    Authentication,
    CupisIdentificationStatus, isClarificationPDStatus, isInnNeedConfirm,
    isRegisterStageStatus,
    PcIdentificationStatus,
    UserInfo,
    UserStatus
} from "../redux/auth";
import {ReduxState} from "../redux/redux";
import moment from 'moment';
import {UPRedirect} from "../util/urlPrefix";
import UserMaster from "./UserMaster";
import {LockLoaderPageWrapper} from "../loader/LockLoader";
import {CongratulationMessage} from "../bind/CongratuliationMessage";
import CupisRegistration from "../bind/CupisRegistration";
import {InnConfirming} from "../bind/InnConfirming";

const Blocked: React.FC = () => {
    const { t } = useTranslation();
    return <div>
        <div className="row">
            <h4 className="col-md-12 text-center">{ t("blocked.title") }</h4>
        </div>
    </div>
};

export const Frozen: React.FC<{ user: UserInfo }> = ({ user }) => {
    const { t } = useTranslation();
    return <div>
        <div className="row">
            <div className="col-md-12">
                <h2 className="text-center">{ t("frozen.title") }</h2>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 text-center">
                { t("frozen.type." + (user.frozenUpTo ? "UP_TO" : "FOREVER"),
                    { frozenUpTo: moment(user.frozenUpTo).format("DD.MM.YYYY HH:mm:ss") }) }
            </div>
        </div>
    </div>
};

interface StateProps {
    auth: Authentication;
}

type PreconditionsProps = StateProps;

const Preconditions: React.FC<PreconditionsProps> = ({ auth }) => {
    if (auth.authenticated === undefined) {
        return null;
    }
    if (!auth.user) {
        return <UPRedirect to="/login"/>;
    }
    if (auth.user.status === UserStatus.BLOCKED) {
        return (<LockLoaderPageWrapper>
            <Blocked/>
        </LockLoaderPageWrapper>);
    }
    if (auth.user.pcIdentification.status !== PcIdentificationStatus.IDENTIFIED || auth.user.status === UserStatus.INN_OBTAINING) {
        return (<LockLoaderPageWrapper>
            <PcBind/>
        </LockLoaderPageWrapper>);
    }
    if (isInnNeedConfirm(auth.user.status)) {
        return (<LockLoaderPageWrapper>
            <InnConfirming/>
        </LockLoaderPageWrapper>);
    }
    if (isRegisterStageStatus(auth.user.cupisIdentification.status)) {
        return (<LockLoaderPageWrapper>
            <CupisRegistration/>
        </LockLoaderPageWrapper>);
    }
    if (isClarificationPDStatus(auth.user.cupisIdentification.status)) {
        return <UserMaster/>;
    }
    if (!auth.user.cupisIdentification.ok) {
        return (<LockLoaderPageWrapper>
            <CupisBind/>
        </LockLoaderPageWrapper>);
    }
    if (auth.user.cupisIdentification.status === CupisIdentificationStatus.IDENTIFY_CONFIRMED) {
        return (<LockLoaderPageWrapper>
            <CongratulationMessage/>
        </LockLoaderPageWrapper>)
    }
    return <UserMaster/>;
};

export default connect<StateProps, {}, {}, ReduxState>(state => ({ auth: state.auth }))(Preconditions);
