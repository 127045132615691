import React from "react";
import ChessQuittance from "./chess/KvitokNew";
import {getDataForKvitokNewChess} from './chess/kvitokSelector';
import {isChessEventType} from '../commons/chess/ChessEventTypes';
import "./style.css";

class QuittanceSelector extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, print, close} = this.props;
        if (!isChessEventType(step.type)) {
            console.log("Unknown darts EventType " + eventType);
            return <div/>
        }
        let data = getDataForKvitokNewChess({session: step.events[step.events.length - 1].session}, step);
        return (<ChessQuittance close={ close } step={step} print={ print } dataForKvitok={data}/>);
    }
}
export default QuittanceSelector;
