import React from 'react';
import PropTypes from 'prop-types';
import LabelInput from "../../forms/LabelInput";
import LabelDateInput from "../../forms/LabelDateInput";
import ReadOnly from "../../forms/ReadOnly";
import {date} from "../../validation/functionalPlugins";
import { blockedConditionally } from "../../forms/utils";

const MigrationRegistrationForm = ({f, t, readOnly, blockedFields}) => {
    const part1 = (
        <ReadOnly readOnly={readOnly}>
            <LabelInput f={f("foreignCitizen.migrationRegistration.type")} blockedConditionally={blockedConditionally('foreignCitizen.migrationRegistration.type', blockedFields)}/>
            <LabelInput f={f("foreignCitizen.migrationRegistration.serial")} blockedConditionally={blockedConditionally('foreignCitizen.migrationRegistration.serial', blockedFields)}/>
            <LabelInput f={f("foreignCitizen.migrationRegistration.number")} blockedConditionally={blockedConditionally('foreignCitizen.migrationRegistration.number', blockedFields)}/>
        </ReadOnly>
    );

    const part2 = (
        <ReadOnly readOnly={readOnly}>
            <LabelDateInput f={f("foreignCitizen.migrationRegistration.issueDate")} blockedConditionally={blockedConditionally('foreignCitizen.migrationRegistration.issueDate', blockedFields)}/>
            <LabelDateInput f={f("foreignCitizen.migrationRegistration.endDate")} blockedConditionally={blockedConditionally('foreignCitizen.migrationRegistration.endDate', blockedFields)}/>
        </ReadOnly>
    );

    const form = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    {part1}
                </div>
                <div className="col-md-6">
                    {part2}
                </div>
            </div>
        )
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">{t(f.i18nPrefix + "migrationRegistrationTitle")}</h4>
                </div>
            </div>
            { form() }
        </div>
    );
};

MigrationRegistrationForm.propTypes = {
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    blockedFields: PropTypes.array,
    readOnly: PropTypes.bool
};

MigrationRegistrationForm.schema = (t, i18nPrefix) => {
    return {
        "foreignCitizen.migrationRegistration.type": [
            {
                formatters: val => val && t(`${i18nPrefix}foreignCitizen.migrationRegistration.type.values.${val}`),
                spec: {
                    values: ["MIGRATION_CARD","TEMPORARY_RESIDENCE_PERMIT","RESIDENCE"]
                }
            }
        ],
        "foreignCitizen.migrationRegistration.serial": {},
        "foreignCitizen.migrationRegistration.number": {},
        "foreignCitizen.migrationRegistration.issueDate": date(),
        "foreignCitizen.migrationRegistration.endDate": date()
    }
};

export default MigrationRegistrationForm;