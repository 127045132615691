import React, {ChangeEvent, useState} from 'react';
import LabelInput from "../forms/LabelInput";
import LabelDateInput from "../forms/LabelDateInput";
import { resultType } from "../validation/FormController";

import {date, maxDate, maxLength, minDate, minLength, pattern, required} from "../validation/functionalPlugins";
import LabelFor from "../forms/LabelFor";
import axios from "axios";
import moment from "moment";

export type IdentificationFormProps = {
    f: resultType;
}

type VideoTypes = 'SKYPE' | 'TAMTAM' | 'WHATSAPP';

const timeRanges = ['10:00 - 10:30', '10:30 - 11:00', '11:30 - 12:00', '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00',
    '14:00 - 14:30', '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:00 - 16:30', '16:30 - 17:00', '17:00 - 17:30', '17:30 - 18:00',
    '18:00 - 18:30', '18:30 - 19:00', '19:00 - 19:30', '19:30 - 20:00'];


const IdentificationForm = ({f}: IdentificationFormProps) => {
    const params = new URLSearchParams(location.search);
    const identType = params.get('type');

    const identTypes = identType ? identType.split(',') : [f.model.identification.type];
    const videoTypes = identTypes.filter(t => t === 'SKYPE' || t === 'TAMTAM' || t === 'WHATSAPP');

    const isVideoType = videoTypes && videoTypes.length > 0;
    const [availableVideo, setAvailableVideo] = useState<Array<VideoTypes>>([]);

    const [selectedType, setSelectedType] = useState<VideoTypes>();

    React.useEffect(() => {
        f.model.identification.meetingTime = timeRanges[0];
        f.model.identification.callTime = timeRanges[0];
    }, []);

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        if (event.target) {
            setSelectedType(event.target.value as VideoTypes);
            f.model.identification.type = event.target.value;
            f.model.identification.skype = "";
            f.onChange(f.model);
        }
    }

    function handleChangeMeetingTime(event: ChangeEvent<HTMLSelectElement>) {
        if (event.target) {
            f.model.identification.meetingTime = event.target.value;
        }
    }

    function handleChangeCallTime(event: ChangeEvent<HTMLSelectElement>) {
        if (event.target) {
            f.model.identification.callTime = event.target.value;
        }
    }

    React.useEffect(() => {
        axios.get("/api/register/video-services")
            .then(resp => {
                let video = resp.data.sort();
                setAvailableVideo(video);
                if (isVideoType) {
                    f.model.identification.type = video[0];
                    setSelectedType(video[0]);
                    f.onChange(f.model);
                } else {
                    f.model.identification.type = identTypes[0];
                }
            });
    }, []);

    return (
        <div className="row">
            <div className="col-md-12">

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            { isVideoType &&
                            <div>
                                <LabelFor id="identification.type" translateKey={f.spec.i18nKey || (f.i18nPrefix + "identification.type")} required={f.spec.required} colmd={4}/>
                                <div className="col-md-8">
                                    <select id="identification.type" onChange={ e => handleChange(e) } className="form-control">
                                        {availableVideo.map((v: VideoTypes, index) => <option value={v} key={index}>{v}</option>)}
                                    </select>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                { isVideoType ?
                    f.model.identification.type === 'SKYPE' ? (
                        <LabelInput f={f("identification.skype")}/>
                    ) : <LabelFor id="identification.type" translateKey={f.spec.i18nKey || (f.i18nPrefix + "identification.videocall")} required={f.spec.required} colmd={12}/>
                    : null
                }

                {
                    f.model.identification.type === "PLACE"
                        ?
                            <div>
                                <LabelInput f={f("identification.address")}/>
                                <LabelDateInput f={f("identification.meetingDate")} withManual={false}/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <LabelFor id="identification.meetingTime" translateKey={f.spec.i18nKey || (f.i18nPrefix + "identification.meetingTime")} required={f.spec.required} colmd={4}/>
                                            <div className="col-md-8">
                                                <select id="identification.meetingTime" onChange={ e => handleChangeMeetingTime(e) } className="form-control">
                                                    {timeRanges.map((v: string, index) => <option value={v} key={index}>{v}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null
                }
                {
                    ['SKYPE', 'WHATSAPP', 'TAMTAM'].includes(f.model.identification.type)
                        ?
                            <div>
                                <LabelDateInput f={f("identification.callDate")} withManual={false}/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <LabelFor id="identification.callTime" translateKey={f.spec.i18nKey || (f.i18nPrefix + "identification.callTime")} required={f.spec.required} colmd={4}/>
                                            <div className="col-md-8">
                                                <select id="identification.callTime" onChange={ e => handleChangeCallTime(e) } className="form-control">
                                                    {timeRanges.map((v: string, index) => <option value={v} key={index}>{v}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null
                }
            </div>
        </div>
    );
}

IdentificationForm.schema = (identificationType: any) => {
    const now = moment();
    return {
        "identification.address": [
            required(identificationType === "PLACE"),
            maxLength(150)
        ],
        "identification.skype": identificationType === "SKYPE" ? [
            required(),
            minLength(6),
            maxLength(32),
            pattern(/[a-zA-Z][a-zA-Z0-9.,\-_]+$/)
        ] : [],
        "identification.meetingDate": [
            required(identificationType === 'PLACE'),
            date(),
            minDate(moment({year: moment().year(), month: moment().month(), day: moment().date()})),
            maxDate(moment(now).add(7, "d")),
        ],
        "identification.meetingTime": [
            // required(identificationType === 'PLACE')
        ],
        "identification.callDate": [
            required('SKYPE,TAMTAM,WHATSAPP' === identificationType),
            date(),
            minDate(moment({year: moment().year(), month: moment().month(), day: moment().date()})),
            maxDate(moment(now).add(3, "d")),
        ],
        "identification.callTime": [
            // required(['SKYPE', 'WHATSAPP', 'VIBER'].includes(identificationType))
        ]
    }
};

export default IdentificationForm;
