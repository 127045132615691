import React from "react";
import classNames from "classnames";

import PropTypes from "prop-types";

import "./ValidationInputAddon.less";

function inputGroupClass(valid) {
    return valid === true ? "glyphicon-ok" : valid === false ? "glyphicon-remove" : "loader";
}

export default function ValidationInputAddon({ valid }) {
    return <div className="input-group-addon">
        <div className={ classNames("glyphicon", inputGroupClass(valid)) }/>
    </div>;
}
ValidationInputAddon.propTypes = {
    valid: PropTypes.bool
};