import React from 'react';
import PropTypes from 'prop-types';
import LabelInput from "../../forms/LabelInput";

import {date} from "../../validation/functionalPlugins"
import ReadOnly from "../../forms/ReadOnly";
import classNames from "classnames";
import { blockedConditionally } from "../../forms/utils";

const AddressInRFForm = ({f, t, readOnly, blockedFields}) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">{t(f.i18nPrefix + "addressInRFTitle")}</h4>
                </div>
            </div>
            <div className="row">
                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className={ classNames("control-label", "col-md-4") }>
                                    {t(f.i18nPrefix + "foreignCitizen.localRegistration.period.label")}
                                </label>
                                <div className="col-md-4">
                                    <input className="form-control" value={ f("foreignCitizen.localRegistration.from").view }/>
                                </div>
                                <div className="col-md-4">
                                    <input className="form-control" value={ f("foreignCitizen.localRegistration.to").view }/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LabelInput f={f("address.region")} blockedConditionally={blockedConditionally('address.region', blockedFields)}/>
                    <LabelInput f={f("address.district")} blockedConditionally={blockedConditionally('address.district', blockedFields)}/>
                    <LabelInput f={f("address.city")} blockedConditionally={blockedConditionally('address.city', blockedFields)}/>
                </ReadOnly>

                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelInput f={f("address.locality")} blockedConditionally={blockedConditionally('address.locality', blockedFields)}/>
                    <LabelInput f={f("address.street")} blockedConditionally={blockedConditionally('address.street', blockedFields)}/>
                    <LabelInput f={f("address.house")} blockedConditionally={blockedConditionally('address.house', blockedFields)}/>
                    <LabelInput f={f("address.flat")} blockedConditionally={blockedConditionally('address.flat', blockedFields)}/>
                </ReadOnly>
            </div>
        </div>
    );
};

AddressInRFForm.propTypes = {
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    blockedFields: PropTypes.array,
    readOnly: PropTypes.bool
};

AddressInRFForm.schema = {
    "foreignCitizen.localRegistration.from": date(),
    "foreignCitizen.localRegistration.to": date(),
    "address.region": {},
    "address.district": {},
    "address.city": {},
    "address.locality": {},
    "address.street": {},
    "address.house": {},
    "address.flat": {},
};

export default AddressInRFForm;
