import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import { ReduxState } from "../../redux/redux";
import { InnerHelp } from "./InnerHelp";
import {LinksHelp} from "./LinksHelp";

export function Help() {
    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    return <div>
        {isMobile ? <MobileDualHeader firstHeader={t("help.title")} secondHeader={t("help.faq.title")}/> : <>
            <h2 className="text-center">{t("help.title")}</h2>
            <h4>{t("help.faq.title")}</h4>
        </>}
        <InnerHelp/>
        <LinksHelp/>
    </div>;
}
