import PropTypes from "prop-types";
import React from "react";
import Plus from "./Plus";
import Minus from "./Minus";

type SelectionListProps = {
    events: Array<any>;
}

const SelectionList = ({ events }: SelectionListProps) =>
    <ul>
        {
            events.map((e) => {
                let sign = null;

                switch (e.selectionMatch) {
                    case 'MATCHES':
                        sign = <Plus/>;
                        break;
                    case 'NOT_MATCHES':
                        sign = <Minus/>;
                }

                return <li key={e.id}>{e.selection} {sign}</li>;
            })
        }
    </ul>;

export default SelectionList;