import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

type SidebarItemProps = {
    value: string | ReactNode;
    setHeader?: string;
    // secondHeader: string;
    href?: string;
    isExternalLink?: boolean;
    children?: ReactNode;
    warningIcon?: boolean;
}

export function SidebarItem(props: SidebarItemProps) {
    const [showChilds, setShowChilds] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();

    function handleMenuItem() {
        if (props.isExternalLink && props.href) {
            location.href = props.href;
            location.replace(props.href);
        } else {
            history.push(props.href ? props.href : '');
            dispatch({ type: "TOGGLE_SIDEBAR_STATUS" });
            if (props.setHeader) {
                dispatch({ type: "SET_MOBILE_HEADERS", mobileHeader: props.setHeader });
            }
        }

    }

    return (
        <div className='sidebar-menu-item'
             onClick={props.children ? () => setShowChilds(prevState => !prevState) : handleMenuItem}>
            <div className={'sidebar-menu-item-wrapper'}>
                <div>{props.value}</div>
                {/*<div className="pa-icon pa-icon-session-exist" style={{width: 18, height: 18, marginRight: 3}}/>*/}
                {props.children && !showChilds && <div className="pa-icon pa-icon-arrow-down"/>}
                {props.children && showChilds && <div className="pa-icon pa-icon-arrow-up"/>}
                {props.warningIcon && <div className="pa-icon pa-icon-session-exist"/>}
            </div>
            {showChilds && props.children}
        </div>
    );

}
