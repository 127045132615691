import moment from 'moment';

moment.locale("RU");

export const getKvitokData = (state) => {
     var result = { KVITNUMBER: "/", STEPNUMBER: "", GAME_TYPE: "", STEPTIME: "", EVENT: "", TIMERESULT: "", RESULTTIME: "" };
     if(state && state.session && state.session.rateDetails) {
          result.KVITNUMBER = state.session.rateDetails.receiptNumber;
          result.STEPNUMBER = state.session.rateDetails.stepNumber;
          result.GAME_TYPE = state.session.rateDetails.eventName;
          result.EVENT = state.session.rateDetails.eventNumber ? state.session.rateDetails.eventNumber : "";
          result.STEPTIME = getData(state.session.registrationTime);
          result.TIMERESULT = getData(state.session.calculationTime);
          result.RESULTTIME = getData(state.session.eventTime);
     }
    return result;

}

export const getDataForKvitokNewChess = (state, step)  => {
  var constData = {};
  var trans = {ROCK: 0, SCISSOR: 1, PAPER: 2};
  var data = [];

  if(state)
    var session = state.session;
  if(session)
    var details = session.details;
  if(details.knbDetails)
    var knbDetails = details.knbDetails;
  if (details.rateDetails)
    var commonRateDetails = details.rateDetails;
  if (knbDetails.rounds)
    var rounds = knbDetails.rounds;
  if (!rounds || (rounds.length <= 0))
    return {
      data: data,
      constData: constData
    }
  else{
    var datum;
    for (var i in rounds)
    {
      datum = {};
      var rateDetails = rounds[i].rateDetails;

      datum.eventName = commonRateDetails.eventName;
      datum.eventNumber = rateDetails.eventNumber;
      datum.chessBoardNumber = i+1;
      var chessInfo = rateDetails.chessInfos[0];
      datum.gameNumber = chessInfo.matchId;
      datum.stepNumber = chessInfo.moveNumber;
      datum.moveTime = chessInfo.moveDate;
      datum.sum = chessInfo.sum;
      datum.group = trans[rounds[i].server];
      datum.symbol = (rounds[i].result === 'LOSE') ? 0 : 1;
      datum.timeResult = rateDetails.eventTime;
      datum.categoryOfCoeff = trans[rounds[i].player];
      datum.timeChoose = rateDetails.registrationTime;
      datum.fen = chessInfo.fen;
      datum.timezone = session.timezoneOffset;
      data.push(datum);
    }
    rateDetails = commonRateDetails;
    constData.kvitNumber = rateDetails.receiptNumber;
    constData.rules = details.wagerRules;
    constData.stepSizeCredits = Math.round(step.betAmount / details.denomination); //knbDetails.rate;
    constData.stepSizeBalls = step.betAmount;//(constData.stepSizeCredits * parseFloat(details.denomination)).toFixed(2);
    constData.stepNumber = rateDetails.stepNumber;
    constData.eventName = commonRateDetails.eventName;
    constData.stepTime = rateDetails.registrationTime;
    constData.countTime = rateDetails.calculationTime;
    constData.creditsResult = Math.round(step.winAmount / details.denomination);//parseFloat(knbDetails.prize);
    constData.ballsResult= step.winAmount;//(constData.creditsResult * parseFloat(details.denomination)).toFixed(2);
    constData.coeff = rateDetails.coefficient;
    constData.currencyType = details.currencyShowType;
    constData.denomination = details.denomination;
  }

  return {
    data: data,
    constData: constData
  }
};

function getData(timestamp) {
    var m = moment(timestamp);
    return m.format("h:mm:ss") + " " + m.format("ll");
}
