import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import axios from "axios";
import "../restrictions/common/style.css";
import {useProgressBar} from "../redux/loadingPage";
import {useDispatch} from "react-redux";

const ReportButton = ({filterParams, dataUrl, t, close, closeModal, onOk}) => {
    const {start, complete} = useProgressBar(useDispatch());

    function handleClick(e) {
        e.preventDefault();
        start();
        axios.post(dataUrl, filterParams,).then(onOk).finally(complete);
        close();
    }

    function closeWindow(e) {
        e.preventDefault();
        closeModal();
    }
    return (
        <div>
            <button className="btn btn-primary size-div" onClick={handleClick}>
                {t('history.steps.ok')}
            </button>
            <button className="btn btn-primary size-div" onClick={closeWindow}>
                {t('history.steps.cancel')}
            </button>
        </div>
    );
};

ReportButton.propTypes = {
    t: PropTypes.func.isRequired,
    filterParams: PropTypes.object.isRequired,
    dataUrl: PropTypes.string.isRequired,
    close: PropTypes.func,
    closeModal: PropTypes.func,
    onOk: PropTypes.func
};
ReportButton.defaultProps = {};

export default withTranslation()(ReportButton);
