import React from "react";
import {useTranslation} from "react-i18next";
import {VerifyPhone} from "../verify/VerifyPhone";
import { ConfirmationRequestResultType } from "./AuthTypes";


type LoginVerifyFormProps = {
    onVerified: any,
    phone: string,
    confirmationRequestResult: ConfirmationRequestResultType;
}

export const LoginVerifyForm = ({ onVerified, phone, confirmationRequestResult }: LoginVerifyFormProps) => {
    const { t } = useTranslation();
    return <div>

        <div className="row">
            <div className="col-md-6 col-md-offset-3">{t("login.verify.message")}</div>
        </div>
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <VerifyPhone onVerifyUrl={ (code: any) => `/api/auth/verify?code=${encodeURIComponent(code)}` }
                             phone={ phone }
                             onVerified={ onVerified }
                             confirmationRequestResult={ confirmationRequestResult }/>
            </div>
        </div>
    </div>;
};