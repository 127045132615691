import axios from "axios";
import {Authentication, NOT_AUTHENTICATED, setAuth} from "./redux/auth";
import AppStore from "./redux/redux";

export function queryAndUpdateAuth(): Promise<Authentication> {
    return axios.get("/api/auth")
        .then(resp => resp.data as Authentication)
        .then(auth => dispatchAuth(auth));
}

export function logout(url: string): Promise<void> {
    return axios.post("/api/auth/logout").then(resetAuth).then(() => goToHomePage(url));
}

export function dispatchAuth(auth: Authentication): Authentication {
    return AppStore.dispatch(setAuth(auth)).auth;
}

export function resetAuth() {
    AppStore.dispatch(setAuth(NOT_AUTHENTICATED));
}

export function goToHomePage(url: string) {
    window.location.replace(url);
}
