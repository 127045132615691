import React from 'react';
import { withTranslation } from "react-i18next";
import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';
import './style.css';

class Header extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        const {kvitokData, print, close, t} = this.props;
        return (
            <div className="matrixRegularKvitok-header bg">
                <PrintButton print={ print }/>
                <h2>{t("regularKvitok.title", { KVITNUMBER : kvitokData.KVITNUMBER })}</h2>
                <h4>{t("regularKvitok.subtitle" )}</h4>
                <CloseButton close={ close }/>
            </div>
        );
    }
}

export default withTranslation()(Header);
