import React, {Component} from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux/redux";
import HeadsOrTailsQuittanceSelector from "./headsAndTails/Quittance";
import BingoPatternQuittanceSelector from "./bingoPattern/Quittance";
import KnbQuittanceSelector from "./knb/QuittanceSelector";
import BallsQuittanceSelector from "./balls/QuittanceSelector";
import TapeQuittanceSelector from "./tape/Quittance";
import BingoQuittanceSelector from "./bingo/Quittance";
import KenoQuittanceSelector from "./keno/Quittance";
import PenaltyQuittanceSelector from "./penalty/Quittance";
import DartsQuittanceSelector from "./darts/Quittance";
import ChessLiveQuittanceSelector from "./chessLive/ChessLiveQuttance";
import {QuittanceSelectorEb, QuittanceSelectorSeb} from "./igrosoft/QuittanceSelector";

// TODO переписать квитанции, слишком много лишней информации приходит от ВЕБ
type QuittancePropsType = {
    print: any;
    step: any;
    close: any;
}

function Quittance(props: QuittancePropsType) {
    const { step, close } = props;
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const print = isMobile ? null : props.print;

    const ptpId = step.ptpId;
    const gameId = step.priId;
    const Quittance: any = getQuittance(ptpId, gameId);
    return (<Quittance step={step} close={close} print={print}/>);
}

const getQuittance = (ptpId: number, gameId: number) => {
    const penaltyEventNames = [239, 240, 241];
    const dartsEventNames = [244, 245, 246];
    const chessLiveGames = [254, 255, 256, 257, 270, 271, 272, 273, 274, 275];
    switch (ptpId) {
        case 1:
        case 2:
        case 3:
            if (chessLiveGames.includes(gameId)) {
                return ChessLiveQuittanceSelector;
            } else {
                return BallsQuittanceSelector;
            }
        case 4:
            return BingoQuittanceSelector;
        case 5:
            if (penaltyEventNames.includes(gameId)) {
                return PenaltyQuittanceSelector;
            } else if (dartsEventNames.includes(gameId)) {
                return DartsQuittanceSelector;
            } else {
                return TapeQuittanceSelector;
            }
        case 6:
        case 7:
            return KenoQuittanceSelector;
        case 8:
            return KnbQuittanceSelector;
        case 9:
            return QuittanceSelectorEb;
        case 10:
            return QuittanceSelectorSeb;
        case 11:
            return BingoPatternQuittanceSelector;
        case 12:
            return HeadsOrTailsQuittanceSelector;
        default:
            return (() => <span/>);
    }
}

export default Quittance;
