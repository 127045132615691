import React from 'react';
import {useTranslation} from "react-i18next";

export const ChessCoeff = ({coeff}) => {
    const {t} = useTranslation();

    if (coeff === 0 || coeff === '0' || coeff === '0.0') {
        return <React.Fragment/>
    }

    return (
        <h6 className={'bold-text'}>{t("chess.coeff") + ": " + coeff}</h6>
    )
}