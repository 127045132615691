import React from 'react';
import { withTranslation } from "react-i18next";

import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';

import './style.css'

import {ChessBoard} from '../commons/comps/chess/ChessBoard';
import {isChessEventType} from '../commons/chess/ChessEventTypes';

import { dateFormatter, timeFormatter } from "../commons/DateTimeUtils";

function getTapeLikeDetails(session) {
    switch (session.details.wagerId) {
        case 239:
        case 240:
        case 241:
            return session.details.pntDetails;

        case 244:
        case 245:
        case 246:
            return session.details.drtDetails;

        default:
            return session.details.tapeDetails;
    }
}

class Quittance extends React.PureComponent {
    constructor(props) {
       super(props);
    }

    render() {
        const {step, t, print, close} = this.props;
        const session = step.events[0].session;
        const denomination = session.details.denomination;
        const rateCredits = Math.round(step.betAmount / denomination);
        const prizeCredits = Math.round(step.winAmount / denomination);
        const rateDetails = session.details.rateDetails;
        const tapeDetails = getTapeLikeDetails(session);
        const receiptNo = step.receiptNumber;
        const stepNo = step.id;
        const eventType = session.details.eventType;
        const championship = rateDetails.championship;
        const tour = rateDetails.tour;
        if (!isChessEventType(eventType)) {
            console.log("Unknown EventType " + eventType);
            return <div/>
        }

        const rate = {};
        rate.credits = rateCredits;//tapeDetails.prizes.map(e => e.rate).reduce(((prev, cur) => prev + cur), 0);
        rate.currency = step.betAmount;

        const prize = {};
        prize.credits = prizeCredits;//tapeDetails.prizes.map(e => e.prize).reduce(((prev, cur) => prev + cur), 0);
        prize.currency = step.winAmount;

        const actions = tapeDetails.prizes.filter(prize => prize.prize > 0);
        return  (
            <div className={['quittance', 'tape'].join(' ')}>
                <div className="header section pnt-bg">
                    <PrintButton print={ print }/>
                    <div className={'pnt-text-bold'}>{t("quittance.tape.title", { receiptNumber : receiptNo })}</div>
                    <div>{t("quittance.tape.subtitle" )}</div>
                    <CloseButton close={ close }/>
                </div>
                <div className="top section">
                    <div>{ t("quittance.tape.stepNumber") + " " + stepNo }</div>
                    <div>
                        <div className={'pnt-text-bold'}>{ t("quittance.tape.eventNumber", {eventName: rateDetails.eventName, eventNumber: rateDetails.eventNumber}) }</div>
                    </div>
                    <div>{ t("quittance.tape.championship") + ": " + championship }</div>
                    <div>{ t("quittance.tape.tour") + ": " + tour }</div>
                    {
                        rateDetails.chessInfos.map((data, i) => {
                            return <div className="pnt-chess-wrapper" key={i}>
                                <ChessBoard fen={data.fen}/>
                                <div className="pnt-chess-info">
                                    <div>{ t("quittance.tape.chess.board", {board: i + 1}) }</div>
                                    <div>{ t("quittance.tape.chess.game", {game: data.matchId}) }</div>
                                    <div>{ t("quittance.tape.chess.move", {move: data.moveNumber}) }</div>
                                    <div>{ t("quittance.tape.chess.moveTime", {moveTime: timeFormatter(data.moveDate, session.timezoneOffset)}) }</div>
                                    <div>{ t("quittance.tape.chess.sumAndGroup", {sum: data.sum, group: rateDetails.groups[i]}) }</div>
                                </div>
                                <div className="pnt-chess-symbol">{ t("quittance.tape.chess.symbol", {symbol: rateDetails.groups[i]}) }</div>
                            </div>
                        })
                    }
                </div>
                <div className="pnt-bottom section">
                    <div>{ t("quittance.tape.chess.coefficient", {coefficient: tapeDetails.prizes.map(e => e.index)}) }</div>
                    <div>{ t("quittance.tape.rate." + session.currencyShowType, rate) }</div>
                    <div>{ t("quittance.tape.coefficient")}: {rateDetails.coefficient}</div>
                </div>
                <div className="section">
                    <div>
                        <div>{ t("quittance.tape.registrationTime") }</div>
                        <div>{ dateFormatter(rateDetails.registrationTime, session.timezoneOffset) }</div>
                        <div>{ t("quittance.tape.eventTime") }</div>
                        <div>{ dateFormatter(rateDetails.eventTime, session.timezoneOffset) }</div>
                    </div>
                    <div>{ t("quittance.tape.calculationTime") }</div>
                    <div>{ dateFormatter(rateDetails.calculationTime, session.timezoneOffset) }</div>
                    {
                        actions.length > 0
                            ? <div className="bottom-result">
                                <div>{ t("quittance.tape.prize." + session.currencyShowType, prize) }</div>
                            </div>
                            : <div className="bottom-result">
                                <div>{ t("quittance.hat.noprizes") }</div>
                            </div>
                    }
                    <div className="middleSection section">
                        <div>{ t("quittance.tape.chess.symbol", {sector : ''}) }</div>
                        <div className={"ball ball" + tapeDetails.sector}>
                            <div className={"innerBall ball" + tapeDetails.sector}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Quittance);
