import React from 'react';
import {useTranslation} from "react-i18next";
import {CupisIdentificationStatus, PcIdentificationStatus, UserInfo, UserStatus} from "../redux/auth";
import {disabledStyle} from "../util/cssUtils";
import NumberFormat from "../util/NumberFormat";
import {UPNavLink} from "../util/urlPrefix";
import UserMenu from "./UserMenu";
import WebSessionMenu from "./WebSessionMenu";

interface OwnProps {
    onLogout: () => void;
}

interface StateProps {
    user: UserInfo;
}

type UserPanelProps = OwnProps & StateProps;

export function getUserName(user: UserInfo): string {
    const fullName = [user.lastName, user.firstName, user.middleName].filter(n => n).join(" ");
    if (fullName.length <= 34) {
        return fullName;
    }
    return [user.lastName].concat([user.firstName, user.middleName].filter(n => n).map(n => String(n)[0])).join(" ");
}

const UserPanel: React.FunctionComponent<UserPanelProps> = ({ user, onLogout }) => {
    const { t } = useTranslation();
    return <form>
        {
            user.status === UserStatus.ACTIVE
            && user.pcIdentification.status === PcIdentificationStatus.IDENTIFIED
            && user.cupisIdentification.ok &&
            <UserMenu user={user}/>
        }
        <div style={ {float: "right"} }>
            {
                user.sessionType !== 'NONE' && user.status === UserStatus.ACTIVE
                    ? <WebSessionMenu
                        sessionType={user.sessionType}
                        activeGames={user.activeGames}
                        shiftActive={user.shiftActive}
                        gamesAllowed={!user.applicationExist}/>
                    : null
            }
            <UPNavLink to="/personal" className="btn btn-link personal" style={disabledStyle(user.closingAccountExist)}>
                <span className="pa-icon pa-icon-profile"/>{ getUserName(user) }
            </UPNavLink>
            {
                user.balance !== null
                && user.cupisIdentification.status !== CupisIdentificationStatus.CLARIFICATION_PD // уточнение данных
                && <div className="dropdown" style={{display: "inline"}}>
                    <button className="btn btn-link" data-toggle="dropdown">
                        <span className="pa-icon pa-icon-balance"/><NumberFormat style="currency" currency="RUB" value={ user.balance }/>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                            <UPNavLink to="/account/details" className="btn btn-link" style={disabledStyle(user.closingAccountExist)}>{t("details.title")}</UPNavLink>
                        </li>
                        {
                            user.status === UserStatus.ACTIVE &&
                            <li>
                                <UPNavLink to="/payment/deposit" className="btn btn-link"
                                           style={disabledStyle(user.closingAccountExist)}>{t("payment.title.deposit")}</UPNavLink>
                            </li>
                        }
                        <li>
                            <UPNavLink to="/payment/withdraw" className="btn btn-link">{t("payment.title.withdraw")}</UPNavLink>
                        </li>
                    </ul>
                </div>
            }
            <button className="btn btn-link" onClick={ e => { e.preventDefault(); onLogout(); } }>
                <span className="pa-icon pa-icon-logout"/>
            </button>
        </div>
        <div className="clearfix"/>
    </form>;
};

export default UserPanel;