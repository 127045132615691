import React from "react";
import {connect} from "react-redux";

import {logout} from "../Auth";

import "../icons/pa-icons.css"
import {Authentication, UserInfo} from "../redux/auth";
import {ReduxState} from "../redux/redux";
import "./Header.css";
import GuestPanel from "./GuestPanel";
import UserPanel from "./UserPanel";

interface StateProps {
    auth: Authentication;
    mainUrl: string;
}

type HeaderProps = StateProps;

const Header: React.FunctionComponent<HeaderProps> = ({auth, mainUrl}) => (
    <div id="pa-user-panel">
        {auth.authenticated
            ? <UserPanel user={auth.user as UserInfo} onLogout={() => logout(mainUrl)}/>
            : <GuestPanel/>
        }
    </div>
);


export default connect<StateProps, {}, {}, ReduxState>(state => ({
    auth: state.auth,
    mainUrl: state.settings.mainUrl
}))(Header);