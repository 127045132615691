import React from 'react';
import {Trans, withTranslation} from "react-i18next";
import {preloadHttp} from "../util/preload";
import axios from "axios";
import {compose} from "redux";
import PropTypes from "prop-types";

const EmailVerify = ({t, status}) => <div>
    <h2 className="text-center">{ t('personal.verifyEmail.title') }</h2>
    <div>{ status.ok
            ? <div>
                <Trans parent="p" i18nKey={'personal.verifyEmail.valid'}>
                    Email <a href={ `mailto:${status.email} `}>{{ email: status.email }}</a> valid
                </Trans>
            </div>
            : t('personal.verifyEmail.invalid')
        }
    </div>
</div>;

EmailVerify.propTypes = {
    t: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired,
};

export default compose(
    withTranslation(),
    preloadHttp(() => axios.post(`/api/personal/verify-email${location.search}`), "status")
)(EmailVerify);
