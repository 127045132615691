import * as moment from "moment";
import { string } from "prop-types";
import React, { FormEvent } from "react";
import Path from "../util/path/Path";
import PathExpression from "../util/path/PathExpression";
import Rule, { RuleTarget } from "./Rule";

import ValidationError from "./ValidationError";

type optsType = {
    [k: string]: any;
    i18nPrefix: string,
    initialModel?: ((props?: any) => ModelType) | ModelType | string,
    schema: ((model: ModelType, props?: any) => SchemaType) | SchemaType | string
}

type SchemaType = {
    type?: Array<any>;
    amount?: Array<any>;
    sendReceipt?: Array<any>;
    code?: Array<any>;
    phone?: Array<any>;
    password?: Array<any>;
} & {[k: string]: any};

type ModelType = {
    type?: string;
    amount?: string;
    sendReceipt?: boolean;
    date?: moment.Moment;
} & {[k: string]: any};

type controlType = {
    [k: string]: any;
}

type ruleType = {
    [k: string]: any;
}

type makeControlReturnType = {
    dirty: boolean;
    value: undefined | any;
    spec: {};
    timerId: null | any;
    parseErrors: any[];
    validationErrors: any[];
    asyncErrors: any[];
}

type WrappedComponentState = {
    controls: {
        [k: string]: any;
    },
    model?: any;
    submitting?: any;
    submitted?: any;
}


export type resultSubmitType = (callback: Function) => (e: FormEvent) => void;

export type resultType = {
    // const result = (other: any) => f(path.concat(other));
    key: any;
    spec: any;
    path: any;
    i18nPrefix: string;
    value: any;
    onChange: Function;
    submittedOrDirty: any;
    submitted: any;
    dirty: any;
    model?: any;
    errors?: any;
    valid?: any;
    reset?: any;
    submit: resultSubmitType | null;
    view: any;
} & Function;

export function form(opts: optsType): (WrappedComponent: React.ElementType) => typeof React.Component {

    function getInitialModel(props: optsType): ModelType {
        switch (typeof opts.initialModel) {
            case "function":
                return opts.initialModel(props);
            case "string":
                return Path.of(opts.initialModel).get(props);
            case "object":
                return opts.initialModel;
            default:
                console.log('Initial model is empty object...');
                return {} as ModelType;
        }
    }

    function getSchema(model: ModelType, props: optsType): SchemaType {
        switch (typeof opts.schema) {
            case "function":
                return opts.schema(model, props);
            case "string":
                return Path.of(opts.schema).get(props);
            case "object":
                return opts.schema;
            default:
                throw new Error("Unknown schema definition");
        }
    }

    function getAsyncDelay(rule: ruleType): number {
        if (typeof rule.asyncDelay === "number") {
            return rule.asyncDelay;
        }
        return opts.defaultAsyncDelay || 2000;
    }

    function joinErrors(control: controlType) {
        return [...control.parseErrors, ...control.validationErrors, ...control.asyncErrors];
    }

    function cleanupAsync(control: controlType): void {
        if (control && control.timerId) {
            clearTimeout(control.timerId);
        }
    }

    function makeControl(): makeControlReturnType {
        return {
            dirty: false,
            value: undefined,
            spec: {},
            timerId: null,
            parseErrors: [],
            validationErrors: [],
            asyncErrors: []
        };
    }

    function handleSchema(schema: SchemaType, model: ModelType, callback: Function) {
        Object.keys(schema).forEach((k: keyof SchemaType) => {
            const rule = Rule.of(schema[k]);
            PathExpression.of(k).traverse(model, (path: any, value: any) => callback(rule, path, value));
        });
    }

    return (WrappedComponent: React.ElementType) => class FormState extends React.Component<optsType, WrappedComponentState> {

        mounted = false;

        constructor(props: optsType) {
            super(props);
            this.state = this.getInitialState();
        }

        getInitialState() {
            const model = getInitialModel(this.props);
            const schema = getSchema(model, this.props);
            const controls = {} as any;
            handleSchema(schema, model, (rule: any, path: any, value: any) => {
                const target = new RuleTarget(rule.spec, schema, model, this.props);
                const control = makeControl();
                control.value = rule.toView(value, target);
                control.spec = rule.spec;
                control.validationErrors = rule.validate(value, control.value, target);
                controls[path.toString()] = control;

            });
            return {
                model: model,
                submitted: false,
                controls: controls
            };
        }

        asyncValidate(rule: any, key: any, newModelValue: any, newViewValue: any, target: any): number | NodeJS.Timeout {
            const finishAsyncValidation = (errors: any) => this.setState((prevState: WrappedComponentState): WrappedComponentState => {
                const oldControl = prevState.controls[key];
                if (!oldControl || oldControl.timerId !== timerId) {
                    return prevState;
                }

                const newControl = { ...oldControl, asyncErrors: errors, timerId: null };
                const newControls = { ...prevState.controls, [key]: newControl };
                return { controls: newControls };
            });

            const timerId = setTimeout(() => {
                rule.asyncValidate(newModelValue, newViewValue, target).then(
                    finishAsyncValidation,
                    () => finishAsyncValidation([ValidationError.of("asyncError")]));
            }, getAsyncDelay(rule));

            return timerId;
        }

        parseAndValidate(path: any, newValue: any, newControls: any, oldModel: any, oldControls: any) {

            const key = path.toString();
            cleanupAsync(oldControls[key]);

            const schema = getSchema(oldModel, this.props);
            const rule = Rule.of(Object.keys(schema)
                .filter(k => PathExpression.of(k).matches(path))
                .map((k: keyof SchemaType) => schema[k])[0] || {});
            const target = new RuleTarget(rule.spec, schema, oldModel, this.props);

            const stableValue = rule.uglify(newValue, target);
            const parseResult = rule.parse(stableValue, target);

            const newControl = makeControl();
            newControl.dirty = true;
            newControl.spec = rule.spec;
            newControl.value = rule.beautify(stableValue, target);
            newControl.parseErrors = parseResult.errors;
            if (newControl.parseErrors.length === 0) {
                newControl.validationErrors = rule.validate(parseResult.value, newControl.value, target);
                if (newControl.validationErrors.length === 0 && rule.asyncValidators.length > 0) {
                    newControl.timerId = this.asyncValidate(rule, key, parseResult.value, newControl.value, target);
                }
            }

            newControls[key] = newControl;
            return path.apply(oldModel, parseResult.value);
        }

        validateOthers(props: any, newModel: any, newControls: any, oldControls: any, path: null | any = null) {
            const newSchema = getSchema(newModel, props);
            handleSchema(newSchema, newModel, (otherRule: any, otherPath: any) => {
                if (path && path.eq(otherPath)) {
                    return;
                }

                const otherKey = otherPath.toString();
                const oldControl = oldControls[otherKey];
                const otherTarget = new RuleTarget(otherRule.spec, newSchema, newModel, this.props);
                const newOtherControl = Path.of("validationErrors").apply(oldControl,
                    oldControl.parseErrors.length === 0
                        ? otherRule.validate(otherPath.get(newModel), oldControl.value, otherTarget)
                        : []);

                newOtherControl.spec = otherRule.spec;
                newControls[otherKey] = newOtherControl;
            });
        }

        onChange(path: any) {
            return (newValue: any) => this.setState(prevState => {
                const newControls = {};
                const newModel = this.parseAndValidate(path, newValue, newControls, prevState.model, prevState.controls);
                this.validateOthers(this.props, newModel, newControls, prevState.controls, path);
                return { controls: newControls, model: newModel };
            });
        }

        UNSAFE_componentwillreceiveprops(nextProps: any) {
            this.setState(prevState => {
                const newControls = {};
                this.validateOthers(nextProps, prevState.model, newControls, prevState.controls);
                return { controls: newControls };
            });
        }

        componentDidMount() {
            this.mounted = true;
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        resetSubmitting() {
            if (this.mounted) {
                this.setState({ submitting: false });
            }
        }

        render(): React.ReactElement {
            const f = (p: any): resultType => {

                const path = Path.of(p);
                const key = path.toString();

                const result: resultType = (other: any): resultType => f(path.concat(other));
                result.key = key;
                result.path = path;
                result.i18nPrefix = opts.i18nPrefix || "";
                result.value = path.get(this.state.model);
                result.onChange = this.onChange(path);
                result.submit = null;
                result.dirty = null;
                result.view = null;
                result.spec = null;

                const control = this.state.controls[key];
                if (control) {

                    const errors = joinErrors(control);
                    Object.assign(result, {
                        dirty: control.dirty,
                        view: control.value,
                        errors: errors,
                        spec: control.spec,
                        valid: errors.length === 0 ? control.timerId === null ? true : null : false
                    } as {[k:string]: any, dirty: any});

                    // console.log(result);

                } else {

                    Object.assign(result, {
                        dirty: false,
                        view: result.model !== undefined ? result.model : "",
                        errors: [],
                        spec: {},
                        valid: true
                    } as {[k:string]: any, dirty: any});

                }

                result.submitted = this.state.submitted;
                result.submittedOrDirty = result.submitted || result.dirty;

                return result as resultType;
            };

            const root = f("");

            root.errors = [];
            root.valid = true;
            root.model = this.state.model;
            root.reset = () => {
                this.setState(this.getInitialState());
            };

            for (let p in this.state.controls) {
                const control = this.state.controls[p];
                const allErrors = joinErrors(control);
                if (allErrors.length > 0) {
                    root.errors.push({ key: p, path: Path.of(p), errors: allErrors });
                    root.valid = false;
                } else if (control.timerId && root.valid) {
                    root.valid = null;
                }
            }

            root.submit = (callback: Function) => (e: FormEvent) => {
                e.preventDefault();
                if (!root.valid || this.state.submitting) {
                    this.setState({ submitted: true });
                    return;
                }
                this.setState(prevState => {
                    const res = callback && callback(prevState.model);
                    if (res instanceof Promise) {
                        const clb = () => this.resetSubmitting();
                        res.then(clb, clb);
                    }
                    return { submitted: true, submitting: res instanceof Promise };
                });
            };

            return <WrappedComponent f={root} {...this.props}/>;
        }

    };
}
