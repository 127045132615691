import React from 'react';
import { withTranslation } from "react-i18next";

class StepResult extends React.PureComponent {
    constructor(props) { 
       super(props);
    }
    render() {
        const {kvitokData, currencyType, t} = this.props;
        return (<span>{ 
            t("regularKvitok.creditsForEB_" + currencyType, { 
                    CREDITS: kvitokData.CREDITS_RESULT,
                    BALLS: kvitokData.BALLS_RESULT
            }) 
        }</span>);
    }
}

export default withTranslation()(StepResult);
