import React, {Component} from 'react';
import EmailDialogView from "./EmailDialogView";

import axios from "axios";
import {compose} from "redux";
import {connect} from "react-redux";
import {setLoading, setUnmountDate} from "../redux/loadingPage";

class ChangeEmailDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailInUse: undefined,
            success: undefined
        };
    }

    render() {
        const {emailInUse, success} = this.state;
        return <EmailDialogView
            isOpen={this.props.isOpen}
            emailInUse={emailInUse}
            success={success}
            onRequestHide={this.props.onRequestHide}
            onChangeRequest={this.onChangeRequest}
        />;
    }

    start = () => this.props.setUnmountDate({unmountDate: new Date(), loading: true});

    complete = () => {
        this.props.setUnmountDate({unmountDate: new Date(), loading: false});
        this.props.setLoading({loading: false});
    }

    onChangeRequest = (changeRequestData) => {
        this.start();
        axios.post("/api/personal/change-email", changeRequestData)
            .then(resp => resp.data)
            .then(data => this.setState({ success: data.ok, emailInUse: data.emailInUse }))
            .finally(() => this.complete());
    }
}

export default compose(
    connect(null, dispatch => ({
        setUnmountDate: (props) => dispatch(setUnmountDate(props)),
        setLoading: (props) => dispatch(setLoading(props)),
    }))
)(ChangeEmailDialog);
