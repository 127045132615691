import moment from 'moment';

export const dateFormatter = (timestamp, timezoneOffset) => {
    var t = '';
    if (timestamp) {
        moment.locale(window.lang);
        var currentTimeZoneOffsetInHours = timezoneOffset / 60;
        var tzStr = currentTimeZoneOffsetInHours > 0 ? "+" : "";
        t = timeFormatter(timestamp, timezoneOffset) + " (UTC" +
            tzStr + String(currentTimeZoneOffsetInHours).padStart(2, '0') + ") " + moment(timestamp).format("ll");
        t = t.replace("г.", "");
    }
    return t;
};

export const timeFormatter = (timestamp, timezoneOffset) => {
    var myOldDateObj = new Date(timestamp);
    var d = new Date(myOldDateObj.getTime() + (60000 * (myOldDateObj.getTimezoneOffset() + timezoneOffset)));

    var hours = format_two_digits(d.getHours());
    var minutes = format_two_digits(d.getMinutes());
    var seconds = format_two_digits(d.getSeconds());
    return hours + ":" + minutes + ":" + seconds + "." + addZeros(d.getMilliseconds(), 3);
};

// DD.mm.yyy HH:MM (UTC+x)
export const formatDate = (timestamp, timezoneOffset) => {
    const date = new Date(timestamp);
    const d = new Date(date.getTime() + (60000 * (date.getTimezoneOffset() + timezoneOffset)));
    const currentTimeZoneOffsetInHours = timezoneOffset / 60;
    const tzStr = currentTimeZoneOffsetInHours > 0 ? "+" : "";
    const tz = "(UTC" + tzStr + String(currentTimeZoneOffsetInHours).padStart(2, '0') + ")";
    const year = d.getFullYear();
    const month = format_two_digits(d.getMonth() + 1);
    const dayOfMonth = format_two_digits(d.getDate());
    const hours = format_two_digits(d.getHours());
    const minutes = format_two_digits(d.getMinutes());

    return dayOfMonth + "." + month + "." + year + " " + hours + ":" + minutes + " " + tz;
};

function format_two_digits(n) {
    return n < 10 ? '0' + n : n;
}

function addZeros(num, stringSupposedLength){
    var returnString = num.toString();
    if (returnString.length < stringSupposedLength) {
        while (returnString.length < stringSupposedLength)
            returnString = '0' + returnString;
    }
    return returnString;
}