import ValidationError from "./ValidationError";
import moment from "moment";
import {compareBigNumbers, flatten, parseNumber} from "../util/utils";
import dataUri from "../util/dataUri";
import {infoUnitToBytes} from "../util/info-unit-utils";
import {isInnValid} from "../util/InnValidator";

export function hasValue(val) {
    return val !== "" && val !== null && val !== undefined;
}

export function hasNumber(val) {
    return val === 0 || val;
}

export const required = (enabled = true) => enabled && ({
    validators: (val, view) => !hasValue(view) && new ValidationError("required", {value: view}),
    spec: {
        required: true
    }
});

export const minLength = (len) => makeViewValidator(len, view => view.length >= len, "minLength");

export const maxLength = (len) => makeViewValidator(len, view => view.length <= len, "maxLength");

export const min = (v) => makeViewValidator(v, view => view >= v, "min");

export const max = (v) => makeViewValidator(v, view => view <= v, "max");

export const pattern = (regex, errorCode) => makeViewValidator(
    regex,
    view => testRegexp(regex, view),
    errorCode || "pattern"
);

export const inn = () => {
    return {
        validators: modelValidator(val => isInnValid(val), "invalid")
    }
}

export const patternNot = (regex, errorCode) => makeViewValidator(
    regex,
    view => !testRegexp(regex, view),
    errorCode || "pattern"
);

export const notOnlyDigits = (regex, errorCode) => makeViewValidator(
    regex,
    view => !testRegexp(regex, view),
    errorCode || "pattern"
);

function testRegexp(regex, value) {
    return new RegExp(regex).test(value);
}

export const digitsOnly = () => pattern(/^[0-9]+$/);

export const email = () => pattern(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const exactLength = (len) => len && [minLength(len), maxLength(len)];

export const maxSameConsecutiveChars = len => makeViewValidator(
    len,
    view => isValidMaxSameConsecutiveChars(len, view),
    "maxSameConsecutiveChars"
);

export const eq = (valueToCompare) => makeModelValidator(
    valueToCompare,
    value => value === valueToCompare,
    "eq"
);

export const noeq = (valueToCompare) => makeModelValidator(
    valueToCompare,
    value => value !== valueToCompare,
    "noeq"
);

export const passwordValidation = [
    minLength(6),
    maxLength(20),
    pattern(/^[0-9a-zA-Z!№%*@#$^&_.]+$/),
    maxSameConsecutiveChars(6),
    patternNot(/^[a-zA-Z]+$/, "nonLetterChars"),
    notOnlyDigits(/^[0-9]+$/, "personal.password.notDigits")
];

export const date = ({dateFormat = "L", modelDateFormat = "YYYY-MM-DD"} = {}) => {
    const spec = { dateFormat, modelDateFormat };

    return {
        parsers: val => {
            if (!val) {
                return { value: null };
            }

            const parsedDate = moment(val, spec.dateFormat, true);
            if (!parsedDate.isValid()) {
                return {errors: {code: "invalidDate", args: {value: val}}};
            }
            return {value: parsedDate.format(spec.modelDateFormat)};
        },
        formatters: val => val ? moment(val, spec.modelDateFormat).format(spec.dateFormat) : null,
        spec
    }
};

export const maxDate = (date) => (spec) => {
    const format = spec.modelDateFormat;
    const max = moment(date, format);

    return {
        validators: modelValidator(
            value => moment(value, format) <= max,
            "maxDate",
            max.format(format)
        ),
        spec: {
            maxDate: max
        }
    };
};

export const minDate = (date) => (spec) => {
    const format = spec.modelDateFormat;
    const min = moment(date, format);

    return {
        validators: modelValidator(
            value => moment(value, format) >= min,
            "minDate",
            min.format(format)
        ),
        spec: {
            minDate: min
        }
    };
};

export const isFirstJanuary = () => {
    return {
        validators: modelValidator(
            value => {
                const date = new Date(value);
                return !(date.getDate() === 1 && date.getMonth() === 0);
            },
            "isFirstJanuary",
            null
        )
    };
};

export const money = (decimalPlaces) => {
    return {
        uglifiers: val => parseNumber(val).format({ thousandSeps: false, decimalPlaces: decimalPlaces }),
        beautifiers: val => parseNumber(val).format({ thousandSeps: true, decimalPlaces: decimalPlaces })
    }
};

export const ruPhone = () => {
    return {
        uglifiers: val => {
            if (!val) {
                return "";
            }

            let result = "";
            for (let ch of val) {
                if ((ch === "+" && result.length === 0) || (ch >= "0" && ch <= "9")) {
                    result += ch;
                }
            }

            if (result.indexOf("+7") === 0) {
                result = result.substring(2);
            }
            if (!result) {
                return "";
            }
            return "+7" + result.substring(0, 10);
        },
        beautifiers: num => {
            if (!num) {
                return "";
            }

            while (num.indexOf("+7") === 0) {
                num = num.substring(2);
            }

            let result = "+7 (";
            for (let ch of num) {
                switch (result.length) {
                    case 7:
                        result += ") ";
                        break;
                    case 12:
                    case 17:
                        result += " - ";
                        break;
                }
                result += ch;
            }
            return result;
        },
        validators: modelValidator(val => val.length === 12, "minLength")
    }
};

export const fileBase64 = { parsers: f => ({ value: f ? f.base64 : null }) };
export const fileMimeType = mimeType => ({ validators: (val, view) => view
    && view.base64
    && dataUri(view.base64).type !== mimeType
    && new ValidationError("wrongFormat", {})
});

export const fileMaxSize = (limit, units = "B") => ({ validators: (val, view) => view
    && view.fileList
    && view.fileList[0]
    && view.fileList[0].size > infoUnitToBytes(limit, units)
    && new ValidationError("fileTooBig", { limit, units })
});

function makeViewValidator(arg, checkValidFn, errorCode) {
    return {
        validators: viewValidator(checkValidFn, errorCode, arg)
    }
}

function makeModelValidator(arg, checkValidFn, errorCode) {
    return {
        validators: modelValidator(checkValidFn, errorCode, arg)
    }
}

function viewValidator(checkValidFn, errorCode, arg) {
    return (modelValue, viewValue, ruleTarget) => valueValidator(viewValue, ruleTarget, checkValidFn, errorCode, arg)
}

function modelValidator(checkValidFn, errorCode, arg) {
    return (modelValue, viewValue, ruleTarget) => valueValidator(modelValue, ruleTarget, checkValidFn, errorCode, arg)
}

function valueValidator(value, ruleTarget, checkValidFn, errorCode, arg) {
    if (!hasValue(value)) {
        return null;
    }

    const isValid = checkValidFn(value, ruleTarget);
    if (isValid === null) {
        return null;
    }

    if (!isValid) {
        return new ValidationError(errorCode, {value: value, arg})
    }

    return null;
}

function isValidMaxSameConsecutiveChars(lim, val) {
    let lastCh = undefined, consecutiveChars = 1;
    for (let ch of val) {
        if (ch === lastCh) {
            ++consecutiveChars;
            if (consecutiveChars >= lim) {
                return false;
            }
        } else {
            consecutiveChars = 1;
        }
        lastCh = ch;
    }
    return true;
}

const isCmpValid = (res, eq) => {
    switch (eq) {
        case "lt":
            return res === -1;

        case "gt":
            return res === 1;

        case "min":
            return res >= 0;

        case "max":
            return res <= 0;

        case "eq":
            return res === 0;
    }
};

export const cmpMoney = (eq = "eq") => arg => hasNumber(arg)
    && (other => ({
        validators: val => hasNumber(val)
            && !isCmpValid(compareBigNumbers(val, other), eq)
            && new ValidationError(eq + "Money", { [eq]: other, val })
    }))(String(arg));

export const minMoney = cmpMoney("min");
export const maxMoney = cmpMoney("max");
export const ltMoney = cmpMoney("lt");
export const gtMoney = cmpMoney("gt");
export const spec = s => ({ spec: s });
export const values = (...args) => spec({ values: flatten(args) });
export const i18nKey = i18nKey => spec({ i18nKey });