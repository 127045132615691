import { combineReducers, createStore } from "redux";
import { AUTH_NOT_LOADED, Authentication, authReducer } from "./auth";
import { initIsMobileDefault, isMobileReducer } from "./isMobile";
import {
    Loading,
    loadingDefault,
    loadingReducer,
    UnmountDate,
    unmountDateDefault,
    unmountDateReducer
} from "./loadingPage";
import { mobileAppLinkReducer, mobileAppLinkReducerDefault } from "./mobileAppLinkReducer";
import { mobileSidebarOpenDefault, mobileSidebarOpenReducer } from "./mobileSidebarOpenReducer";
import { Settings, settingsDefault, settingsReducer } from "./settings";
import { showInstallAppMessageReducer } from "./showInstallAppMessageReducer";

export interface ReduxState {
    auth: Authentication;
    settings: Settings;
    isMobileView: boolean;
    mobileSidebarOpen: boolean;
    showInstallAppMessage: boolean;
    mobileAppLink: string | null;
    unmountDate: UnmountDate;
    loading: Loading;
}

const reducers = combineReducers<ReduxState>({
    auth: authReducer,
    settings: settingsReducer,
    isMobileView: isMobileReducer,
    mobileSidebarOpen: mobileSidebarOpenReducer,
    showInstallAppMessage: showInstallAppMessageReducer,
    mobileAppLink: mobileAppLinkReducer,
    unmountDate: unmountDateReducer,
    loading: loadingReducer
});

const store = createStore(reducers, {
    auth: AUTH_NOT_LOADED,
    settings: settingsDefault,
    isMobileView: initIsMobileDefault(),
    mobileSidebarOpen: mobileSidebarOpenDefault,
    mobileAppLink: mobileAppLinkReducerDefault,
    unmountDate: unmountDateDefault,
    loading: loadingDefault
});

export default store;
