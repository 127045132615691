import moment from "moment";
import React, {useEffect, useState} from 'react';
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {UAParser} from "ua-parser-js";
import FilterableTable from "../components/FilterableTable";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";
import "./Activity.less";
import { ColumnCellType, FilterParamsStateType } from "./Operations";
import PickerInput from "./PickerInput";
import "./Activity.less";
import MultiSelect from "react-multi-select-component";
import axios from "axios";

type ActivityStateType = {
    search: string;
    filterParams: FilterParamsStateType;
}

export function Activity() {

    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const _date_time_format = 'DD.MM.YYYY HH:mm:ss';
    const [state, setState] = useState<ActivityStateType>({
        search: '',
        filterParams: {
            from: moment().startOf('day'),
            to: moment().endOf('day'),
            type: null,
            types: []
        }
    });
    const [storagePeriod, setStoragePeriod] = React.useState(90);

    useEffect(() => {
        axios.get("api/history/storage-period").then(res => setStoragePeriod(res.data.value))
    }, []);

    const renderType = (row: any) => <span>{t('history.activity.table.type.' + row.value)}</span>;
    const renderIpAddress = (row: any) => <span>{row.value}</span>;
    const renderDateTime = (row: any) => <span>{moment(row.value).format(_date_time_format)}</span>;
    const renderUserAgent = (row: any) => {
        const ua = new UAParser(row.value).getResult();
        if (ua.browser.name === undefined) {
            return <span/>
        }
        return <span>{`${ua.browser.name} ${ua.browser.version} - ${ua.os.name}`}</span>
    };
    const renderActivity = (row: any) => {
        const i18nActivity = 'history.activity.table.activity.';
        const errorCode = row.original.errorCode;
        const successOrError = errorCode
            ? t(i18nActivity + 'error', {errorCode})
            : t(i18nActivity + 'success');
        const dateTime = moment(row.original.dateTime).format(_date_time_format);

        return <span>{t(i18nActivity + row.value, {successOrError, dateTime})}</span>
    };

    const makeColumns = (): Array<ColumnCellType> => {
        const i18nKeyPrefix = 'history.activity.table.headers.';

        return [{
            Header: t(i18nKeyPrefix + 'dateTime'),
            accessor: 'dateTime',
            Cell: renderDateTime,
            width: 180
        }, {
            Header: t(i18nKeyPrefix + 'type'),
            accessor: 'type',
            Cell: renderType
        }, {
            Header: t(i18nKeyPrefix + 'action'),
            accessor: 'activity',
            Cell: renderActivity,
            className: 'activity-column'
        }, {
            Header: t(i18nKeyPrefix + 'ipAddress'),
            accessor: 'ipAddress',
            Cell: renderIpAddress,
            width: 150
        }, {
            Header: t(i18nKeyPrefix + 'userAgent'),
            accessor: 'userAgent',
            Cell: renderUserAgent
        }];
    };

    const columns = makeColumns();

    const onOperationTypeChange = (values: typeof state.filterParams.types) => {
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                types: values
            }
        }));
    };

    const onFromDateChange = (time: moment.Moment) => {
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                from: time.startOf('day')
            }
        }));
    };

    const onToDateChange = (time: moment.Moment) => {
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                to: time.endOf('day')
            }
        }));
    };


    const getMinDate = () => {
        const from = state.filterParams.from;
        const now = moment();
        return moment.min(from, now.subtract(storagePeriod, 'days'));
    };

    const getMaxDate = () => {
        const to = state.filterParams.to;
        const now = moment();
        return moment.max(now, to);
    };

    const renderDateTimePickers = (innerKey: string, i18nFilter: string) => <ReactDatePicker
        showYearDropdown
        showMonthDropdown
        fixedHeight
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        selected={innerKey === 'to' ? state.filterParams.to : state.filterParams.from}
        onChange={innerKey === 'to' ? onToDateChange : onFromDateChange}
        id={'operations-' + innerKey + '-date-picker'}
        customInput={<PickerInput label={t(i18nFilter + innerKey + '.label')}/>}

    />;

    const renderOptions = () => {
        const i18nType = 'history.activity.filter.type.';
        const types = [
            {label: t(i18nType + 'AUTHORIZATION'), value: 'AUTHORIZATION'},
            {label: t(i18nType + 'IDENTIFICATION'), value: 'IDENTIFICATION'},
            {label: t(i18nType + 'PERSONAL_DATA_CHANGE'), value: 'PERSONAL_DATA_CHANGE'},
            {label: t(i18nType + 'CONSTRAINTS_CHANGE'), value: 'CONSTRAINTS_CHANGE'},
            {label: t(i18nType + 'STATUS_CHANGE'), value: 'STATUS_CHANGE'},
        ]
        return (<>
                <label>{t(i18nType + 'label')}</label>
                <MultiSelect
                    options={types}
                    value={state.filterParams.types}
                    onChange={onOperationTypeChange}
                    labelledBy="Select"
                    disableSearch={true}
                    selectAllLabel={t(i18nType + 'ALL')}
                    overrideStrings={{
                        "allItemsAreSelected": t(i18nType + 'ALL'),
                        "selectSomeItems": t(i18nType + 'ALL'),
                    }}
                />
            </>
        )
    };

    const i18nFilter = 'history.activity.filter.';


    if (isMobile) {
        return (
            <div>
                <MobileDualHeader firstHeader={t('history.title')} secondHeader={t('history.activity.title')}/>
                <h5><i>{t('history.activity.subHeaderText')}</i></h5>
                <form>

                    <div className="form-group">
                        <div className="col">
                            {renderOptions()}
                        </div>
                    </div>
                    <label>{t(i18nFilter + 'period.label')}</label>
                    <div className="form-group">
                        <div className="col">
                            {renderDateTimePickers('from', i18nFilter)}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col">
                            {renderDateTimePickers('to', i18nFilter)}
                        </div>
                    </div>

                </form>
                <FilterableTable
                    dataUrl="/api/history/activity"
                    columns={columns}
                    filterParams={state.filterParams}
                    defaultSort={[{id:'dateTime', desc:true}]}
                />
            </div>
        );
    } else {
            return (
                <div>
                    <h2 className="text-center">{t('history.activity.title')}</h2>
                    <h5><i>{t('history.activity.subHeaderText')}</i></h5>
                    <form>
                        <div className="row">
                            <div className="form-group col-xs-3">
                                {renderOptions()}
                            </div>
                            <div className="col-xs-7 form-group">
                                <label>{t(i18nFilter + 'period.label')}</label>
                                <div>
                                    <div className="col-xs-6">
                                        {renderDateTimePickers('from', i18nFilter)}
                                    </div>
                                    <div className="col-xs-6">
                                        {renderDateTimePickers('to', i18nFilter)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <FilterableTable
                        dataUrl="/api/history/activity"
                        columns={columns}
                        filterParams={state.filterParams}
                        defaultSort={[{id:'dateTime', desc:true}]}
                    />
                </div>
            );
        }


}
