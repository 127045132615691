import React from "react";
import Wizard from "../components/Wizard";
import { ReduxState } from "../redux/redux";
import LoginForm from "./LoginForm";
import {LoginVerifyForm} from "./LoginVerifyForm";
import {useTranslation} from "react-i18next";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {RootRouteProps} from "../App";
import axios from "axios";

type LoginProps = {
    onLogin: () => void
}

type StepOneProps = {
    w: any;
    onLogin: (stepTwoProps: StepTwoProps) => void;
}

type StepTwoProps = {
    confirmationRequestResult: any;
    phone: any;
    onLogin: any;
}

type Banner = {
    visible: boolean;
    ru: string;
    en: string;
}

export function Login({onLogin}: LoginProps) {
    const {t} = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    const {lang} = useParams < RootRouteProps > ();
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru' || i18n.language === 'ru-RU';

    const [banner, setBanner] = React.useState<Banner | null>(null);
    const [isAuthorizationVisible, setIsAuthorizationVisible] = React.useState<boolean>(false);
    const [isRegistrationVisible, setIsRegistrationVisible] = React.useState<boolean>(false);

    React.useEffect( () => {
        axios.get("/api/settings/bannerPath").then(response => setBanner(response.data));
        axios.get("/api/settings/isFormRegistrationVisible").then(response => setIsRegistrationVisible(response.data.value));
        axios.get("/api/settings/isFormAuthorizationVisible").then(response => setIsAuthorizationVisible(response.data.value));
    }, []);

    const bannerHtml = () => {
        return <>
            {  banner  && banner.visible && (banner.ru || banner.en) &&
                <iframe
                    title="banner"
                    srcDoc={isRu ? banner.ru : banner.en}
                    width="100%"
                    height="200px"
                    frameBorder="0"
                />
            }
        </>
    }

    return <>
        {isAuthorizationVisible && <Wizard commonProps={{onLogin}} steps={[
                ({w, onLogin}: StepOneProps) => <>
                    {isMobile &&
                    <MobileDualHeader firstHeader={t("login.mobileTitle")} secondHeader={t('login.subTitle')}/>}
                    {bannerHtml()}
                    {!isMobile && <div className="row">
                        <h2 className="text-center">{t("login.title")}</h2>
                    </div>}
                    <LoginForm
                        onLogin={(d: StepTwoProps) => d.confirmationRequestResult.status === 'SUCCESS' ? w(d) : onLogin(d)}
                        isFormRegistrationVisible={isRegistrationVisible}
                    />
                </>,
                ({confirmationRequestResult, phone, onLogin}: StepTwoProps) =>
                    <>
                        {isMobile && <MobileDualHeader firstHeader={t("login.title")} secondHeader={t('login.subTitle')}/>}
                        {!isMobile && <div className="row">
                            <div className="col-md-6 col-md-offset-3">
                                <h2 className="text-center">{t("login.verify.title")}</h2>
                            </div>
                        </div>}
                        <LoginVerifyForm
                            onVerified={onLogin}
                            phone={phone}
                            confirmationRequestResult={confirmationRequestResult}
                        />
                    </>
            ]}/>
        }

    </>;
};
