import React from "react";
import './style.css';
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import {setLoading, setUnmountDate} from "../redux/loadingPage";
import {useTicker} from "../util/hooks";

export const LockLoader: React.FC = () => {
    return (<div className="lock-loader"/>)
}

export const LockLoaderPageWrapper: React.FC = ({children}) => {
    const dispatch = useDispatch();
    const {loading: {loading}, settings: {sleepLoadingPage}} = useSelector((state: ReduxState) => state);

    React.useEffect(() => {
        sleep(sleepLoadingPage).then(() => {
            console.log("loading: false")
            dispatch(setUnmountDate({unmountDate: new Date(), loading: false}));
            dispatch(setLoading({loading: false}));
        })
        return () => {
            dispatch(setUnmountDate({unmountDate: new Date(), loading: true}))
        }
    }, [children])

    return (<>
        {loading && <LockLoader/>}
        {children}
    </>)
}

export const LockPageLoaderTimerWrapper: React.FC = ({children}) => {
    const {unmountDate: {unmountDate, loading}, settings: {delayForStartProgressBar}} = useSelector((state: ReduxState) => state);
    const dispatch = useDispatch();

    const ticker = useTicker(() => {
        const diff = new Date().getTime() - unmountDate.getTime();
        if (diff > delayForStartProgressBar) {
            dispatch(setLoading({loading: true}));
        }
    }, 100);

    React.useEffect(() => {
        if (loading) {
            ticker.start();
        } else {
            ticker.stop();
        }
        return () => ticker.stop();
    }, [unmountDate, loading])

    return (<>{children}</>)
}

function sleep (time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}