import React from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router";
import {queryAndUpdateAuth} from "../../Auth";
import {IdentificationChoiceRegister} from "../../components/IdentificationChoice";
import ErrorBoundary from "../../ErrorBoundary";
import {Login} from "../../login/Login";
import CommonRegisterForm from "../../register/common/CommonRegisterForm";
import RegisterCheckEmail from "../../register/RegisterCheckEmail";
import RegisterVerifyEmail from "../../register/RegisterVerifyEmail";
import RegisterVerifyPhone from "../../register/RegisterVerifyPhone";
import Restore from "../../restore/Restore";
import Preconditions from "../Preconditions";
import EmailVerify from "../../personal/EmailVerify";
import {ClubPersonalChoiceHOC, ClubRegisterChoiceHOC} from "../../components/ClubRegisterChoice";
import {LockLoaderPageWrapper, LockPageLoaderTimerWrapper} from "../../loader/LockLoader";
import axios from "axios";

export type MasterRoutesProps = {
    urlPrefix: string
}

export function MasterRoutes(props: MasterRoutesProps) {

    const {t} = useTranslation();
    const {urlPrefix} = props;
    const [isRegistrationVisible, setIsRegistrationVisible] = React.useState<boolean>(false);

    React.useEffect( () => {
        axios.get("/api/settings/isFormRegistrationVisible").then(response => setIsRegistrationVisible(response.data.value));
    }, []);

    return (
        <ErrorBoundary {...{t}}>
            <LockPageLoaderTimerWrapper>
                <LockLoaderPageWrapper>
                    <Switch>
                        <Route exact path={`${urlPrefix}/personal/verify`} component={EmailVerify}/>
                        <Route exact path={`${urlPrefix}/personal/club`} component={ClubPersonalChoiceHOC}/>
                        {isRegistrationVisible && [
                            <Route key={1} exact path={`${urlPrefix}/register`} component={IdentificationChoiceRegister}/>,
                            <Route key={2} exact path={`${urlPrefix}/register/club`} component={ClubRegisterChoiceHOC}/>,
                            <Route key={3} exact path={`${urlPrefix}/register/form`} component={CommonRegisterForm}/>
                        ]}
                        <Route key={4} exact path={`${urlPrefix}/register/success`} component={RegisterCheckEmail}/>
                        <Route key={5} exact path={`${urlPrefix}/register/verify/email`} component={RegisterVerifyEmail}/>
                        <Route key={6} exact path={`${urlPrefix}/register/verify/phone`} component={RegisterVerifyPhone}/>
                        <Route exact path={`${urlPrefix}/restore`} component={Restore}/>
                        <Route exact path={`${urlPrefix}/login`} render={({history}) =>
                            <Login onLogin={() =>
                                queryAndUpdateAuth()
                                    .then(() => history.push(`${urlPrefix}`))}
                            />
                        }/>
                        <Route component={Preconditions}/>

                    </Switch>
                </LockLoaderPageWrapper>
            </LockPageLoaderTimerWrapper>
        </ErrorBoundary>
    )
}