import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import {RouteComponentProps} from "react-router";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";

import {HttpPost, SuccessProps} from "../util/HttpRequest";
import {UPLink} from "../util/urlPrefix";
import qs from "query-string";
import {VerifyResponse} from "./verificationTypes";

type RegisterVerifyEmailProps = RouteComponentProps;


const RegisterVerifyEmail: React.FC<RegisterVerifyEmailProps> = ({ location }) =>
<HttpPost url={`/api/register/verifyEmail${location.search}`} success={({ data }: SuccessProps<VerifyResponse>) => {
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const { t } = useTranslation();
    const i18nPrefix = "register.verify.email";
    return <>
        {isMobile && <MobileDualHeader firstHeader={t('register.title')} secondHeader={ t(`${i18nPrefix}.title`) }/>}
        <h2 className="text-center">{ t(`${i18nPrefix}.title`) }</h2>
        <div className="text-center">
            { data.valid ? <Trans i18nKey={`${i18nPrefix}.valid`}>
                    Your email <a href={`mailto:${data.id}`}>{{email: data.id}}</a> has been successfully validated
                </Trans> : t(`${i18nPrefix}.invalid`)
            }
        </div>
        { data.valid && <div className="row margin-top-10">
            <div className="col-md-2 col-md-offset-5">
                <UPLink to={`/register/verify/phone?id=${qs.parse(location.search).id}`} className="btn btn-primary form-control">
                    { t(`${i18nPrefix}.proceed`) }
                </UPLink>
            </div>
        </div> }
    </>;
}}/>;

export default RegisterVerifyEmail;
