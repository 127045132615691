import * as queryString from "query-string";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import {RouteComponentProps} from "react-router";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";

type RegisterCheckEmailProps = RouteComponentProps;

const RegisterCheckEmail: React.FC<RegisterCheckEmailProps> = ({ location }) => {
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const { t } = useTranslation();
    const email = queryString.parse(location.search).email;
    const i18nPrefix = "register.verify.email";
    return <div>
        {isMobile && <MobileDualHeader firstHeader={t('register.title')} secondHeader={ t(`${i18nPrefix}.title`) }/>}
        {!isMobile && <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <h2 className="text-center">{ t(`${i18nPrefix}.title`) }</h2>
            </div>
        </div>}
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <Trans i18nKey={`${i18nPrefix}.check`}>
                    Check <a href={`mailto:${email}`}>{{email}}</a> to proceed
                </Trans>
            </div>
        </div>
    </div>;
};

export default RegisterCheckEmail;
