import React from 'react';
import { withTranslation } from "react-i18next";
import './style.css';
import { getPrizeFromLines } from '../Helper';

class TotalLinePrize extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        // Для СЭБ расчет выигрыша неверен?
        const {kvitokData, currencyType, t} = this.props;
        return (<span>
            {
                kvitokData.CREDITS_RESULT !== kvitokData.CREDITS
                    ? t("regularKvitok.resultStep_" + currencyType, {
                        CREDITS_RESULT: kvitokData.CREDITS_RESULT,
                        BALLS_RESULT: Number(kvitokData.BALLS_RESULT).toFixed(2),
                        })
                    : t("regularKvitok.sebNoResult")
            }
        </span>);
    }
}

export default withTranslation()(TotalLinePrize);
