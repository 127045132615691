import React from 'react';
import ReactModal from "react-modal";
import { withTranslation } from "react-i18next";
import {form} from "../validation/FormController";
import LabelInput from "../forms/LabelInput";
import {required, ruPhone} from "../validation/functionalPlugins";
import {compose} from "redux";
import PhoneConfirmationForm, { schema as phoneConfirmationSchema } from "../components/ConfirmationResend"


import "./DialogView.less";
import {useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import axios from "axios";

const PhoneDialogView = ({
                             t, f,
                             isOpen,
                             phoneInUse,
                             invalidPassword,
                             changeRequestData,
                             confirmationRequestResult,
                             success,
                             onRequestHide,
                             onChangeRequest,
                             onSaveRequest,
                             onCodeResent,
                         }) => {
    const i18nPrefix = f.i18nPrefix;
    const tr = (key) => t(i18nPrefix + key);
    const phoneAndPassword = validPhoneAndPassword(phoneInUse, invalidPassword);
    const isMobile = useSelector((state) => state.isMobileView);
    const when = (condition, makeEl) => condition ? makeEl() : null;


    const onSubmit = ({phone, password, code}) => {
        if (success) {
            return;
        }

        if (!phoneAndPassword) {
            onChangeRequest({phone, password})
        } else {
            onSaveRequest({phone, password, code})
        }
    };


    function renderFooterInner() {
        if (isMobile) {
            return <footer>
                <button className='btn btn-primary' style={{marginBottom: 15, width: '100%'}} disabled={!(f.errors.length===0)}>
                    {tr(phoneAndPassword ? 'saveButton' : 'changeButton')}
                </button>
                <button className='btn btn-primary' onClick={onRequestHide} style={{width: '100%'}}>
                    {tr('cancel')}
                </button>
            </footer>
        } else {
            return <footer>
                {
                    phoneAndPassword
                        ? <button className='btn btn-primary' disabled={!(f.errors.length===0) || !f.model.code }>
                            {tr('saveButton')}
                        </button>
                        : <button className='btn btn-primary' disabled={!(f.errors.length===0) }>
                            {tr('changeButton')}
                        </button>
                }
            </footer>
        }

    }


    function renderInner() {
        return <form className="form-horizontal" onSubmit={f.submit(onSubmit)}>
            <div className="form-group row modal-window">
                {!isMobile && <header>
                    <button onClick={onRequestHide}>
                        <span className="glyphicon glyphicon-remove"/>
                    </button>
                    <h2>{tr('title')}</h2>
                </header>}
                <div>
                    {when(!phoneAndPassword, () =>
                        <p>{tr('info1')}</p>
                    )}

                    <LabelInput f={f('phone')} autoFocus readOnly={phoneAndPassword} placeholder="+7 (___)___-__-__"/>
                    <LabelInput f={f('password')} type="password" readOnly={phoneAndPassword}/>

                    {when(invalidPassword, () => (
                        <div>{t("personal.changePhoneDialog.password.invalidPassword")}</div>
                    ))}

                    {when(phoneAndPassword, () =>
                        <div>

                            {when(!success, () =>
                                <div>
                                    <PhoneConfirmationForm
                                        f={f}
                                        resendUrl="/api/personal/change-phone"
                                        resendData={changeRequestData}
                                        confirmationRequestResultExtractor={resultExtractor}
                                        isRequired={phoneAndPassword}
                                        codeLength={6}
                                        confirmationRequestResult={confirmationRequestResult}
                                        onResent={onCodeResent}
                                        readOnly={success}
                                    />
                                    <p>{tr('info2')}</p>
                                </div>
                            )}

                            {when(success, () =>
                                <div className="alert alert-success">
                                    {tr('success')}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {renderFooterInner()}
            </div>
        </form>;

    }

    const resultExtractor = (data) => data.confirmationRequestResult;
    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
            {renderInner()}
        </div>
    } else {
        return <div>
            <ReactModal isOpen={isOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={onRequestHide}>{renderInner()}</ReactModal>
        </div>
    }
};

function validPhoneAndPassword(phoneInUse, invalidPassword) {
    return !phoneInUse && !invalidPassword && phoneInUse !== undefined && invalidPassword !== undefined;
}

const formDef = {
    i18nPrefix: 'personal.changePhoneDialog.',
    schema: (m, {phoneInUse, invalidPassword, invalidCode}) => {
        const phoneAndPassword = validPhoneAndPassword(phoneInUse, invalidPassword);

        return {
            phone: [
                required(),
                ruPhone(),
                {
                    asyncValidators: (val) => axios.get("/api/register/phoneInUse", { params: { phone: val } })
                        .then(resp => resp.data && "phoneInUse")
                }
            ],
            password: [
                required(),
                {
                    validators: val => val && val === invalidPassword && "invalidPassword"
                }
            ],
            ...phoneConfirmationSchema(phoneAndPassword, 6, invalidCode)
        }
    }
};

export default compose(
    withTranslation(),
    form(formDef),
)(PhoneDialogView);
