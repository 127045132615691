import React from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useNotifications} from "../util/Notifications";

//todo use SmartCaptcha from @yandex/smart-captcha https://cloud.yandex.ru/docs/smartcaptcha/concepts/react

type CaptchaProps = {
    verified: (ok: boolean) => void;
    form: string;
}

export const CaptchaWidget: React.FC<CaptchaProps> = ({verified, form}) => {
    const {t, i18n} = useTranslation();
    const {addNotification} = useNotifications();
    const [enabled, setEnabled] = React.useState<boolean>(false);
    const [clientKey, setClientKey] = React.useState<string>('');

    React.useEffect(() => {
        axios.get('/api/captcha')
            .then(response => {
                setClientKey(response.data.clientKey);
                setEnabled(response.data.enabled);
                if (response.data.enabled) {
                    const script = document.createElement("script");
                    script.src = response.data.src;
                    script.defer = true;
                    script.async = true;
                    document.body.appendChild(script);
                    if (typeof window !== "undefined") {
                        (window as any).callback = callbackFunction;
                    }
                } else {
                    verified(true);
                }
            });
    }, [])

    const callbackFunction = (token: any) => {
        axios.post("/api/captcha/" + token)
            .then(result => {
                result.data.withError && error();
                verified(result.data.ok);
            })
            .catch(error);
    }

    const error = () => addNotification({message: t("captcha.error." + form)});

    const captcha = () => {
        return <div className="row" style={{marginBottom: '15px'}}>
            <div className="col-md-12">
                <div className="smart-captcha" data-sitekey={clientKey} data-hl={i18n.language} data-callback="callback"/>
            </div>
        </div>
    }

    return enabled ? captcha() : <></>;
}