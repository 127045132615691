import React from "react";

import {withTranslation} from "react-i18next";
import i18n from "i18next";
import {form} from "../validation/FormController";

import PropTypes from "prop-types";
import LabelInput from "../forms/LabelInput";
import LabelCheckbox from "../forms/LabelCheckbox";
import {compose} from "redux";
import ChannelPicture from "./ChannelPicture";

import {gtMoney, i18nKey, ltMoney, maxMoney, money, required, values} from "../validation/functionalPlugins";
import Select from "react-select";
import classNames from "classnames";
import {confirm} from "../util/ConfirmDialog";
import axios from "axios";

class PaymentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: null,
            withdrawChannels: null,
            withdrawalSum: null,
            accounts: null,
            selectedChannel: null,
            selectedAccount: null,
            fees: null,
            channelsDisabled: false
        };
    }

    handleFeeClick = (channel) => {
        this.setState({channelsDisabled: true});
        const {f, type, jackBet} = this.props;
        const {withdrawalSum} = this.state;
        const amount = type === "DEPOSIT" ? f.model.amount : withdrawalSum;
        const urls = {
            'DEPOSIT': 'api/payments/deposit-fee',
            'WITHDRAW': 'api/payments/withdraw-fee'
        };
        axios.get(urls[type], {
            params: {
                amount: amount,
                channel: channel.channel,
                minAmount: channel.minAmount,
                maxAmount: channel.maxAmount,
                isJackBet: !!jackBet
            }
        })
            .then(response => {
                if (response.data.channels.length === 0) {
                    this.setState({
                        fees: null,
                        channelsDisabled: false,
                        selectedChannel: null
                    })
                } else {
                    this.setState({
                        fees: response.data,
                        channelsDisabled: false,
                        selectedChannel: channel
                    });
                }
            }, () => {
                this.setState({
                    fees: null,
                    channelsDisabled: false,
                    selectedChannel: null
                });
            });
    };

    handleSelectChannel = (channel) => (event) => {
        event.preventDefault();
        const {isStatusOk, confirm, t} = this.props;
        if (this.state.withdrawalSum === 0) {
            confirm.show({
                body: t("payment.choicePlayer.dialog.maxSumMoreZero"),
                ok: t("payment.choicePlayer.dialog.ok"),
            });
        } else {
            if (!isStatusOk()) {
                return;
            }
            this.handleFeeClick(channel);
        }
    };

    handleExecute = () => {
        const {f, onSubmit, type, jackBet, confirm, t} = this.props;
        const {selectedChannel, selectedAccount, withdrawalSum} = this.state;
        const newObj = {};
        axios.get('/api/settings/paymentSuspended', {})
            .then(response => {
                if (response.data.value.paymentOperationsSuspended) {
                    confirm.show({
                        body: t("payment.suspended.text", {fromDate: response.data.value.fromDate, toDate: response.data.value.toDate}),
                        ok: t("payment.suspended.ok")
                    });
                } else {
                    Object.assign(newObj, f.model);
                    if (type === "WITHDRAW") {
                        newObj.playerId = selectedAccount.value.id;
                        newObj.amount = withdrawalSum;
                    }
                    newObj.jackBet = jackBet;
                    newObj.type = type;
                    newObj.channel = selectedChannel.channel;
                    onSubmit(newObj);
                }
            })
    };

    handleChoiceAccount = (a) => {
        const maxAmount = this.filterWithdrawChannels(a);
        this.choiceAccount(a, maxAmount);
        if (this.props.onShowPerformedPaymentChange) {
            this.props.onShowPerformedPaymentChange(false);
        }
    };

    filterWithdrawChannels = (a) => {
        const {channels} = this.props;

        let maxAmount = 0;
        channels.filter(c => {
            if (maxAmount <= c.maxAmount) {
                maxAmount = c.maxAmount;
            }
        });

        let withdrawChannels = channels.filter(c => {
            return (c.maxAmount >= maxAmount || c.maxAmount >= a.value.money) && c.minAmount <= a.value.money;
        });


        if (withdrawChannels.length === 0) {
            withdrawChannels = channels.filter(c => maxAmount === c.maxAmount && c.minAmount <= a.value.money);
        }
        this.setState({
            withdrawChannels: withdrawChannels
        });

        return maxAmount;
    };

    choiceAccount = (account, maxAmount) => {
        const {confirm, channels, t} = this.props;
        axios.get("api/payments/calculate-withdrawal-sum", {params: {accountId: account.value.id, maxAmount: maxAmount}})
            .then(resp => resp.data)
            .then((data) => {
                if (data !== account.value.money) {
                    const i18nPrefix = "payment.choicePlayer.dialog.";
                    confirm
                        .show({
                            title: t(i18nPrefix + "title"),
                            body: t(i18nPrefix + "message", {amount: data}),
                            ok: t(i18nPrefix + "ok"),
                            cancel: t(i18nPrefix + "cancel")
                        })
                        .then(() => {
                            this.saveSelectedAccount(account, data);
                        }, () => {
                            this.saveSelectedAccount(null, null);
                            this.setState({
                                withdrawChannels: channels
                            })
                        })
                }
                this.saveSelectedAccount(account, data);
            });
    };

    saveSelectedAccount = (account, amount) => {
        this.setState({
            selectedAccount: account,
            withdrawalSum: amount,
        });
    };


    formatChoiceAccountData = (t, account) => {
        let sum = this.formatMoney(account.money);
        let payout = account.payoutInfo.closeAllowed ? sum : this.formatMoney(0);
        return t("payment.playerInfo", {receiptNo: account.receiptNo, sum: sum, payout: payout});
    };

    formatMoney = (money) => {
        const formatter = new Intl.NumberFormat(i18n.language, {style: "currency", currency: "RUB"});
        return formatter.format(money);
    };

    getClosingProcessAccounts = () => {
        const {accounts} = this.props;
        return accounts.filter(a => a.lifePhase === 'CLOSING_PROCESS');
    };

    filterWithdrawalAccounts = () => {
        const {accounts} = this.props;
        const closings = this.getClosingProcessAccounts();
        return closings.length !== 0
            ? closings
            : accounts;
    };

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            const {type} = this.props;
            this.setState(
                {
                    type: type,
                    channels: null,
                    withdrawChannels: null,
                    withdrawalSum: null,
                    accounts: null,
                    selectedChannel: null,
                    selectedAccount: null,
                    fees: null,
                    channelsDisabled: false
                });
        }
    }

    onChangeAmount = () => {
        this.setState(
            {
                selectedChannel: null,
                fees: null,
                channelsDisabled: false
            });
    };

    render() {
        const {f, t, type, channels, allowInput, jackBet, mailReceiptAvailable} = this.props;

        const imageContext = require.context("./img/", true, /^.*\.png$/);
        const {fees, selectedChannel, selectedAccount} = this.state;
        const images = {};
        const {withdrawChannels} = this.state;
        const availableChannels = type === "WITHDRAW" && selectedAccount ? withdrawChannels : channels;
        imageContext.keys().forEach(function (key) {
            images[key] = imageContext(key);
        });
        const closingAccount = this.getClosingProcessAccounts()[0];
        const makeProxyF = (f, onChange) => ({
            ...f, onChange: (val) => {
                f.onChange(val);
                onChange();
            }
        });

        const paymentRejectReasonMessage = (payoutInfo) => {
            return payoutInfo.hasSession
                ? t("payment.withdraw.sessionExist")
                : payoutInfo.reserve > 0
                    ? t("payment.withdraw.reserveExist")
                    : payoutInfo.remainder > 0
                        ? t("payment.withdraw.deny", {payIn: selectedAccount.payoutInfo.payIn, bets: selectedAccount.payoutInfo.bets, remainder: selectedAccount.payoutInfo.remainder})
                        : "";

        }

        return (
            <React.Fragment>
                {
                    closingAccount &&
                    <div className={classNames("alert", "alert-info")}>
                        {t(`payment.closingAccountExist`, {receiptNo: closingAccount.receiptNo})}
                    </div>
                }
                {
                    availableChannels &&
                    <React.Fragment>
                        {
                            type === "DEPOSIT" &&

                            <div>
                                <div className="row">
                                    <LabelInput
                                        max={6}
                                        f={makeProxyF(f("amount"), () => this.onChangeAmount())}
                                        readOnly={!allowInput || jackBet}
                                        changeViewOnBlur={value => availableChannels.length ? value + ' ₽' : value}
                                    />
                                </div>
                            </div>
                        }
                        {
                            type === "WITHDRAW" &&
                                <div>
                                    <div className="" style={{width: '100%'}}>
                                        <div className="row" style={{marginBottom: 12}}>
                                            <div className="col-md-3">
                                                <label className="control-label required">{t("payment.choicePlayer.title")}</label>
                                            </div>
                                            <div className="col-md-9">
                                                <Select
                                                        value={selectedAccount}
                                                        onChange={this.handleChoiceAccount}
                                                        placeholder={""}
                                                        isDisabled={!allowInput}
                                                        options={this.filterWithdrawalAccounts()
                                                            .map(a => ({
                                                                label: this.formatChoiceAccountData(t, a),
                                                                value: {id: a.id, money: a.money},
                                                                payoutInfo: a.payoutInfo
                                                            }))
                                                        }
                                                    />
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom: 12}}>
                                            <div className={'col-md-3'}>
                                                <label className="control-label required">{t("payment.withdrawal")}</label>
                                            </div>
                                            <div className={"col-md-9"}>
                                                <input className="form-control" value={this.formatMoney(this.state.withdrawalSum)} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        {
                            type === "WITHDRAW" && selectedAccount && !selectedAccount.payoutInfo.closeAllowed &&
                            <div className="" style={{width: '100%'}}>
                                <div className="row" style={{marginBottom: 12, color: "red"}}>
                                    <div className="col-md-12" dangerouslySetInnerHTML={{__html: paymentRejectReasonMessage(selectedAccount.payoutInfo)}}/>
                                </div>
                            </div>
                        }

                        {
                            availableChannels.filter(channel => {
                                return images["./" + channel.channel.toLowerCase() + ".png"];
                            }).length > 0 ?
                                <React.Fragment>
                                    <div>
                                        <div className="" style={{width: '100%'}}>
                                            <div className="row">
                                                <div className={'col-md-8'}>
                                                    <label><b>{t("payment.channels.select." + type)}:</b></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={''}>
                                        <div className="row">
                                            {
                                                availableChannels.filter(channel => {
                                                    return images["./" + channel.channel.toLowerCase() + ".png"];
                                                }).map(channel => {
                                                    return (<ChannelPicture
                                                        key={channel.channel}
                                                        selectedChannel={selectedChannel}
                                                        channel={channel}
                                                        images={images}
                                                        disabled={this.state.channelsDisabled || ((this.state.selectedAccount === null || !this.state.selectedAccount.payoutInfo.closeAllowed ) && type === "WITHDRAW")}
                                                        onSelect={this.handleSelectChannel(channel)}
                                                    />);
                                                })
                                            }
                                        </div>
                                    </div>
                                </React.Fragment> :
                                <div>
                                    <br/>
                                    <label><b>{t("payment.channels.notfound." + type)}</b></label>
                                </div>
                        }

                        <div>
                            {
                                fees && fees.channels && fees.channels.length > 0 &&
                                <div className="" style={{width: '100%'}}>
                                    <div className="row" style={{marginBottom: 12}}>
                                        <div className={'col-md-4'}>
                                            <label className="control-label">{t("payment.amount.fee")}</label>
                                        </div>
                                        <div className={"col-md-8"}>
                                            <input className="form-control" value={this.formatMoney(fees.channels[0].fee)} disabled/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        {mailReceiptAvailable && <div className="row">
                            <div className="col-md-8 col-md-offset-4">
                                <LabelCheckbox f={f("sendReceipt")} readOnly={!allowInput}/>
                            </div>
                        </div>}
                        <div className="form-group" style={{textAlign: "center", marginBottom: "15px"}}>
                            <div style={{display: "flex", justifyContent: 'center'}}>
                                <button className="col-xs-12 col-sm-4 btn btn-primary" type="button"
                                        disabled={!allowInput || this.state.channelsDisabled || selectedChannel === null}
                                        onClick={this.handleExecute}>
                                    {t("payment.execute.submit.label")}
                                </button>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

PaymentForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isStatusOk: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    allowInput: PropTypes.bool,
    mailReceiptAvailable: PropTypes.bool,
    type: PropTypes.string,
    channels: PropTypes.array,
    accounts: PropTypes.array,
    jackBet: PropTypes.object,
    confirm: PropTypes.object
};

export default form({
    i18nPrefix: "payment.",
    initialModel: props => ({
        type: "DEPOSIT",
        amount: props.jackBet ? String(props.jackBet.step) : String(props.minDeposit),
        sendReceipt: true
    }),
    schema: (model) => ({
        type: [
            required(true),
            values("DEPOSIT", "WITHDRAW")
        ],
        amount: [
            required(true),
            money(2),
            gtMoney(0),
            maxMoney(999999.99),
            i18nKey("payment." + model.type.toLowerCase() + ".amount")
        ],
        sendReceipt: [
            required(true)
        ]
    })
})(compose(confirm(), withTranslation())(PaymentForm));
