import React from "react";
import { useTranslation } from "react-i18next";
import LabelDateInput from "../../forms/LabelDateInput";
import RadioButtons from "../../forms/RadioButtons";
import { form } from "../../validation/FormController";
import { FreezeFormPropTypes } from "../common/FreezeForm";

export function DesktopFreezeForm({f, onSubmitFreeze}: FreezeFormPropTypes) {
    const { t } = useTranslation();
    if (f.submit) {
        return (
            <form name="account-restriction" className="form-horizontal" onSubmit={f.submit(onSubmitFreeze)}>
                <h3>{t('restrictions.freeze.title')}</h3>
                <span>{t('restrictions.freeze.info')}</span>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-6">
                            <RadioButtons f={f('type')}/>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <LabelDateInput f={f('date')} showLabel={false}/>
                                </div>
                                <div className="col-md-6">
                                    <LabelDateInput f={f('time')} showTimeSelectOnly showLabel={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="btn btn-primary"
                        disabled={!f.model.type}
                >
                    {t('restrictions.freeze.btn')}
                </button>
            </form>
        );
    } else {
        return null;
    }

}
