import {AxiosInterceptorManager} from "axios";
import {useEffect} from "react";

interface Ticker {
    start(): void;
    stop(): void;
}

export class IntervalTicker implements Ticker {

    private readonly func: () => void;
    private readonly tick: number;

    private starting: boolean = false;
    private id: ReturnType<typeof setInterval> | undefined;

    constructor(func: () => void, tick: number, autoStart: boolean = true) {
        this.func = func;
        this.tick = tick;
        if (autoStart) {
            this.start();
        }
    }

    public start(): void {
        if (this.id) {
            return;
        }

        this.starting = true;

        // call to func() may call timer.stop(),
        // in such cases there is no need to call setInterval

        this.func();
        if (this.starting) {
            this.id = setInterval(this.func, this.tick);
        }
    }

    public stop(): void {
        this.starting = false;
        if (!this.id) {
            return;
        }
        clearInterval(this.id);
        this.id = undefined;
    }
}

export const useTicker = (func: () => void, timeout: number, autoStart: boolean = true, deps: Array<any> = []): Ticker => {
    const t = new IntervalTicker(func, timeout, false);
    useEffect(() => {
        if (autoStart) {
            t.start();
        }
        return () => t.stop();
    }, deps);
    return { start: () => t.start(), stop: () => t.stop() };
};

export const useAxiosInterceptor = <T>(interceptors: AxiosInterceptorManager<T>,
                                       onFulfilled: (value: T) => T | Promise<T>,
                                       onRejected?: (error: any) => any) => useEffect(() => {
    const interceptorId = interceptors.use(onFulfilled, onRejected);
    return () => interceptors.eject(interceptorId);
}, []);
