import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReduxState } from "../../redux/redux";

export function InnerHelp() {
    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    const headerStyles = {
        fontSize: isMobile ? 12 : 'initial',
        fontWeight: "initial"
    } as CSSProperties;

    return (
        <>
            {["q0", "q1", "q2", "q3"].map((q: any) =>
            <div key={q} className="panel-group" style={{fontSize: isMobile ? 12 : 'initial'}}>
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <h4 className="panel-title" style={headerStyles}>
                            <a data-toggle="collapse" href={`#${q}`}>{t(`help.faq.questions.${q}.question`)}</a>
                        </h4>
                    </div>
                    <div id={q} className="panel-collapse collapse">
                        <div className="panel-body">{t(`help.faq.questions.${q}.answer`)}</div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
}
