export default class AssetsLinks {

  constructor() {
    this.links={};
    this.links["k"] = "./res/img/chess/black/king.png";
    this.links["q"] = "./res/img/chess/black/queen.png";
    this.links["r"] = "./res/img/chess/black/castle.png";
    this.links["b"] = "./res/img/chess/black/bishop.png";
    this.links["n"] = "./res/img/chess/black/knight.png";
    this.links["p"] = "./res/img/chess/black/pawn.png";

    this.links["K"] = "./res/img/chess/white/king.png";
    this.links["Q"] = "./res/img/chess/white/queen.png";
    this.links["R"] = "./res/img/chess/white/castle.png";
    this.links["B"] = "./res/img/chess/white/bishop.png";
    this.links["N"] = "./res/img/chess/white/knight.png";
    this.links["P"] = "./res/img/chess/white/pawn.png";

    this.fontFigures = {};
    this.fontFigures["k"] = {letter: 'l', color: 'black'};
    this.fontFigures["q"] = {letter: 'w', color: 'black'};
    this.fontFigures["r"] = {letter: 't', color: 'black'};
    this.fontFigures["b"] = {letter: 'v', color: 'black'};
    this.fontFigures["n"] = {letter: 'm', color: 'black'};
    this.fontFigures["p"] = {letter: 'o', color: 'black'};

    this.fontFigures["K"] = {letter: 'l', color: 'white'};
    this.fontFigures["Q"] = {letter: 'w', color: 'white'};
    this.fontFigures["R"] = {letter: 't', color: 'white'};
    this.fontFigures["B"] = {letter: 'v', color: 'white'};
    this.fontFigures["N"] = {letter: 'm', color: 'white'};
    this.fontFigures["P"] = {letter: 'o', color: 'white'};

    this.sq_side = 5.5;

  }
}
