import React, { useState } from 'react';
import { useTranslation} from "react-i18next";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {compose} from "redux";
import { ReduxState } from "../redux/redux";
import PersonalInfo from "./PersonalInfo";
import { preloadGet} from "../util/preload";
import PreApply from "./PreApply";
import withReloading from "../Reloading";
import ChangePhoneDialog from "./ChangePhoneDialog";
import ChangeEmailDialog from "./ChangeEmailDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {RouteComponentProps, withRouter} from "react-router";
import {useNotifications} from "../util/Notifications";
import {Notification} from "react-notification-system";
import {useProgressBar} from "../redux/loadingPage";

interface PersonalProps extends RouteComponentProps {
    account: any;
    blockedFields?: string[];
    application?: any;
    doComponentReload: any;
}

const Personal: React.FC<PersonalProps> = ({application, account, blockedFields, doComponentReload, history: {push, goBack}}) => {

    const [phase] = useState<string>('info');
    const [showChangePhoneModal, setShowChangePhoneModal] = useState<boolean>(false);
    const [showChangeEmailModal, setShowChangeEmailModal] = useState<boolean>(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const {addNotification} = useNotifications();
    const goToEdit = () => push('/personal/edit');
    const {start, complete} = useProgressBar(useDispatch());

    const handleDownload = () => {
        window.open(`/api/personal/download-application?locale=${i18n.language}`)
    };

    const handleTakeBack = () => {
        axios.post(
            "/api/personal/take-back-application",
            {},
            {
                params: {
                    applicationNumber: application.applicationNumber
                },
            }
        ).then((resp) => {
            let notification: Notification;
            if (!resp.data) {
                notification = {message: t("personal.application.takeBackError"), level: "error"}
            } else {
                notification = {message: t("personal.application.takeBackSuccess"), level: "success"}
            }
            addNotification(notification);
            doComponentReload();
        })
    };

    const handleChangeEmail = () => {
        setShowChangeEmailModal(true);
    };

    const handleChangePhone = () => {
        setShowChangePhoneModal(true);
    };

    const handleChangePassword = () => {
        setShowChangePasswordModal(true);
    };

    const handleSentApplication = () => {
        doComponentReload();
    };

    const handleChange2FA = (enable: any) => {
        start();
        axios.post("/api/personal/change-2fa", { enable }).then(doComponentReload).finally(complete);
    }

    function renderMainPersonalInfo() {
        return phase === 'info'
            ? <PersonalInfo account={account}
                            blockedFields={blockedFields}
                            hasApplication={!!application}
                            onApply={goToEdit}
                            onTakeBack={handleTakeBack}
                            onDownload={handleDownload}
                            onChangePassword={handleChangePassword}
                            onChange2FA={handleChange2FA}
                            onChangeEmail={handleChangeEmail}
                            onChangePhone={handleChangePhone}
            />
            : <PreApply onSentApplication={handleSentApplication}
            />;
    }

    if (!isMobile) {
        return <div>
            {renderMainPersonalInfo()}
            <ChangePhoneDialog
                addNotification={addNotification}
                isOpen={showChangePhoneModal}
                start={start}
                complete={complete}
                refresh={doComponentReload}
                onRequestHide={() => setShowChangePhoneModal(false)}/>

            <ChangeEmailDialog
                isOpen={showChangeEmailModal}
                onRequestHide={() => setShowChangeEmailModal(false)}/>

            <ChangePasswordDialog
                isOpen={showChangePasswordModal}
                onRequestHide={() => setShowChangePasswordModal(false)}/>
        </div>;
    } else {
        return <div>
            {!(showChangePhoneModal || showChangeEmailModal || showChangePasswordModal) && renderMainPersonalInfo()}
            {showChangePhoneModal && <ChangePhoneDialog
                addNotification={addNotification}
                isOpen={showChangePhoneModal}
                start={start}
                complete={complete}
                onRequestHide={() => setShowChangePhoneModal(false)}/>}

            {showChangeEmailModal && <ChangeEmailDialog
                isOpen={showChangeEmailModal}
                onRequestHide={() => setShowChangeEmailModal(false)}/>}

            {showChangePasswordModal && <ChangePasswordDialog
                isOpen={showChangePasswordModal}
                onRequestHide={() => setShowChangePasswordModal(false)}/>}
        </div>;
    }
}

type PreloadInvalidatedFieldsType = {
    blockedFields: string[];
}

type PreloadPersonalType = {
    account: any
}

type PreloadPersonalApplicationType = {
    application: any;
}


export default compose<React.ComponentType<PreloadPersonalApplicationType>>(
    withReloading(),
    preloadGet<PreloadInvalidatedFieldsType, string[], {}>("api/auth/invalidated-fields", "blockedFields"),
    preloadGet<PreloadPersonalType, any, PreloadInvalidatedFieldsType>("/api/personal", "account"),
    preloadGet<PreloadPersonalApplicationType, any, any>(
        "/api/personal/get-application",
        resp => resp ? {application: resp} : {application: null}
    ),

)(withRouter(Personal));
