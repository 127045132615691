import React, {Component} from 'react';
import RestoreView from "./RestoreView";

import axios from "axios";

class Restore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNotInUse: undefined,
            phone: undefined,
            code: undefined,
            invalidCode: undefined,
            success: undefined,
            unknownNumber: false,
            captchaResolved: false
        };
    }

    render() {
        return <RestoreView
            phone={this.state.phone}
            unknownNumber={this.state.unknownNumber}
            phoneNotInUse={this.state.phoneNotInUse}
            invalidCode={this.state.invalidCode}
            captchaResolved={this.state.captchaResolved}
            confirmationRequestResult={this.state.confirmationRequestResult}
            success={this.state.success}
            onRestoreRequest={this.onRestoreRequest}
            onVerifyRequest={this.onVerifyRequest}
            onCodeResent={this.onCodeResent}
            onChangeCallback={this.onChangeCallback}
            onCaptchaResolved={this.onCaptchaResolved}
        />;
    }

    onCaptchaResolved = (ok) => {
        this.setState(prev => ({...prev, captchaResolved: ok}))
    }

    onChangeCallback = () => {
        this.setState(prev => ({...prev, unknownNumber: false}))
    }

    onRestoreRequest = (phone, callback) => {
        this.setState({phone}, () => {
            axios.post("/api/restore", undefined, { params: { phone }}).then(resp => {
                this.handleRestoreResponse(resp.data);
                callback && callback();
            });
        })
    };

    onVerifyRequest = ({phone, code}, callback) => {
        this.setState({phone, code}, () =>
            axios.post("/api/restore/verify", undefined, { params: { phone, code } }).then(resp => {
                this.handleVerifyResponse(resp.data);
                callback && callback();
            }));
    };

    onCodeResent = () => {
        this.setState({invalidCode: undefined, success: undefined})
    };

    handleRestoreResponse = ({status, confirmationRequestResult}) => {
        this.setState({
            unknownNumber: status === 'UNKNOWN_NUMBER',
            phoneNotInUse: status === 'UNKNOWN_NUMBER' ? this.state.phone : false,
            confirmationRequestResult: confirmationRequestResult,
        });
    };

    handleVerifyResponse = (isValid) => {
        this.setState({
            invalidCode: !isValid ? this.state.code : false,
            success: isValid,
        })
    };
}

export default Restore;
