import { dateFormatter } from "../commons/DateTimeUtils";

export const lineHasSpecialSymbol = (array, specSymbol) => {
    let counter = 0;
    for(var i = 0; i < array.length; i++) {
        if(array[i] == specSymbol){
            counter++;
        }
    }
    return !(counter == 0 || counter == array.length);
}

export const modeWithoutSpecialSymbol = (array, specSymbol, isMarkoPolo) => {
    if(! isMarkoPolo) {
        for(var i = 0; i < array.length; i++)
        {
           if(array[i] != specSymbol){
               return array[i];
           }
        }
        return specSymbol;
     } else {
        for(var j = 0; j < array.length; j++)
        {
           if(array[j] != specSymbol && array[j] !== "10"){
               return array[j];
           }
        }
        return specSymbol;
     }
}

export const getWinningSymbolsFromLine = (symbols, pL) => {
    var result = [];
    if(pL && pL.coordinates && pL.coordinates.length > 0) {
        for (var i = 0; i < pL.coordinates.length; i++) {
            result.push(symbols[pL.coordinates[i]]);
        }
    }
    return result;
}

export const getKoeficientForWinningBalls = (mode, ballsLength, tableData) => {
    for (var i = 0; i < tableData[mode].balls.length; i++) {
        if(countRealValuesInArray(tableData[mode].balls[i].combo) == ballsLength)
            return tableData[mode].balls[i].koeficient;
    }
    return 0;
}

export const countRealValuesInArray = (array) => {
    let counter = 0;
    for(var i = 0; i < array.length; i++) {
        if(array[i] !== null){
            counter++;
        }
    }
    return counter;
}

export const getCombo = (state) => {
    if (state && state.session && state.session.details && state.session.details.hatDetails)
        return state.session.details.hatDetails.combination;
    else
        return 0;
};

export const getStepSize = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
        return state.session.details.hatDetails.rate;
    else
        return 0;
};

export const getCurrentDoubleStepAndTotalSteps = (state) => {
    var result = { step: null, totalSteps: null};
     if(state && state.session && state.session.details && state.session.details.hatDetails) {
         result.step = state.session.details.hatDetails.step;
         result.totalSteps = state.session.details.hatDetails.totalSteps;
         return result;
     } else
        return result;
};

export const getHistoryDoubleBalls = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.expressBetHistory || [];
    else
       return [];
};

export const getCurrentDoubleBall = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.expressBetHistory[0];
    else
       return null;
};

export const getMatrixRegularKvitokData = (state, rate, prize, receiptNo, stepNo, getLocaleString) => {

    var result = getTopKvitokData(state, receiptNo, stepNo, getLocaleString);
    let denomination = getDenomination(state);
    if(state && state.session && state.session.details && state.session.details.hatDetails) {
        result.COMBO = state.session.details.hatDetails.lineCount;
        result.CREDITS = Math.round(rate / denomination);
        result.BALLS = rate;
        result.CREDITS_RESULT = Math.round(prize / denomination);
        result.BALLS_RESULT = prize;
        result.eb = {
            rate: rate
        };
    }
    return result;
};

export const getTopKvitokData = (state, receiptNo, stepNo, getLocaleString) => {

    var result = { KVITNUMBER: "/", STEPNUMBER: "", GAME_TYPE: "", STEPTIME: "", EVENT: "", TIMERESULT: "", RESULTTIME: "" };

    if(state && state.session && state.session.details && state.session.details.rateDetails) {
          result.KVITNUMBER = receiptNo;
          result.STEPNUMBER = stepNo;
          result.GAME_TYPE = state.session.details.rateDetails.eventName;
          result.EVENT = state.session.details.rateDetails.eventNumber ? state.session.details.rateDetails.eventNumber : "";
          result.STEPTIME = dateFormatter(state.session.details.rateDetails.registrationTime, state.session.timezoneOffset, getLocaleString); //шаг выполнен
          result.TIMERESULT = dateFormatter(state.session.details.rateDetails.eventTime, state.session.timezoneOffset, getLocaleString); //время расчета результата
          result.RESULTTIME = dateFormatter(state.session.details.rateDetails.calculationTime, state.session.timezoneOffset, getLocaleString); //время наступления события
          result.eventType = state.session.details.eventType;
    }
    return result;
};

export const getBallsFromState = (state) => {

    if(state && state.session && state.session.details && state.session.details.hatDetails && state.session.details.hatDetails.result)
        return state.session.details.hatDetails.result;
    else
        return [];
};

export const isFreeSpinsPossible = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.state === "INITIAL_SUPER_EXPRESS_BET";
   else
       return false;
};

export const isFreeSpinsMode = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.state === "SUPER_EXPRESS_BET";
   else
       return false;
};

export const getTotalSteps = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.totalSteps;
    else
       return 0;
};

export const getStep = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.step;
    else
       return 0;
};

export const isEndOfSEB = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.lastState == "SUPER_EXPRESS_BET" && state.session.details.hatDetails.state == "TAKE_OR_WIN";
    else
       return false;
};

export const isEndOfEB = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.lastState == "EXPRESS_BET" && state.session.details.hatDetails.state == "REGULAR";
    else
       return false;
};

export const getSelectedDoubleBall = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
       return state.session.details.hatDetails.expressBet;
    else
       return null;
};

export const getRegularCurrentWin = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails)
        return state.session.details.hatDetails.currentPrize;
    else
        return 0;
};

export const getPrizeFromLines = (lines) => {
    return lines
        .map(line => line.prize)
        .reduce((prev, cur) => prev + cur, 0);
}

export const getDenomination = (state) => {
    if(state && state.session && state.session.details && state.session.details.denomination)
        return state.session.details.denomination;
    else
        return 1;
}

/*eslint-disable*/
export const getDataForEar = (state)  => {
  var datum;
  var coefficient = 0;
  var currentCoeffCategory = 0;
  var currentGroup = 0;
  var totalSum = 0;
  var totalGroup = 0;
  var sum = 0;
  var datums = [];
  var chessInfoDatums = [];
  var symbolTriads = [];
  var hatState = '';
  var timezone = 0;
  var lines = [];
  var lineRate = 0;
  var currentPrize = 0;

  if(state)
    var session = state.session;
  if(session) {
    var details = session.details;
    timezone = session.timezoneOffset;
  }

  if (details.hatDetails) {
      var hatDetails = details.hatDetails;
      if(hatDetails.symbols)
          var symbols = hatDetails.symbols;
      if(hatDetails.prize)
          currentPrize = parseInt(hatDetails.prize);
      if(hatDetails.rate)
          lineRate =  parseInt(hatDetails.rate);

      if(hatDetails.result)
          var result =  hatDetails.result;
  }

  symbolTriads = [[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1]];

  if (details.rateDetails) {
      if (details.rateDetails.coefficient !== "") {
          coefficient = details.rateDetails.coefficient;
      } else {
          coefficient = 0
      }
  }

  if (details.rateDetails){
    var groups = details.rateDetails.groups;
    if(details.rateDetails.chessInfos && details.rateDetails.chessInfos.length > 0){
      var ci;
      for (var j=0; j<details.rateDetails.chessInfos.length; j++)
      {
        ci = details.rateDetails.chessInfos[j];
        var chessInfoDatum ={};
        chessInfoDatum.fen = ci.fen;
        chessInfoDatum.matchId = ci.matchId;
        chessInfoDatum.moveNumber =  ci.moveNumber;
        chessInfoDatum.moveInfo = ci.moveInfo;
        chessInfoDatum.timeOfMove =  ci.moveDate;
        chessInfoDatum.sum = ci.sum;
        chessInfoDatum.currentRound = j+1;
        chessInfoDatums.push(chessInfoDatum);
        totalSum += ci.sum;
      }

      if( groups!= null){
        for (var i in groups)
        {
          datum = { roundNumber: 0, group: 0, coeffCategory: 0};
          datum.roundNumber = i+1;
          datum.group = groups[i];
          // datum.coeffCategory = coeffCategories[i];
          datums.push(datum);
          currentGroup= groups[i];

        }
      }
    }
    totalGroup = (totalSum%2 == 0)? 2: 1;
  }
  return {
    coefficient: coefficient,
    currentCoeffCategory:currentCoeffCategory,
    currentGroup:currentGroup,
    totalSum: totalSum,
    totalGroup: totalGroup,
    datums:datums,
    symbolTriads:symbolTriads,
    sum: sum,
    chessInfoDatums: chessInfoDatums,
    hatState: hatState,
    timezone: timezone,
    result: result,
    championship: details.rateDetails.championship,
    tour: details.rateDetails.tour
  }
}

export const getCurrencyShowType = (state) => {
    //return "CURRENCY_CREDITS";
    //return "CURRENCY";
    //return "CREDITS";

    if(state && state.session && state.session.details){
        return state.session.details.currencyShowType;
    } else
        return "CREDITS";
}

export const getSEBCount = (state) => {
    if (state && state.session && state.session.details && state.session.details.hatDetails) {
        return state.session.details.hatDetails.sebCount;
    } else
        return 0;
}

//проверка повторного выпадения СЕБа в марко поло играх
export const isMarkoPoloSebAgain = (state) => {
    if(state && state.session && state.session.details && state.session.details.hatDetails && state.session.details.hatDetails.symbols) {
        if(state.session.details.hatDetails.symbols.length > 0) {
            let result = 0;
            for(var i = 0; i < state.session.details.hatDetails.symbols.length; i++) {
                if(state.session.details.hatDetails.symbols[i] == 10) { //marko polo SEB symbol
                    result++;
                }
            }
            return result > 2;
        }
    }
    return false;
}