import { Reducer } from "redux";

export interface ShowInstallAppAction {
    type: "TOGGLE_SHOW_MESSAGE";
}

export const showInstallAppDefault: boolean = true;

export const showInstallAppMessageReducer: Reducer<boolean, ShowInstallAppAction> = (state = showInstallAppDefault, action) => {
    return (action.type === 'TOGGLE_SHOW_MESSAGE') ? !state : state;
};

export function toggleShowInstallAppMessage() {
    return { type: 'TOGGLE_SHOW_MESSAGE' };
}
