import React from "react";
import { useDispatch } from "react-redux";
import logo from "../icons/mobile/logo.png";
import {useTranslation} from "react-i18next";
import {ExternalMenuItem} from "./sidebar/ExternalMenuItems";
import {compose} from "redux";
import {confirm} from "../util/ConfirmDialog";
import {preloadGet} from "../util/preload";

type MobileFooterProps = {
    version: string;
}

export function MobileFooter({ version }: MobileFooterProps ) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (<div style={{
        position: 'absolute',
        width: '100%',
        bottom: 0,
        backgroundColor: '#AB1919',
        height: 60,
        backgroundImage: `url(${logo})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: 'flex-end',
        padding: '0px 18px'
    }}>
        <span style={{ fontSize: 10, marginTop: 9, color: "white", cursor: "pointer" }}
              onClick={() => dispatch({ type: "TOGGLE_MOBILE_VIEW_STATUS" })}>{ t("footer.version.full") }</span>
        <div style={{
            fontSize: 12,
            position:'absolute',
            bottom:2,
            right:0,
            paddingRight:10,
            color:'white',
        }}>
            { version }
        </div>
    </div>);

}
