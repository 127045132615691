import React from "react";
import {useTranslation} from "react-i18next";
import './cookieStyle.css'
import classNames from "classnames";

interface CookieDisclaimerProps {
    mobile: boolean
}

export const CookieDisclaimer: React.FC<CookieDisclaimerProps> = ({mobile}) => {
    const {t} = useTranslation()
    const [showDisclaimer, setShowDisclaimer] = React.useState<boolean>(false);
    const lifetime = 365;
    const cookieName = 'cookie-consent';
    const cookieValue = 1;

    React.useEffect(() => {
        setShowDisclaimer(!getCookieValue());
    }, [showDisclaimer])

    const setCookie = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const date = new Date();
        date.setTime(date.getTime() + (lifetime * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/;Secure;SameSite=None; Secure=false";
        setShowDisclaimer(false);
    }

    const getCookieValue = () => {
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length + 1, cookie.length + 1); // + '='
            }
        }
        return null;
    }

    const desktopDisclaimer = () => {
        return <div>
            <div id='cookit' className={'cookie-details'}>
                <p id='cookit-message'>{t('cookie.text')}</p>
                <div className={'button-container'}>
                    <button className={'cookie-accept-button'} id='cookit-button' onClick={setCookie}>
                        {t('cookie.accept')}
                    </button>
                </div>
            </div>
        </div>
    }

    const mobileDisclaimer = () => {
        return (
            <div className={classNames('notifications', 'list-block', 'media-list')}
                 style={{display: 'block', transform: 'translate3d(0px, 0px, 0px)'}}>
                <ul>
                    <li className={'notification-item'} style={{transform: 'translate3d(0px, 0px, 0px)'}}>
                        <div className={'item-content'}>
                            <div className={'item-inner'}>
                                <div className={'item-text'}>{t('cookie.text')}</div>
                                <div className={'button-container'}>
                                    <button className={'close-notification'} onClick={setCookie}>{t('cookie.accept')}</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        );
    }
    return showDisclaimer ? (mobile ? mobileDisclaimer() : desktopDisclaimer()) : <></>;
}