export default class ValidationError {

    code;
    args;

    constructor(code, args = {}) {
        this.code = code;
        this.args = args;
    }

    static of(what) {
        if (what instanceof ValidationError) {
            return what;
        }
        if (typeof what === "object") {
            return new ValidationError(what.code, what.args);
        }
        if (typeof what === "string") {
            return new ValidationError(what);
        }
        throw new Error("Unknown ValidationError spec: " + what);
    }
}