import React from "react";
import { useTranslation } from "react-i18next";
import CreateSessionButton from "../CreateSessionButton";

export type DesktopGamesProps = {
    games: Array<string>;
    titleKey: string;
    readonly: any;
}

export function DesktopGames(props: DesktopGamesProps) {
    const { titleKey, games, readonly } = props;
    const { t } = useTranslation();
    return (
        <div>
            <h2 className="text-center">{t(titleKey)}</h2>
            <div className="form-group">
                {games.map(game =>
                    <div key={game} className="col-md-4 text-center">
                        <CreateSessionButton game={game} disabled={readonly}/>
                    </div>
                )}
            </div>
        </div>
    );
}
