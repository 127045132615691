import axios from 'axios';
import React, {Dispatch, SetStateAction} from "react";

export interface Address {
    id: number;
    objectId: number;
    parentId: number;
    name: string;
    level: number;
}

export interface GetAddressProps extends BaseProps {
    minLevel: number;
    maxLevel: number;
}

export interface BaseProps {
    partOfName: string;
    parentId: number;
    setter: Dispatch<SetStateAction<Array<Address>>>;
}

export type levelType = 'regions' | 'districts' | 'cities' | 'localities' | 'streets' | 'houses' | 'buildings';

export interface Level {
    levelType: levelType;
    id: number;
    minLevel: number;
    maxLevel: number;
    value: string;
}

const getAddress = ({partOfName, minLevel, maxLevel, parentId, setter}: GetAddressProps) => {
    axios.get('/api/address', {params: {partOfName, minLevel, maxLevel, parentId}})
        .then(res => setter(res.data as Array<Address>));
};

const getHouse = ({partOfName, parentId, setter}: BaseProps) => {
    axios.get('/api/address/house', {params: {number: partOfName, parentId}})
        .then(res => setter(res.data.map((home: string, i: number) => toAddress(home, i, parentId))));
};

const getBuildings = ({partOfName, parentId, setter}: BaseProps) => {
    axios.get('/api/address/buildingAndStructure', {params: {houseNumber: partOfName, parentId, buildText: null, structureText: null}})
        .then(res => setter(res.data.map((home: string, i: number) => toAddress(home, i, parentId))));
};

const toAddress = (home: string, index: number, parentId: number): Address => ({
    id: index, name: home, parentId, objectId: index, level: 0
});

export const useFiasAddress = (level: Level) => {
    const [address, setAddress] = React.useState([] as Array<Address>);
    const [value, setValue] = React.useState("");
    const [parentId, setParentId] = React.useState(0);
    if (level === null) {
        return {address, update: null, onKeyUp: null, onKeyDown: null};
    }

    const update = (v: string, pId: number) => {
        setValue(v);
        setParentId(pId);
    };

    const get = () => {
        switch (level.levelType) {
            case "houses":
                return getHouse({partOfName: value, parentId, setter: setAddress});
            case "buildings":
                return getBuildings({partOfName: value, parentId, setter: setAddress});
            default:
                return getAddress({partOfName: value, parentId, ...level, setter: setAddress});
        }
    };

    let timer: any;

    const onKeyUp = () => {
        clearTimeout(timer);
        timer = setTimeout(get, 1000);
    };

    const onKeyDown = () => {
        clearTimeout(timer);
    };

    return {address, update, onKeyUp, onKeyDown};
};

export const useFiasLevels = () => {
    const [levels, setLevels] = React.useState<Array<Level>>([
        {levelType: "regions", id: 0, minLevel: 1, maxLevel: 1, value: ""},
        {levelType: "districts", id: 0, minLevel: 2, maxLevel: 3, value: ""},
        {levelType: "cities", id: 0, minLevel: 4, maxLevel: 4, value: ""},
        {levelType: "localities", id: 0, minLevel: 5, maxLevel: 6, value: ""},
        {levelType: "streets", id: 0, minLevel: 7, maxLevel: 8, value: ""},
        {levelType: "houses", id: 0, minLevel: 0, maxLevel: 0, value: ""},
        {levelType: "buildings", id: 0, minLevel: 0, maxLevel: 0, value: ""},
    ]);

    const getParenValue = (type: levelType) => {
        return (getLevel(type) || {value: ""}).value;
    };

    const getParentId = (type: levelType) => {
        const index =  levels.findIndex(a => a.levelType === type);
        if (index === 0) {
            return 0;
        }
        const filtered = levels.filter((el, i) => i < index && el.id > 0);
        if (filtered.length === 0) {
            return 0;
        }
        return filtered.reverse()[0].id;
    };

    const updateLevel = (type: levelType, id: number, value: string) => {
        setLevels(prev => prev.map(p => p.levelType === type ? {...p, id, value} : p));
    };

    const getLevel = (type: levelType) => levels.find(l => l.levelType === type);

    return {getParentId, getParenValue, updateLevel, getLevel};
};
