import React from 'react';
import {withTranslation} from "react-i18next";

import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';

import ArrowLeft from './arrow_left.png';
import ArrowRight from './arrow_right.png';

import './bpt.css'

import {ChessBoard} from '../commons/comps/chess/ChessBoard';
import ChessLinks from '../commons/comps/chess/ChessLinks';
import {isChessEventType} from '../commons/chess/ChessEventTypes';

import {dateFormatter, timeFormatter} from "../commons/DateTimeUtils";


class Header extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {print, close, t, receiptNo} = this.props;
        return (
            <div className="header section bg">
                <PrintButton print={print}/>
                <div>{t("quittance.bpt.title", {receiptNumber: receiptNo})}</div>
                <div>{t("quittance.bpt.subtitle")}</div>
                <CloseButton close={close}/>
            </div>
        );
    }
}

class Quittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, t, print, close} = this.props;
        const session = step.events[0].session;
        const {eventType, denomination} = session.details;
        if (!isChessEventType(eventType)) {
            console.log("Unknown bingo_pattern EventType " + eventType);
            return <div/>
        }
        const rate = {};
        rate.credits = Math.round(step.betAmount / denomination);
        rate.currency = step.betAmount;

        const result = {};
        result.credits = Math.round(step.winAmount / denomination);
        result.currency = step.winAmount;

        return <ChessQuittance step={step} print={print} close={close} t={t} rate={rate} result={result}/>
    }
}

function transformCard(card) {
    const isWinCard = card.result.coefficient > 0;
    const isWinCell = (i) => isWinCard && card.result.patternCellsIndexes.indexOf(i) > -1;
    const size = 4;
    const symbols = [...card.cellsNumbers].map((symbol, i) => ({value: symbol, win: isWinCell(i)}));
    let rows = [];
    for (let i = 0; i < Math.ceil(symbols.length / size); i++) {
        rows[i] = symbols.slice((i * size), (i * size) + size);
    }
    return rows;
}

function winnerSymbols(card) {
    const isWinCard = card.result.coefficient > 0;
    const isWinCell = (i) => isWinCard && card.result.patternCellsIndexes.indexOf(i) > -1;
    return card.cellsNumbers.filter((symbol, i) => isWinCell(i));
}

class ChessQuittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, t, print, close, rate, result} = this.props;
        const session = step.events[0].session;
        const {rateDetails, bptDetails, game} = session.details;
        const symbols = bptDetails.result;
        const stepNo = step.id;
        const receiptNo = step.receiptNumber;
        const championship = rateDetails.championship;
        const tour = rateDetails.tour;
        const winner = bptDetails.ticket.cards.map(winnerSymbols).reduce((a, b) => a.concat(b)).filter((v, i, a) => a.indexOf(v) === i);
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <div>
                    <div className={['quittance-b', 'bpt', 'chess', game].join(' ')}>
                        <Header print={print} close={close} t={t} rateDetails={rateDetails} receiptNo={receiptNo}/>
                        <div className="top section">
                            <div>
                                <div className={'bold-text'}>{t("quittance.bpt.stepNumber") + " " + stepNo}</div>
                                <div className={'bold-text'}>{t("quittance.bpt.eventNumber", {
                                    eventName: rateDetails.eventName,
                                    eventNumber: rateDetails.eventNumber
                                })}</div>
                                <div>{t("quittance.bpt.eventTime")}</div>
                                <div>{dateFormatter(rateDetails.eventTime, session.timezoneOffset)}</div>
                                <div>{ t("quittance.bpt.championship") + ": " + championship }</div>
                                <div>{ t("quittance.bpt.tour") + ": " + tour }</div>
                            </div>
                        </div>

                        <div className="section bg info">
                            <div>{t("quittance.bpt.registrationTime")}</div>
                            <div>{dateFormatter(rateDetails.registrationTime, session.timezoneOffset)}</div>
                            <div>{t("quittance.bpt.rate." + session.currencyShowType, rate)}</div>
                            <div>{t("quittance.bpt.ticketNumber", {number: bptDetails.ticket.number})}</div>
                        </div>
                        <div className="cards-wrapper">
                            {bptDetails.ticket.cards.filter(card => card.chosen).map((card, i) => (
                                <div key={i}>
                                    <div className="symbols-wrapper">{t("quittance.bpt.cardsAmount", {cardsAmount: i + 1})}</div>
                                    <div className="symbols-wrapper">
                                        <table>
                                            <tbody>
                                            {transformCard(card).map((row, i) => (
                                                <tr key={i}>
                                                    {row.map((cell, cellI) => (
                                                        <td className={cell.win ? 'win' : ''} key={cellI}>{cell.value}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}

                        </div>

                        <div className="bottom section bottom-result">
                            <div>{t("quittance.bpt.calculationTime")}</div>
                            <div>{dateFormatter(rateDetails.calculationTime, session.timezoneOffset)}</div>
                            {result.credits
                                ? <div>{t("quittance.bpt.result." + session.currencyShowType, result)}</div>
                                : <div>{t("quittance.bpt.noprizes")}</div>}
                        </div>

                        <div className="footer section">
                            <div className="chess-symbols">
                                <div className="symbols-label">{t("quittance.keno.chess.symbols")}</div>
                                <div className="symbols-wrapper">
                                    {symbols.map((symbol, index) => (
                                        <div className={winner.indexOf(symbol) > -1 ? 'chess-result win' : 'chess-result'} key={index}>{symbol}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    backgroundColor: "#fef6ee", display: "flex",
                    justifyContent: "space-between", flexWrap: "wrap",
                    width: "450px", flexDirection: "column"
                }}>
                    <div style={{width: "100%", textAlign: "center", paddingTop: "10px"}}>{t("quittance.keno.outcome")}</div>
                    <ChessBoardsContainer t={t} groups={rateDetails.groups} boards={rateDetails.chessInfos} symbols={symbols} tz={session.timezoneOffset}/>
                </div>
            </div>
        );
    }
}

class ChessBoardsContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
    }

    nextPage = () => {
        this.setState(prev => ({...prev, index: prev.index === 3 ? 0 : prev.index + 1}));
    };
    previousPage = () => {
        this.setState(prev => ({...prev, index: prev.index === 0 ? 3 : prev.index - 1}));
    };

    render() {
        const {boards, tz, t, symbols, groups} = this.props;
        const {index} = this.state;
        const pageSize = 6;
        return (
            <div style={{
                backgroundColor: "#fef6ee", display: "flex",
                justifyContent: "space-around", flexWrap: "wrap",
                width: "450px", flexGrow: "1"
            }}>
                <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap", alignSelf: "flex-start"}}>
                    {
                        boards
                            .slice(pageSize * index, pageSize * (index + 1))
                            .map((data, i) => {
                                return <div className="bingo chess-wrapper" key={i}>
                                    <div style={{
                                        paddingBottom: "5px",
                                        fontWeight: "bold"
                                    }}>{t("quittance.bpt.chess.board", {board: pageSize * index + i + 1})}</div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <ChessBoard fen={data.fen}/>
                                        <div className="chess-info">
                                            <div>{t("quittance.bpt.chess.game", {game: data.matchId})}</div>
                                            <div>{t("quittance.bpt.chess.move", {move: data.moveNumber})}</div>
                                            <div>{t("quittance.bpt.chess.moveTime", {moveTime: timeFormatter(data.moveDate, tz)})}</div>
                                            <div className="chess-symbol">
                                                <div>{t("quittance.bpt.chess.symbol", {
                                                    symbol: symbols.slice(pageSize * index, pageSize * (index + 1))[i]
                                                })}</div>
                                                <div>{t("quittance.bpt.chess.index", {
                                                    index: data.sum % (40 - (pageSize * index + i))
                                                })}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{paddingTop: "15px", fontWeight: "bold"}}>
                                        {t("quittance.tape.chess.sumAndGroup", {sum: data.sum, group: groups.slice(pageSize * index, pageSize * (index + 1))[i]})}
                                    </div>
                                </div>
                            })
                    }
                </div>
                <div id="bingo-chess-navigation">
                    <img src={ArrowLeft} alt="" onClick={this.previousPage}/>
                    <div style={{alignSelf: "center"}}>{index + 1} / {4}</div>
                    <img src={ArrowRight} alt="" onClick={this.nextPage}/>
                </div>
            </div>
        );
    }
}


const boardPositionFEN = (value) => {
    let rawsOnTheBoard = [];
    let dividedBySpaces = value.split(' ');
    let boardSquares = (dividedBySpaces[0]).split('/');
    for (let i = 0; i < boardSquares.length; i++) {
        if (boardSquares[i] === "")
            continue;
        rawsOnTheBoard = createRawOfPieces(rawsOnTheBoard, boardSquares[i], i)
    }
    return rawsOnTheBoard;
};

const createRawOfPieces = (rawsOnTheBoard, rawString, rawNumber) => {
    const chessLinks = new ChessLinks();
    let arr = rawString.split("");
    let index = 0;

    for (let i in arr) {

        let piece = arr[i];
        let spacesInRaw = parseInt(piece);
        if (!isNaN(spacesInRaw)) {
            index = index + spacesInRaw;
            continue;
        }
        rawsOnTheBoard.push({
            link: chessLinks.links[piece],
            x: (chessLinks.sq_side * index) + 'px',
            y: (chessLinks.sq_side * rawNumber) + 'px',
            color: chessLinks.fontFigures[piece].color,
            letter: chessLinks.fontFigures[piece].letter
        });
        index++;
    }
    return rawsOnTheBoard;
};

export default withTranslation()(Quittance);