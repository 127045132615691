import React from 'react';
import ReactTable from "react-table";
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {useTicker} from "../util/hooks";

export const Reports: React.FC<{type: 'STEPS' | 'OPERATIONS', callback: (v: boolean) => void, days: number}> = ({type, callback, days}) => {
    const [reports, setReports] = React.useState<Array<Report>>([]);
    const {t} = useTranslation();
    const fetchData = () => {
        axios.get('/api/reports', {params: {type}}).then(res => {
            setReports(res.data.reports);
            callback(res.data.hasNotCompleted);
        })
    }
    useTicker(fetchData, 30000);

    if (!reports.length) {
        return <div/>
    }
    const makeColumns = () => {

        const i18nKeyPrefix = 'reports.table.headers.';

        return [{
            Header: t(i18nKeyPrefix + 'name'),
            accessor: 'name',
            Cell: (row: any) => <div>
                <a href={"/api/reports/" + row.value}>{row.value}</a>
            </div>,
            className: 'text-center'
        }, {
            Header: t(i18nKeyPrefix + 'filter.' + type),
            accessor: 'filter',
            Cell: (row: any) => {
                const prefix = type === "OPERATIONS" ? "history.operations.table.type." : "history.steps.filter.type."
                return <div>{t(prefix + row.value)}</div>
            },
            className: 'text-center'
        }, {
            Header: t(i18nKeyPrefix + 'period'),
            accessor: 'period',
            Cell: (row: any) => <div>{row.value}</div>,
            className: 'text-center'
        }, {
            Header: t(i18nKeyPrefix + 'maxDate'),
            accessor: 'maxDate',
            Cell: (row: any) => <div>{row.value}</div>,
            className: 'text-center'
        }]
    };
    return <div className="reports-info">
        <h3>{t("reports.title")}</h3>
        <h5><i>{t("reports.subtitle")}</i></h5>
        <ReactTable
            data={reports}
            columns={makeColumns()}
            showPagination={false}
            minRows={1}
        />
        {[1, 2].map(i => (
            <h5 key={i}><i>{t("reports.info." + i, {days})}</i></h5>
        ))}
    </div>
}

interface Report {
    name: string;
    filter: string;
    period: string;
    maxDate: string;
}