import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {ReduxState} from "../redux/redux";
import {CupisIdentificationStatus, UserInfo} from "../redux/auth";
import {OfferDocumentMap, OfferType} from "./OfferDocument";
import {useTranslation} from "react-i18next";
import {useProgressBar} from "../redux/loadingPage";
import {EcupsCommonResponse, EcupsRegistration, InitEcupsRegistration} from "./EcupsRegistration";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";
import ReactModal from "react-modal";

interface StateProps {
    user: UserInfo;
}

type CupisRegistrationProps = StateProps;

const CupisRegistration: React.FC<CupisRegistrationProps> = ({ user }) => {

    const { t } = useTranslation();
    const {start, complete} = useProgressBar(useDispatch());
    const [offerDocuments, setOfferDocuments] = React.useState<OfferDocumentMap | null>(null);
    const [registration, setRegistration] = React.useState<EcupsRegistration>(InitEcupsRegistration);
    const [cupisCode, setCupisCode] = React.useState<string>('');
    const [codeRequested, setCodeRequested] = React.useState<boolean>(false);
    const [codeSent, setCodeSent] = React.useState<boolean>(false);
    const [cupisResponse, setCupisResponse] = React.useState<EcupsCommonResponse | null>(null);
    const cupisStatus = user.cupisIdentification.status;
    const withError = !!user.cupisIdentification.codeNum;
    const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
    const [documentContent, setDocumentContent] = React.useState<string>('');

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setDocumentContent('');
        setModalOpen(false);
    }

    useEffect(() => {
        if (cupisStatus === CupisIdentificationStatus.DUPLICATED) {
            const duplicateResponse : EcupsCommonResponse = {
                ok: false, errorNum: user.cupisIdentification.codeNum, errorCode: user.cupisIdentification.errorCode
            };
            setCupisResponse(duplicateResponse);
        }
        if (cupisStatus === CupisIdentificationStatus.NONE || cupisStatus === CupisIdentificationStatus.INIT) {
            getOfferDocuments();
        }
        if (cupisStatus === CupisIdentificationStatus.CONFIRMED_PD) {
            deleteUnidentifiedBind();
        }
    }, [cupisStatus]);

    const onChangeRegistration = (accepted: boolean, offerType: OfferType) => {
        let docVersion = !!offerDocuments && !!offerDocuments[offerType] ? offerDocuments[offerType].version : '';
        switch (offerType) {
            case OfferType.OFFER:
                setRegistration(prev => ({...prev, offerAccepted: accepted, offerVersion: docVersion}))
                break;
            case OfferType.PD_AGREEMENT:
                setRegistration(prev => ({...prev, pdAgreementAccepted: accepted, pdAgreementVersion: docVersion}))
                break;
        }
    }

    const docAcceptedValue = (offerType: OfferType) => offerType === OfferType.OFFER ? registration.offerAccepted : registration.pdAgreementAccepted;

    function getOfferDocuments() {
        start();
        axios.get("/api/bind/offers")
            .then(response => setOfferDocuments(response.data))
            .finally(complete);
    }

    // Удаление недоидентифицированной привязки
    const deleteUnidentifiedBind = () => {
        axios.post('/api/bind/delete-unidentified')
            .then(queryAndUpdateAuth)
    }

    const register = () => {
        start();
        axios.post("/api/bind/register", registration)
            .then(response => {
                setCupisResponse(response.data);
                if (!response.data.ok) {
                    setRegistration(InitEcupsRegistration)
                }
            })
            .finally(() => {
                queryAndUpdateAuth().then(complete);
            });
    }

    const deleteBind = () => {
        updateBind("/api/bind/delete-bind");
    }

    const deleteDuplicatedBind = () => {
        updateBind("/api/bind/delete-duplicated-bind");
    }

    const confirmPhoneUpdated = () => {
        updateBind("/api/bind/confirm-phone-updated");
    }

    const updateBind = (url: string) => {
        start();
        axios.post(url)
            .then(response => {
                setCupisResponse(response.data);
            })
            .finally(() => {
                queryAndUpdateAuth();
                complete();
            });
    }

    const registerDisabled = () => {
        return !registration.offerAccepted || !registration.pdAgreementAccepted;
    }

    const sendCode = () => {
        start();
        setCodeRequested(true)
        axios.post("/api/bind/send-cupis-code")
            .then(response => {
                setCupisResponse(response.data);
                if (!response.data.ok) {
                    setCodeRequested(false);
                }
            })
            .finally(() => complete());
    }

    const checkCodeButtonDisabled = () => {
        return !codeRequested || codeSent || cupisCode.length != 4;
    }

    const onChangeCupisCode = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^[0-9\b]+$/;
        if (value === '' || regExp.test(value)) {
            setCupisCode(value);
        }
    }

    const checkCode = () => {
        start();
        setCodeSent(true);
        axios.post("/api/bind/check-cupis-code/" + cupisCode)
            .then(response => {
                setCupisResponse(response.data);
                if (!response.data.verified) {
                    setCodeSent(false);
                    setCodeRequested(false);
                    setCupisCode('');
                }
            })
            .finally(() => {
                queryAndUpdateAuth();
                complete();
            });
    }

    const openWindow = (offerType: OfferType) => {
        let text = !!offerDocuments && !!offerDocuments[offerType] ? offerDocuments[offerType].content : '';
        setDocumentContent(text);
        handleOpen();
    }

    const ErrorIfExist = <div>
        {
            cupisResponse && !cupisResponse.ok &&
            <div className="text-center">
                <div className="col-md-offset-3 col-md-6 text-danger text-center" style={{fontWeight: "bold"}}>
                    { t(`ecups.error.label`) }: { t('ecups.error.' + cupisResponse.errorCode, {code: cupisResponse.errorNum}) }
                </div>
            </div>
        }
    </div>
    const DocumentModal = <ReactModal isOpen={isModalOpen}
                                      className="Modal"
                                      overlayClassName="Overlay"
                                      ariaHideApp={false}
                                      onRequestClose={() => handleClose()}>
        <div className="form-group row modal-window" style={{width: '80%', maxHeight: '80%', position:"relative", overflow: "auto"}}>
            <div className="text-center" dangerouslySetInnerHTML={{__html: documentContent}}/>
            <button type="button" style={{bottom:'10px', width:'50px'}} className="btn btn-primary" onClick={() => handleClose()}>
                OK
            </button>
        </div>
    </ReactModal>

    const Agreement = <div>
        <h2 className="text-center">{ t("ecups.registration.title") }</h2>
        {
            cupisResponse && !cupisResponse.ok
                ? <div className="text-center">
                    <div className="col-md-offset-3 col-md-6 text-danger text-center" style={{fontWeight: "bold"}}>
                        { t(`ecups.error.label`) }: { t('ecups.error.' + cupisResponse.errorCode, {code: cupisResponse.errorNum}) }
                    </div>
                </div>
                : <div className="text-center" dangerouslySetInnerHTML={{__html: t("ecups.registration.message")}}/>
        }
        { DocumentModal }
        {
            !!offerDocuments && Object.keys(offerDocuments).map((offerType : OfferType) => {
                return <div className="checkbox col-md-offset-2 col-md-8" key={offerType}>
                    <input id={offerType} name={offerType} type="checkbox" onChange={ e => onChangeRegistration(e.target.checked, offerType) } checked={docAcceptedValue(offerType)}/>
                    <div>
                        {t(`ecups.registration.confirmOffer`)}
                        <a type="button" style={{cursor:"pointer"}} onClick={() => openWindow(offerType)}>
                            {t(`ecups.registration.${offerType}`)}
                        </a>
                    </div>
                </div>
            })
        }
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => register()} className="form-control btn btn-primary" disabled={registerDisabled()}>
                    { t(withError ? 'ecups.registration.repeat' : 'ecups.registration.next') }
                </button>
            </div>
        </div>
    </div>

    const PhoneVerification = <div>
        <h2 className="text-center">{ t("ecups.registration.title") }</h2>
        <div className="text-center" dangerouslySetInnerHTML={{__html: t("ecups.registration.phoneVerification")}}/>
        { ErrorIfExist }
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => sendCode()} className="form-control btn btn-primary" disabled={codeRequested}>
                    { t('ecups.registration.sendCode') }
                </button>
            </div>
        </div>

        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-8">
                <div className="form-group">
                    <label htmlFor="sms-code" className="col-md-3 control-label" style={{marginTop: '5px'}}>{t('ecups.registration.confirmCode')}</label>
                    <div className="col-md-3">
                        <input id="sms-code" type="text" className="form-control"
                               value={cupisCode}
                               maxLength={4}
                               onChange={onChangeCupisCode}
                               disabled={!codeRequested}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => checkCode()} className="form-control btn btn-primary" disabled={checkCodeButtonDisabled()}>
                    { t('ecups.registration.checkConfirmCode') }
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4 col-md-offset-4">
                <div className="form-group">
                    <div className="error">{ !!cupisResponse && !cupisResponse.verified && cupisResponse.failReason ? t(`ecups.registration.checkCodeFail.${cupisResponse.failReason}`) : ''}</div>
                </div>
            </div>
        </div>
    </div>
    const DeleteUser = <div>
        <h2 className="text-center">{ t("ecups.deleting.title") }</h2>
        <div className="text-center" dangerouslySetInnerHTML={{__html: t("ecups.deleting.message")}}/>
        { ErrorIfExist }
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => deleteBind()} className="form-control btn btn-primary">
                    { t('ecups.deleting.next') }
                </button>
            </div>
        </div>
    </div>

    const DuplicateUser = <div>
        <h2 className="text-center">{ t("ecups.identification.title") }</h2>
        { ErrorIfExist }
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => deleteDuplicatedBind()} className="form-control btn btn-primary">
                    { t('ecups.deleting.next') }
                </button>
            </div>
        </div>
    </div>

    const PhoneUpdating = <div>
        <h2 className="text-center">{ t("ecups.updating.title") }</h2>
        <div className="text-center" dangerouslySetInnerHTML={{__html: t("ecups.updating.message")}}/>
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-offset-4 col-md-4 text-center">
                <button type="button" onClick={() => confirmPhoneUpdated()} className="form-control btn btn-primary">
                    { t('ecups.updating.next') }
                </button>
            </div>
        </div>
    </div>

    switch (cupisStatus) {
        case CupisIdentificationStatus.NONE:
        case CupisIdentificationStatus.INIT:
            return Agreement;
        case CupisIdentificationStatus.REGISTERED:
            return PhoneVerification;
        case CupisIdentificationStatus.DELETED:
            return DeleteUser;
        case CupisIdentificationStatus.DUPLICATED:
            return DuplicateUser;
        case CupisIdentificationStatus.PHONE_UPDATED:
            return PhoneUpdating;
        default:
            return <div/>;
    }
}

export default connect<StateProps, {}, {}, ReduxState>(state => ({ user: state.auth.user as UserInfo }))(CupisRegistration);