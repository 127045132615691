import React from "react";
import NumberFormatOptions = Intl.NumberFormatOptions;


interface OwnProps {
    value: number;
    title?: string;
}

type NumberFormatProps = OwnProps & NumberFormatOptions;

const NumberFormat: React.FunctionComponent<NumberFormatProps> = ({ value, title, ...rest }) => {
    return <span title={title}>{ new Intl.NumberFormat('ru', rest).format(value) }</span>;
};
export default NumberFormat;
