export const Combinations = new Map([
    [1, ["1,1", "1,0"]],
    [2, ["1,1,1,1", "1,0,1,1", "1,0,1,0", "1,1,1,0"]],
    [3, ["1,1,1,1,1,1", "1,0,1,0,1,0", "1,0,1,0,1,1", "1,0,1,1,1,1", "1,1,1,0,1,0", "1,1,1,1,1,0", "1,0,1,1,1,0", "1,1,1,0,1,1"]],
    [4, ["0,0", "0,1"]],
    [5, ["0,0,0,0", "0,1,0,1", "0,1,0,0", "0,0,0,1"]],
    [6, ["0,0,0,0,0,0", "0,1,0,1,0,1", "0,0,0,0,0,1", "0,0,0,1,0,1", "0,0,0,1,0,0", "0,1,0,0,0,0", "0,1,0,0,0,1", "0,1,0,1,0,0"]],
    [7, ["1,1"]],
    [8, ["1,1,1,1"]],
    [9, ["1,1,1,1,1,1"]],
    [10, ["0,1", "1,0"]],
    [11, ["0,1,0,1", "1,0,1,0", "0,1,1,0", "1,0,0,1"]],
    [12, ["0,1,0,1,0,1", "1,0,1,0,1,0", "1,0,1,0,0,1", "0,1,0,1,1,0", "0,1,1,0,0,1", "0,1,1,0,1,0", "1,0,0,1,1,0", "1,0,0,1,0,1"]],
    [13, ["0,0"]],
    [14, ["0,0,0,0"]],
    [15, ["0,0,0,0,0,0"]]
]);