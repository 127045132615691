import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { withTranslation } from "react-i18next";

const LabelFor = ({t, id, translateKey, required, colmd = 4}) => {
    return (
        <div>
            <label htmlFor={id} className={ classNames("col-md-".concat(colmd), "control-label", required && "required") }>
                {t(`${translateKey}.label`, `${id}.label`)}
            </label>
        </div>
    );
};

LabelFor.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    translateKey: PropTypes.string.isRequired,
    required: PropTypes.bool,
    colmd: PropTypes.number
};

export default withTranslation()(LabelFor);
