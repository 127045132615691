import React from 'react';
import {CupisBindClientStatus} from "../redux/auth";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";

export const CupisStatusShow: React.FC<{status: CupisBindClientStatus}> = ({status}) => {
    const {t} = useTranslation();

    const needShowBindButton = [CupisBindClientStatus.DELETED_BY_CUPIS,
        CupisBindClientStatus.REBIND_ERROR_FOR_DELETED_CLIENT,
        CupisBindClientStatus.REBIND_TIMEOUT_ERROR_FOR_DELETED_CLIENT,
        CupisBindClientStatus.ERROR_REBIND_FOR_DELETED_CLIENT];

    function bind() {
        axios.post("/api/bind/delete")
            .then(queryAndUpdateAuth);
    }
    return (<div className="alert alert-info" style={{textAlign: 'center'}}>
        <div dangerouslySetInnerHTML={{__html: t(`identification.cupis.status.${status}`)}}/>
        {needShowBindButton.indexOf(status) > -1 && (
            <div>
                <button type="button" className="form-control btn btn-info" style={{width: '150px'}} onClick={bind}>
                    {t('ecups.error.next')}
                </button>
            </div>
        )}
    </div>)
}