import {UserInfo} from "../../redux/auth";
import {ConfirmProps} from "../../util/ConfirmDialog";
import {RouteComponentProps} from "react-router";

export interface OwnProps {
    category: Category;
}

export interface BlockedPreloadProps {
    blockedFields: Array<string>;
}

export interface StateProps {
    user: UserInfo;
}

export interface CategoriesPreloadProps {
    categories: Array<Category>;
}

export interface GamesPreloadProps {
    games: Array<string>;
}

export interface CategoryGameSelectorProps extends StateProps, BlockedPreloadProps, CategoriesPreloadProps, ConfirmProps, RouteComponentProps {
}

export interface GameSelectorProps extends OwnProps, StateProps, BlockedPreloadProps, GamesPreloadProps, ConfirmProps, RouteComponentProps{
}

export enum Category {
    BASIC = "BASIC",
    SPORT = "SPORT"
}