import React from 'react';
import { useTranslation } from "react-i18next";

const RestrictedMessage = () => {
    const { t } = useTranslation();
    return (
        <div>{t('restrictions.restrictedMessage')}</div>
    );
};

export default RestrictedMessage;
