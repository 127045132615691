import React from 'react';

const withReloading = () => Component => class ReloadingWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reload: false
        }
    }

    handleStateUpdate = () => {
        this.setState({ reload: true }, () => this.setState({ reload: false }))
    };

    render() {
        return (
            this.state.reload
                ? null
                : <Component doComponentReload={this.handleStateUpdate} {...this.props}/>
        );
    }
};

export default withReloading;