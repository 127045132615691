import React from 'react';
import { useTranslation } from "react-i18next";
// @ts-ignore TODO add types react-table right version
import ReactTable from "react-table";

export function DesktopAccountDetails({columns, accounts }: {columns: any, accounts: any;}) {
    const { t } = useTranslation();
    return (
        <div className="col-md-8 col-md-offset-2">
            <h2 className="text-center">{t("details.title")}</h2>
            <ReactTable
                data={accounts}
                columns={columns}
                showPagination={true}
                defaultPageSize={10}
                resizable={false}
                pageSizeOptions={[10, 20]}
                previousText={t('components.table.previousText')}
                nextText    ={t('components.table.nextText')}
                loadingText ={t('components.table.loadingText')}
                noDataText  ={t('components.table.noDataText')}
                pageText    ={t('components.table.pageText')}
                ofText      ={t('components.table.ofText')}
                rowsText    ={t('components.table.rowsText')}
            />
        </div>
    )
}
