import React from "react";
import { useTranslation } from "react-i18next";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import { AccountDetails } from "../common/AccountDetails";
import './mobile-account.css';
import NumberFormatOptions = Intl.NumberFormatOptions;

export function MobileAccountDetails({ accounts }: {accounts: AccountDetails[]}) {
    const { t } = useTranslation();
    return (
        <div style={{fontSize: 12}}>
            <MobileDualHeader firstHeader={t('payment.title.management')} secondHeader={t("details.title")}/>
            {accounts.map(el => <div key={el.id}>
                <MobileAccountItem isJackBet={el.jackBet} receiptNo={el.receiptNo} money={el.money} reserve={el.reserve} nfo={{currency: "RUB", style: 'currency'}} />
            </div>)}
        </div>
    );

}

function MobileAccountItem({ isJackBet, receiptNo, money, reserve, nfo }: {isJackBet: boolean, receiptNo: string, money: number, reserve: number, nfo: NumberFormatOptions}) {
    const i18nKeyPrefix = 'details.table.headers.';
    const { t,i18n } = useTranslation();
    return (
        <table className={'mobile-account-table'}>
            <thead>
            <tr>
                <td className={'mobile-account-table-header'}>{t(i18nKeyPrefix + 'receiptNo')}</td>
                <td>
                    { receiptNo }{ isJackBet && <span className="pa-icon pa-icon-jackbet" style={{ marginLeft: "50px" }}/> }
                </td>
            </tr>

            </thead>
            <tbody>

            <tr>
                <td className={'mobile-account-table-header'}>{t(i18nKeyPrefix + 'balance')}</td>
                <td>{ new Intl.NumberFormat(i18n.language, nfo).format(money) }</td>
            </tr>
            <tr>
                <td className={'mobile-account-table-header'}>{t(i18nKeyPrefix + 'reserve')}</td>
                <td>{ new Intl.NumberFormat(i18n.language, nfo).format(reserve) }</td>
            </tr>

            </tbody>
        </table>
    );

}
