import React, {useState} from "react";
import {Wizard} from "../components/Wizard";
import {withRouter} from "react-router";
import ApplicationForm from "../personal/ApplicationForm";
import PropTypes from "prop-types";
import axios from "axios";
import {preloadGet} from "../util/preload";
import {IdentificationChoicePersonal} from "../components/IdentificationChoice";
import {up} from "../util/urlPrefix";
import {useProgressBar} from "../redux/loadingPage";
import {useDispatch} from "react-redux";

const IdentificationStep = ({w}) => <IdentificationChoicePersonal
    i18nPrefix="personal.identificationSelection."
    onChoice={identificationType => w({identificationType})}/>;

IdentificationStep.propTypes = {
    w: PropTypes.func.isRequired
};

const ApplicationStep = ({ account, identificationType, onSentApplication }) => {
    const {start, complete} = useProgressBar(useDispatch());
    const onSubmit = account => {
        start();
        axios.post("/api/personal/application", account).then(onSentApplication).finally(complete);
    }
    return <ApplicationForm account={account} onSubmit={onSubmit} identificationType={identificationType}/>;
};

export const AppStepRoute = preloadGet("/api/personal", "account")(withRouter(({match, history, account}) => {
    const urlPrefix = up(match);
    const {start, complete} = useProgressBar(useDispatch());
    const onSubmit = account => {
        start();
        axios.post("/api/personal/application", account)
            .then(() => history.push(`${urlPrefix}/personal`))
            .finally(complete);
    }
    return <ApplicationForm account={account} onSubmit={onSubmit} identificationType={match.params.type}/>;
}));

const AppStep = preloadGet("/api/personal", "account")(ApplicationStep);

const PreApply = ({onSentApplication}) => <Wizard commonProps={{onSentApplication}} steps={[
    IdentificationStep,
    AppStep
]}/>;

PreApply.propTypes = {
    onSentApplication: PropTypes.func.isRequired,
};

export default PreApply;