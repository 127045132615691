import React from 'react';
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import {useNotifications} from "../util/Notifications";
import {withRouter} from "react-router";
import "./Game.css";

// Можно было бы объединить с ChessGame.tsx,
// но необходимо в ПЦ изменять урл инициализации игры, придумывать что-то с параметрами и много всего

export const GameRedirect = withRouter(() => {
    const {t, i18n: {language}} = useTranslation();
    const auth = useSelector((state: ReduxState) => state.auth);
    const {addNotification} = useNotifications();

    React.useEffect(() => {
        if (auth && auth.user && auth.user.applicationExist) {
            addNotification({message: t('personal.applicationExist')});
            return;
        }

        axios.get("/api/play/change-session?type=SPORT_SB").then(resp => {
            queryAndUpdateAuth();
            location.href = resp.data.replace("{lang}", language);
        })
    }, []);

    return <div className={"white-fullscreen"}/>
});