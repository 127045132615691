import React from "react";

import axios from "axios";
import {compose} from "redux";
import { withTranslation } from "react-i18next";

import queryString from "query-string";
import classNames from "classnames";

import cupis from "../cupis/cupis";
import {preloadGet} from "../util/preload";
import PaymentForm from "./PaymentForm";
import URL from "url-parse";
import {withNotifications} from "../util/Notifications";
import {queryAndUpdateAuth} from "../Auth";
import {confirm} from "../util/ConfirmDialog";
import {connect} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import {setLoading, setUnmountDate} from "../redux/loadingPage";

function redirectParams(model, ok, paymentId) {
    return queryString.stringify({ type: model.type, amount: model.amount, ok: ok, id: paymentId });
}

function mapRequest(model, t, info) {
    const baseUrl = window.location.href.split("?")[0];
    const successUrl = `${baseUrl}?${redirectParams(model, true)}`;
    const failUrl = `${baseUrl}?${redirectParams(model, false)}`;
    return {
        type: model.type,
        clientCashout: model.amount,
        cupisMoneyAmount: model.type === 'WITHDRAW'? info.cupisMoneyAmount : model.amount,
        clientTax: info.clientTax,
        clubTax: info.clubTax,
        taxBase: info.taxBase,
        nonTaxable: info.nonTaxable,
        sendReceipt: model.sendReceipt,
        channel : model.channel,
        playerId: model.playerId,
        jackBet: model.jackBet,
        successUrl: successUrl,
        failUrl: failUrl,
        description: t(`payment.${model.type.toLowerCase()}.description`, { amount: model.amount })
    };
}

function createCupis(cupisUrls) {
    const urls = Object.keys(cupisUrls).reduce((obj, k) => { obj[k] = new URL(cupisUrls[k]); return obj; }, {});
    return cupis({
        domain: urls.deposit.origin,
        frameDomain: urls.depositFrame.origin,
        siteRefillPath:  urls.deposit.pathname,
        frameRefillPath: urls.depositFrame.pathname,
        siteWithdrawPath: urls.withdraw.pathname,
        frameWithdrawPath: urls.withdrawFrame.pathname
    });
}

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type : null,
            jackBetStep : null,
            showPerformedPayment: false
        }
        //this.state = {allowInput : false};
    }

    componentDidMount() {
        const {type, location} = this.props;
        const paramsOk = queryString.parse(location.search).ok === "true";
        this.setState({showPerformedPayment:paramsOk});
        this.setState({type: type});
        this.requestChannels(type);
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            const {type} = this.props;
            this.setState({type: type});
            this.requestChannels(type);
            this.isPlayerStatusOk();
        }
        if (this.props.location.search !== prevProps.location.search) {
            const { location } = this.props;
            const params = queryString.parse(location.search);
            this.getPaymentErrorDescription(params.id);
        }
    }

    UNSAFE_componentWillMount() {
        const { location } = this.props;
         if (location.state !== undefined && location.state !== null) {
             this.setState({ jackBetStep: location.state.jackBetParams });
         }
        this.isPlayerStatusOk();
    }

    updateShowPerformedPayment = (value) => {
        this.setState({ showPerformedPayment: value });
    };

    isPlayerStatusOk = () => {
        const { t, blockedFields, notificationSystem } = this.props;
        if (blockedFields && blockedFields.length > 0) {
            notificationSystem.addNotification({
                message: t("payment.userConditionallyBlocked")
            });
            return false;
        }
        return true;
    };

    requestChannels = (type) => {
        const urls = {
            'DEPOSIT': 'api/payments/deposit',
            'WITHDRAW': 'api/payments/withdraw'
        };
        this.setState({channels : null});
        this.props.setUnmountDate({unmountDate: new Date(), loading: true})
        axios.get(urls[type])
            .then(response => this.setState({channels: response.data}))
            .finally(() => {
                this.props.setUnmountDate({unmountDate: new Date(), loading: false});
                this.props.setLoading({loading: false})
            });
    };

    getPaymentErrorDescription = (paymentId) => {
        if (paymentId) {
            axios.get("/api/payments/error-description?paymentId=" + paymentId).then(resp => {
                this.setState({paymentError: resp.data});
            })
        }
    }

    handlePaymentResponse = (model, requestId, paymentId) => {
        const { history, type } = this.props;
        const params = requestId
            ? queryString.stringify({
                requestId,
                type: model.type,
                amount: model.amount
            })
            : redirectParams(model, false, paymentId);
        history.push("/payment/" + type.toLowerCase() +"/?" + params);
    };

    showTaxDialog = (data, cashout) => {
        const {t, confirm} = this.props;
        if (Number(data.clientTax) === 0 && Number(data.ndfl) === 0) {
            return Promise.resolve(data);
        }
        const message = Number(data.clientTax) !== 0
            ? t("payment.tax.message", { cashout: cashout, tax: data.clientTax, amount: data.cupisMoneyAmount})
            : data.showNdfl
                ? t("payment.tax.selfPaidWithSum", { tax: data.ndfl})
                : t("payment.tax.selfPaidWithoutSum");
        return confirm
            .show({
                title: t("payment.tax.title"),
                body: message,
                ok: t("payment.tax.ok"),
                cancel: t("payment.tax.cancel")
            })
            .then(() => data);
    };

    submit = (model) => {
        const {t, notificationSystem, auth} = this.props;
        if (auth.user.applicationExist) {
            notificationSystem.addNotification({
                message: t('personal.applicationExist')
            });
            return;
        }
        if (!this.isPlayerStatusOk()) {
            return;
        }

        const check = (func) => {
            this.props.setUnmountDate({unmountDate: new Date(), loading: true});
            axios.get('api/payments/check', {params: {type: model.type, amount: model.amount}})
                .then(res => {
                    if (res.data.value === "OK") {
                        return Promise.resolve();
                    } else {
                        notificationSystem.addNotification({
                            message: t("payment.check." + res.data.value)
                        });
                        return Promise.reject();
                    }
                })
                .then(func)
                .catch(() => {
                    this.handlePaymentResponse(model, null);
                    this.props.setUnmountDate({unmountDate: new Date(), loading: false});
                    this.props.setLoading({loading: false})
                });
        }

        const complete = () => {
            const playerIdParam = model.type === "DEPOSIT" ? "" : "&playerId=" + model.playerId;
            const urlPrefix = model.type.toLowerCase();
            axios.get("api/payments/" + urlPrefix + "-info?withdraw=" + model.amount + playerIdParam)
                .then(resp => resp.data)
                .then(data => {
                    if (model.type === "DEPOSIT") {
                        return Promise.resolve(data)
                    }
                    this.props.setUnmountDate({unmountDate: new Date(), loading: false});
                    this.props.setLoading({loading: false});
                    return this.showTaxDialog(data, model.amount)
                })
                .then(data => axios.post("/api/payments", mapRequest(model, t, data)))
                .then(
                    resp => this.handlePaymentResponse(model, resp.data.requestId, resp.data.paymentId),
                    err => {
                        notificationSystem.addNotification({
                            message: t("payment.cupis.error." + err.data.errorCode)
                        });
                        this.handlePaymentResponse(model, null, null);
                    }
                )
                .finally(() => {
                    this.props.setUnmountDate({unmountDate: new Date(), loading: false});
                    this.props.setLoading({loading: false})
                });
        }
        check(complete);
    };

    render() {
        const {t, blockedFields, info, history, location, type} = this.props;
        const allowInput = !blockedFields || blockedFields.length === 0;
        const params = queryString.parse(location.search);
        if (params.ok) {
            params.ok = params.ok === "true";
        }

        if (params.requestId) {
            const c = createCupis(info.cupisUrls);
            const key = params.type === "DEPOSIT" ? "refill" : "withdraw";
            const callback = (ok) => () => queryAndUpdateAuth().then(() => history.push("/payment/" + type.toLowerCase() + "/?" + redirectParams(params, ok)));
            c[key](params.requestId, callback(true), callback(false));
            return null;
        }

        const title = t(this.state.jackBetStep ? "payment.title.jackBet" : "payment.title." + type.toLowerCase());

        return <div className={'row'}><div className="col-md-8 col-md-offset-2">

            {this.props.isMobile ? <MobileDualHeader firstHeader={'Управление счетом'} secondHeader={title}/> : <h2 className="text-center">{title}</h2>}
            {
                this.state.showPerformedPayment &&
                <div className={ classNames("alert", "alert-info") }>
                    { t(`payment.${params.type.toLowerCase()}.status.ok`, {amount: params.amount}) }
                </div>
            }
            {
                params.ok !== undefined && !params.ok && this.state.paymentError &&
                <div className={ classNames("alert", "alert-danger") }>
                    { t(`payment.fail.${this.state.paymentError.attempt}.${this.state.paymentError.name}`, {code: this.state.paymentError.code})}
                </div>
            }
            {
                this.props.paymentSuspended.value.paymentOperationsSuspended  &&
                <div className={ classNames("alert", "alert-danger") }>
                    { t(`payment.suspended.text`, {fromDate: this.props.paymentSuspended.value.fromDate, toDate: this.props.paymentSuspended.value.toDate})}
                </div>
            }
            <div className="col">
                <PaymentForm allowInput={allowInput && !this.props.paymentSuspended.value.paymentOperationsSuspended}
                             mailReceiptAvailable={info.mailReceiptAvailable}
                             minDeposit={info.minDeposit}
                             channels={this.state.channels}
                             accounts={info.accounts}
                             type={type}
                             jackBet={this.state.jackBetStep}
                             onSubmit={model => this.submit(model)}
                             isStatusOk={this.isPlayerStatusOk}
                             onShowPerformedPaymentChange={this.updateShowPerformedPayment}
                />
            </div>
        </div>
        </div>
    }
}

export default compose(
    confirm(),
    preloadGet("/api/auth/invalidated-fields", "blockedFields"),
    preloadGet("/api/account/details/info", "info"),
    preloadGet("/api/settings/paymentSuspended", "paymentSuspended"),
    withTranslation(),
    connect(state => ({isMobile: state.isMobileView, auth: state.auth})),
    withNotifications,
    connect(null, dispatch => ({
        setUnmountDate: (props) => dispatch(setUnmountDate(props)),
        setLoading: (props) => dispatch(setLoading(props)),
    })),
)(Payment);
