import React from "react";
import {useTranslation} from "react-i18next";
import {isInnValid} from "../util/InnValidator";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";
import {useNotifications} from "../util/Notifications";

export const InnConfirming: React.FC = () => {

    const {t} = useTranslation();
    const {addNotification} = useNotifications();
    const [inn, setInn] = React.useState<string>('');
    const [validInn, setValidInn] = React.useState<boolean>(false);

    React.useEffect(() => {
        axios.get('/api/register/inn')
            .then(response => {
                setInn(response.data.value);
                setValidInn(isInnValid(response.data.value));
            })
    }, []);

    const onChangeInn = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^[0-9\b]+$/;
        if ((value === '' || regExp.test(value)) && value.length <= 12) {
            setInn(value);
            setValidInn(isInnValid(value));
        }
    }

    const showInnError = () => {
        return !validInn && inn.length == 12;
    }

    const confirmInn = () => {
        console.log("confirm-inn!")
        axios.post('/api/register/inn/' + inn)
            .then(showCompleteMessage)
            .then(queryAndUpdateAuth)
    }

    const showCompleteMessage = () => {
        addNotification({
            level: 'success', message: t("identification.inn.confirmed")
        });
    }

    return <div>
        <h2 className="text-center">{ t("identification.inn.title") }</h2>
        <div className="text-center" dangerouslySetInnerHTML={{__html: t("identification.inn.message")}}/>
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-6 col-md-offset-2">
                <div className="form-group">
                    <label htmlFor="inn" className="col-md-4 text-right" style={{marginTop:'7px'}}>
                        { t('identification.inn.label') }
                    </label>
                    <div className="col-md-8">
                        <input id="inn" type="text" className="form-control" value={inn} onChange={onChangeInn}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 col-md-offset-2">
                <div className="form-group">
                    <div className="col-md-8 col-md-offset-4">
                        <div className="error">{ showInnError() ? t("identification.inn.error") : ''}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{marginTop: 20}}>
            <div className="col-md-6 col-md-offset-2">
                <div className="form-group">
                    <div className="col-md-8 col-md-offset-4">
                        <button type="button" onClick={() => confirmInn()} className="form-control btn btn-primary" disabled={!validInn}>
                            { t('identification.inn.confirm') }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

