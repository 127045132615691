import React from 'react';
import {getCurrencyShowType, getDenomination, getMainQuittanceData, getTopQuittanceData} from "../commons/helper";
import {useTranslation} from "react-i18next";
import {
    ChessBoardsCarousel,
    QuittanceGameWrapper,
    Time,
    ResultTimeTitle,
    StepSize,
    EventTimeTitle, CalculationTimeTitle, PrizeSize, StepDoneTitle, TextWrapper, TextBoldWrapper
} from "../commons/components";
import '../commons/style.css'

export const QuittanceSelectorEb = ({step, close, print}) => {
    return (<QuittanceSelector {...{step, close, print}} countOfPage={1} symbolsName={"symbolsExpressBet"}/>)
}

export const QuittanceSelectorSeb = ({step, close, print}) => {
    return (<QuittanceSelector {...{step, close, print}} countOfPage={2} symbolsName={"symbolsSeb"}/>)
}

const QuittanceSelector = ({step, close, print, symbolsName, countOfPage}) => {
    const state = step.events[0];
    const {session: {timezoneOffset, details: {game, rateDetails: {eventName, eventNumber}}}} = state;
    const {betAmount, winAmount, receiptNumber, id} = step;
    const denomination = getDenomination(state);
    const currencyType = getCurrencyShowType(state);
    const topQuittanceData = getTopQuittanceData(state, receiptNumber, id, "rateDetails");
    const mainQuittanceData = getMainQuittanceData(state, betAmount, winAmount, topQuittanceData, 'isoftDetails');
    console.log({topQuittanceData, mainQuittanceData, denomination})
    return (
        <QuittanceGameWrapper
            stepNo={id}
            receiptNumber={mainQuittanceData.receiptNumber}
            {...{eventName, eventNumber, game, print, close}}
        >
            <ChessBoardsCarousel
                countOfPage={countOfPage}
                game={game}
                rateDetails={state.session.details.rateDetails}
                timezoneOffset={timezoneOffset}
            />
            <TextWrapper>
                <StepSize credits={mainQuittanceData.credits} scores={mainQuittanceData.scores} currencyType={currencyType}/>
                <Coefficients coefficients={mainQuittanceData.choiceCombination}/>
                <ResultTimeTitle/>
                <Time timeResult={topQuittanceData.stepTime}/>
            </TextWrapper>
            <TextBoldWrapper>
                <EventTimeTitle/>
                <Time timeResult={topQuittanceData.timeResult}/>
                <CalculationTimeTitle/>
                <Time timeResult={topQuittanceData.resultTime}/>
                <PrizeSize credits={mainQuittanceData.creditsResult} scores={mainQuittanceData.scoresResult} currencyType={currencyType}/>
            </TextBoldWrapper>
            <TextWrapper>
                <StepDoneTitle/>
            </TextWrapper>
            <SymbolsExpressBet symbols={mainQuittanceData[symbolsName]}/>
        </QuittanceGameWrapper>
    )
}

export const Coefficients = ({coefficients}) => {
    const {t} = useTranslation();
    return (<span>{t('quittance.igrosoft.coefficients', {coefficients})}</span>)
}

export const SymbolsExpressBet = ({symbols}) => {
    const {t} = useTranslation();
    return (<div className={"symbols-express-bet-wrapper"}>
        <span>{t('quittance.base.symbols')}</span>
        <div className={"symbols-express-bet"}>
            {symbols.map((s, i) => (
                <div key={i} className={s.color}>{s.symbol}</div>
            ))}
        </div>
    </div>)
}





