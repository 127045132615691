import {Reducer} from "redux";
import IdentificationType from "./IdentificationType";

export interface Settings {
    minAge: number;
    mainUrl: string;
    enMainUrl: string;
    anotherLkUrl: string;
    showAnotherLkUrl: boolean;
    identificationTypes: Array<IdentificationType>;
    delayForStartProgressBar: number;
    sleepLoadingPage: number;
}

export interface SetSettingsAction {
    type: "SET_SETTINGS";
    settings: Settings;
}

export function setSettings(settings: Settings): SetSettingsAction {
    return {
        type: "SET_SETTINGS",
        settings
    };
}

export const settingsDefault: Settings = {
    minAge: 0,
    mainUrl: "",
    enMainUrl: "",
    anotherLkUrl: "",
    showAnotherLkUrl: false,
    identificationTypes: [],
    delayForStartProgressBar: 500,
    sleepLoadingPage: 0
};
export const settingsReducer: Reducer<Settings, SetSettingsAction> = (state, action) => {
    return action.type === "SET_SETTINGS" ? action.settings : state || settingsDefault;
};
