enum IdentificationType {
    SKYPE = "SKYPE",
    TAMTAM = "TAMTAM",
    WHATSAPP = "WHATSAPP",
    PLACE = "PLACE",
    CLUB = "CLUB",
    EUROSET = "EUROSET",
    CONTACT = "CONTACT"
    // QUICK = "QUICK"
}

export default IdentificationType;
