import React from "react";
import { useRouteMatch } from "react-router";
import { up } from "../../util/urlPrefix";
import { SidebarItem } from "./SidebarItem";
import {useTranslation} from "react-i18next";

export function UnaithorithedSidebaritems() {
    const match = useRouteMatch();
    const {t} = useTranslation();
    return (
        <>
            <SidebarItem value={t("main.title")} href={`${up(match)}/login`}/>
        </>
    )
}