import { Reducer } from "redux";

export interface ToggleSidebarOpen {
    type: "TOGGLE_SIDEBAR_STATUS";
}

export const mobileSidebarOpenDefault: boolean = false;

export const mobileSidebarOpenReducer: Reducer<boolean, ToggleSidebarOpen> = (state = false, action) => {
    return action.type === 'TOGGLE_SIDEBAR_STATUS' ? !state : state;
};
