import React from 'react';
import {preloadGet} from "../../util/preload";
import {useTranslation} from "react-i18next";

export const LinksHelp = preloadGet('/api/tpl/links', 'links')(({links}: LinksHelpProps) => {
    const {t} = useTranslation();
    const i18n = "help.links.";
    return (<div>
        <h4>{t(i18n + 'title')}</h4>
        <ul style={{paddingLeft: '15px'}}>
            {Object.entries(links).map(obj => (
                <li key={obj[0]}>
                    <a href={obj[1]} target={"_blank"}>{t(i18n + obj[0])}</a>
                </li>
            ))}
        </ul>
    </div>)
})

type Links = {
    rulesOfBets: string;
    privacyPolicy: string;
    constituentDocuments: string;
    license: string;
}

type LinksHelpProps = {
    links: Links;
}