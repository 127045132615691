
export enum InformationUnit {
    B = "B",
    KB = "KB",
    MB = "MB",
    GB = "GB",
    TB = "TB",
    EB = "EB",
    KiB = "KiB",
    MiB = "MiB",
    GiB = "GiB",
    TiB = "TiB",
    EiB = "EiB"
}

const makeUnits = (keys: Array<InformationUnit>, base: number, init: number = 1) => keys.reduce(({ map, val }, k) =>
    ({ val: val * base, map: {...map, [k]: val }}), { val: init, map: {} }).map;

const infoUnits: { [k: string]: number } = {
    ...makeUnits([
        InformationUnit.B,
        InformationUnit.KB,
        InformationUnit.MB,
        InformationUnit.GB,
        InformationUnit.TB,
        InformationUnit.EB], 1000),
    ...makeUnits([
        InformationUnit.KiB,
        InformationUnit.MiB,
        InformationUnit.GiB,
        InformationUnit.TiB,
        InformationUnit.EiB], 1024, 1024)
};

export const infoUnitToBytes = (amount: number, unit: InformationUnit = InformationUnit.B): number => {
    const bytesInUnit = infoUnits[unit];
    if (!bytesInUnit) {
        throw new Error("Unknown unit: " + unit);
    }
    return amount * bytesInUnit;
};
