import React from "react";
import {useTranslation} from "react-i18next";
import { connect, useSelector } from "react-redux";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import {ReduxState} from "../redux/redux";
import {NavLink, RouteComponentProps} from "react-router-dom";
import {UPLink} from "../util/urlPrefix";

interface StateProps {
    mainUrl: string;
    minAge: number;
    returnUrl: "/register" | "/personal";
}

const ClubRegisterChoice: React.FunctionComponent<StateProps & RouteComponentProps> = ({ mainUrl, minAge, returnUrl }) => {
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const { t } = useTranslation();
    const i18nPrefix = "personal.identificationSelection.identification.clubPage.";
    const url = mainUrl + "/betting_shops";

    return <div>
        {isMobile && <MobileDualHeader firstHeader={t('register.title')} secondHeader={t(i18nPrefix + "title")}/>}
        {!isMobile && <div className="row">
            <div className="col-md-8 col-md-offset-2">
                <h2 className="text-center">{t(i18nPrefix + "title")}</h2>
            </div>
        </div>}

        <div>
            {!isMobile && <div dangerouslySetInnerHTML={{__html: t(i18nPrefix + "message_1", {url: url})}}/>}
            {isMobile && <div style={{marginLeft: "10px"}}>{t(i18nPrefix + "message_1_mobile")}</div>}
            <div style={{marginLeft: "10px"}}>{t(i18nPrefix + "message_2")}</div>
            <div style={{marginLeft: "10px"}}>{t(i18nPrefix + "message_3")}</div>
            <div style={{marginLeft: "10px"}}>{t(i18nPrefix + "message_4")}</div>
            <div style={{marginLeft: "10px"}}>{t(i18nPrefix + "message_5")}</div>
            <div>{t(i18nPrefix + "message_6", {age: minAge})}</div>
        </div>
        <div className="row">
            {
                isMobile && <div className="col-md-2 col-md-offset-5">
                    <a href={url} className="btn btn-primary btn-block btn-sm">
                        {t(i18nPrefix + "findClub")}
                    </a>
                </div>
            }

            <div className="col-md-2 col-md-offset-5">
                <UPLink to={returnUrl} className="text-bold btn btn-primary btn-selector">
                    <strong>{ t(i18nPrefix + "back") }</strong>
                </UPLink>
            </div>
        </div>
    </div>
};

export const ClubRegisterChoiceHOC = connect<StateProps, {}, {}, ReduxState>(
    state => ({ mainUrl: state.settings.mainUrl, minAge: state.settings.minAge, returnUrl: "/register" })
)(ClubRegisterChoice);

export const ClubPersonalChoiceHOC = connect<StateProps, {}, {}, ReduxState>(
    state => ({ mainUrl: state.settings.mainUrl, minAge: state.settings.minAge, returnUrl: "/personal" })
)(ClubRegisterChoice);
