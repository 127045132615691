import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { SessionType } from "../../redux/auth";

type MobileSportSessionProps = {
    closeSession: (e: React.MouseEvent) => void;
    sessionType: SessionType;
    handleClick: (e: React.MouseEvent) => void;
};

export function MobileSportSession({handleClick, closeSession, sessionType}: MobileSportSessionProps) {

    return <div>

        <div style={{marginTop: 5, marginBottom: 5}} onClick={(e) => handleClick(e)}>
            <ActiveGameTitle sessionType={sessionType} />
        </div>

        <button className="btn btn-primary btn-block btn-sm" onClick={(e) => closeSession(e)}>
            Завершить сессию
        </button>
    </div>
}


type ActiveGameTitleProps = {
    sessionType: SessionType;
}

function ActiveGameTitle({sessionType}: ActiveGameTitleProps) {

    const { t } = useTranslation();

    function sportTypeClassName(sessionType: SessionType) {
        let suffix = "";
        switch(sessionType) {
            case SessionType.SPORT_MB: suffix = "MB"; break;
            case SessionType.SPORT_SB: suffix = "SB"; break;
            default:
        }
        return "event-type-" + suffix;
    }

    return <div style={{marginBottom: 10, fontWeight: 'bold', marginTop: 10}}>


        <div style={{borderRadius: 5, backgroundColor: '#f3f3f3', padding: '13px 15px', boxShadow: '0 0 4px 0px #0000003b'}}>
            <span className={classNames("event-type-icon", sportTypeClassName(sessionType))}/>
            { t(`gameSelector.games.${sessionType}.alt`)}
        </div>
    </div>
}
