import axios from "axios";
import i18n from "i18next";
import queryString from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { queryAndUpdateAuth } from "../../Auth";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import { EventTypeAndGame, UserInfo } from "../../redux/auth";
import { ReduxState } from "../../redux/redux";
import { MobileGameSession } from "../mobile/MobileGameSession";
import { useHistory } from "react-router-dom";
import { MobileSportSession } from "../mobile/MobileSportSession";
import {useProgressBar} from "../../redux/loadingPage";

export function PlaySessions() {

    const user = useSelector((state: ReduxState) => state.auth.user);
    const history = useHistory();
    const { t } = useTranslation();
    const {start, complete} = useProgressBar(useDispatch());

    const closeSession = (e: React.MouseEvent) => {
        e.preventDefault();
        start();
        axios.post("/api/play/close-session").then(() => {
            queryAndUpdateAuth();
        }).finally(complete);
    };

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const sessionType = user ? user.sessionType : '';
        axios.get("/api/play/open-session?type=" + sessionType).then(resp => {
            const url = queryString.stringify({url : resp.data});
            history.push("/play/game?" + url +" &game=" + sessionType)
        });
    };

    function renderMobileSessions(user: UserInfo) {
        return user.sessionType === "ACTIVE_GAMES" || user.sessionType === "INACTIVE_GAMES"
            ? <MobileGameSession closeSession={closeSession} activeGames={user.activeGames}/>
            : <MobileSportSession closeSession={closeSession} handleClick={handleClick} sessionType={user.sessionType} />
    }

    return (
        <div>
            <MobileDualHeader firstHeader={t('play.sessions.title')}/>
            {
                user && user.sessionType !== 'NONE'
                    ? renderMobileSessions(user)
                    : <div>{t('play.sessions.noActiveSessions')}</div>
            }
        </div>
    )
}

