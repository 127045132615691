import React from 'react';
import { withTranslation } from "react-i18next";

class CirculationTime extends React.PureComponent {
    constructor(props) { 
       super(props);
    }
    render() {
        const {t} = this.props;
        return (<span>{t("regularKvitok.circulationTime")}</span>);
    }
}

export default withTranslation()(CirculationTime);
