import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import React, {ReactNode, useEffect, useState} from 'react';

export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}


export interface OkProps<Resp> {
    resp: AxiosResponse<Resp>;
    reload: () => void;
}

export interface SuccessProps<Resp> {
    data: Resp;
    reload: () => void;
}

export interface MethodHttpRequestProps<Resp, Req = any> {
    url: string;
    config?: AxiosRequestConfig;
    request?: Request;
    ok?: React.ComponentType<OkProps<Resp>>;
    success?: React.ComponentType<SuccessProps<Resp>>;
    error?: (err: AxiosError, reload?: () => void) => ReactNode;
    loading?: (() => ReactNode) | ReactNode;
}

export interface HttpRequestProps<Resp, Req = any> extends MethodHttpRequestProps<Resp, Req> {
    method: HttpMethod;
}

interface HttpRespState<T> {
    isError: false;
    resp: AxiosResponse<T>;
}

interface HttpErrorState {
    isError: true;
    err: AxiosError;
}

type HttpRequestState<T> = HttpRespState<T> | HttpErrorState | undefined;

const HttpRequest: React.FC<HttpRequestProps<any>> = <Resp, Req = any>({ url, config, method, request, ok, success, error, loading }: HttpRequestProps<Resp, Req>) => {
    const mergedConfig: AxiosRequestConfig = {
        ...(config ? config : {}),
        method,
        url,
        data: request
    };

    const [req, setReq] = useState(0);
    const [resp, setResp] = useState<HttpRequestState<Resp>>(undefined);

    useEffect(() => {

        axios.request<Resp>(mergedConfig)
            .then(resp => setResp({ isError: false, resp }), err => setResp({ isError: true, err }));

    }, [url, request, config, req]);

    const reload = () => setReq(req + 1);

    if (resp === undefined) {
        return typeof loading === "function" ? loading() : loading === undefined ? null : loading;
    } else if (resp.isError && error) {
        return error((resp as HttpErrorState).err, reload);
    } else if (!resp.isError && ok) {
        const Component = ok;
        return <Component resp={(resp as HttpRespState<Resp>).resp} reload={reload}/>;
    } else if (!resp.isError && success) {
        const Component = success;
        return <Component data={(resp as HttpRespState<Resp>).resp.data} reload={reload}/>;
    } else {
        return null;
    }
};

export const HttpPost: React.FC<MethodHttpRequestProps<any>> = <Resp, Req = any>(props: MethodHttpRequestProps<Resp, Req>) =>
    <HttpRequest method={HttpMethod.POST} {...props}/>;

export const HttpGet: React.FC<MethodHttpRequestProps<any>> = <Resp, Req = any>(props: MethodHttpRequestProps<Resp, Req>) =>
    <HttpRequest method={HttpMethod.GET} {...props}/>;

export const HttpPut: React.FC<MethodHttpRequestProps<any>> = <Resp, Req = any>(props: MethodHttpRequestProps<Resp, Req>) =>
    <HttpRequest method={HttpMethod.PUT} {...props}/>;

export default HttpRequest;