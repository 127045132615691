import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";

class Wizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { step: this.props.initialStep || 0, props: this.props.initialProps };
    }

    render() {
        const Step = this.props.steps[this.state.step];
        const w = (nextProps, offset = 1) => this.setState(prevState => ({ step: prevState.step + offset, props: nextProps }));
        return <Step { ...this.props.commonProps } { ...this.state.props } w={ w } />;
    }

    static propTypes = {
        steps: PropTypes.array.isRequired,
        commonProps: PropTypes.object,
        initialStep: PropTypes.number,
        initialProps: PropTypes.object
    }

}
export default Wizard;

export const Wizard = withRouter(({history, steps, commonProps, initialStep, initialProps}) => {
    const [state, setState] = React.useState({ step: initialStep || 0, props: initialProps });
    const Step = steps[state.step];
    const w = (nextProps, offset = 1) => {
        setState(prevState => ({ step: prevState.step + offset, props: nextProps }));
        history.replace(history.location.pathname + "/" + nextProps.identificationType)
    };
    return (<Step { ...commonProps } { ...state.props } w={ w } />)
});
