import React from "react";
import { useTranslation } from "react-i18next";

type QuittanceAdditionalInfoProps = {
    step: any;
}

export function QuittanceAdditionalInfo({ step }: QuittanceAdditionalInfoProps) {
    const { t } = useTranslation();

    if (!step || !step.events) {
        return (<span/>);
    }

    const { winAmount, denomination, currencyShowType } = step;
    if (!currencyShowType || currencyShowType === 'CURRENCY') {
        return (<span/>);
    }

    return (
        <div className="quittance-additional-info">
            <div className="quittance-additional-info-content">
                <span>{t("quittance.additional.credits", { denomination: denomination })}</span>
                <span>{t("quittance.additional.balance", { balance: String(Number(winAmount).toFixed(2)) })}</span>
            </div>
        </div>
    );

}