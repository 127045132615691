import React, {useEffect, useState} from "react";
import ReactTable, {SortingRule} from "react-table";
import axios, {CancelTokenSource} from "axios";
import "react-table/react-table.css";
import '../payment/mobile/mobile-account.css';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ColumnCellType, FilterParamsStateType} from "../history/Operations";
import {StepsStateType} from "../history/Steps";
import {ReduxState} from "../redux/redux";
import {useProgressBar} from "../redux/loadingPage";


type FilterableTableProps = {
    columns: ColumnCellType[];
    dataUrl: string;
    filterParams: FilterParamsStateType;
    needUpdate?: boolean;
    dataFilter?: () => void;
    defaultSort?: SortingRule[];
    disableReport?: () => void;
    handleShowDialog?: (el: any) => void;
    enableReport?: () => void;
    getTrProps?: (state: StepsStateType, rowInfo: any) => { onDoubleClick: () => void }
}

const FilterableTable = (props: FilterableTableProps) => {
    let {filterParams, defaultSort, disableReport, enableReport, dataUrl, columns, handleShowDialog, ...rest} = props;
    const {needUpdate = true} = props;
    const [data, setData] = useState<any[]>([]);
    const [pages, setPages] = useState(-1);
    const [page, setPage] = useState(0);
    const [sorted, setSorted] = useState(defaultSort ? defaultSort : [{id: 'id', desc: true}]);
    const [fetchCancellable, setFetchCancellable] = useState<CancelTokenSource>();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const {t} = useTranslation();
    const {start, complete} = useProgressBar(useDispatch());

    const fetchData = ({page, sorted}: { page: number, sorted: SortingRule[] }) => {
        cancelFetch();
        const source = axios.CancelToken.source();
        start();
        axios.post(dataUrl, {...filterParams, types: filterParams.types.map(t => t.value)}, {
            cancelToken: source.token,
            params: {page, sort: encodeSort(sorted), pageSize: 20}
        }).then(({data: {content, totalPages: pages, numberOfElements}}) => {
            setData(content);
            setPages(pages);
            setFetchCancellable(source);
            if (numberOfElements !== 0) {
                disableReport && disableReport();
            } else {
                enableReport && enableReport();
            }
        }
        ).finally(complete)

    };

    const cancelFetch = () => {
        if (fetchCancellable) {
            fetchCancellable.cancel();
        }
    };

    const changePage = (newPage: number) => {
        setPage(newPage);
        fetchData({page: newPage, sorted})
    };


    const sortBy = (newSorted: SortingRule[], column: any, additive: boolean) => {
        setSorted(newSorted);
        setPage(0);
        fetchData({page: 0, sorted: newSorted})
    };

    useEffect(() => {
        setPage(0);
        if (needUpdate) {
            fetchData({page: 0, sorted});
        } else {
            setData([]);
        }
        return cancelFetch();
    }, [filterParams, needUpdate]);

    const labels = t('components.table', {returnObjects: true});

    if (isMobile) {
        return <div>

            {data && data.map(el => <table className={'mobile-account-table'} key={el.id}>
                <thead>

                </thead>
                <tbody onClick={() => handleShowDialog ? handleShowDialog(el) : null}>

                {columns && columns.map((colEl, id) => <tr key={id}>
                    <td className={'mobile-account-table-header'}>{colEl.Header}</td>
                    <td>{colEl.Cell({value: el[colEl.accessor], original: el})}</td>
                </tr>)}

                </tbody>
            </table>)}


        </div>
    } else {
        return <ReactTable
            manual
            {...rest}
            {...labels}
            {...{columns, page, pages, data}}
            showPageSizeOptions={false}
            onPageChange={changePage}
            onSortedChange={sortBy}
        />
    }
};

const encodeSort = (sorted: SortingRule[]) => (sorted && sorted.length > 0)
    ? sorted.map(({id, desc}) => "" + id + (desc ? ",desc" : ""))
    : null;


export default FilterableTable;
