const dataUriPrefix = "data:";
const base64Signature = ";base64,";

export interface DataUri {
    uri: string;
    base64: string;
    type: string;
}

function parseDataUri(uri: string): DataUri {
    return {
        uri,
        base64: uri.substring(uri.indexOf(base64Signature) + base64Signature.length),
        type: uri.substring(dataUriPrefix.length, uri.indexOf(";", dataUriPrefix.length))
    };
}

export default parseDataUri;
