import React, {FormEventHandler, useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {queryAndUpdateAuth} from "../Auth";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";
import {useTicker} from "../util/hooks";

import {SendResponse, VerifyResponse} from "./verificationTypes";
import moment from "moment";
import axios from "axios";
import {useProgressBar} from "../redux/loadingPage";

type RegisterVerifyPhoneProps = RouteComponentProps;

const RegisterVerifyPhone: React.FC<RegisterVerifyPhoneProps> = ({ location, history }) => {
    const { t } = useTranslation();
    const i18nPrefix = "register.verify.phone";
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const createResendAt = () => new Date().getTime() + 30 * 1000;
    const [status, setStatus] = React.useState<SendResponse>({} as SendResponse);
    const [req, setReq] = useState(0);
    const [code, setCode] = useState("");
    const [duration, setDuration] = useState(0);
    const [invalid, setInvalid] = useState<boolean | undefined>(false);
    const [resendAt, setResendAt] = useState<number>(createResendAt());
    const [verified, setVerified] = useState<'unknown' | 'verified' | 'notVerified'>('unknown');
    const {start, complete} = useProgressBar(useDispatch());

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        axios.get(`/api/register/phoneIsVerify${location.search}`, {cancelToken: cancelTokenSource.token})
            .then(res => setVerified(res.data ? 'verified' : 'notVerified'));
    }, [location.search]);

    useEffect(() => {
        if (req === 0) {
            return;
        }
        ticker.start()
        const cancelTokenSource = axios.CancelToken.source();
        setResendAt(createResendAt);
        axios.post(`/api/register/sendPhone${location.search}`, {},{
            cancelToken: cancelTokenSource.token
        }).then(resp => setStatus(resp.data));
        return () => cancelTokenSource.cancel();
    }, [location.search, req]);

    const resend = () => setReq(req + 1);

    const ticker = useTicker(() => {
        const d = Math.ceil(moment(resendAt).diff(moment()) / 1000);
        setDuration(d);
        if (d <= 0) {
            ticker.stop();
        }
    }, 500, false, [resendAt]);

    const onCodeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setCode(e.target.value.replace(/[^0-9]/g, ""));
        setInvalid(false);
    };

    const onSubmit: FormEventHandler = e => {
        e.preventDefault();
        setInvalid(undefined);
        start();
        axios.post<VerifyResponse>("/api/register/verifyPhone" + location.search + "&code=" + code)
            .then(resp => resp.data)
            .then(verification => verification.valid ? queryAndUpdateAuth() : Promise.reject("invalidCode"))
            .then(() => history.push("/"), reason => {
                setCode("");
                if (reason === "invalidCode") {
                    setInvalid(true);
                }
            })
            .finally(complete);
    };

    const onNext = () => Promise.resolve().then(queryAndUpdateAuth).then(() => history.push("/"));

    return <>
        {isMobile && <MobileDualHeader firstHeader={t('register.title')} secondHeader={ t(`${i18nPrefix}.title`) }/>}
        {!isMobile && <h2 className="text-center">{ t(`${i18nPrefix}.title`) }</h2>}

        {verified === "verified" && <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="text-center">{t(`${i18nPrefix}.already`)}</div>
                <div className="form-group">
                    <div className="col-md-8 col-md-offset-2">
                        <button type="button" onClick={onNext} className="form-control btn btn-default">
                            {t(`${i18nPrefix}.next`)}
                        </button>
                    </div>
                </div>
            </div>
        </div>}

        {verified === "notVerified" && <div className="row">
            <div className="col-md-6 col-md-offset-3">
                {req === 0 && <div>{t(`${i18nPrefix}.subtitle`)}</div>}
                {req > 0 && <Trans i18nKey={`${i18nPrefix}.check`}>
                    Check your phone <strong>{{phone: status.id}}</strong> for SMS code
                </Trans>}
                <form className="form-horizontal" style={{marginTop: 5}} onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="code" className="col-md-2 control-label">{t(`${i18nPrefix}.codeLabel`)}</label>
                        <div className="col-md-8">
                            <input id="code" type="text" className="form-control"
                                   value={code}
                                   maxLength={status.codeLength}
                                   onChange={onCodeChange}
                                   placeholder={t(`${i18nPrefix}.codePlaceholder`)}/>
                        </div>
                        <div className="col-md-8 col-md-offset-2 text-danger"
                             style={{visibility: invalid ? "visible" : "hidden"}}>
                            {t(`${i18nPrefix}.codeInvalid`)}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-md-8 col-md-offset-2">
                            <button type="button"
                                    disabled={duration > 0}
                                    onClick={resend}
                                    className="form-control btn btn-default">
                                {duration > 0
                                    ? t(`${i18nPrefix}.resendTimer`, {duration})
                                    : req === 0 ? t(`${i18nPrefix}.send`) : t(`${i18nPrefix}.resend`)}
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-md-8 col-md-offset-2">
                            <button type="submit"
                                    disabled={code.length !== status.codeLength || invalid === undefined}
                                    className="form-control btn btn-primary">
                                {t(`${i18nPrefix}.submit`)}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>}

    </>;
}

export default RegisterVerifyPhone;
