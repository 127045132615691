import React from 'react';
import { withTranslation } from "react-i18next";

class StepSize extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        const {kvitokData, currencyType, t} = this.props;
        return (<span>{
            t("regularKvitok.stepSize_" + currencyType, {
                CREDITS: kvitokData.CREDITS,
                BALLS: kvitokData.BALLS
            })
        }</span>);
    }
}

export default withTranslation()(StepSize);
