import React from "react";

import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import {compose} from "redux";

import {form} from "../validation/FormController";
import moment from "moment";
import NameBirthDateGenderForm from "../components/NameBirthDateGenderForm";
import AddressForm from "../components/AddressForm";
import PassportForm from "../components/PassportForm";
import deepEqual from "deep-equal";
import IdentificationForm from "../components/IdentificationForm";
import {preloadGet} from "../util/preload";
import {connect} from "react-redux";
import AdditionalInfoForm from "../components/AdditionalInfoForm";

const ApplicationForm = ({t, f, onSubmit, account}) => {
    function isAccountNotChanged(oldAcc, newAcc) {
        const oldWithoutIdentification = {...oldAcc};
        oldWithoutIdentification.identification = null;
        oldWithoutIdentification.address.address = null;

        const newWithoutIdentification = {...newAcc};
        newWithoutIdentification.identification = null;
        newWithoutIdentification.address.address = null;

        for (let key in newWithoutIdentification.address) {
            newWithoutIdentification.address[key] = newWithoutIdentification.address[key] === ''
                ? null
                : newWithoutIdentification.address[key];
        }
        return deepEqual(oldWithoutIdentification, newWithoutIdentification);
    }

    return (
        <form name="application" className="form-horizontal" onSubmit={f.submit(onSubmit)}>
            <h2 className="text-center">{t(f.i18nPrefix + "edit")}</h2>
            <div className="row">
                <div className="col-md-6">
                    <NameBirthDateGenderForm f={f}/>
                </div>
                <div className="col-md-6">
                    <IdentificationForm f={f}/>
                </div>
            </div>
            <PassportForm f={f} t={t}/>
            <AdditionalInfoForm f={f} t={t}/>
            <AddressForm f={f} t={t}/>
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <button className="btn btn-primary form-control"
                            type="submit"
                            disabled={isAccountNotChanged(account, f.model) || f.errors.length > 0}
                    >
                        {t(f.i18nPrefix + "submit")}
                    </button>
                </div>
            </div>
        </form>
    )
};

ApplicationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    f: PropTypes.func.isRequired,
    identificationType: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired
};

const i18nPrefix = "personal.";

export default compose(
    withTranslation(),
    connect(state => ({ minAge: state.settings.minAge })),
    form({
        i18nPrefix,
        initialModel: ({account, identificationType}) => {
            const model = JSON.parse(JSON.stringify(account));
            model.identification.type = identificationType;
            model.identification.status = null;
            model.citizenship = 'RUS';
            model.identification.callDate = moment();
            model.identification.meetingDate = moment();
            return model
        },
        schema: (account, {t, identificationType, minAge}) => {
            const now = moment();
            const birthDate = account.birthDate
                ? moment(account.birthDate, "YYYY-MM-DD")
                : null;

            return {
                ...NameBirthDateGenderForm.schema(now, t, i18nPrefix, minAge),
                ...PassportForm.schema(now, birthDate),
                ...AdditionalInfoForm.schema,
                ...AddressForm.schema,
                ...IdentificationForm.schema(account.identification.type),
            };
        }
    })
)(ApplicationForm);