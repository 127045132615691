import React from "react";
import {useTranslation} from "react-i18next";

import "./IdentificationChoice.css";
import classnames from "classnames";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import IdentificationType from "../redux/IdentificationType";
import {ReduxState} from "../redux/redux";
import {UPLink} from "../util/urlPrefix";
import {array} from "prop-types";
import {CaptchaWidget} from "../captcha/Captcha";

interface OwnProps {
    i18nPrefix: string;
    onChoice?: (type: IdentificationType, video?: Array<IdentificationType>) => void;
}

interface StateProps {
    minAge: number;
    types: Array<IdentificationType>;
    clubChoiceUrl: "/register/club" | "/personal/club";
}

type IdentificationChoiceProps = StateProps & OwnProps & RouteComponentProps;

const IdentificationChoice: React.FC<IdentificationChoiceProps> = ({ i18nPrefix = "", onChoice, types, minAge, clubChoiceUrl }) => {

    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const authenticated = useSelector((state: ReduxState) => state.auth.authenticated);
    const { t } = useTranslation();
    const match = useRouteMatch();
    const [captchaResolved, setCaptchaResolved] = React.useState<boolean>(false);
    const videoIdentTypes = Object.values(IdentificationType).filter(t => t === IdentificationType.TAMTAM || t === IdentificationType.WHATSAPP || t === IdentificationType.SKYPE);
    console.log('match.IdentificationChoice');
    console.log(match);


    const captcha = () => {
        return <div className="col-md-8 col-md-offset-2">
            <CaptchaWidget form="register" verified={ok => setCaptchaResolved(ok)}/>
        </div>
    }

    const choice = () => {
        return <>
            {
                !authenticated && <div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <p>{ t("identification.description", { age: minAge }) }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <p className="text-center">{ t(i18nPrefix + "identification.prompt") }</p>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-8 col-md-offset-2" style={{padding: 0}}>
                    { Object.values(IdentificationType).filter(t => !videoIdentTypes.includes(t) && types.indexOf(t) >= 0).map((type, index) =>
                        <div key={ type }
                             className={ classnames("col-md-4", index === 0 && types.length < 3 && "col-md-offset-" + ((3 - types.length) * 2)) }>
                            {type === IdentificationType.CLUB ?
                                <UPLink to={clubChoiceUrl} className="text-bold btn btn-primary btn-selector">
                                    {t(i18nPrefix + "identification.CLUB")}
                                </UPLink> :
                                onChoice ?
                                    <button className="btn btn-primary btn-selector" onClick={() => onChoice(type)}>
                                        { t(i18nPrefix + "identification." + type) }
                                    </button> :
                                    <UPLink to={{pathname: `/register/form`, search: `?type=${type}`}}
                                            className="text-bold btn btn-primary btn-selector">
                                        {t(i18nPrefix + "identification." + type)}
                                    </UPLink>
                            }
                        </div>
                    ) }
                    { videoIdentTypes.filter(t => types.indexOf(t) >= 0).length > 0 &&
                    <div className="col-md-4">
                        {
                            onChoice
                                ? <button className="btn btn-primary btn-selector" onClick={() => onChoice(videoIdentTypes[1])}>
                                    { t(i18nPrefix + "identification.VIDEOCALL") }
                                </button>
                                : <UPLink to={{pathname: `/register/form`, search: `?type=${videoIdentTypes.filter(t => types.indexOf(t) >= 0)}`}}
                                          className="text-bold btn btn-primary btn-selector">
                                    {t(i18nPrefix + "identification.VIDEOCALL")}
                                </UPLink>
                        }

                    </div>
                    }
                </div>
            </div>
        </>
    }


    return <div>
        {isMobile && <MobileDualHeader firstHeader={t('register.title')} secondHeader={t(i18nPrefix + "identification.title")}/>}
        {!isMobile && <div className="row">
            <div className="col-md-8 col-md-offset-2">
                <h2 className="text-center">{t(i18nPrefix + "identification.title")}</h2>
            </div>
        </div>}
        {
            !authenticated && !captchaResolved ? captcha() : choice()
        }
    </div>;
};

export const IdentificationChoiceRegister = connect<StateProps, {}, OwnProps, ReduxState>(
    state => ({ minAge: state.settings.minAge, types: state.settings.identificationTypes, clubChoiceUrl: "/register/club" })
)(IdentificationChoice);

export const IdentificationChoicePersonal = connect<StateProps, {}, OwnProps, ReduxState>(
    state => ({ minAge: state.settings.minAge, types: state.settings.identificationTypes, clubChoiceUrl: "/personal/club" })
)(IdentificationChoice);