import React, {useState} from "react";

import queryString, {ParsedQuery} from "query-string";
import {RouteComponentProps, withRouter} from "react-router";
import CloseSessionButton from "./CloseSessionButton";

import "./Game.css";

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";

type GameProps = RouteComponentProps;

const Game: React.FunctionComponent<GameProps> = ({location, history}) => {

    const [fullScreen, setFullScreen] = useState(false);
    const {i18n, t} = useTranslation();
    const parsedQuery = queryString.parse(location.search);
    const url = getQueryParam(parsedQuery, "url");
    const formattedUrl = url.replace("{lang}", i18n.language);
    const [chessUrl, setChessUrl] = useState<string | null>(null);
    const auth = useSelector((state: ReduxState) => state.auth);
    const sports = ['SPORT_RB', 'SPORT_MT', 'SPORT_MB', 'SPORT_SB'];
    const checkSession = (games: string[]) =>  auth && auth.user && games.includes(auth.user.sessionType);
    const isSport = checkSession(sports);
    const isSportOrChess = checkSession([...sports, 'CHESS_GAMES']);
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const title = isSportOrChess ? "sportSelector.title" : "gameSelector.title";

    React.useEffect(() => {

        queryAndUpdateAuth()
            .then(auth => {
                if (auth && auth.user && auth.user.activeGames && auth.user.activeGames.length > 0 && !isSport && isSportOrChess) {
                    const chess = auth.user.activeGames.find(a => a.eventType === 'CHESS_LIVE');
                    if (chess) {
                        axios.get("/api/play/open-session?type=" + auth.user.sessionType)
                            .then(resp => {
                                const params = "&subject=" + chess.eventType + "&wager=" + chess.gameIndex;
                                const url = resp.data.replace("{lang}", i18n.language) + params;
                                setChessUrl(url);
                            });
                    } else {
                        setChessUrl(null);
                    }
                }
                if (auth && auth.user && auth.user.sessionType === 'NONE') {
                    history.push("/play/sport");
                }
            })

    }, [url]);

    function getQueryParam(parsedQuery: ParsedQuery, param: string, defaultValue: string = "") {
        const val = parsedQuery[param];
        return Array.isArray(val) ? val.join("") : val || defaultValue;
    }

    function enableFullScreen() {
        setFullScreen(true)
    }

    return <div>
        <div className="row">
            <h2 className="text-center col-md-12">{t(title)}</h2>
        </div>
        {isSportOrChess
            &&
                <div className="between">
                    <CloseSessionButton/>
                    {isSport && (
                        <button type="button"
                                className="btn btn-default"
                                onClick={enableFullScreen}
                                title={t("sportSelector.fullScreen.hint")}
                        >
                            <span className="glyphicon glyphicon-resize-full"/> {t("sportSelector.fullScreen.label")}
                        </button>
                    )}
                </div>


        }
        <div className="row">
            <div className="col-md-12">

            </div>
        </div>
        <div className="row top-offset">
            <div className="col-md-12">
                <iframe allowFullScreen
                        src={chessUrl ? chessUrl : formattedUrl}
                        className={fullScreen ? "game-fullscreen" : isMobile ? "game-frame-mobile" : "game-frame"}
                />
            </div>
        </div>
    </div>;

};

export default withRouter(Game);