import React, { useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { ConfirmationRequestResultType } from "../login/AuthTypes";
import {form} from "../validation/FormController";
import PhoneConfirmationForm, {schema as phoneConfirmationSchema} from "../components/ConfirmationResend"

type VerifyFormProps = {
    doVerify: any;
    children: any;
    f: any;
}

type VerifyPhoneState = {
    code?: any;
    invalidCode?: any;
}

type VerifyPhoneProps = {
    onVerifyUrl: (code: any) => any;
    onVerified: (code: any) => void;
    confirmationRequestResult: ConfirmationRequestResultType;
    phone: string;
}

export function VerifyPhone(props: VerifyPhoneProps) {

    const {phone, onVerifyUrl, onVerified, confirmationRequestResult} = props;
    const [state, setState] = useState<VerifyPhoneState>({
        code: undefined
    });
    const [success, setSuccess] = useState(true);
    const { t } = useTranslation();

    const doVerify = (code: any) => {
        setState(prevState => ({...prevState, code: code}));
        axios.post(onVerifyUrl(code)).then(resp => {
            if (resp.data.ok) {
                onVerified(resp.data);
                setSuccess(true);
            } else {
                setState(prevState => ({ ...prevState, invalidCode: prevState.code }));
                setSuccess(false);
            }
        });
    }

    const onCodeResent = () => setState(prevState => ({...prevState, invalidCode: undefined}));

    return <VerifyForm invalidCode={state.invalidCode} doVerify={doVerify}>
        {(f: any) =>
            <div>
                <PhoneConfirmationForm
                    f={f}
                    resendUrl="/api/auth/resend-code"
                    resendParams={{phone}}
                    isRequired={true}
                    codeLength={6}
                    confirmationRequestResult={confirmationRequestResult}
                    onResent={onCodeResent}
                    success={success}
                />

                <div className="form-group">
                    <button className="btn btn-primary form-control" type="submit">
                        {t("verifyPhone.submit.label")}
                    </button>
                </div>
            </div>
        }
    </VerifyForm>;

}

const VerifyForm = form({
    i18nPrefix: "verifyPhone.",
    schema: (m, {invalidCode}) => phoneConfirmationSchema(true, 6, invalidCode),
})(class VerifyFormClass extends React.Component<VerifyFormProps> {
    render() {
        const {doVerify, children, f} = this.props;
        return (
            <form name="verify" className="form-horizontal" onSubmit={f.submit((model: any) => doVerify(model.code))}>
                {children(f)}
            </form>
        )
    }
});