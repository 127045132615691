import React from 'react';
import {withTranslation} from "react-i18next";
import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';

import math from "mathjs";

import './keno.css';

import {ChessBoard} from '../commons/comps/chess/ChessBoard';
import {isChessEventType} from '../commons/chess/ChessEventTypes';

import {dateFormatter, timeFormatter} from "../commons/DateTimeUtils";

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {print, close, t, receiptNo} = this.props;
        return (
            <div className="header section bg">
                <PrintButton print={print}/>
                <div>{t("quittance.keno.title", {receiptNumber: receiptNo})}</div>
                <div>{t("quittance.keno.subtitle")}</div>
                <CloseButton close={close}/>
            </div>
        );
    }
}

class Top extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {eventType, rateDetails, t, tz, stepNo} = this.props;
        const championship = rateDetails.championship;
        const tour = rateDetails.tour;
        return (
            <div className="top section">
                <div className={isChessEventType(eventType) ? "top-chess-section" : null}>
                    <div className={'bold-text'}>{t("quittance.keno.stepNumber") + " " + stepNo}</div>
                    <div className={'bold-text'}>{t("quittance.keno.eventNumber", {
                        eventName: rateDetails.eventName,
                        eventNumber: rateDetails.eventNumber
                    })}</div>
                    <div>{t("quittance.keno.championship") + ": " + championship}</div>
                    <div>{t("quittance.keno.tour") + ": " + tour}</div>
                </div>
                <div className={isChessEventType(eventType) ? "top-chess-section" : null}>
                    <div>{t("quittance.keno.eventTime")}</div>
                    <div>{dateFormatter(rateDetails.eventTime, tz)}</div>
                </div>
            </div>
        );
    }
}

class Middle extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {rateDetails, session, rate, t} = this.props;
        const eventType = session.details.eventType;
        const state = session.details.kenoDetails.state;
        const text = isChessEventType(eventType) ? 'quittance.keno.categorycoefficient' : 'quittance.keno.combination';
        return (
            <div className="middle section">
                <div>{t("quittance.keno.registrationTime")}</div>
                <div>{dateFormatter(rateDetails.registrationTime, session.timezoneOffset)}</div>
                <div>{t("quittance.keno.rate." + session.currencyShowType, rate)}</div>
                {
                    <div>
                        { t(text, {
                            value: state === 'REGULAR'
                                ? session.details.kenoDetails.regularDetails.cards.length
                                : session.details.kenoDetails.expressBetDetails.lastChoice === 'RED' ? 1 : 9
                        }) }
                    </div>
                }
            </div>
        );
    }
}

class Footer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, t, result} = this.props;
        const session = step.events[0].session;
        const rateDetails = session.details.rateDetails;
        const kenoDetails = session.details.kenoDetails;
        const eventType = session.details.eventType;
        const state = kenoDetails.state;

        let coef = math.fraction(
            session.details.kenoDetails.regularDetails.cards
                .filter(card => card.prize)
                .map(card => card.prizeCoefficient)
                .reduce((prev, cur) => prev + cur, 0),
            session.details.kenoDetails.regularDetails.cards.length)
            .toFraction(true);
        return (
            <div className="footer section">
                {
                    state === 'REGULAR'
                        ? <div className="table-wrapper">
                            <table className={isChessEventType(eventType) ? "chess-table" : null}>
                                <tbody>
                                {
                                    kenoDetails.regularDetails.cards.map((card, i) => {
                                        return (
                                            <tr key={'card' + i}>
                                                <td style={{width: "80px"}}>{t("quittance.keno.card.number", {number: i + 1})}</td>
                                                {
                                                    card.numbers.map((e, j) => {
                                                        return (
                                                            <td key={'number' + j} className={e.matchType}>{e.number}</td>)
                                                    })
                                                }
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        : null
                }
                <div className="footer-end">
                    <div>{t("quittance.keno.calculationTime")}</div>
                    <div>{dateFormatter(rateDetails.calculationTime, session.timezoneOffset)}</div>
                    {
                        isChessEventType(eventType)
                            ? <div dangerouslySetInnerHTML={{
                                __html: t("quittance.keno.coefficient", {
                                    coefficient:
                                        state === 'REGULAR'
                                            ? math.fraction(
                                            session.details.kenoDetails.regularDetails.cards
                                                .filter(card => card.prize)
                                                .map(card => card.prizeCoefficient)
                                                .reduce((prev, cur) => prev + cur, 0),
                                            session.details.kenoDetails.regularDetails.cards.length)
                                                .toFraction(true)
                                            : result.credits ? "2" : "0"
                                })
                            }}/>
                            : null
                    }
                    {
                        result.credits
                            ? <div>
                                <div>{t("quittance.keno.result." + session.currencyShowType, result)}</div>
                            </div>
                            : <div>{t("quittance.keno.noresult")}</div>
                    }

                </div>
            </div>
        );
    }
}

class Quittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, t, print, close} = this.props;
        const session = step.events[0].session;
        const eventType = session.details.eventType;

        if (!isChessEventType(eventType)) {
            console.log("Unknown keno EventType " + eventType);
            return <div/>
        }

        const denomination = step.denomination;
        const rate = {};
        rate.credits = Math.round(step.betAmount / denomination);
        rate.currency = step.betAmount;

        const result = {};
        result.credits = Math.round(step.winAmount / denomination);
        result.currency = step.winAmount;
        return <ChessQuittance step={step} print={print} close={close} t={t} rate={rate} result={result}/>
    }
}

class ChessQuittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, t, print, close, rate, result} = this.props;
        const session = step.events[0].session;
        const rateDetails = session.details.rateDetails;
        const symbols = session.details.kenoDetails.regularDetails.result.event.numbers.map(e => e.number);
        const state = session.details.kenoDetails.state;
        const receiptNo = step.receiptNumber;
        const stepNo = step.id;
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <div>
                    <div className={['quittance', 'keno', 'chess'].join(' ')}>
                        <Header print={print} close={close} t={t} rateDetails={rateDetails} receiptNo={receiptNo}/>
                        <Top eventType={session.details.eventType} rateDetails={rateDetails} t={t}
                             tz={session.timezoneOffset} stepNo={stepNo}/>
                        <Middle rateDetails={rateDetails} session={session} rate={rate} t={t}/>
                        <Footer step={step} rate={rate} result={result} t={t}/>
                        <div className="chess-symbols">
                            <div className="symbols-label">
                                {
                                    state === 'REGULAR'
                                        ? t("quittance.keno.chess.symbols")
                                        : t("quittance.keno.chess.symbol")
                                }
                            </div>
                            <div className="symbols-wrapper">
                                {
                                    state === 'REGULAR'
                                        ? symbols.map((group, i) => <div className="symbol"
                                                                         key={'group' + i}>{group}</div>)
                                        : <div
                                            className={[session.details.kenoDetails.expressBetDetails.history[0], 'ball'].join(' ')}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    backgroundColor: "#fef6ee", display: "flex",
                    justifyContent: "space-around", flexWrap: "wrap",
                    width: "450px"
                }}>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "10px"
                    }}>{t("quittance.keno.outcome")}</div>
                    {
                        rateDetails.chessInfos.map((data, i) => {
                            return <div className="keno chess-wrapper" key={i}>
                                <ChessBoard fen={data.fen}/>
                                <div className="chess-info">
                                    <div>{t("quittance.keno.chess.board", {board: i + 1})}</div>
                                    <div>{t("quittance.keno.chess.game", {game: data.matchId})}</div>
                                    <div>{t("quittance.keno.chess.move", {move: data.moveNumber})}</div>
                                    <div>{t("quittance.keno.chess.moveTime", {moveTime: timeFormatter(data.moveDate, session.timezoneOffset)})}</div>
                                    {
                                        state === 'REGULAR'
                                            ? <div>{t("quittance.keno.chess.sumAndGroup", {
                                                sum: data.sum,
                                                group: rateDetails.groups[i]
                                            })}</div>
                                            : <div>{t("quittance.keno.chess.sumOnly", {sum: data.sum})}</div>
                                    }

                                </div>
                                {
                                    state === 'REGULAR'
                                        ? <div
                                            className="chess-symbol">{t("quittance.keno.chess.symbol", {symbol: symbols[i]})}</div>
                                        : null
                                }
                            </div>
                        })
                    }
                    {
                        state === 'REGULAR'
                            ? null
                            : <div style={{
                                width: "100%", display: "flex", flexDirection: "row", border: "1px solid #F8B775",
                                margin: "20px 80px", justifyContent: "space-around", alignItems: "center"
                            }}>
                                <div>{t("quittance.keno.chess.sum", {sum: rateDetails.totalSum})}</div>
                                <div>{t("quittance.keno.chess.group", {group: session.details.kenoDetails.expressBetDetails.history[0] === 'RED' ? 1 : 2})}</div>
                            </div>
                    }
                </div>
            </div>

        )
    }
}

export default withTranslation()(Quittance);
