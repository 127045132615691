import React, {useContext, useEffect, useRef, useState} from "react";

import NotificationSystem, {Notification} from "react-notification-system";

export interface NotificationSystem {
    addNotification(n: Notification): void;
}

const noopNotificationSender: NotificationSystem = {
    addNotification: () => console.error("Notification System not initialized but want to show notifications")
};

const systemNotificationSender = (system: NotificationSystem.System): NotificationSystem => ({
    addNotification: n => {
        system.clearNotifications();
        system.addNotification({
            level: "error",
            position: "tc",
            autoDismiss: 10,
            ...n
        })
    }
});

const NotificationContext = React.createContext<NotificationSystem>(noopNotificationSender);

export const Notifications: React.FunctionComponent = ({ children }) => {
    const ref = useRef<NotificationSystem.System>(null);
    const [ ready, setReady ] = useState(false);

    useEffect(() => setReady(true), []);

    return <React.Fragment>
        { ready ? <NotificationContext.Provider value={systemNotificationSender(ref.current as NotificationSystem.System)}>
            { children }
        </NotificationContext.Provider> : null }
        <NotificationSystem ref={ref}/>
    </React.Fragment>;
};

export const useNotifications = (): NotificationSystem => useContext(NotificationContext);

export interface WithNotifications {
    notificationSystem: NotificationSystem;
}

/**
 * @deprecated {@link useNotifications} instead
 */
export const withNotifications = <OwnProps,>(Component: React.ComponentType<OwnProps & WithNotifications>) => (props: OwnProps) => {
    const system = useNotifications();
    return <Component {...props} notificationSystem={system}/>;
};

