import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { resultType } from "../validation/FormController";
import ErrorList from "./ErrorList";
import classNames from "classnames";
import {validationClass} from "./utils";
import LabelFor from "./LabelFor";

type LabelRadioProps = {
    f: resultType,
    id?: string
    styles?: object
}

function LabelRadio({ f, id = f.key, styles = {} }: LabelRadioProps) {
    const {t} = useTranslation();
    const fullKey = f.i18nPrefix + id;
    return <div className={classNames("form-group", validationClass(f))}>
        <LabelFor t={t} id={id} translateKey={fullKey} required={f.spec.required}/>

        <div className="col-md-8">
            {
                f.spec.values.map((val: any) => <label key={val} className="radio-inline">
                    <input type="radio" style={styles} name={id} value={val} checked={val === f.value} onChange={e => f.onChange(e.target.value)}/>
                    {t(`${fullKey}.values.${val}`, [`${id}.values.${val}`, `${id}.${val}`])}
                </label>)
            }
            <ErrorList f={f} id={id}/>
        </div>
    </div>;
}

// LabelRadio.propTypes = {
//     t: PropTypes.func.isRequired,
//     f: PropTypes.func.isRequired,
//     id: PropTypes.string
// };

export default LabelRadio;
