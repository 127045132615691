import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { MobileDualHeader } from "../../mobile-components/MobileDualHeader";
import { ReduxState } from "../../redux/redux";
import withReloading from "../../Reloading";
import { preloadGet } from "../../util/preload";
import MainRestrictions from "./MainRestrictions";
import RestrictedMessage from "./RestrictedMessage";

type RestrictionsPropsTypes = {
    accountStatus: 'ACTIVE' | 'FROZEN' | 'BLOCKED' | 'PREREGISTER' | 'PHONE_NOT_CONFIRMED';
    doComponentReload: Function;
}

function Restrictions(props: RestrictionsPropsTypes) {
    const {t} = useTranslation();
    const { accountStatus, doComponentReload } = props;
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    return (
        <div>
            {isMobile ? <MobileDualHeader firstHeader={'Ограничения'}/> : <h2 className="text-center">{t('restrictions.title')}</h2>}
            {
                (accountStatus === 'ACTIVE')
                    ? <MainRestrictions doComponentReload={doComponentReload}/>
                    : <RestrictedMessage/>
            }
        </div>
    );

}

export default compose(
    withTranslation(),
    withReloading(),
    preloadGet("/api/personal", (account: any) => { return {accountStatus: account.status} })
)(Restrictions);
