import React from 'react';
import { useTranslation } from "react-i18next";
import LabelDateInput from "../../forms/LabelDateInput";
import RadioButtons from "../../forms/RadioButtons";
import { resultType } from "../../validation/FormController";

type FreezeFormPropTypes = {
    f: resultType;
    onSubmitFreeze: (freeze: any) => void;
}

export const MobileFreezeForm = ({f, onSubmitFreeze}: FreezeFormPropTypes) => {
    const { t } = useTranslation();
    if (f.submit) {
        return (
            <form name="account-restriction" onSubmit={f.submit(onSubmitFreeze)} style={{marginTop: 20}}>
                <p style={{textTransform: "uppercase"}}>{t('restrictions.freeze.title')}</p>
                <span>{t('restrictions.freeze.info')}</span>
                <div className="form-group">
                    <div style={{fontWeight: "bold"}}>{t('restrictions.limitsHeader')}</div>
                    <RadioButtons f={f('type')} styles={{marginTop: '1px'}}/>
                    <LabelDateInput f={f('date')}/>
                    <LabelDateInput f={f('time')} showTimeSelectOnly/>
                </div>

                <button className="btn btn-primary" style={{width: '100%'}}
                        disabled={!f.model.type}
                >
                    {t('restrictions.freeze.btn')}
                </button>
            </form>
        );
    } else {
        return null;
    }

};

