import React from 'react';
import axios from "axios";
import {queryAndUpdateAuth} from "../Auth";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import {useNotifications} from "../util/Notifications";
import {withRouter} from "react-router";
import "./Game.css";

export const ChessGame = withRouter(({match: {params: {game, variant, locale}}}) => {
    const {t} = useTranslation();
    const auth = useSelector((state: ReduxState) => state.auth);
    const {addNotification} = useNotifications();

    React.useEffect(() => {
        if (auth && auth.user && auth.user.applicationExist) {
            addNotification({message: t('personal.applicationExist')});
            return;
        }

        axios.get(`/api/play/create-session?type=CHESS&wager=${game}&lineCount=${variant}`).then(resp => {
            queryAndUpdateAuth();
            var chessGameIndex = game.replace('GAME', '');
            location.href = resp.data.replace("{lang}", locale) + `&subject=CHESS_LIVE&wager=${chessGameIndex}`;
        })
    }, []);

    return <div className={"white-fullscreen"}/>
});