import React, { Component } from 'react';
import PropTypes from "prop-types";
import {ChessBoard} from '../../commons/comps/chess/ChessBoard';
import { withTranslation } from "react-i18next";
import { dateFormatter, timeFormatter } from "../../commons/DateTimeUtils";

class KvitokNewChessBoardETCDatum extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getLocaleString = (prefix, a, args) => this.props.t(prefix + "." + a, args);

  formattedDate = (date) => {
    const {params} = this.props;
    return dateFormatter(date, params.timezone, this.getLocaleString);
  };

  render() {
    const {params} = this.props;
    const boldStyle = { fontWeight : "bold"};
    return (
        <div className="KvitokNewChessBoardETCDatum">
            <span className="kvitokNewChessDataEventNumber">
              {this.getLocaleString("kvitokNew", "eventNumber", {EVENT_NAME: params.eventName, EVENT_NUMBER: this.props.params.eventNumber})}
            </span>
            <div className="kvitokNewChessDataBoard">
              <div className="kvitokNewChessBoardWrapper">
                <ChessBoard fen={params.fen}/>
              </div>
              <div className="kvitokNewChessData">
                <span>{this.getLocaleString("kvitokNew", "chessBoard", {NUMBER: params.chessBoardNumber})}</span><br/>
                <span>{this.getLocaleString("kvitokNew", "gameNumber", {NUMBER: params.gameNumber})}</span><br/>
                <span>{this.getLocaleString("kvitokNew", "stepNumber", {NUMBER: params.stepNumber})}</span><br/>
                <span style={{width: "130px",display: "inline-block"}}>{this.getLocaleString("kvitokNew", "moveTime", {TIME: timeFormatter(params.moveTime, params.timezone)})}</span><br/>
                <span>{this.getLocaleString("kvitokNew", "sumAndGroup", {SUM: params.sum, GROUP: params.group})}</span><br/>
                <span style={boldStyle}> {this.getLocaleString("kvitokNew", "symbol", {NUMBER: params.group})}</span><br/>
              </div>
            </div>
            <div className="kvitokNewChessDataRest">
              <span>{this.getLocaleString("kvitokNew", "timeResult", {TIMERESULT: this.formattedDate(params.timeResult)})}</span><br/>
              <span style={boldStyle}> {this.getLocaleString("kvitokNew", "categoryOfCoeff", {NUMBER: params.categoryOfCoeff})}</span><br/>
              <span style={boldStyle}> {this.getLocaleString("kvitokNew", "timeChoose", {TIMECHOSE: this.formattedDate(params.timeChoose)})}</span><br/>
            </div>
        </div>
    );
  }
}

KvitokNewChessBoardETCDatum.contextTypes = {
  assets: PropTypes.object,
  consts: PropTypes.object
};

export default withTranslation()(KvitokNewChessBoardETCDatum);