import React from "react";

// import { Modal, ModalHeader, ModalFooter, ModalBody, ModalClose, ModalTitle } from "react-modal-bootstrap";
import ReactModal from "react-modal";

export interface ConfirmDialog {
    show(opts: Opts): Promise<void>;
    hide(ok: boolean): void;
}

export interface ConfirmProps {
    confirm: ConfirmDialog;
}

interface Opts {
    title?: string;
    body: string;
    ok?: string;
    cancel?: string;
    linkText?: string;
    linkUrl?: string;
}

interface State {
    opts: Opts | null;
}


export const confirm = <Props,>() => (Component: React.ComponentType<Props & ConfirmProps>) => class extends React.Component<Props, State> implements ConfirmDialog {

    promise?: Promise<void>;
    resolve?: () => void;
    reject?: () => void;

    constructor(props: Props) {
        super(props);
        this.state = { opts: null };
    }

    UNSAFE_componentWillMount() {
        ReactModal.setAppElement('body');
    }

    componentWillUnmount() {
        this.hide(false);
    }

    show(opts: Opts): Promise<void> {
        this.hide(false);

        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.setState({ opts });
        return this.promise;
    }

    hide(ok: boolean): void {
        if (!this.resolve || !this.reject) {
            return;
        }
        if (ok) {
            this.resolve();
        } else {
            this.reject();
        }
        this.promise = undefined;
        this.resolve = undefined;
        this.reject = undefined;
        this.setState({ opts: null });
    }

    render() {
        return <React.Fragment>
            <Component {...this.props} confirm={this}/>
            {
                this.state.opts &&
                    <ReactModal
                        isOpen={this.state.opts !== null}
                        onRequestClose={() => this.hide(false)}
                        className="Modal"
                        shouldCloseOnOverlayClick={true}
                    >

                        <div className="form-group row" style={{backgroundColor: "#e2e2e2", borderRadius : '4px'}}>

                            {
                                this.state.opts.title &&
                                <div className="text-center">
                                    <h4>{this.state.opts.title}</h4>
                                </div>
                            }
                            <div className="size-div">
                                <p>{this.state.opts.body}</p>
                            </div>
                            <div className="text-center">
                                <div>
                                    {
                                        this.state.opts.ok &&
                                        <button style={{margin: "5px"}} className="btn btn-primary" onClick={() => this.hide(true)}>
                                            {this.state.opts.ok}
                                        </button>
                                    }
                                    {
                                        this.state.opts.cancel &&
                                        <button style={{margin: "5px"}} className="btn btn-default" onClick={() => this.hide(false)}>
                                            {this.state.opts.cancel}
                                        </button>
                                    }
                                    {
                                        (this.state.opts.linkText && this.state.opts.linkUrl) &&
                                        <a style={{margin: "5px"}} className="btn btn-primary" href={this.state.opts.linkUrl}>
                                            {this.state.opts.linkText}
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </ReactModal>
            }
        </React.Fragment>;
    }
};

