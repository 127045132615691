import React, {Component} from 'react';
import PropTypes from "prop-types";
import Piece from './Piece';
import './chess-board.css';

export const ChessBoard = ({fen}) => {
    const symbols = getSymbols(fen);
    return (<div className="board">
        {symbols.map((s, i) => (
            <div className={['square', s.color].join(" ")} key={i}>
                {s.letter}
            </div>
        ))}
    </div>)
}

const fontFigures = {
    k: {letter: 'l', color: 'black'},
    q: {letter: 'w', color: 'black'},
    r: {letter: 't', color: 'black'},
    b: {letter: 'v', color: 'black'},
    n: {letter: 'm', color: 'black'},
    p: {letter: 'o', color: 'black'},

    K: {letter: 'l', color: 'white'},
    Q: {letter: 'w', color: 'white'},
    R: {letter: 't', color: 'white'},
    B: {letter: 'v', color: 'white'},
    N: {letter: 'm', color: 'white'},
    P: {letter: 'o', color: 'white'}
}

const getSymbols = (fen) => {
    const symbols = [];
    fen.split("").filter(s => s !== "/" && s !== " ").forEach(s => {
        const spaces = Number(s);
        if (!isNaN(spaces)) {
            for (let i = 0; i < spaces; i++) {
                symbols.push({letter: null});
            }
        } else {
            symbols.push(fontFigures[s]);
        }
    });
    return symbols;
}
