import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterableTable from "../components/FilterableTable";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";
import PickerInput from "./PickerInput";
import numeral from 'numeral';
import Modal from "react-modal";
import ReportButton from "../components/ReportButton";
import './modal.css'
import MultiSelect from "react-multi-select-component";
import {Reports} from "./Reports";

export type ColumnCellType = {
    Header: string;
    accessor: string;
    Cell: (row: any) => JSX.Element | null;
    width?: number;
    sortable?: boolean;
    className?: string;
    style?: object;
}

export type FilterParamsStateType = {
    from: moment.Moment;
    to: moment.Moment;
    type: string | null;
    types: Array<{label: string, value: string}>
};

export type OperationsStateType = {
    search: string;
    filterParams: FilterParamsStateType;
    showModal: boolean;
    modal: boolean;
    disableReportButton: boolean;
    email: string;
}

export function Operations() {

    const _date_time_format = 'DD.MM.YYYY HH:mm:ss';

    const [state, setState] = useState<OperationsStateType>({
        search: '',
        filterParams: {
            from: moment().startOf('day'),
            to: moment().endOf('day'),
            type: null,
            types: []
        },
        showModal: false,
        modal: false,
        disableReportButton: true,
        email: ''
    });
    const [hasNotCompletedReport, setHasNotCompletedReport] = React.useState(false);
    const [storagePeriod, setStoragePeriod] = React.useState(90);
    const [showData, setShowData] = React.useState(true);
    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    const callbackReport = (v: boolean) => setHasNotCompletedReport(v);

    useEffect(() => {
        axios.post("api/history/email")
            .then(res => setState(prev => ({ ...prev, email: res.data }) ));
        axios.get("api/history/storage-period").then(res => setStoragePeriod(res.data.value))
        return () => console.log('component Operations did unmount');
    }, []);

    function handleClick() {
        setState(prev => ({...prev, modal: true}));
    }

    const handleCloseWindow = () => {
        setState(prev => ({...prev, modal: false}));
    };

    const handleCloseModal = () => {
        setState(prev => ({...prev, showModal: false}));
    };

    const handleCloseConfirmationWindow = () => {
        setState(prev => ({...prev, modal: false}));
        // showAlert();
    };

    const handleDisableReportButton = () => {
        setState(prevState => ({...prevState, disableReportButton: false}));
    };

    const handleEnableReportButton = () => {
        setState(prevState => ({...prevState, disableReportButton: true}));
    };

    // START BLOCK with render column functions
    const renderReceiptNo = (row: any) => {
        return <div className="text-right">
            {
                row.original.jackbet && <span className="pa-icon pa-icon-jackbet"/>
            }
            {row.value}
        </div>
        // <span>{row.value}</span>;
    }
    const renderId = (row: any) => {
        const search = state.search;
        const value = row.value.toString().padStart(15, '0');

        if (search && search.length) {
            return <span dangerouslySetInnerHTML={{__html: value.replace(search, `<span style="color:#ab1919">${search}</span>`)}}/>
        }

        return <span>{value}</span>
    }
    const renderType = (row: any) => <span>{t('history.operations.table.type.' + row.value)}</span>;
    const renderDateTime = (row: any) => <span>{moment(row.value).format(_date_time_format)}</span>;
    const renderSum = (row: any) => row.original.changesBalance ? <span>{numeral(row.value).format('0.00')}</span> : null;
    const renderFee = (row: any) => {
        if (row.value == null) {
            return null;
        }

        if (row.original.changesBalance) {
            return <span>{numeral(row.value).format('0.00')}<br/>{row.original.channel}</span>
        }

        return null;
    };
    const renderTax = (row: any) => {
        if (row.value == null) {
            return null;
        }

        if (row.original.changesBalance) {
            return <span>{numeral(row.value).format('0.00')}</span>
        }

        return null;
    };
    const renderBalance = (row: any) => <span>{row.value ? numeral(row.value).format('0.00') : ''}</span>;
    const renderComment = (row: any) => <span>{t('history.operations.table.comment.' + row.original.type, {amount: Math.abs(row.original.sum)})}</span>;
    // END BLOCK with render column functions

    const makeColumns = (): Array<ColumnCellType> => {
        const i18nKeyPrefix = 'history.operations.table.headers.';

        return [{
            Header: t(i18nKeyPrefix + 'receiptNo'),
            accessor: 'receiptNo',
            Cell: renderReceiptNo,
            width: 100,
        }, {
            Header: t(i18nKeyPrefix + 'id'),
            accessor: 'id',
            Cell: renderId,
            width: 100,
        }, {
            Header: t(i18nKeyPrefix + 'type'),
            accessor: 'type',
            Cell: renderType,
            sortable: false,
            className: 'wrap'
        }, {
            Header: t(i18nKeyPrefix + 'dateTime'),
            accessor: 'timestamp',
            Cell: renderDateTime
        }, {
            Header: t(i18nKeyPrefix + 'sum'),
            accessor: 'sum',
            Cell: renderSum,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'tax'),
            accessor: 'tax',
            Cell: renderTax,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'fee'),
            accessor: 'fee',
            Cell: renderFee,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'balance'),
            accessor: 'balance',
            Cell: renderBalance,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'comment'),
            accessor: '',
            Cell: renderComment,
            sortable: false,
            className: 'wrap'
        }];
    };
    const columns = makeColumns();


    const onSearchChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({
            ...prev,
            search: value.length <= 15 ? value : prev.search,
            filterParams: {...prev.filterParams, search: value}
        }));
    };

    const onOperationTypeChange = (values: typeof state.filterParams.types) => {
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                types: values
            }
        }))
    };

    const checkDiffDates = (from: moment.Moment, to: moment.Moment) => {
        const days = moment.duration(to.diff(from)).asDays();
        setShowData(days <= storagePeriod);
    }

    const onFromDateChange = (time: moment.Moment): void => {
        if (time != null) {
            const from = time.startOf('day');
            checkDiffDates(from, state.filterParams.to)
            setState(oldState => ({
                ...oldState,
                filterParams: {
                    ...oldState.filterParams,
                    from
                }
            }));
        }
    };

    const onToDateChange = (time: moment.Moment): void => {
        if (time != null) {
            const to = time.endOf('day');
            checkDiffDates(state.filterParams.from, to)
            setState(oldState => ({
                ...oldState,
                filterParams: {
                    ...oldState.filterParams,
                    to
                }
            }));
        }
    };

    const getMaxDate = (): moment.Moment => {
        const to = state.filterParams.to;
        const now = moment();

        return moment.max(now, to);
    };

    const renderDateTimePicker = (innerKey: string, i18nFilter: string) => <ReactDatePicker
        showYearDropdown
        showMonthDropdown
        fixedHeight
        // minDate={getMinDate()}
        maxDate={getMaxDate()}
        selected={innerKey === 'to' ? state.filterParams.to : state.filterParams.from}
        onChange={innerKey === 'to'  ? onToDateChange : onFromDateChange}
        id={'operations-' + innerKey + '-date-picker'}
        customInput={<PickerInput label={t(i18nFilter + innerKey + '.label')}/>}
    />;


    const renderOptions = () => {

        const i18nType = 'history.operations.filter.type.';
        const types = [
            'PaymentDeposits', 'PaymentWithdraws', 'PaymentClientTax', 'PaymentPlayerStakes', 'PaymentPlayerWins'
        ].map(type => ({label: t(i18nType + type), value: type}))
        return (<>
                <label>{t(i18nType + 'label')}</label>
                <MultiSelect
                    options={types}
                    value={state.filterParams.types}
                    onChange={onOperationTypeChange}
                    labelledBy="Select"
                    disableSearch={true}
                    selectAllLabel={t(i18nType + 'ALL')}
                    overrideStrings={{
                        "allItemsAreSelected": t(i18nType + 'ALL'),
                        "selectSomeItems": t(i18nType + 'ALL'),
                    }}
                />
            </>
        )
    };

    const renderForm = () => {

        const i18nFilter = 'history.operations.filter.';
        const i18nSearch = 'history.operations.search.';

        if (isMobile) {
            return <form>

                <div className="form-group">
                    <div className="col">
                        {renderOptions()}
                    </div>
                </div>
                <label>{t(i18nFilter + 'period.label')}</label>
                <div className="form-group">
                    <div className="col">
                        {renderDateTimePicker('from', i18nFilter)}
                    </div>
                </div>
                <div className="form-group">
                    <div className="col">
                        {renderDateTimePicker('to', i18nFilter)}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="operations-search"
                           style={{visibility: 'hidden'}}>
                        {t(i18nSearch + 'label')}
                    </label>
                    <input type="text"
                           placeholder={t(i18nSearch + 'placeholder')}
                           value={state.search}
                           onChange={onSearchChange}
                           className="form-control"
                           id="operations-search"/>
                </div>

            </form>;
        } else {
            return <form>
                <div className="row">
                    <div className="form-group col-xs-3">
                        {renderOptions()}
                    </div>

                    <div className="col-xs-7 form-group">
                        <label>{t(i18nFilter + 'period.label')}</label>
                        <div>
                            <div className="col-xs-6">
                                {renderDateTimePicker('from', i18nFilter)}
                            </div>
                            <div className="col-xs-6">
                                {renderDateTimePicker('to', i18nFilter)}
                            </div>
                        </div>

                    </div>

                    <div className="form-group col-xs-2">
                        <label htmlFor="operations-search"
                               style={{visibility: 'hidden'}}>
                            {t(i18nSearch + 'label')}
                        </label>
                        <input type="text"
                               placeholder={t(i18nSearch + 'placeholder')}
                               value={state.search}
                               onChange={onSearchChange}
                               className="form-control"
                               id="operations-search"/>
                    </div>
                </div>
            </form>
        }
    };
    const i18nPrefix = 'history.operations.';
    return (
        <div>

            {isMobile ?
                <MobileDualHeader firstHeader={t('history.title')} secondHeader={t('history.operations.title')}/> :
                <h2 className="text-center">{t('history.operations.title')}</h2>}

            <Reports type={"OPERATIONS"} callback={callbackReport} days={storagePeriod}/>
            {renderForm()}
            <div>
                <button className="btn btn-primary btn-block btn-sm"
                        style={{width: isMobile ? '100%' : 150, marginBottom: 15}}
                        onClick={handleClick}
                        disabled={(state.disableReportButton && showData) || hasNotCompletedReport}>
                    {t('components.reportButton')}
                </button>
            </div>
            {hasNotCompletedReport && <h5><i>{t("history.operations.hasNotCompletedReport")}</i></h5>}
            {!showData && !hasNotCompletedReport && <h5><i>{t("history.gtStoragePeriod")}</i></h5>}
            <Modal isOpen={state.modal}
                   contentLabel="Step"
                   className="Modal"
                   overlayClassName="Overlay"
                   onRequestClose={handleCloseModal}
                   shouldCloseOnOverlayClick={true}
            >
                <div className="form-group row modal-window">
                    <div className="size-div">
                        {t(i18nPrefix + 'report',
                            {
                                from: state.filterParams.from.format('YYYY-MM-DD'),
                                to: state.filterParams.to.format('YYYY-MM-DD'),
                                email: state.email.toString()
                            })}
                    </div>
                    <div className="text-center size-div">{t(i18nPrefix + 'continue')}</div>
                    <div className="text-center">
                        <ReportButton
                            filterParams={{...state.filterParams, types: state.filterParams.types.map(t => t.value)}}
                            dataUrl="/api/history/operations/generateReport"
                            close={handleCloseConfirmationWindow}
                            closeModal={handleCloseWindow}
                            onOk={() => setHasNotCompletedReport(true)}
                        />
                    </div>
                </div>
            </Modal>

            <FilterableTable
                dataUrl="/api/history/operations"
                columns={columns}
                disableReport={handleDisableReportButton}
                enableReport={handleEnableReportButton}
                filterParams={state.filterParams}
                needUpdate={showData}
                defaultSort={[
                    { // the sorting model for the table
                        id: 'id',
                        desc: true
                    }
                ]}
            />

        </div>
    )

}