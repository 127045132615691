import React from 'react';
import {preloadGet} from "../util/preload";

type UsedeskWidgetProps = {
    usedesk: {
        value: string
    };
}

export const UsedeskWidget = preloadGet('/api/tpl/usedesk', 'usedesk')((props: UsedeskWidgetProps) => {

    React.useEffect(() => {
        if (props.usedesk.value.length > 0) {
            const script = document.createElement("script");

            script.src = props.usedesk.value;
            script.async = true;

            document.body.appendChild(script);
        }
    }, [props.usedesk.value])

    return <></>
});