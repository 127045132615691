import React from 'react';
import ReactModal from "react-modal";
import { withTranslation } from "react-i18next";
import {form} from "../validation/FormController";
import LabelInput from "../forms/LabelInput";
import {email, required, maxLength} from "../validation/functionalPlugins";
import {compose} from "redux";
import {useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import axios from "axios";

const EmailDialogView = ({
                             t, f,
                             isOpen,
                             success,
                             onRequestHide,
                             onChangeRequest,
                         }) => {
    const tr = (key) => t(f.i18nPrefix + key);
    let pokas = success;
    const isMobile = useSelector((state) => state.isMobileView);

    const onSubmit = (email) => {
        if (!success) {
            onChangeRequest(email);
        }
    };

    const onAfterOpen = () => {
        console.log("success: ", success);

        pokas = false;
        console.log("pokas", pokas);
        onRequestHide();
        // f.reset();
    };

    function renderFooterInner() {
        if (isMobile) {
            return <footer>
                <button className='btn btn-primary' style={{width: '100%', marginBottom: 15}}>
                    {tr('changeButton')}
                </button>
                <button className='btn btn-primary' onClick={onAfterOpen} style={{width: '100%'}}>отмена</button>
            </footer>;
        } else {
            return <footer>
                <button className='btn btn-primary'>
                    {tr('changeButton')}
                </button>
            </footer>
        }

    }

    function renderInner() {
        return <form className="form-horizontal" onSubmit={f.submit(onSubmit)}>
            <div className="form-group row modal-window">
                {!isMobile && <header>
                    <button onClick={onAfterOpen}>
                        <span class="glyphicon glyphicon-remove"/>
                    </button>
                    <h2>{tr('title')}</h2>
                </header>}
                <div>
                    <p>{tr('info')}</p>
                    <LabelInput f={f('email')} autoFocus type="email"/>
                    {
                        pokas
                            ? <div className="alert alert-success">
                                {tr('success')}
                            </div>
                            : null
                    }
                </div>
                {renderFooterInner()}
            </div>
        </form>;
    }

    if (isMobile) {
        return (
            <div>
                <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
                {renderInner()}
            </div>
        );
    } else {
        return (
            <div>
                <ReactModal isOpen={isOpen}
                            className="Modal"
                            overlayClassName="Overlay"
                            onRequestClose={onAfterOpen}>
                    {renderInner()}
                </ReactModal>
            </div>
        );
    }


};

const formDef = {
    i18nPrefix: 'personal.changeEmailDialog.',
    schema: (m, {emailInUse}) => (
        {
            email: [
                required(),
                email(),
                maxLength(255),
                {
                    asyncValidators: (val) => axios.get("/api/register/emailInUse", {params: {email: val}})
                            .then(resp => resp.data && "emailInUse")
                },
                {
                    asyncValidators: (val) =>
                        axios.get("/api/register/emailTemplated", {params: {email: val}})
                            .then(async resp => {
                                return !resp.data && "emailTemplated";
                            })
                }
            ]
        }
    )
};

export default compose(
    withTranslation(),
    form(formDef),
)(EmailDialogView);
