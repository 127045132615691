import React from 'react';
import { withTranslation } from "react-i18next";
import './style.css';
import { getPrizeFromLines } from './Helper';

class TotalLinePrize extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        const {prizeLines, currencyType, denomination, t} = this.props;
        const prizeFromLines = getPrizeFromLines(prizeLines);
        return (<span>
            {
                prizeFromLines
                    ? t("regularKvitok.resultStep_" + currencyType, {
                        CREDITS_RESULT: prizeFromLines,
                        BALLS_RESULT: String(Number(prizeFromLines * denomination).toFixed(2))
                        })
                    : t("regularKvitok.sebNoResult")
            }
        </span>);
    }
}

export default withTranslation()(TotalLinePrize);
