import ParseResult from "./ParseResult";
import {flatTruth} from "../util/utils";
import ValidationError from "./ValidationError";

export class RuleTarget {

    spec;
    model;
    props;
    schema;

    constructor(spec, schema, model, props) {
        this.spec = spec;
        this.schema = schema;
        this.model = model;
        this.props = props;
    }

}

export default class Rule {

    validators = [];
    parsers = [];
    formatters = [];
    beautifiers = [];
    uglifiers = [];
    asyncValidators = [];
    asyncDelay = null;
    spec = {};

    constructor(what) {
        this.merge(what);
    }

    merge(what) {
        if (!what) {
            return;
        }
        if (Array.isArray(what)) {
            what.forEach(e => this.merge(e));
        } else if (typeof what === "object") {
            Object.keys(this)
                .filter(k => what.hasOwnProperty(k) && what[k] !== null && what[k] !== undefined)
                .forEach(k => this[k] = Rule.mergeField(this[k], what[k]));
        } else if (typeof what === "function") {
            this.merge(what(this.spec));
        }
    }

    static mergeField(l, r) {
        if (Array.isArray(l)) {
            return [ ...l, ...flatTruth(r) ];
        }
        if (l && typeof l === "object") {
            return { ...l, ...r };
        }
        return r;
    }

    /**
     * Validates value for errors
     * @param modelValue {*} model value to validate
     * @param viewValue {*} view value to validate
     * @param target {RuleTarget} field specification
     * @returns {Array} array of validation errors
     */
    validate(modelValue, viewValue, target) {
        return flatTruth(this.validators.map(v => v(modelValue, viewValue, target))).map(ValidationError.of);
    }

    /**
     * Parses specified value to model.
     * This is needed to convert view model (what user type) to domain model.
     * For example this can be used to translate strings to: <ul>
     *     <li>numbers</li>
     *     <li>enums</li>
     *     <li>dates</li>
     *     <li>etc</li>
     * </ul>
     *
     * @param value {string} a value to parse (usually string)
     * @param target {RuleTarget} field specification
     * @returns ParseResult an object with parsed value or list of errors
     */
    parse(value, target) {
        for (let parser of this.parsers) {
            const result = parser(value, target);
            if (result.errors && (!Array.isArray(result.errors) || result.errors.length > 0)) {
                return ParseResult.withErrors(flatTruth(result.errors).map(ValidationError.of));
            }
            value = result.value;
        }
        return ParseResult.withValue(value);
    }

    /**
     * Formats specified domain value to view model.
     * This is needed to convert domain model to string.
     * For example this can be used to translate <ul>
     *     <li>numbers</li>
     *     <li>enums</li>
     *     <li>dates</li>
     *     <li>etc</li>
     * </ul> to string
     *
     * @param value {*} a value to format (can be any type)
     * @param target {RuleTarget} field specification
     * @returns {string} formatted string value
     */
    format(value, target) {
        return this.formatters.reduce((cur, fmt) => fmt(cur, target), value);
    }

    /**
     * Beautifies value to make it more readable for user.
     *
     * Examples:
     * <ul>
     *     <li>Phone formatting from +71231231231 to +7 (123) 123-12-31</li>
     *     <li>Money formatting (to add thousand separators) from 4312433.40 to 4 312 433.40</li>
     * </ul>
     *
     * @param value
     * @returns {*}
     */
    beautify(value, target) {
        if (value === null || value === undefined) {
            value = "";
        }
        const result = this.beautifiers.reduce((cur, fmt) => fmt(cur, target), value);
        return result === null || result === undefined ? "" : result;
    }

    /**
     * Uglify specified value. Opposite of [beautify]{@link Rule#beautify}.
     *
     * This is needed to prepare beautified value for parsing (using [parse]{@link Rule#parse} function)
     * because [beautify]{@link Rule#beautify} can make `value` unparseable.
     *
     * Examples:
     * <ul>
     *     <li>Phone formatting from +7 (123) 123-12-31 to +71231231231</li>
     *     <li>Money formatting (to add thousand separators) from 4 312 433.40 to 4312433.40</li>
     * </ul>
     *
     * @param value
     * @returns {*} ugly value (prepared for #parse)
     */
    uglify(value, target) {
        return this.uglifiers.reduce((cur, fmt) => fmt(cur, target), value);
    }

    asyncValidate(value, view, target) {
        return Promise.all(this.asyncValidators.map(v => v(value, view, target))).then(flatTruth).then(errors => errors.map(ValidationError.of));
    }

    toView(value, target) {
        return this.beautify(this.format(value, target), target);
    }

    static of(what) {
        if (what instanceof Rule) {
            return what;
        } else {
            return new Rule(what);
        }
    }

}


/*

Schema example:

{

    minDate: {
        type: "date",
        required: Boolean(model.maxDate),
        leq: model.maxDate
    },
    maxDate: {
        type: "date",
        required: Boolean(model.minDate),
        geq: model.minDate
    },
    money: {
        type: "money",
        required: true
    },
    login: {
        required: true,
        asyncDelay: 5
        async: login => fetch("api/users/" + login).
            then(resp => resp.status === 404
                ? Promise.resolve()
                : Promise.reject([isSuccessfulStatus(resp.status) ? "loginInUse" : "unknownError"]))
    },
    password: {
        required: true,
        pattern: /^[a-zA-Z0-9]{6,}$/
    },
    passwordRepeat: {
        required: Boolean(model.password)
        eq: model.password
    }

}

 */