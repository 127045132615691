import React from 'react';
import PropTypes from 'prop-types';
import LabelInput from "../../forms/LabelInput";
import LabelDateInput from "../../forms/LabelDateInput";
import ReadOnly from "../../forms/ReadOnly";
import {date} from "../../validation/functionalPlugins";
import { blockedConditionally } from "../../forms/utils";

const VisaForm = ({f, t, readOnly, blockedFields}) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-center">{t(f.i18nPrefix + "visaTitle")}</h4>
                </div>
            </div>
            <div className="row">
                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelInput f={f("foreignCitizen.visa.serial")} blockedConditionally={blockedConditionally('foreignCitizen.visa.serial', blockedFields)}/>
                    <LabelInput f={f("foreignCitizen.visa.number")} blockedConditionally={blockedConditionally('foreignCitizen.visa.number', blockedFields)}/>
                </ReadOnly>

                <ReadOnly className="col-md-6" readOnly={readOnly}>
                    <LabelDateInput f={f("foreignCitizen.visa.issueDate")} blockedConditionally={blockedConditionally('foreignCitizen.visa.issueDate', blockedFields)}/>
                    <LabelDateInput f={f("foreignCitizen.visa.endDate")} blockedConditionally={blockedConditionally('foreignCitizen.visa.endDate', blockedFields)}/>
                </ReadOnly>
            </div>
        </div>
    );
};

VisaForm.propTypes = {
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    blockedFields: PropTypes.array,
    readOnly: PropTypes.bool
};

VisaForm.schema = {
    "foreignCitizen.visa.serial": {},
    "foreignCitizen.visa.number": {},
    "foreignCitizen.visa.issueDate": date(),
    "foreignCitizen.visa.endDate": date()
};

export default VisaForm;