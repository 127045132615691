
export function flatten(list, result = []) {
    if (Array.isArray(list)) {
        list.forEach(e => flatten(e, result));
    } else {
        result.push(list);
    }
    return result;
}

export const flatTruth = (list, result) => flatten(list, result).filter(e => e);

export function splitByLength(v, maxLength, dir) {
    const result = [];

    let chunk = "";
    if (dir) {
        for (let i = 0; i < v.length; i++) {
            chunk += v[i];
            if (chunk.length >= maxLength) {
                result.push(chunk);
                chunk = "";
            }
        }
        if (chunk) {
            result.push(chunk);
        }
    } else {
        for (let i = v.length - 1; i >= 0; i--) {
            chunk = v[i] + chunk;
            if (chunk.length >= maxLength) {
                result.unshift(chunk);
                chunk = "";
            }
        }
        if (chunk) {
            result.unshift(chunk);
        }
    }
    return result;
}

export function thousandSeps(v, dir = false) {
    return splitByLength(v, 3, dir).join(" ");
}

function isDigit(ch) {
    return ch >= "0" && ch <= "9";
}

function isSign(ch) {
    return ch === "+" || ch === "-";
}

function appendNumChar(ch, pos, result) {
    if (ch === ".") {
        if (result.pointPos !== null) {
            throw new Error("Multiple floating point char at " + pos);
        }
        result.pointPos = result.magnitude.length;
    } else if (isDigit(ch)) {
        if (result.sign !== null) {
            throw new Error("Illegal sign position after " + (pos + 1));
        }
        result.magnitude += ch;
    } else if (isSign(ch)) {
        if (result.sign !== null) {
            throw new Error("Multiple sign char at " + pos);
        }
        result.sign = ch === "+";
    }
}

export function prepareNumber(num) {
    const result = {
        sign: null,
        pointPos: null,
        magnitude: ""
    };
    for (let i = num.length - 1; i >= 0; i--) {
        const ch = num[i];
        appendNumChar(ch, i, result);
    }
    result.sign = result.sign === null ? true : result.sign;
    result.pointPos = result.pointPos === null ? 0 : result.pointPos;
    return result;
}

export function compareMagnitudes(p1, p2) {
    const maxPos = Math.max(p1.pointPos, p2.pointPos);
    const limit = maxPos + Math.max(p1.magnitude.length - p1.pointPos, p2.magnitude.length - p2.pointPos);

    let result = 0;
    for (let i = 0; i < limit; i++) {
        const n1 = p1.magnitude[i - maxPos + p1.pointPos] || "0";
        const n2 = p2.magnitude[i - maxPos + p2.pointPos] || "0";
        if (n1 < n2) {
            result = -1;
        } else if (n1 > n2) {
            result = 1;
        }
    }
    return result;
}

export function compareBigNumbers(num1, num2) {
    const p1 = prepareNumber(num1);
    const p2 = prepareNumber(num2);
    if (!p1.sign && p2.sign) {
        return -1;
    } else if (p1.sign && !p2.sign) {
        return 1;
    }
    const magCmp = compareMagnitudes(p1, p2);
    return p1.sign ? magCmp : -magCmp;
}

export function parseNumber(num) {
    if (typeof num === 'number') {
        num = num + '';
    }

    const result = {
        sign: null,
        int: "",
        fp: null,
        format: (opts) => {
            const i = opts.thousandSeps ? thousandSeps(result.int) : result.int;
            const f = opts.decimalPlaces > 0
                ? (result.fp !== null ? result.fp.substring(0, opts.decimalPlaces) : result.fp)
                : opts.decimalPlaces === 0 ? null : result.fp;
            return (result.sign !== null ? result.sign ? "+" : "-" : "") + i + (f !== null ? "." + f : "");
        }
    };
    if (num) {
        for (let ch of num) {
            if (isSign(ch)) {
                if (result.sign !== null) {
                    continue;
                }
                result.sign = ch === "+";
            } else if (isDigit(ch)) {
                if (result.fp !== null) {
                    result.fp += ch;
                } else {
                    result.int += ch;
                }
            } else if (ch === ".") {
                if (result.int && result.fp === null) {
                    result.fp = "";
                }
            }
        }
    }
    return result;
}

export function preventDefault(handler) {
    return function (e, ...rest) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        handler(e, ...rest);
    };
}